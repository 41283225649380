import React from 'react';
import { Protect } from '@clerk/clerk-react';
import { Navigate, Outlet } from 'react-router-dom';

import LocalAdsChecker from '@components/LocalAdsChecker';

import { IndustryInsightsPage } from '../reports/IndustryInsights';

import AccessDenied from '../errors/AccessDenied';

import BusinessProfile from './BusinessProfile';
import LocalAds from './LocalAds';
import SWIS from './SWIS';
import ClicksToSales from './ClicksToSales';
import LocalListingsChain from './LocalListingsChain';
import LocalAdsChain from './LocalAdsChain';
import ProductLocator from './ProductLocator';

export const InsightsLayout = () => {
	return (
		<Protect permission="org:insights:read" fallback={<AccessDenied />}>
			<Outlet />
		</Protect>
	);
};

export const insightsRoutes = [
	// chain
	{ path: 'local-listings', element: <LocalListingsChain /> },
	{ path: 'local-ads', element: <LocalAdsChain /> },
	// both
	{
		path: ':shopId?',
		children: [
			{ path: 'product-locator', element: <ProductLocator /> },
			{ path: 'business-profile', element: <BusinessProfile /> },
			{ index: true, element: <Navigate to="local-listings" replace /> }
		]
	},
	// shop only
	{
		path: ':shopId',
		children: [
			{ path: 'industry', element: <IndustryInsightsPage /> },
			{ path: 'google-swis', element: <Navigate to="local-listings" replace /> },
			{ path: 'local-listings', element: <SWIS /> },
			{
				path: 'local-ads',
				element: (
					<LocalAdsChecker>
						<LocalAds />
					</LocalAdsChecker>
				)
			},
			{
				path: 'clicks-to-sales',
				element: (
					<LocalAdsChecker>
						<ClicksToSales />
					</LocalAdsChecker>
				)
			}
		]
	}
];
