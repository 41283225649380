import React from 'react';
import { useFormContext } from 'react-hook-form';
import { ActualButton as Button } from '@nearst/ui';
import channels from '@nearst/channel-definitions';
import Shopkeeper from '../../../Forms/Shopkeeper';
import UnsupportedMessage from '../../../Components/UnsupportedMessage';

import styles from '../Registration.module.scss';

const RegistrationBlock = ({ onSubmit, onValidationError, submitErrorMsg }) => {
	const methods = useFormContext();

	const {
		getValues,
		handleSubmit,
		formState: { isSubmitting, isSubmitted, isValid }
	} = methods;

	const country = getValues('country');
	const unsupportedCountry = !channels.google.requirements.countries.includes(country);
	// check for unsupported store type
	const types = getValues('types');
	const supportedStoreType = types === undefined || (Array.isArray(types) && (types?.includes('store') || types?.includes('shop')));
	const unsupportedStoreType = !supportedStoreType;
	// pre-fill hubspot data
	const company = getValues('name');

	const disabled = isSubmitting || (isSubmitted && !isValid);

	if (unsupportedCountry) {
		return (
			<UnsupportedMessage
				warning="The country you have selected is not supported."
				description={[
					'At the moment, Google does not support local inventory listings in your country.',
					"We recommend that you join our waitlist and we'll update you when this changes."
				]}
				callToAction={{ href: 'https://start.near.st/waitlist', label: 'Sign up to our waitlist' }}
			/>
		);
	}

	if (unsupportedStoreType) {
		return (
			<UnsupportedMessage
				warning="The business you have selected is not marked as a store."
				description={[
					'Thank you for your interest in NearSt.',
					"Unfortunately, we cannot utilise our technology to display other types of establishments via Google See What's in Store unless you sell physical items with industry-standard or custom barcodes."
				]}
				callToAction={{
					href: `https://start.near.st/signup-qualification${company ? `?company=${encodeURIComponent(company)}` : ''}`,
					label: 'Request a review from our team'
				}}
			/>
		);
	}

	return (
		<>
			<Shopkeeper />
			<p className={styles.validation}>{submitErrorMsg}</p>
			<Button disabled={disabled} onClick={handleSubmit(onSubmit, onValidationError)} primary>
				Start trial
			</Button>
			<p className={styles.disclaimer}>Your NearSt subscription will start after your inventory is live.</p>
		</>
	);
};

export default RegistrationBlock;
