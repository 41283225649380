import React from 'react';
import Select, { components } from 'react-select';
import ConnectButton from '../../ConnectButton';
import { getPosProviderDetail } from '../../utils';
import { showIntercomArticle } from '@services/Intercom/utils';

import styles from './Dropdown.module.scss';

const SelectOption = (props) => {
	return (
		<components.Option {...props}>
			<div>{props.data.label}</div>
			<small>{props.data.vendor}</small>
		</components.Option>
	);
};

const Dropdown = ({ selectedPOS, posList, onSelect, onSubmit }) => {
	const handleSelect = (selected) => {
		const pos = getPosProviderDetail(selected, posList);
		onSelect(pos);
	};

	return (
		<>
			<div className={styles.selectContainer}>
				<Select
					placeholder="Select a POS..."
					isLoading={!posList}
					options={posList || []}
					components={{
						ClearIndicator: () => null,
						IndicatorSeparator: () => null,
						Option: SelectOption
					}}
					styles={{
						control: (styles) => ({
							...styles,
							width: 240,
							borderColor: '#84898c',
							borderRadius: 0
						})
					}}
					onChange={handleSelect}
					value={selectedPOS && getPosProviderDetail(selectedPOS, posList)}
				/>
			</div>
			{selectedPOS && selectedPOS.value !== 'other' ? (
				<ConnectButton text="Start now" onConnect={onSubmit} />
			) : (
				<a
					onClick={(e) => {
						if (window.Intercom) {
							e.preventDefault();
							showIntercomArticle(7950090);
						}
					}}
					className={styles.link}
					href="https://support.near.st/en/articles/7950090-my-pos-is-not-on-the-list-of-current-integrations"
					target="_blank"
					rel="noreferrer"
				>
					Why can't I find my POS on the list of current integrations?
				</a>
			)}
		</>
	);
};

export default Dropdown;
