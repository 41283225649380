import React from 'react';
import papa from 'papaparse';
import { Stack, Switcher } from '@nearst/ui';
import * as Stats from '@components/StatsCard';
import { useInsightsDate } from '@services/InsightsDateProvider';
import { getDailySwisInsights } from '@services/insights';
import Toggle from '@components/Graphs/Toggle';
import DataWrapper from '@components/Graphs/DataWrapper';
import { useShop } from '@services/ShopProvider';
import InsightCardContainer from '../../components/InsightCardContainer';
import TimeSeriesGraph from '@components/Graphs/TimeSeriesGraph';
import { sumByDate, sumField } from '../../utils';

import styles from './FreeLocalListings.module.scss';

const Totals = ({ swisInsights }) => {
	const impressions = sumField('impressions', swisInsights.impressions);
	const impressionsLookback = sumField('impressions', swisInsights.impressionsLookback);
	const clicks = sumField('clicks', swisInsights.clicks);
	const clicksLookback = sumField('clicks', swisInsights.clicksLookback);
	const ctr = clicks && impressions ? ((clicks / impressions) * 100).toFixed(2) : 0;
	const previousCtr = clicksLookback && impressionsLookback ? ((clicksLookback / impressionsLookback) * 100).toFixed(2) : 0;

	return (
		<Switcher>
			<Stats.Block description="Views" currentValue={Number(impressions)} previousValue={Number(impressionsLookback)} />
			<Stats.Block description="Product Clicks" currentValue={Number(clicks)} previousValue={Number(clicksLookback)} />
			<Stats.Figure>
				{`${ctr}%`}
				<Stats.FigureDescription>
					CTR
					<Stats.Tooltip>
						Your click-through rate is a measure of how relevant your products are to the search terms
					</Stats.Tooltip>
					<Stats.PercentageChange previousValue={previousCtr} currentValue={ctr} />
				</Stats.FigureDescription>
			</Stats.Figure>
		</Switcher>
	);
};

const Chart = ({ swisInsights, measure }) => {
	const yAxis = measure === 'views' ? 'impressions' : 'clicks';
	const series = measure === 'views' ? swisInsights.impressions : swisInsights.clicks;
	const previousSeries = measure === 'views' ? swisInsights.impressionsLookback : swisInsights.clicksLookback;

	// sometimes, our previous period data might be shorter than our current period data
	const offset = previousSeries.length - series.length;

	return (
		<div className={styles.graphContainer}>
			<TimeSeriesGraph
				series={series.map((row, index) => ({
					date: row.date,
					value: row[yAxis],
					previousDate: previousSeries[index + offset]?.date,
					previousValue: previousSeries[index + offset]?.[yAxis]
				}))}
				yAxisLabel={measure === 'views' ? 'Views' : 'Clicks'}
				lineColor="#2aca79"
				dateAxis
			/>
		</div>
	);
};

const FreeLocalListings = ({ teaser = false, subtitle = '' }) => {
	const { shop, data } = useShop();
	const shopIds = shop?.id ? [shop.id] : data.map(({ id }) => id);
	const { selectedRange, startDate, endDate, to, from, fromStartOfWeekStr, toEndOfWeekStr, lookbackPeriod, interval } = useInsightsDate();

	const CreateCsvData = (impressions, clicks) => {
		const csv = impressions.map((item) => ({
			date: item.date,
			impressions: item.impressions,
			clicks: clicks.find((clickItem) => clickItem.date === item.date)?.clicks || 0
		}));
		return papa.unparse(csv);
	};

	return (
		<InsightCardContainer>
			{teaser ? (
				<Stack className={styles.teaser}>
					<Stats.Header>
						<div>
							<Stats.Title>
								Local Listings performance
								<Stats.Tooltip>
									With Google Local Listings, your products surface organically across Google Search, Images, Shopping,
									Maps, Lens and more.
								</Stats.Tooltip>
							</Stats.Title>
							<Stats.Subtitle>
								{selectedRange}
								{subtitle && ` | ${subtitle}`}
							</Stats.Subtitle>
						</div>
					</Stats.Header>
				</Stack>
			) : (
				<>
					<Stats.Header>
						<div>
							<Stats.Title>
								Local Listings performance
								<Stats.Tooltip>
									With Google Local Listings, your products surface organically across Google Search, Images, Shopping,
									Maps, Lens and more.
								</Stats.Tooltip>
							</Stats.Title>
							<Stats.Subtitle>
								{selectedRange}
								{subtitle && ` | ${subtitle}`}
							</Stats.Subtitle>
						</div>
					</Stats.Header>

					<DataWrapper
						query={[
							{
								id: shopIds,
								table: 'local_listings',
								columns: ['impressions', 'clicks', 'shop_id', 'date'],
								to,
								from
							},
							{
								id: shopIds,
								table: 'local_listings_weekly',
								columns: ['start_date', 'end_date', 'impressions', 'shop_id'],
								filter: [
									['start_date', '>=', fromStartOfWeekStr],
									['end_date', '<=', toEndOfWeekStr]
								],
								sort: [{ column: 'start_date', order: 'desc' }]
							},
							{
								id: shopIds,
								table: 'local_listings',
								columns: ['impressions', 'clicks', 'shop_id', 'date'],
								from: lookbackPeriod.from,
								to: lookbackPeriod.to
							},
							{
								id: shopIds,
								table: 'local_listings_weekly',
								columns: ['start_date', 'end_date', 'impressions', 'shop_id'],
								filter: [
									['start_date', '>=', lookbackPeriod.fromStartOfWeek],
									['end_date', '<=', lookbackPeriod.toEndOfWeek]
								],
								sort: [{ column: 'start_date', order: 'desc' }]
							}
						]}
					>
						{([swisDaily, swisWeekly, swisDailyLookback, swisWeeklyLookback]) => {
							const impressions = getDailySwisInsights(startDate, endDate, swisDaily, swisWeekly, shopIds);
							const impressionsLookback = getDailySwisInsights(
								new Date(lookbackPeriod.fromStartOfWeek),
								new Date(lookbackPeriod.toEndOfWeek),
								swisDailyLookback,
								swisWeeklyLookback,
								shopIds
							);
							const clicks = swisDaily.map((item) => ({ date: item.date, clicks: item.clicks }));
							const clicksLookback = swisDailyLookback.map((item) => ({ date: item.date, clicks: item.clicks }));
							const swisInsights = {
								impressions: sumByDate(impressions, interval),
								impressionsLookback: sumByDate(impressionsLookback, interval),
								clicks: sumByDate(clicks, interval),
								clicksLookback: sumByDate(clicksLookback, interval)
							};
							return (
								<>
									<Stack>
										<Stats.Download filename="google-free-local-listings" csv={CreateCsvData(impressions, clicks)} />

										<Totals swisInsights={swisInsights} />
										<Toggle
											initialMeasure={{ label: 'Views', value: 'views' }}
											measures={[
												{ label: 'Views', value: 'views' },
												{ label: 'Clicks', value: 'clicks' }
											]}
										>
											{(measure) => (
												<div className={styles.graphWrapper}>
													<Chart measure={measure.value} swisInsights={swisInsights} />
												</div>
											)}
										</Toggle>
									</Stack>
								</>
							);
						}}
					</DataWrapper>
				</>
			)}
		</InsightCardContainer>
	);
};

export default FreeLocalListings;
