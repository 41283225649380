import React, { useState } from 'react';
import { ActualButton as Button, TemplateModal, Modal, Icon, Loader } from '@nearst/ui';
import { applyCoupon } from '@services/billing';
import getSymbolFromCurrency from 'currency-symbol-map';

import styles from './Modals.module.scss';
import HubspotForm from '../../../components/HubspotForm';

const CouponStatus = ({ coupon }) => {
	const currencySymbol = getSymbolFromCurrency(coupon.currency || 'GBP');
	return (
		<p className={styles.success}>
			<span className={styles.activePromo}>
				<Icon>check_circle</Icon> Promo code active:
			</span>
			<span className={styles.name}>
				{coupon.name}{' '}
				{coupon.percent_off
					? `${coupon.percent_off}% off`
					: `${currencySymbol}
							${coupon?.amount_off / 100} off`}
			</span>
		</p>
	);
};

// used in Slider version
const PromoCodeModal = ({ shop }) => {
	const activeCoupon = false;

	const [promoModal, setPromoModal] = useState(false);
	const openPromoModal = () => setPromoModal(true);
	const closePromoModal = () => {
		setPromoCode(undefined);
		setCouponError(false);
		setPromoModal(false);
	};

	const [loading, setLoading] = useState(false);

	const [appliedCoupon, setAppliedCoupon] = useState();
	const [promoCode, setPromoCode] = useState();
	const [couponError, setCouponError] = useState(false);

	const applyPromoCode = () => {
		setCouponError(false);
		setLoading(true);
		applyCoupon(shop.id, promoCode)
			.then((data) => {
				setAppliedCoupon(data.coupon);
				closePromoModal();
			})
			.catch(() => setCouponError(true))
			.finally(() => setLoading(false));
	};

	return (
		<>
			{/* customer already has an active coupon that was created earlier */}
			{!appliedCoupon && activeCoupon && <CouponStatus coupon={activeCoupon} />}
			{/* newly activated coupon */}
			{appliedCoupon && <CouponStatus coupon={appliedCoupon} />}
			{!appliedCoupon && (
				<button className={styles.promoCode} onClick={openPromoModal}>
					Have a promo code?
				</button>
			)}

			<Modal isOpen={promoModal} onDismiss={closePromoModal} className={styles.couponModalContainer} aria-label="Enter a promo code">
				<div className={styles.titleContainer}>
					<h2>Enter promo code</h2>
					<button className={styles.exitButton} onClick={closePromoModal}>
						<Icon>clear</Icon>
					</button>
				</div>
				<div className={styles.contentContainer}>
					<p>If you have a promo code, enter it here.</p>
					<input
						type="text"
						value={promoCode}
						onChange={(e) => setPromoCode(e.target.value)}
						onKeyDown={(e) => e.key === 'Enter' && applyPromoCode()}
					/>
					{loading && <Loader />}
					{couponError && <p className={styles.errorMessage}>Sorry, that promo code can't be applied.</p>}
				</div>
				<div className={styles.actionsContainer}>
					<div className={styles.buttonsContainer}>
						<Button primary onClick={applyPromoCode} disabled={loading}>
							Continue
						</Button>
						<Button onClick={closePromoModal}>Cancel</Button>
					</div>
				</div>
			</Modal>
		</>
	);
};

// used in Card version
const CouponModal = ({ open, close, shopId, setAppliedCoupon }) => {
	const [loading, setLoading] = useState(false);

	const [promoCode, setPromoCode] = useState();
	const [couponError, setCouponError] = useState(false);

	const closeModal = () => {
		setPromoCode(undefined);
		setCouponError(false);
		close();
	};

	const applyPromoCode = () => {
		setCouponError(false);
		setLoading(true);
		applyCoupon(shopId, promoCode)
			.then((data) => {
				setAppliedCoupon(data.coupon);
				closeModal();
			})
			.catch(() => setCouponError(true))
			.finally(() => setLoading(false));
	};
	return (
		<Modal isOpen={open} onDismiss={closeModal} className={styles.couponModalContainer} aria-label="Enter a promo code">
			<div className={styles.titleContainer}>
				<h2>Enter promo code</h2>
				<button className={styles.exitButton} onClick={closeModal}>
					<Icon>clear</Icon>
				</button>
			</div>
			<div className={styles.contentContainer}>
				<p>If you have a promo code, enter it here.</p>
				<input
					data-cy="coupon_input"
					type="text"
					value={promoCode}
					onChange={(e) => setPromoCode(e.target.value)}
					onKeyDown={(e) => e.key === 'Enter' && applyPromoCode()}
				/>
				{loading && <Loader />}
				{couponError && <p className={styles.errorMessage}>Sorry, that promo code can't be applied.</p>}
			</div>
			<div className={styles.actionsContainer}>
				<div className={styles.buttonsContainer}>
					<Button primary onClick={applyPromoCode} disabled={loading}>
						Continue
					</Button>
					<Button onClick={closeModal}>Cancel</Button>
				</div>
			</div>
		</Modal>
	);
};

const TurnOffLIAModal = ({ show, close, cancelPlan }) => {
	return (
		<>
			<TemplateModal
				isOpen={show}
				onDismiss={close}
				title="Turn off Local Ads"
				confirmationAction={cancelPlan}
				cancel="Turn off Local Ads"
				data-cy={`local-ads-pause-modal`}
				aria-label="Turn off Local Ads"
			>
				<p>Your Local Ads plan will be turned off tomorrow morning</p>
			</TemplateModal>
		</>
	);
};

const ChangeLIAModal = ({ shop, budget, choosePlan, show, close }) => {
	return (
		<div>
			<Modal
				isOpen={show}
				onDismiss={() => {
					close();
				}}
				className={styles.couponModalContainer}
				aria-label="Switching to Local Ads"
				data-cy={`local-ads-change-modal`}
			>
				<div className={styles.titleContainer}>
					<h2>Updating your plan</h2>
					<button
						className={styles.exitButton}
						onClick={() => {
							close();
						}}
					>
						<Icon>clear</Icon>
					</button>
				</div>
				<div className={styles.contentContainer}>
					<p data-cy={'lia-modal-text'}>{`Your new ${budget}/day budget will start from tomorrow`}</p>
				</div>
				<div className={styles.actionsContainer}>
					<div className={styles.buttonsContainer}>
						<Button data-cy={`modal-confirm`} confirm onClick={choosePlan}>
							Confirm plan
						</Button>
						<Button onClick={() => close()}>Cancel</Button>
					</div>
					<PromoCodeModal shop={shop} />
				</div>
			</Modal>
		</div>
	);
};

const ConfirmLIAModal = ({ show, close }) => {
	return (
		<Modal
			isOpen={show}
			onDismiss={() => {
				close();
			}}
			className={styles.couponModalContainer}
			aria-label="Switching to Local Ads"
			data-cy={`local-ads-confirm-modal`}
		>
			<div className={styles.titleContainer}>
				<h2>Thanks for activating Local Inventory Ads!</h2>
				<button className={styles.exitButton} onClick={close}>
					<Icon>clear</Icon>
				</button>
			</div>
			<div className={styles.contentContainer}>
				<p data-cy={'lia-modal-text'}>
					To learn more about to get the most out of this feature please join our next webinar - register{' '}
					<a href="https://us02web.zoom.us/webinar/register/WN_EAJ5XKW6SKepLYtPxG6Pjg" target="_blank" rel="noopener noreferrer">
						here!
					</a>{' '}
				</p>
				Essential marketing training for tech-savvy retailers!
			</div>
			<div className={styles.actionsContainer}>
				<div className={styles.buttonsContainer}>
					<Button data-cy={`modal-confirm`} primary onClick={close}>
						Close
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export { PromoCodeModal, TurnOffLIAModal, ChangeLIAModal, ConfirmLIAModal, CouponModal };
