import React, { useContext } from 'react';
import * as Chart from 'recharts';
import * as utils from '../utils';
import { MeasureContext } from '..';
import styles from './PerformanceGraph.module.scss';
import { numberFormat } from '@components/Graphs/formatters';
import { useInsightsDate } from '@services/InsightsDateProvider';
import { eachDayOfInterval, format } from 'date-fns';
import { useShop } from '@services/ShopProvider';
import { TooltipContainer } from '@components/Graphs/Tooltip';
import { getDailyAdsSpend } from '@utils/localAdsPlans';

const calculateAverageDailyBudget = (startDate, endDate, plans, shopId) => {
	const budgets = getDailyAdsSpend(plans, startDate, endDate, [{ id: shopId }]).map((dailyPlan) => dailyPlan.budget);
	const averageDailyBudget = budgets.reduce((acc, cur) => acc + cur / budgets.length, 0);
	return averageDailyBudget;
};

const ChainPerformanceTooltip = ({ metrics, plans, insightType, label }) => {
	const { startDate, endDate } = useInsightsDate();
	const { data: shops } = useShop();
	const clicks = metrics['clicks'].find((metric) => metric.name === label)?.value;
	const shopId = metrics['impressions'].find((metric) => metric.name === label)?.shopId;
	const impressions = metrics['impressions'].find((metric) => metric.name === label)?.value;
	const averageDailyBudget = insightType === 'lia' ? calculateAverageDailyBudget(startDate, endDate, plans, shopId) : undefined;

	return (
		<TooltipContainer>
			<strong>{label}</strong>
			{insightType === 'lia' && (
				<p>
					Average budget:{' '}
					{averageDailyBudget?.toLocaleString('en', {
						style: 'currency',
						currency: shops[0]?.billingCurrency || 'GBP'
					})}{' '}
					per day
				</p>
			)}
			<p>
				{insightType === 'lia' ? 'Impressions' : 'Views'}: {impressions?.toLocaleString()}
			</p>
			<p>Clicks: {clicks?.toLocaleString()}</p>
		</TooltipContainer>
	);
};

const Performance = ({ metrics, org, insightType, plans = [] }) => {
	const { selectedMeasure } = useContext(MeasureContext);

	const data = metrics[selectedMeasure].sort((a, b) => b.value - a.value).slice(0, 15);
	const chartColor = insightType === 'lia' ? '#50a1ff' : '#2aca79';

	return (
		<div className={styles.chartContainer}>
			<Chart.ResponsiveContainer width="100%" height={325}>
				<Chart.BarChart
					aria-roledescription="Bar chart"
					style={{
						fontSize: '0.8rem',
						fontFamily: "'nearst-body', -apple-system, 'Helvetica Neue', 'Arial', sans-serif"
					}}
					margin={{ top: 5, right: 0, bottom: 0, left: -12 }}
					data={data}
				>
					<Chart.XAxis
						aria-roledescription="axis"
						aria-orientation="horizontal"
						aria-label="Shop name"
						dataKey="name"
						interval={0}
						tick={(props) => {
							const { x, y, payload } = props;

							return (
								<g className={styles.tick} transform={`translate(${x - payload.offset},${y})`} height={props.height}>
									<foreignObject textAnchor="middle" x={0} y={0} width={payload.offset * 2} height={100}>
										<div className={styles.tickContent} xmlns="http://www.w3.org/1999/xhtml">
											{utils.removeOrgPrefix(payload.value, org)}
										</div>
									</foreignObject>
								</g>
							);
						}}
					/>
					<Chart.YAxis
						yAxisId="value"
						aria-label={selectedMeasure}
						aria-roledescription="axis"
						aria-orientation="vertical"
						tickFormatter={numberFormat}
					></Chart.YAxis>

					<Chart.Tooltip
						cursor={{ fill: 'var(--grey-lightest' }}
						content={({ label }) => (
							<ChainPerformanceTooltip metrics={metrics} plans={plans} insightType={insightType} label={label} />
						)}
					/>

					<Chart.Bar
						yAxisId="value"
						dataKey="value"
						fill={chartColor}
						fillOpacity={1}
						aria-label="value"
						radius={[4, 4, 0, 0]}
						maxBarSize={50}
					/>
				</Chart.BarChart>
			</Chart.ResponsiveContainer>
		</div>
	);
};

export { Performance };
