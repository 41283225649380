import React from 'react';
import { format } from 'date-fns';
import * as Chart from 'recharts';

import Tooltip from '@components/Graphs/Tooltip';
import styles from './StackedColourGraph.module.scss';

const StackedColourGraph = ({ series, className, label = '' }) => {
	const data = Object.values(
		series.reduce((accumulator, current) => {
			current.dataset.forEach((item) => {
				if (!accumulator[item.label]) {
					accumulator[item.label] = { date: item.label };
				}
				accumulator[item.label][current.label] = item.value;
			});
			return accumulator;
		}, {})
	);

	return (
		<div className={`${styles.graph} ${className}`}>
			<Chart.ResponsiveContainer width="100%" height="100%">
				<Chart.LineChart
					data={data}
					style={{
						fontSize: '0.8rem',
						fontFamily: "'nearst-body', -apple-system, 'Helvetica Neue', 'Arial', sans-serif"
					}}
				>
					<Chart.XAxis
						aria-roledescription="axis"
						aria-orientation="horizontal"
						aria-label="date"
						allowDuplicatedCategory
						dataKey="date"
						interval="equidistantPreserveStart"
						tickFormatter={(date) => format(new Date(date), 'MMM d')}
					/>
					<Chart.YAxis
						tickFormatter={(v) => v.toLocaleString()}
						aria-label="Views"
						aria-roledescription="axis"
						aria-orientation="vertical"
						allowDuplicatedCategory
					>
						<Chart.Label angle={-90} value={label} position="insideLeft" />
					</Chart.YAxis>
					<Chart.Tooltip content={Tooltip} />
					{series.map((s) => (
						<Chart.Line
							key={s.label}
							type="monotone"
							dataKey={s.label}
							dot={false}
							strokeWidth={3}
							stackId={1}
							stroke={s.backgroundColor}
							fill={s.backgroundColor}
							fillOpacity={1}
						/>
					))}
				</Chart.LineChart>
			</Chart.ResponsiveContainer>
		</div>
	);
};

export default StackedColourGraph;
