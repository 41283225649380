import React from 'react';
import styles from './LocationAdsBudget.module.scss';

const LocationAdsBudget = ({ shop, mobile = false }) => {
	return (
		<div className={styles.adsBudget}>
			{mobile && 'Ads budget: '}
			{shop.totalAdsBudget?.toLocaleString('en', {
				style: 'currency',
				currency: shop.billingCurrency || 'GBP',
				minimumFractionDigits: 0
			})}
		</div>
	);
};

export default LocationAdsBudget;
