import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({ color = '#1A1919', height = '40px', ...props }) => {
	return (
		<svg height={height} viewBox="0 0 380 140" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g fill={color} fillRule="evenodd">
				<path d="M78.241 82.136V30h18.413v80.031H77.329L48.516 58.584v51.447H30V30h19.317l28.924 52.136M123.371 86.266c.344 6.632 3.09 12.232 12.232 12.232 6.061 0 9.04-2.742 10.174-6.283h17.157c-1.6 10.402-11.432 18.86-27.21 18.86-19.326 0-29.724-12.005-29.724-29.04C106 65.916 116.175 53 135.267 53c17.26 0 28.346 11.66 28.346 25.148 0 2.29 0 4.803-.568 8.118h-39.674zm0-10.059h23.095c0-7.089-4.682-11.547-11.32-11.547-6.861 0-11.775 4.114-11.775 11.547zM232.161 54.373v55.106H214.67v-7.773c-2.625 5.031-9.72 9.146-16.804 9.146-15.89 0-26.866-12.465-26.866-28.928C171 65.46 181.976 53 197.866 53c7.084 0 14.179 4.231 16.804 9.263v-7.89h17.491zM214.67 81.924c0-7.545-5.26-14.062-13.144-14.062-7.886 0-13.145 6.517-13.145 14.062 0 7.661 5.259 14.066 13.145 14.066 7.884 0 13.144-6.405 13.144-14.066zM323.314 62.519c0 7.592-6.526 10.324-14.521 10.324-9.133 0-14.793-4.198-14.793-11.59h9.998c0 3.398 2.132 4.729 5.129 4.729 2.457 0 4.189-.866 4.189-2.997 0-2.467-2.522-2.798-8.592-4.799-5.325-1.666-9.924-3.198-9.924-9.393 0-6.661 5.531-9.793 14.188-9.793 9.326 0 13.861 4.729 13.861 10.859h-10.064c0-2.663-1.397-4.194-4.198-4.194-2.057 0-3.798.932-3.798 2.863 0 2.332 2.803 2.733 8.202 4.53 5.659 1.665 10.323 3.197 10.323 9.461M350.247 70.635c-1.526.265-3.798.535-6.795.535-4.794 0-11.385-.6-11.385-11.329V46.119H327v-7.597h5.067V29h10.117v9.522h7.328v7.597h-7.328v11.46c0 4.729 1.873 5.459 4.403 5.459 1.268 0 2.729-.065 3.66-.2v7.797" />
				<path d="M10 130h360V10H10v120zM0 140h380V0H0v140z" />
				<path d="M282.578 70.814c-2.402-.572-3.889-.8-5.949-.8-9.837 0-16.129 4.919-16.129 18.531v21.505H243V54.915h17.5v8.922c2.518-5.148 9.037-9.837 17.044-9.837 1.83 0 3.433.227 5.034.915v15.899" />
			</g>
		</svg>
	);
};

const SmallLogo = ({ color = '#1A1919', height = '40px', ...props }) => {
	return (
		<svg
			height={height}
			viewBox="0 0 373 373"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}
		>
			<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Artboard" fill={color} fillRule="nonzero">
					<path
						d="M0,373 L0,0 L373,0 L373,373 L0,373 Z M26.6917258,346.357143 L346.308274,346.357143 L346.308274,26.6428571 L26.6917258,26.6428571 L26.6917258,346.357143 Z M99.205,246 L99.205,197.89 L139.835,197.89 L139.835,246 L173.155,246 L173.155,126.15 L139.835,126.15 L139.835,171.2 L99.205,171.2 L99.205,126.15 L65.885,126.15 L65.885,246 L99.205,246 Z M314.085,266.06 L288.755,233.76 C302.355,222.88 311.025,205.88 311.025,185.65 C311.025,149.61 283.825,124.28 249.655,124.28 C215.655,124.28 188.115,149.61 188.115,185.65 C188.115,221.69 215.655,247.19 249.655,247.19 C253.565,247.19 257.305,246.85 261.045,246.17 L274.305,266.06 L314.085,266.06 Z M249.655,216.25 C232.315,216.25 222.115,204.18 222.115,185.65 C222.115,166.78 232.315,154.88 249.655,154.88 C266.825,154.88 277.195,166.78 277.195,185.65 C277.195,204.18 266.825,216.25 249.655,216.25 Z"
						id="nearst-2018"
					></path>
				</g>
			</g>
		</svg>
	);
};

Logo.Small = SmallLogo;

Logo.propTypes = {
	color: PropTypes.string
};

export default Logo;
