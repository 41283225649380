import { differenceInDays, endOfYear, startOfQuarter, startOfYear, subDays, subMonths, subQuarters, subYears } from 'date-fns';
import { createStaticRanges } from 'react-date-range';

export const getDefaultDateRanges = ({ daysBehind }) => {
	return createStaticRanges([
		{
			label: 'Last 7 days',
			range: () => ({
				startDate: subDays(new Date(), 6 + daysBehind),
				endDate: subDays(new Date(), daysBehind)
			})
		},
		{
			label: 'Last 30 days',
			range: () => ({
				startDate: subDays(new Date(), 30 + daysBehind),
				endDate: subDays(new Date(), daysBehind)
			})
		},
		{
			label: 'Last 3 months',
			range: () => ({
				startDate: subMonths(subDays(new Date(), daysBehind), 3),
				endDate: subDays(new Date(), daysBehind)
			})
		},
		{
			label: 'Quarter to date',
			range: () => ({
				startDate: startOfQuarter(subDays(new Date(), daysBehind)),
				endDate: subDays(new Date(), daysBehind)
			})
		},
		{
			label: 'This year',
			range: () => ({
				startDate: startOfYear(new Date()),
				endDate: subDays(new Date(), daysBehind)
			})
		},
		{
			label: 'Last year',
			range: () => ({
				startDate: startOfYear(subYears(new Date(), 1)),
				endDate: endOfYear(subYears(new Date(), 1))
			})
		}
	]);
};

export const getDefaultComparisonRanges = ({ startDate, endDate }) => {
	const daysBetween = differenceInDays(endDate, startDate);
	return createStaticRanges(
		[
			{
				label: 'Previous period',
				range: () => ({
					startDate: subDays(startDate, 1 + daysBetween),
					endDate: subDays(startDate, 1)
				})
			},
			daysBetween <= 30 && {
				label: 'Previous month',
				range: () => ({
					startDate: subMonths(startDate, 1),
					endDate: subMonths(endDate, 1)
				})
			},
			daysBetween <= 90 && {
				label: 'Previous quarter',
				range: () => ({
					startDate: subQuarters(startDate, 1),
					endDate: subQuarters(endDate, 1)
				})
			},
			{
				label: 'Previous year',
				range: () => ({
					startDate: subYears(startDate, 1),
					endDate: subYears(endDate, 1)
				})
			}
		].filter(Boolean)
	);
};
