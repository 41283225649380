import React, { useState } from 'react';
import { Loader } from '@nearst/ui';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './UpgradePaymentForm.module.scss';
import LoadingButton from '../../../channels/SettingsPages/MetaCommerce/components/LoadingButton';

const UpgradePaymentForm = () => {
	const stripe = useStripe();
	const elements = useElements();
	const { productId, period } = useParams();
	let navigate = useNavigate();

	const [errorMessage, setErrorMessage] = useState(null);
	const [paymentLoading, setPaymentLoading] = useState(true);
	const [submitting, setSubmitting] = useState(false);

	const handleSubmit = async (event) => {
		event.preventDefault();

		setSubmitting(true);
		setErrorMessage(null);

		if (!stripe || !elements) {
			// Make sure to disable submission until Stripe.js has loaded.
			return;
		}

		const confirmStepUrl = `/upgrade/${productId}/${period}/confirm`;

		const { error, setupIntent } = await stripe.confirmSetup({
			elements,
			redirect: 'if_required',
			confirmParams: {
				// the redirect URL for customers that use redirect-based payment methods:
				return_url: import.meta.env.VITE_MYSHOP_URL + confirmStepUrl
			}
		});

		if (error) {
			setErrorMessage(error.message);
		} else if (setupIntent) {
			navigate(`${confirmStepUrl}?setup_intent=${setupIntent.id}&payment_method=${setupIntent.payment_method}`, { replace: true });
		}

		setSubmitting(false);
	};

	const options = {
		layout: {
			type: 'accordion',
			defaultCollapsed: false,
			radios: true,
			spacedAccordionItems: false
		}
	};

	return (
		<>
			<PaymentElement onLoaderStart={() => setPaymentLoading(false)} options={options} />
			{paymentLoading ? (
				<Loader />
			) : (
				<LoadingButton loading={submitting} onClick={handleSubmit} disabled={!stripe || submitting} className={styles.button}>
					Continue
				</LoadingButton>
			)}
			{errorMessage && <div className={styles.error}>{errorMessage}</div>}
		</>
	);
};

export default UpgradePaymentForm;
