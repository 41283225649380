import React from 'react';
import PropTypes from 'prop-types';

import Loader from '../Loader';

import './style.scss';

const SimpleTable = ({ fields, data, loading = false }) => {
	const renderTableHeader = () => {
		return (
			<tr>
				{fields.map((field) => (
					<th key={field.title}>{field.title}</th>
				))}
			</tr>
		);
	};

	const renderRows = () => {
		return data.map((row, index) => (
			<tr key={row.id || index}>
				{fields.map((field, fieldIndex) => (
					<td key={fieldIndex} style={field.style || {}}>
						{field.formatter(row)}
					</td>
				))}
			</tr>
		));
	};

	return (
		<div>
			<table className="simple-table">
				<thead>{renderTableHeader()}</thead>
				<tbody>{renderRows()}</tbody>
			</table>
			{loading && <Loader />}
		</div>
	);
};

SimpleTable.propTypes = {
	loading: PropTypes.bool,
	fields: PropTypes.array.isRequired,
	data: PropTypes.array.isRequired
};

export default SimpleTable;
