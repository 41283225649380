const placedEmail = (shop) => ({
	title: `Thanks for your order - Here are your next steps!`,
	message: `Hi there,

Thanks for your order. We've received it, and will let you know once it's ready for collection.

If you have any questions about your order or would like to cancel it, please get in touch with us by replying to this email or giving us a call on ${shop.publicPhoneNumber}.

Thanks,

${shop.name}`
});

const confirmationEmail = (shop) => ({
	title: `Your product is ready for collection!`,
	message: `Hi there,

Great news - Your order is ready for collection!

Make your way to the store, inform the team of your name, and we'll get your order right away. Looking forward to seeing you soon!

Any issues? Please call us on ${shop.publicPhoneNumber}.

Best wishes,

${shop.name}`
});

const cancellationEmail = (shop) => ({
	title: `Your order has been cancelled`,
	message: `Hi there,

Thanks so much for placing an order for collection with us.

Unfortunately, at this moment in time we're unable to fulfil your order and on this occasion we will need to cancel it - apologies for the inconvenience.

If you have questions or concerns regarding the cancellation of your order please feel free to get in touch and I'll be more than happy to help.

Best wishes,

${shop.name}`
});

/**
 * @param {string} template {'placed' | 'confirmation' | 'cancellation'}
 * @returns {object}
 */
export const defaultMessageConfig = {
	placed: placedEmail,
	confirmation: confirmationEmail,
	cancellation: cancellationEmail
};
