import React from 'react';
import { useFormContext } from 'react-hook-form';
import ErrorMessage from '../ErrorMessage';
import schema from '../../../schema';

import styles from '../Fields.module.scss';

const Terms = () => {
	const {
		register,
		formState: { errors }
	} = useFormContext();

	return (
		<label>
			<div className={styles.terms}>
				<span>
					<input
						className={`${styles.input} ${errors?.terms ? styles.invalid : ''}`}
						type="checkbox"
						{...register('terms', { required: true, validate: schema.terms })}
					/>
				</span>
				<p>
					I agree to the{' '}
					<a href="https://www.near.st/legal/retailer-terms" target="_blank" rel="noreferrer">
						Retailer Terms & Conditions
					</a>
					<span className={styles.required}>*</span>
				</p>
			</div>
			<ErrorMessage name="terms" error="Please agree to the terms and conditions before continuing." />
		</label>
	);
};

export default Terms;
