import React from 'react';
import { ActualButton, Card, Page, Switcher, InputGroup, TextInput } from '@nearst/ui';
import { Protect } from '@clerk/clerk-react';
import { debounce } from 'lodash';

import ImageInput from '@components/ImageInput';
import AuthorizationTooltip from '@components/AuthorizationTooltip';
import AccessDenied from '../../../errors/AccessDenied';
import newLocationContact from '@utils/newLocationContact';
import { useShop } from '@services/ShopProvider';
import { useCheckPermission } from '@services/auth/hooks';

import styles from './LocationProfile.module.scss';
import ProfileDefaultImage from '../../../../assets/default-profile.png';

const LocationProfile = () => {
	const { shop, updateShop } = useShop();
	const authorized = useCheckPermission('org:locations:manage');

	const debouncedUpdate = debounce(({ target }) => authorized && updateShop(shop.id, { [target.id]: target.value }), 200);

	return (
		<Protect permission="org:locations:read" fallback={<AccessDenied />}>
			<Page.Header>
				<h1>Location profile</h1>
				<p>Edit the basic details of the location, used across all channels.</p>
			</Page.Header>
			<Page.Section>
				<Card
					title="Contact details"
					description="Details of the main contact of the account to contact about payments and legals."
				>
					<InputGroup label="Main contact name" htmlFor="contactName">
						<AuthorizationTooltip authorized={authorized}>
							<TextInput id="contactName" defaultValue={shop.contactName} onChange={debouncedUpdate} disabled={!authorized} />
						</AuthorizationTooltip>
					</InputGroup>
					<InputGroup label="Contact email" htmlFor="contactEmail">
						<AuthorizationTooltip authorized={authorized}>
							<TextInput
								id="contactEmail"
								defaultValue={shop.contactEmail}
								onChange={debouncedUpdate}
								disabled={!authorized}
							/>
						</AuthorizationTooltip>
					</InputGroup>
				</Card>

				<Card title="Location details" description="Public information about your shop.">
					<InputGroup label="Shop name" htmlFor="name">
						<AuthorizationTooltip authorized={authorized}>
							<TextInput id="name" defaultValue={shop.name} onChange={debouncedUpdate} disabled={!authorized} />
						</AuthorizationTooltip>
					</InputGroup>
					<InputGroup label="Public phone number" htmlFor="publicPhoneNumber">
						<AuthorizationTooltip authorized={authorized}>
							<TextInput
								id="publicPhoneNumber"
								defaultValue={shop.publicPhoneNumber}
								onChange={debouncedUpdate}
								disabled={!authorized}
							/>
						</AuthorizationTooltip>
					</InputGroup>

					<div className={styles.helpText}>
						Want to change your shop address?{' '}
						<a href={`mailto:support@near.st?subject=Changing%20my%20shop%20address%20(${shop.legacyId})`}>Send us a message</a>
					</div>
				</Card>
				<Protect permission="org:locations:manage">
					<Switcher className={styles.imageUploaders}>
						<Card title="Favicon" description="Configure the icon shown for the shop page URL and your local listings.">
							<ImageInput
								displayName="Header image"
								field="iconImageUrl"
								placeholder="https://near.st/favicon.svg"
								containerId="header-image"
								imageClass={styles.profileFaviconPicture}
								authorized={authorized}
							/>
						</Card>
						<Card
							title="Location profile image"
							description="Help shoppers identify your location across all enabled channels."
						>
							<ImageInput
								displayName="Profile image"
								field="profileImageUrl"
								placeholder={ProfileDefaultImage}
								containerId="profile-image"
								imageClass={styles.profileShopPicture}
								authorized={authorized}
							/>
						</Card>
					</Switcher>

					<Card
						title="Use NearSt across your locations"
						description="Have another location? Sign it up for NearSt, and it will be added directly to your account."
					>
						<ActualButton onClick={newLocationContact}>Sign up a new location</ActualButton>
					</Card>
				</Protect>
			</Page.Section>
		</Protect>
	);
};

export default LocationProfile;
