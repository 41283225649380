import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSignIn, useClerk } from '@clerk/clerk-react';
import LoadingContainer from '@components/LoadingContainer';

export const Impersonate = () => {
	const [queryParams] = useSearchParams();
	const signInToken = queryParams.get('ticket');
	const { signIn, setActive } = useSignIn();
	const { signOut } = useClerk();
	let navigate = useNavigate();

	if (!signInToken) throw Error('No token provided!');

	useEffect(() => {
		const signInWithTicket = async () => {
			if (signInToken) {
				await signOut();

				const res = await signIn.create({
					strategy: 'ticket',
					ticket: signInToken
				});

				setActive({ session: res.createdSessionId }).then(() => {
					navigate('/home');
				});
			}
		};

		if (signIn) {
			signInWithTicket();
		}
	}, [signIn, signOut, setActive, navigate, signInToken]);

	return <LoadingContainer />;
};
