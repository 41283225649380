import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Protect } from '@clerk/clerk-react';

import { Page, ModernTabContainer, ModernTabContent } from '@nearst/ui';
import ErrorBoundary from '@components/ErrorBoundary';
import AccessDenied from '../../../errors/AccessDenied';
import BillingSettings from './BillingSettings';
import Invoices from './Invoices';

import './styles.scss';

const Billing = () => {
	let navigate = useNavigate();
	const location = useLocation();

	return (
		<Protect permission="org:billing:read" fallback={<AccessDenied />}>
			<div className="account-section">
				<Page.Header>
					<h1>Billing</h1>
					<p>Manage your billing settings and invoices.</p>
				</Page.Header>
				<Page.Section>
					<ModernTabContainer
						tabTitles={[<span key="settings">Billing settings</span>, <span key="invoices">Invoices</span>]}
						navigate={navigate}
						location={location}
					>
						<ModernTabContent key="settings">
							<ErrorBoundary>
								<BillingSettings />
							</ErrorBoundary>
						</ModernTabContent>
						<ModernTabContent key="invoices">
							<ErrorBoundary>
								<Invoices />
							</ErrorBoundary>
						</ModernTabContent>
					</ModernTabContainer>
				</Page.Section>
			</div>
		</Protect>
	);
};

export default Billing;
