import React from 'react';

import './style.scss';

const Stack = ({ children, space = '1.5rem', className = '', ...restOfProps }) => {
	return (
		<div style={{ '--space': space }} className={`nearst-layout__stack ${className}`} {...restOfProps}>
			{children}
		</div>
	);
};

export default Stack;
