import React from 'react';
import { HomeIcon } from '@heroicons/react/20/solid';

import Sidebar from '@components/Sidebar';

const Dashboard = () => {
	return (
		<Sidebar.Item to="/dashboard">
			<HomeIcon />
			Dashboard
		</Sidebar.Item>
	);
};

export default Dashboard;
