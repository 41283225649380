import * as geolib from 'geolib';

export function generateInitialBounds(shops) {
	const shopLocations = shops.map((shop) => ({ ...JSON.parse(shop.geolocation), shop }));
	const center = geolib.getCenterOfBounds(shopLocations);
	const shopDistances = geolib.orderByDistance(center, shopLocations);
	const maxDistance = geolib.getDistance(center, shopDistances[shopDistances.length - 1]);

	// 10% extra padding
	const withPadding = maxDistance + maxDistance * 0.1;
	const bounds = geolib.getBoundsOfDistance(
		{
			latitude: Number(center.latitude),
			longitude: Number(center.longitude)
		},
		withPadding
	);
	const initialBounds = bounds.map(({ latitude, longitude }) => [longitude, latitude]);

	return initialBounds;
}
