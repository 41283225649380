import React, { useMemo, useState } from 'react';
import { Filter } from '@nearst/ui';

const getAvailableChannels = (channels) => {
	return channels
		?.filter((channel) => channel.enabledShops.length)
		.reduce((acc, curr) => {
			acc[curr.id] = {
				value: curr.id,
				checked: false,
				label: curr.label
			};

			return acc;
		}, {});
};

const getDefaultFilters = (filters) =>
	Object.keys(filters).reduce((acc, filterKey) => {
		return {
			...acc,
			[filters[filterKey].value]: filters[filterKey].checked
		};
	}, {});

const LocationFilter = ({ channels, onApply }) => {
	const statusFilter = {
		live: {
			value: 'live',
			checked: false,
			label: 'Live'
		},
		onboarding: {
			value: 'onboarding',
			checked: false,
			label: 'Onboarding'
		},
		disconnected: {
			value: 'disconnected',
			checked: false,
			label: 'Disconnected'
		}
	};
	const enabledChannelsFilter = useMemo(() => getAvailableChannels(channels), [channels]);

	const [selectedFilters, setSelectedFilters] = useState(getDefaultFilters(statusFilter));
	const [appliedFiltersCount, setAppliedFiltersCount] = useState(0);

	const handleFilterChange = (event) => {
		setSelectedFilters({ ...selectedFilters, [event.target.name]: event.target.checked });
	};

	const handleApplyClick = () => {
		const checkedOptions = Object.entries(selectedFilters)
			.filter(([, checked]) => checked)
			.map(([name]) => name);

		const status = Object.keys(statusFilter).filter((value) => checkedOptions.includes(value));
		const channels = Object.keys(enabledChannelsFilter).filter((value) => checkedOptions.includes(value));
		setAppliedFiltersCount(status.length + channels.length);

		onApply({
			status,
			channels
		});
	};

	const handleOnReset = () => {
		setSelectedFilters(getDefaultFilters(statusFilter));
	};

	return (
		<Filter.Dropdown
			text={`Filters ${appliedFiltersCount ? `(${appliedFiltersCount})` : ''}`}
			handleOnReset={handleOnReset}
			handleApplyClick={handleApplyClick}
			content={
				<>
					<Filter.Block
						title="Status"
						filters={statusFilter}
						selectedFilters={selectedFilters}
						handleFilterChange={handleFilterChange}
					/>
					{enabledChannelsFilter && Object.keys(enabledChannelsFilter).length > 0 && (
						<Filter.Block
							title="Enabled channels"
							filters={enabledChannelsFilter}
							selectedFilters={selectedFilters}
							handleFilterChange={handleFilterChange}
						/>
					)}
				</>
			}
		/>
	);
};

export default LocationFilter;
