import React from 'react';
import { useFormContext } from 'react-hook-form';
import ErrorMessage from '../ErrorMessage';
import schema from '../../../schema';

import styles from '../Fields.module.scss';

const Field = ({ name, required = false, children, ...rest }) => {
	const {
		register,
		formState: { errors }
	} = useFormContext();

	return (
		<label>
			<p>
				{children} {required ? <span className={styles.required}>*</span> : ''}
			</p>
			<input
				type="text"
				className={`${styles.input} ${errors?.contactName ? styles.invalid : ''}`}
				{...register(name, { required: required ? 'Please fill in this field.' : '', validate: schema[name] })}
				{...rest}
			/>
			<ErrorMessage name={name} error={rest.error} />
		</label>
	);
};

export default Field;
