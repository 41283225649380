import React from 'react';
import styles from './Fieldset.module.scss';

const Fieldset = ({ children, onChange, className = '', ...props }) => {
	return (
		<div className={styles.container}>
			<fieldset onChange={onChange} className={`${styles.fieldset} ${className}`} {...props}>
				{children}
			</fieldset>
		</div>
	);
};

const Radio = ({ value, children, name = 'radio', className = '', ...props }) => {
	if (!children) children = value;
	return (
		<label htmlFor={value} className={`${styles.radio} ${className}`}>
			<input type="radio" id={value} value={value} name={name} {...props} />
			{children}
		</label>
	);
};

Fieldset.Radio = Radio;
export default Fieldset;
