import React from 'react';

import { SquaresPlusIcon } from '@heroicons/react/20/solid';

import { useShop } from '@services/ShopProvider';
import Sidebar from '@components/Sidebar';
import OnboardingAccessControl from '@components/OnboardingAccessControl';
import { Protect } from '@clerk/clerk-react';

const Channels = () => {
	const { shop, data: shops } = useShop();
	const shopSelected = !!shop?.id;
	const swisEnabled = shopSelected ? !!shop.swisUrl : shops.some((shop) => !!shop.swisUrl);

	if (!shopSelected) return null;

	return (
		<OnboardingAccessControl allowedState={'shop.google.pos-link-ok'}>
			{swisEnabled && (
				<Protect permission="org:channels:read">
					<Sidebar.Item to="/channels">
						<SquaresPlusIcon />
						Channels
					</Sidebar.Item>
				</Protect>
			)}
		</OnboardingAccessControl>
	);
};

export default Channels;
