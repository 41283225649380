import React from 'react';
import { Icon } from '@nearst/ui';

const UserAvatar = ({ user }) => {
	return user.publicUserData?.imageUrl ? (
		<img src={user.publicUserData?.imageUrl} alt="Avatar" />
	) : (
		<div>
			<Icon>person_add</Icon>
		</div>
	);
};

export default UserAvatar;
