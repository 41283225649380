import React from 'react';
import { DropdownItem, DropdownMenu } from '@nearst/ui';

import styles from './OrderActionsDropdown.module.scss';

const OrderActionsDropdown = ({ order, handleUpdate }) => {
	switch (order.status) {
		case 'paid':
		case 'reserved':
			return (
				<DropdownMenu icon="more_horiz" primary={false} mini className={styles.actions}>
					<DropdownItem
						data-testid="confirm-order"
						onClick={(e) => {
							e.stopPropagation();
							handleUpdate({
								...order,
								status: 'confirmed'
							});
						}}
					>
						Confirm
					</DropdownItem>
					<DropdownItem
						onClick={(e) => {
							e.stopPropagation();
							handleUpdate({
								...order,
								status: 'cancelled'
							});
						}}
					>
						Cancel
					</DropdownItem>
				</DropdownMenu>
			);
		case 'confirmed':
			return (
				<DropdownMenu icon="more_horiz" primary={false} mini className={styles.actions}>
					<DropdownItem
						onClick={(e) => {
							e.stopPropagation();
							handleUpdate({
								...order,
								status: 'completed'
							});
						}}
					>
						Mark as collected
					</DropdownItem>
					<DropdownItem
						onClick={(e) => {
							e.stopPropagation();
							handleUpdate({
								...order,
								status: 'cancelled'
							});
						}}
					>
						Cancel
					</DropdownItem>
				</DropdownMenu>
			);
		case 'refund_failed':
			return <p className={styles.csAction}>Contact Customer Success</p>;
		default:
			return null;
	}
};

export default OrderActionsDropdown;
