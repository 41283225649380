import React from 'react';
import { Pill } from '@nearst/ui';
import { Cluster } from '@nearst/ui';

const Pills = ({ children: product }) => {
	return (
		<Cluster space="0.5rem">
			{!product.hasIssues && !product.hidden && (
				<Pill colour="green" data-cy="inventory-pill-active">
					Active
				</Pill>
			)}
			{product.hasIssues && (
				<Pill colour="grey" data-cy="inventory-pill-inactive">
					Inactive
				</Pill>
			)}
			{product.hidden && (
				<Pill colour="blue" data-cy="inventory-pill-hidden">
					Hidden
				</Pill>
			)}
			{product.edited && (
				<Pill colour="blue" data-cy="inventory-pill-edited">
					Edited
				</Pill>
			)}
		</Cluster>
	);
};

export default Pills;
