import { string } from 'yup';

export const createOption = (label) => ({
	label,
	value: label
});

export const validateEmail = (email) => {
	return string().email().trim().isValidSync(email);
};
