import React, { useEffect, useState } from 'react';
import { Button, Cluster, TextInput } from '@nearst/ui';
import SubStep from '../../Substep';
import HelpButton from '../../HelpButton';
import LoadingButton from '../../LoadingButton';
import { updateChannel } from '@services/channels';
import { useShop } from '@services/ShopProvider';

import styles from './MetaCommerceStepTwo.module.scss';

const EnterVerificationStep = ({ channel, setDomainAdded, setClickedVerify }) => {
	const { shop, updateShop } = useShop();
	const [metaTag, setMetaTag] = useState('');
	const [loading, setLoading] = useState(false);

	// regex to check if meta tag is valid
	const regex = /<meta name="(facebook|meta)-domain-verification" content="(?<code>\w{16,32})"(\s+)?\/>/im;
	const isValid = regex.test(metaTag);

	const saveVerificationToken = async () => {
		if (!isValid) return;

		setLoading(true);

		const domainVerificationCode = metaTag.match(regex)?.groups?.code;

		await updateChannel(shop.id, 'meta-commerce', {
			domainVerificationCode
		});

		// (temporary)
		await updateShop(shop.id, {
			facebookDomainVerificationCode: domainVerificationCode
		});

		setClickedVerify(true);
	};

	useEffect(() => {
		if (channel?.config?.domainVerificationCode) {
			setMetaTag(`<meta name="facebook-domain-verification" content="${channel?.config?.domainVerificationCode}" />`);
		}
	}, [channel]);

	return (
		<div className={styles.cardInner}>
			<SubStep progress="completed" title="Enter your NearSt website on the domain settings page" />
			<SubStep progress="in progress" title="Enter verification code">
				<p>
					Copy the code in the first section called <strong>Copy this meta tag</strong> and paste below.
				</p>
				<TextInput
					placeholder='<meta name="facebook-domain-verification" content="xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx" />'
					className={styles.input}
					onChange={(e) => setMetaTag(e.target.value)}
					value={metaTag}
				/>
				{!isValid && metaTag.length > 0 && <p className={styles.validationError}>Please enter a valid meta tag.</p>}

				<Cluster className={styles.buttons}>
					<LoadingButton loading={loading} disabled={loading || !isValid} onClick={saveVerificationToken} primary>
						Continue
					</LoadingButton>
					<Button link onClick={() => setDomainAdded(false)}>
						Go back
					</Button>
				</Cluster>

				<HelpButton href="https://support.near.st/en/articles/8106146-how-can-i-verify-my-website-domain">
					Where can I find the meta tag?
				</HelpButton>
			</SubStep>
			<SubStep title={`Click "Verify domain"`} />
		</div>
	);
};

export default EnterVerificationStep;
