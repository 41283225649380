import React from 'react';

import { formatDimensionValue } from './dataVisualization';
import styles from '../ComparisonCard.module.scss';

const Tooltip = ({ active, payload }) => {
	if (!active || !payload || !payload.length) {
		return null;
	}

	const { shopName, dimension, description } = payload[0].payload;

	return (
		<div className={styles.tooltip} role="tooltip">
			<p>
				<strong>{shopName || 'Value'}</strong>
			</p>
			{description}
			{payload.map((item) => (
				<p key={item.name}>{formatDimensionValue(item.value, dimension)}</p>
			))}
		</div>
	);
};

export default Tooltip;
