import React from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';
import * as Slider from '@radix-ui/react-slider';
import * as Popover from '@radix-ui/react-popover';
import { Cluster } from '@nearst/ui';
import { parseCurrency } from '@utils/currency';

import styles from './BudgetSlider.module.scss';

const prices = [3, 5, 10, 15, 20, 30, 50];

const QuickChoose = ({ quickChooseValue, handleQuickChooseClick, currency }) => {
	return (
		<>
			<p>Quick choose:</p>
			<Cluster space="0.25rem" className={styles.choices}>
				{prices.map((price) => (
					<button
						className={`${styles.pill} ${price === quickChooseValue ? styles.selected : ''}`}
						key={price}
						onClick={() => handleQuickChooseClick(price)}
						data-cy={`local-ads-quick-choose-${price}`}
					>
						{parseCurrency(price, { currency: currency }).format()}
					</button>
				))}
			</Cluster>
		</>
	);
};

const LiaNumberInput = ({ budgetLabel, currency, value, handleChange }) => {
	return (
		<>
			<label className={`${styles.mobileInput} ${styles.additionalBudget}`} htmlFor="input-local-ads">
				{budgetLabel}
				<span>
					{getSymbolFromCurrency(currency || 'GBP')}
					<input
						data-cy={`local-ads-mobile-input`}
						id="input-local-ads"
						type="number"
						min={0}
						max={100}
						step={2}
						value={value}
						onChange={handleChange}
					/>
				</span>
			</label>
		</>
	);
};

const LiaSlider = ({ sliderValue, setSliderValue, handleSliderCommit, currency }) => {
	const formattedSliderValue = parseCurrency(sliderValue[0], { currency }).format();
	return (
		<>
			<Popover.Root open>
				<Popover.Content>
					<p data-cy={`local-ads-slider-popover`} className={styles.popover}>
						{formattedSliderValue}
					</p>
					<Popover.Arrow className={styles.popoverArrow} />
				</Popover.Content>

				<Slider.Root
					onValueChange={([newValue]) => setSliderValue([newValue === 1 ? 0 : newValue])}
					onValueCommit={handleSliderCommit}
					value={sliderValue}
					className={`${styles.slider}`}
					max={100}
					step={1}
					aria-label="Local Ads Spend"
				>
					<Slider.Track className={styles.sliderTrack}>
						<Slider.Range className={styles.sliderRange} />
					</Slider.Track>
					<Popover.Anchor asChild>
						<Slider.Thumb data-cy={`local-ads-slider`} className={styles.sliderThumb} />
					</Popover.Anchor>
				</Slider.Root>
			</Popover.Root>
		</>
	);
};

export { LiaSlider, LiaNumberInput, QuickChoose };
