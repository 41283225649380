import React from 'react';
import { Stack } from '@nearst/ui';

import styles from './Legend.module.scss';

const Legend = ({ series }) => {
	return (
		<Stack space="1rem" className={styles.summary}>
			{series.map((item) => (
				<div key={item.label} className={styles.legendItem}>
					<h4 style={{ color: item.colour }}>
						{item.value.toLocaleString()} {item.label}
					</h4>
					<p>{item.description}</p>
				</div>
			))}
		</Stack>
	);
};

export default Legend;
