import React from 'react';
import { showIntercomArticle } from '@services/Intercom/utils';
import styles from './HelpButton.module.scss';

const HelpButton = ({ href, children }) => {
	// extract the ID from the Intercom article URL
	const intercomArticleId = href.match(/\d{5,}/)?.[0];

	return (
		<div>
			<a
				onClick={(event) => {
					if (!window.Intercom || !intercomArticleId) {
						return;
					}
					event.preventDefault();
					showIntercomArticle(intercomArticleId);
				}}
				href={href}
				target="_blank"
				rel="noreferrer"
				className={styles.button}
			>
				{children}
			</a>
		</div>
	);
};

export default HelpButton;
