import myShopClient from './nearlive';

/**
 * Get a link to a Stripe checkout session. Specifically used to get the
 * user to add payment details before enabling a Local Ads plan.
 *
 * @param {string} shopId Shop ID
 * @param {object} body Object of additional properties to pass back in the redirect
 * @returns {Promise<object>} The Stripe checkout session
 */
export async function getStripeCheckoutSession(shopId, body) {
	const client = await myShopClient();
	const { data } = await client.post(`/api/billing/checkout-session/${shopId}`, body);
	return data;
}

/**
 * List payment methods for the given shop's Stripe customer.
 *
 * @param {string} shopId Shop ID
 * @returns {Promise<object[]>} List of Stripe payment methods
 */
export async function listPaymentMethods(shopId) {
	const client = await myShopClient();
	const { data } = await client.get(`/api/billing/payment-method/${shopId}`);
	return data;
}

/**
 * Get a list of recent invoices for a given shop.
 *
 * @param {string[]} shopId Shop ID
 * @returns {Promise<object[]>} List of Stripe invoices
 */
export async function getInvoices([shopId]) {
	const client = await myShopClient();
	try {
		const { data } = await client.get(`/api/billing/invoices/${shopId}`);
		return data;
	} catch (e) {
		return [];
	}
}

/**
 * Get Stripe customer information for a given shop.
 *
 * @param {string[]} shopId Shop ID
 * @returns {Promise<object>} Stripe customer object
 */
export async function getCustomer([shopId]) {
	const client = await myShopClient();
	const { data } = await client.get(`/api/billing/customer/${shopId}`);
	return data;
}

/**
 * Get a link to the Stripe billing portal to update a billing method.
 *
 * @param {string} shopId Shop ID
 * @returns {Promise<object>} Stripe billing portal session object
 */
export async function getPaymentUpdateUrl(shopId) {
	const client = await myShopClient();
	const { data } = await client.post(`/api/billing/payment/${shopId}`);
	return data;
}

/**
 * Update a shop's billing address in Stripe.
 *
 * @param {string} shopId Shop ID
 * @param {object} body Object containing the new address
 * @returns {Promise<void>}
 */
export async function updateBillingAddress(shopId, body) {
	const client = await myShopClient();
	const { data } = await client.post(`/api/billing/address/${shopId}`, body);
	return data;
}

/**
 * Apply a coupon to the shop's Stripe customer.
 *
 * @param {string} shopId Shop ID
 * @param {string} code Coupon code
 * @returns {Promise<object>} Stripe coupon object
 */
export async function applyCoupon(shopId, code) {
	const client = await myShopClient();
	const { data } = await client.post(`/api/billing/coupon/${shopId}`, { code });
	return data;
}

/**
 * List available plans to upgrade to for the given currency and plan ID.
 *
 * @param {object} params
 * @param {string} params.currency Currency code
 * @param {string} params.plan Plan ID
 * @param {string} params.interval Plan interval (year, month)
 * @returns {Promise<object[]>} List of available plans
 */
export async function listPlans({ currency = 'GBP', plan, interval }) {
	const client = await myShopClient();
	const { data } = await client.get(`/api/billing/plans`, { params: { currency, plan, interval } });
	return data;
}

/**
 * Create or update the Stripe subscription for a shop (main NearSt plan).
 *
 * @param {string} priceId Stripe Price ID
 * @returns {Promise<void>}
 */
export async function subscribeToPlan(priceId) {
	const client = await myShopClient();
	const { data } = await client.post(`/api/billing/subscribe/${priceId}`);
	return data;
}

/**
 * Get Stripe setup intent data for a given shop, allowing the user to add a payment method.
 *
 * @param {string[]} shopId Shop ID
 * @returns {Promise<object>} Stripe setup intent object
 */
export async function setupIntent([shopId]) {
	const client = await myShopClient();
	const { data } = await client.get(`/api/billing/setup-intent/${shopId}`);
	return data;
}

/**
 * Mark a newly added payment method through a setup intent as the Stripe customer's default payment method.
 *
 * @param {string} shopId
 * @param {object} params
 * @param {string} params.paymentMethodId Stripe payment method ID
 * @param {string} params.setupIntentId Stripe setup intent ID
 * @returns {Promise<void>}
 */
export async function setPaymentMethodAsDefault(shopId, { paymentMethodId, setupIntentId }) {
	const client = await myShopClient();
	const { data } = await client.post(`/api/billing/set-payment-method-as-default/${shopId}`, { paymentMethodId, setupIntentId });
	return data;
}
