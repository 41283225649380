import React from 'react';
import * as Stats from '@components/StatsCard';

import styles from './InsightCardContainer.module.scss';

const InsightCardContainer = ({ children }) => {
	return <Stats.Card className={`${styles.card} ${styles.lia}`}>{children}</Stats.Card>;
};

export default InsightCardContainer;
