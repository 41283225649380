import React from 'react';
import { Outlet } from 'react-router-dom';

import { Protect } from '@clerk/clerk-react';

import { FallbackWithShopId } from '@utils/RedirectWithShopId';

import FeedOverview from './FeedOverview';
import InventoryItem from './InventoryItem';
import Inventory from './InventoryManagement/InventoryViewer';

import AccessDenied from '../errors/AccessDenied';

export const InventoryLayout = () => {
	return (
		<FallbackWithShopId>
			<Protect permission="org:inventory:read" fallback={<AccessDenied />}>
				<Outlet />
			</Protect>
		</FallbackWithShopId>
	);
};

export const inventoryRoutes = [
	{ index: true, element: <FeedOverview /> },
	{ path: 'items/:barcode', element: <InventoryItem /> },
	{ path: 'items', element: <Inventory /> }
];
