import { ActualButton as Button, Cluster } from '@nearst/ui';
import React from 'react';

import styles from './InventoryViewer.module.scss';

const buildNumberArray = (size) => {
	return Array.from({ length: size }, (v, i) => i + 1);
};

const buildPaginationList = (totalPages, currentPage, offset) => {
	if (totalPages <= offset) {
		return buildNumberArray(totalPages);
	}

	if (currentPage > offset) {
		const newRange = [];
		for (let i = 0; i < offset; i++) {
			newRange.push(currentPage - i);
		}
		return newRange.reverse();
	} else {
		return buildNumberArray(offset);
	}
};

const TablePagination = ({ pages, currentPage, onPageChange }) => {
	const OFFSET = 5;
	const useOffset = pages > OFFSET && currentPage !== pages;
	const [range, setRange] = React.useState([]);

	React.useEffect(() => {
		setRange(buildPaginationList(pages, currentPage, OFFSET));
	}, [currentPage, pages]);

	return (
		<Cluster className={styles.pagination}>
			<Button disabled={currentPage === 1} onClick={() => onPageChange(currentPage - 1)} data-testid="inventory-back-button">
				Back
			</Button>
			{range.map((pageNum) => (
				<Button
					disabled={pageNum === currentPage}
					key={pageNum}
					onClick={() => onPageChange(pageNum)}
					data-testid={`inventory-page-button`}
				>
					{pageNum}
				</Button>
			))}
			{useOffset && (
				<Button disabled data-testid={`inventory-page-more-button`}>
					...
				</Button>
			)}
			<Button
				disabled={pages < 2 || currentPage > pages - 1}
				onClick={() => onPageChange(currentPage + 1)}
				data-testid="inventory-forward-button"
			>
				Next
			</Button>
		</Cluster>
	);
};

export default TablePagination;
