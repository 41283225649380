import React, { useState } from 'react';
import { Card } from '@nearst/ui';
import DomainVerificationStep from './DomainVerificationStep';
import EnterVerificationStep from './EnterVerificationStep';
import ClickVerifyStep from './ClickVerifyStep';

const MetaCommerceStepTwo = ({ channel, finish }) => {
	const [domainAdded, setDomainAdded] = useState(false);
	const [clickedVerify, setClickedVerify] = useState(false);

	return (
		<Card
			sectioned
			progress={50}
			title="Step 2: Verify your domain"
			description="You need to verify your near.st domain to successfully link your product pages to Facebook Shops or Instagram Shops."
		>
			{!domainAdded && !clickedVerify && <DomainVerificationStep channel={channel} setDomainAdded={setDomainAdded} />}
			{domainAdded && !clickedVerify && (
				<EnterVerificationStep channel={channel} setDomainAdded={setDomainAdded} setClickedVerify={setClickedVerify} />
			)}
			{clickedVerify && <ClickVerifyStep setClickedVerify={setClickedVerify} finish={finish} />}
		</Card>
	);
};

export default MetaCommerceStepTwo;
