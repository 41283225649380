class Categories {
	static get LOCAL_COMMERCE() {
		return {
			title: 'Local commerce',
			description: 'Unlock new purchase journeys using the power of real-time local inventory.'
		};
	}

	static get DISCOVERY_CHANNELS() {
		return {
			title: 'Discovery channels',
			description: 'Create frictionless online-to-offline discovery journeys for your local audience.'
		};
	}

	static get SOCIAL_COMMERCE() {
		return {
			title: 'Social commerce',
			description: 'Get closer to your customers by offering new discovery and purchase experiences on social media platforms.'
		};
	}

	static get OMNICHANNEL_EXPERIENCE() {
		return {
			title: 'Omnichannel experience',
			description: 'Leverage powerful web tools to create seamless customer experiences.'
		};
	}
}

module.exports = Categories;
