import { getHubspotAuth } from '@services/shop';
import { useShop } from '@services/ShopProvider';
import { useState } from 'react';

const setHubspotIdentity = async () => {
	let auth = {};
	try {
		auth = await getHubspotAuth();
	} catch (e) {
		// if this request fails, we can still initiate
		// an anonymous HubSpot chat
	}
	window.hsConversationsSettings = {
		...auth
	};
	window.HubSpotConversations.widget.load();
};

export const loadHubspot = () => {
	if (typeof window === 'undefined') {
		return;
	}

	if (window.HubSpotConversations) {
		// HubSpot SDK already loaded
		setHubspotIdentity();
	} else {
		// Otherwise, callbacks can be added to the hsConversationsOnReady on the window object,
		// these callbacks will be called once the external API has been initialized
		window.hsConversationsOnReady = [setHubspotIdentity];
		window.hsConversationsSettings = { loadImmediately: false };

		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.async = true;
		script.src = 'https://js.hs-scripts.com/1999324.js';
		const parentScript = document.getElementsByTagName('script')[0];
		parentScript.parentNode.insertBefore(script, parentScript);
	}
};

export const useHubspot = () => {
	const { shop } = useShop();
	const [setupHubspot, setSetupHubspot] = useState(false);

	if (shop && !setupHubspot && import.meta.env.PROD) {
		setSetupHubspot(true);
		loadHubspot();
	}
};
