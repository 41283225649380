import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const InfoCard = ({ title, subtitle, children, classes, column, row, width, height, ...props }) => {
	const classList = ['info-card'];

	if (column) {
		classList.push('info-card--column');
	}
	if (row) {
		classList.push('info-card--row');
	}
	if (classes) {
		classList.push(classes);
	}

	return (
		<div className={classList.join(' ')} {...props} style={{ width: width, height: height }}>
			<div className="info-card__titles-container">
				<h3 className="info-card__title">{title}</h3>
				<h4 className="info-card__subtitle">{subtitle}</h4>
			</div>
			{children}
		</div>
	);
};

InfoCard.propTypes = {
	children: PropTypes.node,
	title: PropTypes.string,
	subtitle: PropTypes.string,
	classes: PropTypes.string,
	column: PropTypes.bool,
	row: PropTypes.bool,
	width: PropTypes.string,
	height: PropTypes.string
};

export default InfoCard;
