import React from 'react';
import { useBootIntercom } from './utils';

const Provider = ({ children }) => {
	useBootIntercom();

	return <React.Fragment>{children}</React.Fragment>;
};

export default Provider;
