import { Card } from '@nearst/ui';
import React, { useState } from 'react';
import { debounce } from 'lodash';
import { InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@mui/icons-material/Search';
import mixpanel from 'mixpanel-browser';

import { useData } from '@services/insights';
import LocationFilter from './LocationFilter';
import LocationManagementTable from './LocationManagementTable';
import { getCombinedInsights } from './helpers';

import styles from './LocationManagement.module.scss';

const MainInsightsWrapper = ({ shops, children }) => {
	const shopIds = shops.map((shop) => shop.id);
	const results = useData(getCombinedInsights, shopIds);

	const insights = {};

	if (results.resultSet) {
		insights.swisDailyImpressionsCorrected = results.resultSet.swisDailyImpressionsCorrected;
		insights.dailySwis = results.resultSet.dailySwis;
		insights.lia = results.resultSet.lia;
		insights.previousSwisDailyCorrected = results.resultSet.previousSwisDailyCorrected;
		insights.previousDailySwis = results.resultSet.previousDailySwis;
		insights.previousLia = results.resultSet.previousLia;
	}
	return <>{children(insights)}</>;
};

const LocationManagement = ({ shops, channels }) => {
	const [appliedFilters, setAppliedFilters] = useState({ status: [], channels: [] });
	const [searchQuery, setSearchQuery] = useState('');

	const handleSearchChange = (e) => {
		const query = e.target.value;
		setSearchQuery(query);
		mixpanel.track('Locations search', { query });
	};

	return (
		<MainInsightsWrapper shops={shops}>
			{(insights) => {
				return (
					<>
						<div className={styles.actionContainer}>
							<TextField
								data-testid="inventory-search-input"
								variant="outlined"
								className={styles.searchField}
								onChange={debounce(handleSearchChange, 50)}
								placeholder="Search for location..."
								InputProps={{
									className: styles.searchInput,
									startAdornment: (
										<InputAdornment position="start">
											<SearchIcon className={styles.searchFieldIcon} />
										</InputAdornment>
									)
								}}
							/>
							<LocationFilter channels={channels} onApply={setAppliedFilters} />
						</div>
						<LocationManagementTable
							shops={shops}
							channels={channels}
							insights={insights}
							appliedFilters={appliedFilters}
							searchQuery={searchQuery}
						/>
					</>
				);
			}}
		</MainInsightsWrapper>
	);
};

export default LocationManagement;
