import React from 'react';
import { Card } from '@nearst/ui';

import { StockInventorySource, ProductInventorySource } from '../InventorySource';
import styles from './ConnectorStatus.module.scss';

const ConnectorStatus = ({ shop }) => {
	return (
		<Card data-cy="connector-status" sectioned className={styles.connectorStatus}>
			<StockInventorySource inventorySource={shop.stockInventorySource} />
			<ProductInventorySource inventorySource={shop.productInventorySource} />
		</Card>
	);
};

export default ConnectorStatus;
