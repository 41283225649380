const Categories = require('../model/Categories');
const ChannelDefinition = require('../model/ChannelDefinition');

class DeliverooChannel extends ChannelDefinition {
	id = 'deliveroo';
	title = 'Deliveroo';
	description = 'List your products on Deliveroo and increase your sales by reaching additional customers through delivery services.';
	category = Categories.LOCAL_COMMERCE;

	iconUrl = 'https://go.near.st/hubfs/channels/deliveroo.svg';
	coverImageUrl = 'https://go.near.st/hubfs/channels/headers/deliveroo.png';

	longDescription = `
### Overview
Connect your Deliveroo account and allow nearby customers to get products from your store delivered to them through the Deliveroo app.

### How it works
NearSt automatically creates and manages the menu for each of your locations. 

The menu is updated daily with the product information you provide in the dashboard, combined with real-time product availability from your POS system. This ensures you always have up to date menus, significantly reducing order cancellations and refunds.

You can manage all product-specific settings, such as category, custom prices and restrictions from the dashboard.

### Requirements
- Have an active Deliveroo partner account. [Sign up here](https://restaurants.deliveroo.com/en-gb/).
- Have a product data source connected to provide images and descriptions for your menu items

If you are a convenience sector retailer, we can help you source the right data. [Contact us](mailto:support@near.st).`;

	productSpecificVisibility = true;
	comingSoon = true;
	registerInterestUrl = 'https://share.hsforms.com/15J1ldiEtTrKlpiKqDfNY3Q16uos';
}

module.exports = DeliverooChannel;
