import React from 'react';
import papa from 'papaparse';
import { format, startOfWeek } from 'date-fns';
import mixpanel from 'mixpanel-browser';

import * as Stats from '@components/StatsCard';
import Download from '@components/Graphs/Download';
import Table from '@components/Table';
import InsightCardContainer from '../../components/InsightCardContainer';
import DataWrapper from '@components/Graphs/DataWrapper';
import { useInsightsDate } from '@services/InsightsDateProvider';
import { useShop } from '@services/ShopProvider';
import NoData from '@components/Graphs/Placeholders/NoData';
import { updateImpressions } from '../../utils';

const PopularProductsChain = () => {
	const { shop, data } = useShop();
	const shopIds = shop?.id ? [shop.id] : data.map(({ id }) => id);
	const { fromStartOfWeekStr, toEndOfWeekStr } = useInsightsDate();
	const range = `${format(new Date(fromStartOfWeekStr), 'MMM d, yyyy')} – ${format(new Date(toEndOfWeekStr), 'MMM d, yyyy')}`;
	const isTooRecent = new Date(toEndOfWeekStr) > startOfWeek(new Date());
	const placeholder = <NoData text={isTooRecent ? 'No data available yet for this week' : 'No results'} />;

	return (
		<InsightCardContainer>
			<Stats.Title>Popular Products</Stats.Title>
			<Stats.Subtitle>
				<span>Weekly data: {range} | All locations</span>
				<Stats.Tooltip>Data is segmented by week, updated every Monday.</Stats.Tooltip>
			</Stats.Subtitle>
			<DataWrapper
				Placeholder={() => placeholder}
				query={[
					{
						id: shopIds,
						table: 'product_local_listings_weekly',
						columns: ['impressions', 'clicks', 'title', 'barcode'],
						sum: ['impressions', 'clicks'],
						sort: [{ column: 'impressions', order: 'desc' }],
						filter: [
							['start_date', '>=', fromStartOfWeekStr],
							['end_date', '<=', toEndOfWeekStr]
						]
					}
				]}
			>
				{(data) => {
					const enhancedListings = data?.map(updateImpressions);
					return (
						<>
							{!enhancedListings.length ? (
								placeholder
							) : (
								<>
									<Stats.Download
										filename="popular-products"
										csv={papa.unparse(
											enhancedListings.map((row) => ({
												product: row.title,
												barcode: row.barcode,
												clicks: row.clicks,
												views: row.displayImpressions
											}))
										)}
									/>
									<div>
										<Table
											data={enhancedListings}
											defaultSort={{ orderBy: 'views', direction: 'asc', handler: (a, b) => a.clicks - b.clicks }}
											initialLength={10}
											maxLength={40}
											expandable
											onExpand={(expanded) =>
												mixpanel.track(expanded ? 'Expand insights table' : 'Collapse insights table', {
													'Table name': 'Local Listings Popular Products Chain'
												})
											}
											fields={[
												{
													id: 'product',
													title: 'Product name',
													formatter: (row) => {
														return row.title;
													},
													width: '70%'
												},
												{
													id: 'views',
													title: 'Views',
													formatter: (row) => {
														return row.displayImpressions;
													},
													sortFn: {
														desc: (a, b) => a.impressions - b.impressions,
														asc: (a, b) => b.impressions - a.impressions
													},
													width: '15%'
												},
												{
													id: 'clicks',
													title: 'Clicks',
													formatter: (row) => {
														return row.clicks;
													},
													sortFn: { desc: (a, b) => a.clicks - b.clicks, asc: (a, b) => b.clicks - a.clicks },
													width: '15%'
												}
											]}
										/>
									</div>
								</>
							)}
						</>
					);
				}}
			</DataWrapper>
		</InsightCardContainer>
	);
};

export default PopularProductsChain;
