import React from 'react';
import useSWR from 'swr';

import { AdditionalFeaturesList, AdvancedAnalyticsFeature, IndustryInsightsFeature, ReportsFeature } from '../FeaturesList';
import UpgradeModal from '../UpgradeModal';

import { getCustomer } from '@services/billing';
import { useShop } from '@services/ShopProvider';
import { formatPlanString } from '@utils/formatPlanString';

const ChannelUpgradeModal = ({ channel, showDialog, setShowDialog }) => {
	const { shop } = useShop();
	const { data: customer } = useSWR([shop.id, 'customer'], getCustomer);
	const planString = formatPlanString(customer?.subscription?.interval);

	return (
		<UpgradeModal feature={channel.title} showDialog={showDialog} setShowDialog={setShowDialog}>
			<p>
				Boost your online visibility and offer new discovery and purchase journeys for your local customers by showing your products
				in more places online.
			</p>
			<br />
			<p>
				By upgrading your {planString}, all of your products will be available in <strong>{channel.title}</strong>, plus:
			</p>

			<AdditionalFeaturesList>
				<AdvancedAnalyticsFeature />
				<IndustryInsightsFeature />
				<ReportsFeature />
			</AdditionalFeaturesList>
		</UpgradeModal>
	);
};

export default ChannelUpgradeModal;
