import React from 'react';
import { Card } from '@nearst/ui';

import { formatPrice } from '@utils/currency';

import styles from './StockData.module.scss';

const StockData = ({ stockItem }) => {
	const { barcode, price, currency, quantity } = stockItem;

	return (
		<Card title="Stock data" data-cy="inventory-item-stock-data">
			<div className={styles.container}>
				<span className={styles.field}>Barcode</span> <span>{barcode}</span>
				<span className={styles.field}>Price</span> <span>{formatPrice(price, currency)}</span>
				<span className={styles.field}>Quantity</span> <span>{quantity} in stock</span>
			</div>
		</Card>
	);
};

export default StockData;
