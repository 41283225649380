import React from 'react';

import { classnames } from '../../utils';
import styles from './Alert.module.scss';

/**
 * Alert container.
 *
 * @typedef {"info"|"warning"|"error"|"success"} AlertVariant
 *
 * @param {Object} props
 * @param {React.ReactNode} props.children
 * @param {AlertVariant} [props.variant]
 * @returns JSX.Element
 */
const Alert = ({ children, variant = 'info' }) => {
	return (
		<div className={classnames(styles.container, styles[variant])} role="alert">
			{children}
		</div>
	);
};

export default Alert;
