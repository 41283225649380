import React from 'react';
import { spinner } from './LoadingSpinner.module.scss';
const LoadingSpinner = ({ className = '', size = 512, ...props }) => {
	return (
		<svg
			className={`${spinner} ${className}`}
			width={size}
			height={size}
			viewBox="0 0 600 600"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<title>loader</title>
			<g strokeWidth="44" strokeDasharray="1750" strokeDashoffset="-1250" strokeLinecap="round" fill="none">
				<circle id="1" fill="none" cx="300" cy="300" r="278">
					<animateTransform
						attributeName="transform"
						type="rotate"
						values="0 300 300;270 300 300;360 300 300"
						keyTimes="0;0.5;1"
						dur="1s"
						repeatCount="indefinite"
						begin="0s"
					/>
				</circle>
				<circle cx="300" cy="300" r="278">
					<animateTransform
						attributeName="transform"
						type="rotate"
						values="0 300 300;360 300 300"
						keyTimes="0;1"
						dur="1s"
						repeatCount="indefinite"
						begin="0s"
					/>
				</circle>
				<circle cx="300" cy="300" r="278">
					<animateTransform
						attributeName="transform"
						type="rotate"
						values="0 300 300;90 300 300;360 300 300"
						keyTimes="0;0.4;1"
						dur="1s"
						repeatCount="indefinite"
						begin="0s"
					/>
				</circle>
				<circle cx="300" cy="300" r="278">
					<animateTransform
						attributeName="transform"
						type="rotate"
						values="0 300 300;90 300 300;360 300 300"
						keyTimes="0;0.6;1"
						dur="1s"
						repeatCount="indefinite"
						begin="0s"
					/>
				</circle>
			</g>
		</svg>
	);
};
export default LoadingSpinner;
