import React from 'react';
import { Link } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import { ActualButton as Button, Stack, Icon } from '@nearst/ui';

import styles from './UpgradeBanner.module.scss';

const UpgradeBanner = ({ shop }) => {
	const setup = !!shop.swisUrl;
	return (
		<div className={styles.banner}>
			<div className={styles.warning}>
				<Icon>error</Icon>
			</div>
			<div
				className={styles.bannerContent}
				onClick={() => {
					mixpanel.track('LIA upgrade banner (new insights page)', {
						'Shop ID': shop.id,
						'Shop LegacyId': shop.legacyId
					});
				}}
			>
				{setup ? (
					<>
						<Stack className={styles.bannerInfo} space="0.25rem">
							<p className={styles.title}>You don&rsquo;t currently have Local Ads Enabled.</p>
							<p className={styles.subtitle}>Let us bring shoppers to you, starting from £2 a day.</p>
						</Stack>
						<Button to={`/subscription/${shop.id}`} as={Link} data-cy="activate-local-ads" className={styles.button} success>
							Activate Local Ads <Icon>arrow_right</Icon>
						</Button>
					</>
				) : (
					<Stack className={styles.bannerInfo} space="0.25rem">
						<p className={styles.title}>We are still setting up your Google See What&rsquo;s In Store feed.</p>
						<p className={styles.subtitle}>You will be able to activate local ads once the setup is complete.</p>
					</Stack>
				)}
			</div>
		</div>
	);
};

export default UpgradeBanner;
