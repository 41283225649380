import React, { useEffect, useState } from 'react';
import { classnames } from '../../utils';
import Logo from '../Logo';
import Link from './Link';
import UserMenu from './UserMenu';
import Dropdown from './Dropdown';
import CollapsingDropdown from './CollapsingDropdown';
import Divider from './Divider';

import './style.scss';

const DefaultLogo = (
	<a href="/" className={classnames('logo', 'nav-item-logo')}>
		<Logo />
	</a>
);

const Bar = ({ children, logo = DefaultLogo, className = '', isFixed = false, showMenu = true }) => {
	const [open, setOpen] = useState(false);
	const [fixed, setFixed] = useState(isFixed);
	const [shown, setShown] = useState(isFixed);

	useEffect(() => {
		if (!isFixed) {
			return;
		}
		let scrollPos = 0;
		const handleScroll = () => {
			const y = window.pageYOffset;
			if (y > 96) {
				setFixed(true);
			} else if (y <= 0) {
				setFixed(false);
			}
			setShown(y < scrollPos);
			scrollPos = y;
		};
		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, [isFixed]);

	const toggleOpen = () => {
		const newOpen = !open;
		const classes = document.getElementsByTagName('html')[0].classList;
		if (newOpen) {
			classes.add('dropdown-open');
		} else {
			classes.remove('dropdown-open');
		}

		setOpen(newOpen);
	};

	return (
		<>
			<header
				className={`${open ? 'nav--open ' : ''}${fixed || open || isFixed ? 'header--fixed' : `header--absolute ${className}`}${
					shown || open || isFixed ? ' header--shown' : ''
				}`}
			>
				<nav aria-label="Main navigation">
					<span className="logo" onClick={() => setOpen(false)}>
						{logo}
					</span>
					{showMenu && (
						<>
							<button className="nav-button" onClick={toggleOpen}>
								<span className="nav-button__lines" />
							</button>
							<div className="nav-inner" onClick={() => setOpen(false)}>
								{children}
							</div>
						</>
					)}
				</nav>
			</header>
			<div className="header__spacer" />
		</>
	);
};

export { Bar, Link, Divider, Dropdown, UserMenu, CollapsingDropdown };
