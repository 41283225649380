import React from 'react';
import { Icon, UItils } from '@nearst/ui';

import styles from './ConnectionStatus.module.scss';

const CompleteTheme = ({ status, summary, className }) => {
	return (
		<div className={UItils.classnames(styles.container, className)}>
			<div className={`${styles.icon} ${styles.iconDone}`}>
				<Icon>done</Icon>
			</div>
			<div className={styles.statusContainer}>
				<div className={`${styles.text} ${styles.textDone}`}>{status}</div>
				<div className={styles.summary}>{summary}</div>
			</div>
		</div>
	);
};

const DefaultThemeWithCustomIcon = ({ status, summary, icon, className, textClassName }) => {
	return (
		<div className={UItils.classnames(styles.container, className)}>
			{icon}
			<div className={styles.statusContainer}>
				<div className={UItils.classnames(styles.text, styles.textBlack, textClassName)}>{status}</div>
				<div className={UItils.classnames(styles.summary, styles.summaryFontWeight)}>{summary}</div>
			</div>
		</div>
	);
};

const ConnectionStatus = ({
	connection = 'done' || 'pending' || 'setup' || 'warning',
	status,
	summary,
	outcome = null,
	footer = null,
	className = null,
	children = null
}) => {
	return (
		<>
			{connection === 'setup' && (
				<DefaultThemeWithCustomIcon
					className={className}
					status={status}
					summary={summary}
					icon={
						<div className={UItils.classnames(styles.icon, styles.iconSetup)}>
							<Icon>settings</Icon>
						</div>
					}
				/>
			)}
			{connection === 'pending' && (
				<DefaultThemeWithCustomIcon
					className={className}
					status={status}
					textClassName={styles.textPending}
					summary={summary}
					icon={
						<div className={UItils.classnames(styles.icon, styles.iconPending)}>
							<Icon>hourglass_bottom</Icon>
						</div>
					}
				/>
			)}
			{connection === 'warning' && (
				<DefaultThemeWithCustomIcon
					className={className}
					status={status}
					summary={summary}
					textClassName={styles.textWarning}
					icon={
						<div className={UItils.classnames(styles.icon, styles.iconWarning)}>
							<Icon>warning</Icon>
						</div>
					}
				/>
			)}
			{connection === 'done' && <CompleteTheme className={className} status={status} summary={summary} />}
			{children}
			{outcome && <p className={styles.outcome}>{outcome}</p>}
			<div className={styles.footer}>{footer}</div>
		</>
	);
};

export default ConnectionStatus;
