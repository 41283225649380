import React from 'react';
import { Pill } from '@nearst/ui';
import { shopIsOnSwis } from '@services/channels';

const SwisStatus = ({ shops }) => {
	const shopsOffSwis = shops.filter((shop) => !shopIsOnSwis(shop)).length;

	let swisMessage = 'live';
	if (shopsOffSwis > 0) swisMessage = `${shopsOffSwis} shop${shopsOffSwis !== 1 ? 's' : ''} offline`;
	if (shopsOffSwis === shops.length) swisMessage = 'offline';

	return <Pill colour={swisMessage === 'live' ? 'green' : 'red'}>{swisMessage}</Pill>;
};

export default SwisStatus;
