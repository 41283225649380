import { useEffect } from 'react';
import { useShop } from '../ShopProvider';
import { getAuthSession } from '@services/auth';

const APP_ID = 'az7yulln';

const shouldUseIntercom = () => {
	return !!(
		(import.meta.env.MODE === 'production' || import.meta.env.MODE === 'staging' || import.meta.env.VITE_INTERCOM) &&
		window &&
		window.Intercom
	);
};

const useBootIntercom = () => {
	useEffect(() => {
		if (shouldUseIntercom()) {
			window.Intercom('boot', {
				app_id: APP_ID
			});
		}
	}, []);
};

const useUpdateIntercom = () => {
	const { shop } = useShop();

	useEffect(() => {
		getAuthSession().then(({ email }) => {
			if (!email) {
				return;
			}
			if (shouldUseIntercom()) {
				window.Intercom('update', {
					created_at: Date.now(),
					email: email,
					user_id: email,
					shop_id: shop.id,
					pos: shop.stockInventorySource?.pointOfSale,
					lia: shop.currentPlusPlan?.title,
					plan: shop.currentSubscription?.title
				});
			}
		});
	}, [shop]);
};

const naiveIntercom = (email) => {
	if (shouldUseIntercom()) {
		window.Intercom('update', {
			created_at: Date.now(),
			email
		});
		window.Intercom('show');
	}
};

const showIntercomWithMessage = (message) => {
	if (shouldUseIntercom()) {
		window.Intercom('showNewMessage', message);
		return true;
	}
	return false;
};

const showIntercomArticle = (articleId) => {
	if (shouldUseIntercom()) {
		window.Intercom('showArticle', articleId);
		return true;
	}
	return false;
};

const shutdownIntercom = () => {
	if (shouldUseIntercom()) {
		window.Intercom('shutdown');
		window.Intercom('boot', {
			app_id: APP_ID
		});
	}
};

export { useBootIntercom, useUpdateIntercom, naiveIntercom, shutdownIntercom, showIntercomWithMessage, showIntercomArticle };
