import React from 'react';
import { Link } from 'react-router-dom';
import * as HoverCard from '@radix-ui/react-hover-card';

import { Stack, Card as UICard, Icon as UiIcon, UItils } from '@nearst/ui';
import DownloadCsv from '@components/Graphs/Download';

import styles from './StatsCard.module.scss';

export const Card = ({ children, className = '', ...props }) => {
	return (
		<UICard data-testid="stats-card" className={`${className} ${styles.card}`} {...props}>
			{children}
		</UICard>
	);
};
export const Section = (props) => {
	return <UICard.Section {...props} />;
};

export const Figure = ({ children, className = '', ...props }) => {
	return (
		<span className={`${className} ${styles.figure}`} {...props}>
			{children}
		</span>
	);
};

export const FigureDescription = ({ children, className = '', ...props }) => {
	return (
		<p className={`${className} ${styles.figureDescription}`} {...props}>
			{children}
		</p>
	);
};

export const Header = ({ children, className = '', ...props }) => {
	return (
		<div className={`${className} ${styles.header}`} {...props}>
			{children}
		</div>
	);
};

export const Title = ({ children, className = '', ...props }) => {
	return (
		<h4 className={`${className} ${styles.title}`} {...props}>
			{children}
		</h4>
	);
};

export const Subheading = ({ children, className = '', ...props }) => {
	return (
		<h5 className={`${className} ${styles.subheading}`} {...props}>
			{children}
		</h5>
	);
};
export const Subtitle = ({ children, className = '', ...props }) => {
	return (
		<p className={`${className} ${styles.subtitle}`} {...props}>
			{children}
		</p>
	);
};

export const Tooltip = ({ children, className = '', icon = 'info', ...props }) => {
	return (
		<HoverCard.Root openDelay={100}>
			<HoverCard.Trigger className={`${styles.icon} ${className}`}>
				<UiIcon {...props}>{icon}</UiIcon>
			</HoverCard.Trigger>
			<HoverCard.Portal>
				<HoverCard.Content className={styles.tooltip}>
					{/* offset here makes sure arrow doesn't look weird with border radius on tooltip content */}
					<HoverCard.Arrow offset={3} className={styles.arrow} />
					{children}
				</HoverCard.Content>
			</HoverCard.Portal>
		</HoverCard.Root>
	);
};

export const PercentageChange = ({ previousValue, currentValue, inverted = false, className = '' }) => {
	const difference = currentValue - previousValue;

	let percentageChange = previousValue !== 0 ? difference / previousValue : 0;
	if (percentageChange === Infinity) percentageChange = 0;

	const isPositive = inverted ? percentageChange < 0 : percentageChange > 0;

	return (
		<span className={UItils.classnames(styles.difference, isPositive && styles.positive, className)}>
			{percentageChange > 0 ? '+' : ''}
			{percentageChange.toLocaleString('en', { style: 'percent', maximumFractionDigits: 1 })}
		</span>
	);
};

export const Block = ({ description, currentValue, previousValue = undefined, className = '' }) => {
	const total = Number(currentValue).toLocaleString();

	return (
		<Figure className={`${className}`}>
			{total}
			<FigureDescription>
				{description}
				{currentValue !== undefined && previousValue !== undefined ? (
					<PercentageChange previousValue={previousValue} currentValue={currentValue} />
				) : null}
			</FigureDescription>
		</Figure>
	);
};

const Background = ({ className = '', children, ...props }) => {
	return (
		<Stack className={UItils.classnames(styles.blur, className)} space="0" {...props}>
			{children}
		</Stack>
	);
};

const Foreground = ({ className = '', children, ...props }) => {
	return (
		<div className={`${className} ${styles.blurOverlay}`} {...props}>
			{children}
		</div>
	);
};

const Blur = ({ className = '', children, ...props }) => {
	return (
		<div className={UItils.classnames(styles.blurOverlayContainer, className)} {...props}>
			{children}
		</div>
	);
};

Blur.Background = Background;
Blur.Foreground = Foreground;

export { Blur };

export const ViewMore = ({ className = '', to, children = 'View more', ...props }) => (
	<Link className={`${styles.viewMore} ${className}`} to={to} {...props}>
		{children}
	</Link>
);

export const Column = ({ className = '', children, ...props }) => (
	<div className={`${styles.column} ${className}`} {...props}>
		{children}
	</div>
);

export const Download = ({ className = '', ...props }) => {
	return <DownloadCsv className={`${styles.download} ${className}`} {...props} />;
};
