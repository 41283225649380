import React from 'react';
import useSWR from 'swr';
import { useNavigate } from 'react-router-dom';
import { getOrderOptions } from '@services/channels';
import { DropdownItem, DropdownMenu } from '@nearst/ui';

import styles from '../LocationManagement.module.scss';

const Actions = ({ shop }) => {
	let navigate = useNavigate();
	const { data: orderOptions } = useSWR(`order-options-${shop.id}`, () => getOrderOptions(shop.id));

	return (
		<DropdownMenu icon="more_horiz" primary={false} mini className={styles.actions}>
			<DropdownItem onClick={() => navigate(`/settings/${shop.id}#account`)}>Account Settings</DropdownItem>
			{orderOptions?.anyOptionEnabled && <DropdownItem onClick={() => navigate(`/orders/${shop.id}`)}>Orders</DropdownItem>}
			<DropdownItem onClick={() => navigate(`/inventory/${shop.id}`)}>Inventory</DropdownItem>
		</DropdownMenu>
	);
};

export default Actions;
