import React, { useState } from 'react';
import { ActualButton as Button } from '@nearst/ui';

const ConnectButton = ({ text, onConnect }) => {
	const [isDisabled, setIsDisabled] = useState(false);
	return (
		<Button
			disabled={isDisabled}
			primary
			onClick={() => {
				setIsDisabled(true);
				onConnect();
			}}
		>
			{text}
		</Button>
	);
};

export default ConnectButton;
