import React from 'react';
import styles from './ProgressBar.module.scss';
import { classnames } from '../../utils';

/**
 * @param {Object} props
 * @param {number} [props.value=0]
 * @param {number} [props.max=100]
 * @param {string?} [props.className]
 */
const ProgressBar = ({ value = 0, max = 100, className, ...props }) => {
	return <progress max={max} value={value} className={classnames(styles.bar, className)} {...props} />;
};

export default ProgressBar;
