import React from 'react';
import { Switcher } from '@nearst/ui';
import * as Stats from '../../../../../components/StatsCard';

import styles from './Figures.module.scss';

const DefaultFigures = () => {
	const impressions = 62000;
	const clicks = 1000;

	const ctr = !clicks || !impressions ? 0 : ((clicks / impressions) * 100).toFixed(2);

	return (
		<Switcher space="2rem" className={styles.stats}>
			<Stats.Figure>
				{impressions.toLocaleString()}
				<Stats.FigureDescription>Impressions</Stats.FigureDescription>
			</Stats.Figure>
			<Stats.Figure>
				{clicks.toLocaleString()}
				<Stats.FigureDescription>Clicks</Stats.FigureDescription>
			</Stats.Figure>
			<Stats.Figure>
				{ctr}%
				<Stats.FigureDescription>
					CTR
					<Stats.Tooltip>
						Your click-through rate is a measure of how relevant your products are to the search terms
					</Stats.Tooltip>
				</Stats.FigureDescription>
			</Stats.Figure>
		</Switcher>
	);
};

export default DefaultFigures;
