import React from 'react';

import * as Tip from '@radix-ui/react-tooltip';
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import mixpanel from 'mixpanel-browser';

import { UItils } from '@nearst/ui';

import styles from './Download.module.css';
import { useShop } from '@services/ShopProvider';
import { useCheckPermission } from '@services/auth/hooks';
import AuthorizationTooltip from '@components/AuthorizationTooltip';

export const download = (filename, data, isURL = false) => {
	const element = document.createElement('a');
	element.setAttribute('href', isURL ? data : 'data:text/csv;charset=utf-8,' + encodeURIComponent(data));
	element.setAttribute('download', filename);

	element.style.display = 'none';
	document.body.appendChild(element);

	element.click();

	document.body.removeChild(element);
};

const Download = ({ className = '', title = 'Download data as CSV', filename, csv }) => {
	const { shop } = useShop();
	const authorized = useCheckPermission('org:reports:download');

	if (!csv) return null;

	const handleClick = () => {
		mixpanel.track('Download data', {
			filename,
			'Shop LegacyId': shop.legacyId
		});
		download(filename, csv);
	};

	return (
		<span className={styles.container}>
			<span className={className}>
				{!authorized ? (
					<AuthorizationTooltip authorized={authorized} tooltipContent="You don't have access to download reports">
						<span className={UItils.classnames(styles.download, styles.disabled)}>
							<ArrowDownTrayIcon />
						</span>
					</AuthorizationTooltip>
				) : (
					<Tip.Root delayDuration={100}>
						<Tip.Content className={styles.tooltip}>
							<Tip.Arrow offset={3} />
							{title}
						</Tip.Content>
						<Tip.Trigger role="button" aria-label="Download CSV" onClick={handleClick} className={styles.download}>
							<ArrowDownTrayIcon />
						</Tip.Trigger>
					</Tip.Root>
				)}
			</span>
		</span>
	);
};

export default Download;
