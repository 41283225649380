import UpgradeRequiredBanner from '../UpgradeRequiredBanner';

const UpgradeRequiredProductsBanner = ({ unprocessedStock, isChain = false }) => {
	const title = isChain
		? `Local shoppers can't discover all of the products you are currently stocking.`
		: `Local shoppers can't discover ${unprocessedStock} products you are currently stocking.`;

	return (
		<UpgradeRequiredBanner title={title} customBtnText={'Upgrade to display all products'}>
			{isChain
				? 'You can only display 800 in stock products on your current plan for each location.'
				: 'You can only display 800 in stock products on your current plan.'}
		</UpgradeRequiredBanner>
	);
};

export default UpgradeRequiredProductsBanner;
