import React, { useEffect, useState } from 'react';
import { Loader } from '@nearst/ui';
import { useFacebookSdk } from '../../helpers/metaCommerce';
import ConnectionStatus from '../../../../components/ConnectionStatus';

const CatalogStatus = ({ channel }) => {
	const facebook = useFacebookSdk();
	const [catalogStatus, setCatalogStatus] = useState('loading');
	const [productCount, setProductCount] = useState(0);

	useEffect(() => {
		if (!facebook) {
			return;
		}

		(async () => {
			try {
				const catalog = await facebook(`/${channel.config.catalogId}`, {
					access_token: channel.config.accessToken,
					fields: 'id,name,product_count'
				});
				if (catalog.product_count) {
					setCatalogStatus('active');
					setProductCount(catalog.product_count);
				} else {
					setCatalogStatus('pending');
				}
			} catch (e) {
				setCatalogStatus('warning');
			}
		})();
	}, [facebook, channel]);

	if (catalogStatus === 'active') {
		return (
			<ConnectionStatus
				status="Enabled"
				connection="done"
				summary={`${productCount.toLocaleString()} products synced to your Meta Commerce catalog.`}
			/>
		);
	}

	if (catalogStatus === 'pending') {
		return (
			<ConnectionStatus
				status="Product sync in progress"
				connection="pending"
				summary="Your catalog is being populated, check back soon."
			/>
		);
	}

	if (catalogStatus === 'warning') {
		return (
			<ConnectionStatus
				status="Unable to get product sync status"
				connection="warning"
				summary="Use the button below to check your catalog status or contact our Customer Success team."
			/>
		);
	}

	return <Loader />;
};

export default CatalogStatus;
