import React from 'react';
import { Dialog } from '@reach/dialog';
import PropTypes from 'prop-types';

import Button from '../Button';
import Icon from '../Icon';

import '@reach/dialog/styles.css';
import './style.scss';

const Modal = ({ children, className = '', ...props }) => {
	return (
		<Dialog className={`modal__container ${className}`} {...props}>
			{children}
		</Dialog>
	);
};

Modal.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string
};

const TemplateModal = ({
	children,
	className = '',
	onDismiss,
	isOpen,
	title,
	confirmationAction,
	cancel,
	confirm,
	showDismissButton = true,
	...props
}) => {
	const confirmationActionAndClose = (e) => {
		onDismiss();
		confirmationAction(e);
	};
	return (
		<Dialog className={`modal__container ${className}`} onDismiss={onDismiss} isOpen={isOpen} {...props}>
			<div className="modal__title-container">
				<h2 className="modal__title-text">{title}</h2>
				<button className="modal__exit-button" onClick={onDismiss}>
					<Icon>clear</Icon>
				</button>
			</div>
			<div className="modal__content-container">{children}</div>
			<div className="modal__buttons-container">
				{/* this wrapper div is actually necessary for styling */}
				<div>
					{cancel ? (
						<Button data-cy={`modal-cancel`} onClick={confirmationActionAndClose} cancel>
							{cancel}
						</Button>
					) : null}
					{confirm ? (
						<Button data-cy={`modal-confirm`} onClick={confirmationActionAndClose} confirm>
							{confirm}
						</Button>
					) : null}
					{showDismissButton ? <Button onClick={onDismiss}>Cancel</Button> : null}
				</div>
			</div>
		</Dialog>
	);
};

Modal.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string
};

TemplateModal.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	onDismiss: PropTypes.func,
	isOpen: PropTypes.bool,
	title: PropTypes.string,
	confirmationAction: PropTypes.func,
	cancel: PropTypes.string,
	confirm: PropTypes.string
};

export { Modal, TemplateModal };
