import React from 'react';
import { Icon } from '@nearst/ui';
import * as Accordion from '@radix-ui/react-accordion';

import styles from './FAQ.module.scss';

const FAQItem = (props) => {
	const { value, question, answer, children } = props;
	let content;
	if (answer && children) {
		content = (
			<>
				<p>{answer}</p>
				{children}
			</>
		);
	} else if (children) content = children;
	else content = answer;
	return (
		<Accordion.Item className={styles.item} value={value}>
			<Accordion.Header className={styles.header}>
				<Accordion.Trigger className={styles.trigger}>
					<span>{question}</span> <Icon>arrow_right</Icon>
				</Accordion.Trigger>
			</Accordion.Header>
			<Accordion.Content className={styles.panel}>{content}</Accordion.Content>
		</Accordion.Item>
	);
};

const FAQ = ({ children, defaultValue = '', className = '' }) => {
	return (
		<Accordion.Root type="single" collapsible defaultValue={defaultValue} className={className}>
			{children}
		</Accordion.Root>
	);
};

export { FAQ, FAQItem };
