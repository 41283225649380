import React from 'react';
import { Button, Card, Cluster } from '@nearst/ui';

import HelpButton from '../../HelpButton';
import styles from './MetaCommerceStepThree.module.scss';

const MetaCommerceStepThree = ({ channel, finish }) => {
	return (
		<Card
			progress={75}
			title="Step 3: Create a shop"
			description="The last step in the process is creating a shop using the NearSt product catalog you have connected."
		>
			<p className={styles.description}>
				Please ensure you already have a Facebook Page or Instagram account linked to your business account.
			</p>

			<Cluster className={styles.buttons}>
				<Button
					href={`https://business.facebook.com/commerce_manager/onboarding_landing/?business_id=${channel.config.businessId}&catalogId=${channel.config.catalogId}`}
					target="_blank"
					rel="noreferrer"
					primary
				>
					Create your shop
				</Button>
				<Button link onClick={finish}>
					Finish &rarr;
				</Button>
			</Cluster>

			<HelpButton href="https://support.near.st/en/articles/8065007-how-do-i-create-a-shop">How can I create a shop?</HelpButton>
			<HelpButton href="https://support.near.st/en/articles/6683779-how-to-delete-a-meta-shop">
				How can I remove an existing shop?
			</HelpButton>
		</Card>
	);
};

export default MetaCommerceStepThree;
