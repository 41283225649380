import React from 'react';

import { Icon, UItils } from '@nearst/ui';
import styles from './LocationLabel.module.scss';

const LocationLabel = ({ children, className = '' }) => {
	return (
		<div className={UItils.classnames(styles.container, className)}>
			<div className={styles.icon}>
				<Icon>store</Icon>
			</div>
			<span className={styles.text}>{children}</span>
		</div>
	);
};

export default LocationLabel;
