import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ActualButton, Icon, Loader } from '@nearst/ui';
import { useOrders } from '@services/orders';
import ConnectionStatus from '../../../components/ConnectionStatus';

import styles from './StatusContainer.module.scss';
import { Protect } from '@clerk/clerk-react';
import { useCheckPermission } from '@services/auth/hooks';
import AuthorizationTooltip from '@components/AuthorizationTooltip';

const StripeLink = ({ onClick }) => {
	return (
		<Protect permission="org:channels:manage">
			<ActualButton className={styles.stripeFinishBtn} onClick={onClick} link>
				<span>Finish Stripe account setup</span>
				<Icon>chevron_right</Icon>
			</ActualButton>
		</Protect>
	);
};

const StatusContainer = ({ shopId, status, loading, handleStripeCallback }) => {
	const { data: orders, isLoading } = useOrders({ shopIds: [shopId] });
	let navigate = useNavigate();

	// checkout disabled
	const isFetchingOrders = !orders && isLoading;
	const hasOrders = !!(orders?.length > 0);
	const authorized = useCheckPermission('org:channels:manage');

	return !isFetchingOrders && !loading ? (
		<>
			{status === 'enabled' && (
				<ConnectionStatus
					connection="done"
					status="You're good to go!"
					summary="Your Local Checkout is live on Google."
					outcome="Your Stripe connected account is set up. We have enabled checkout in your SWIS."
					footer={
						<ActualButton primary onClick={() => navigate(`/orders/${shopId}`)}>
							View orders
						</ActualButton>
					}
				/>
			)}

			{(status === 'disabled' || !status) && (
				<ConnectionStatus
					connection="setup"
					status="Set up an account"
					summary="All you need to do to get started is register your own Stripe account."
					outcome="After that, we can go ahead and begin the process of enabling Local Checkout on your SWIS."
					footer={
						<div className={styles.statusFooter}>
							<AuthorizationTooltip authorized={authorized}>
								<ActualButton
									className={styles.getStartedBtn}
									onClick={authorized ? handleStripeCallback : undefined}
									disabled={!authorized}
									primary
								>
									<span>Get started</span>
									<Icon>chevron_right</Icon>
								</ActualButton>
							</AuthorizationTooltip>
							{hasOrders && (
								<ActualButton secondary onClick={() => navigate(`/orders/${shopId}`)}>
									View orders
								</ActualButton>
							)}
						</div>
					}
				/>
			)}

			{status === 'pending' && (
				<ConnectionStatus
					connection="pending"
					status="In progress"
					summary="We're waiting for your Stripe account to be registered"
					outcome="The customer success team will contact you if they need further information to connect your Stripe Account. Once connected, Local Checkout will be enabled."
					footer={<StripeLink onClick={handleStripeCallback} />}
				/>
			)}
		</>
	) : (
		<div className={styles.loader}>
			<Loader />
		</div>
	);
};

export default StatusContainer;
