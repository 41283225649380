import React, { useState } from 'react';

import ModalVideo from 'react-modal-video';
import 'react-modal-video/css/modal-video.min.css';
import { Button } from '@nearst/ui';

export default function VideoLinkV2({ id, title = 'What does it look like?' }) {
	const [isOpen, setIsOpen] = useState(false);
	return (
		<div>
			<ModalVideo channel="youtube" isOpen={isOpen} videoId={id} onClose={() => setIsOpen(false)} />
			<Button link onClick={() => setIsOpen(true)}>
				{title}
			</Button>
		</div>
	);
}
