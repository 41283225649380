const ICON_STATUS = {
	CURRENT: 'current',
	DISABLED: 'disabled',
	ALERT: 'alert',
	COMPLETED: 'completed'
};

const MIN_IN_STOCK_COUNT = 50;

const CONTACT_US = 'Questions? Reach out to our Customer Success team through the live chat support.';

export { ICON_STATUS, MIN_IN_STOCK_COUNT, CONTACT_US };
