import React, { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import qs from 'qs';
import { useClerk } from '@clerk/clerk-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { sendAnalytics } from '../../utils/sendAnalytics';
import { shutdownIntercom } from '@services/Intercom/utils';
import LoadingContainer from '@components/LoadingContainer';

export const Logout = () => {
	const { signOut } = useClerk();
	let location = useLocation();
	const navigate = useNavigate();
	const { shop } = qs.parse(location.search.substring(1));

	useEffect(() => {
		if (signOut) {
			sendAnalytics('Logout', 'Signed out of MyShop', `${shop}`);
			mixpanel.track('Logout');
			mixpanel.reset();
			shutdownIntercom();
			signOut();
			localStorage.clear();
			navigate('/login');
		}
	}, [signOut, shop, navigate]);

	return <LoadingContainer />;
};
