import React from 'react';
import papa from 'papaparse';
import { format, startOfMonth, isSameMonth, sub } from 'date-fns';
import mixpanel from 'mixpanel-browser';

import * as Stats from '@components/StatsCard';

import Table from '@components/Table';

import DataWrapper from '@components/Graphs/DataWrapper';
import { useInsightsDate } from '@services/InsightsDateProvider';

import NoData from '@components/Graphs/Placeholders/NoData';

export const GBPSearchKeywords = ({ shopIds }) => {
	const { startOfMonthStr, endOfMonthStr } = useInsightsDate();

	const formattedRangeStart = format(new Date(startOfMonthStr), 'MMMM Y');
	const formattedRangeEnd = isSameMonth(new Date(endOfMonthStr), new Date())
		? format(sub(new Date(endOfMonthStr), { months: 1 }), 'MMMM Y')
		: format(new Date(endOfMonthStr), 'MMMM Y');

	const dateRangeText = `Monthly data: ${formattedRangeStart === formattedRangeEnd ? formattedRangeStart : formattedRangeStart + ' - ' + formattedRangeEnd}`;

	const isTooRecent = new Date(endOfMonthStr) > startOfMonth(new Date());
	const placeholder = <NoData text={isTooRecent ? 'No data available yet' : 'No results'} />;

	return (
		<Stats.Card>
			<Stats.Title>Google Business Profile search keywords</Stats.Title>
			<Stats.Subtitle>
				<span>{dateRangeText}</span>
				<Stats.Tooltip>Data is segmented by month, updated on the 3rd of each month.</Stats.Tooltip>
			</Stats.Subtitle>
			<DataWrapper
				Placeholder={() => placeholder}
				query={[
					{
						id: shopIds,
						table: 'gbp_search_keywords',
						columns: ['impressions', 'keyword'],
						min: ['threshold'],
						sum: ['impressions'],
						sort: [{ column: 'impressions', order: 'desc' }],
						filter: [
							['start_date', '>=', startOfMonthStr],
							['end_date', '<=', endOfMonthStr]
						],
						limit: 100
					}
				]}
			>
				{(data) => {
					return (
						<>
							{!data.length ? (
								placeholder
							) : (
								<>
									<Stats.Download
										filename="business-profile-keywords"
										csv={papa.unparse(
											data.map((row) => ({
												keyword: row.keyword,

												impressions: row.impressions > 0 ? row.impressions : '<' + row.threshold
											}))
										)}
									/>
									<div>
										<Table
											data={data}
											defaultSort={{
												orderBy: 'impressions',
												direction: 'desc',
												handler: (a, b) => b.impressions - a.impressions
											}}
											initialLength={10}
											maxLength={40}
											expandable
											onExpand={(expanded) =>
												mixpanel.track(expanded ? 'Expand insights table' : 'Collapse insights table', {
													'Table name': 'GBP Keywords'
												})
											}
											fields={[
												{
													id: 'keyword',
													title: 'Keyword',
													formatter: (row) => {
														return row.keyword;
													},
													width: '70%'
												},
												{
													id: 'impressions',
													title: 'Impressions',
													formatter: (row) => {
														return row.impressions > 0 ? row.impressions.toLocaleString() : '<' + row.threshold;
													},
													sortFn: {
														desc: (a, b) => b.impressions - a.impressions,
														asc: (a, b) => a.impressions - b.impressions
													},
													width: '15%'
												}
											]}
										/>
									</div>
								</>
							)}
						</>
					);
				}}
			</DataWrapper>
		</Stats.Card>
	);
};
