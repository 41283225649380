import React from 'react';
import { GoogleMap, Circle, withGoogleMap, withScriptjs } from 'react-google-maps';

const style = [
	{
		featureType: 'administrative',
		elementType: 'all',
		stylers: [
			{
				saturation: '-100'
			}
		]
	},
	{
		featureType: 'administrative.province',
		elementType: 'all',
		stylers: [
			{
				visibility: 'off'
			}
		]
	},
	{
		featureType: 'landscape',
		elementType: 'all',
		stylers: [
			{
				saturation: -100
			},
			{
				lightness: 65
			},
			{
				visibility: 'on'
			}
		]
	},
	{
		featureType: 'poi',
		elementType: 'all',
		stylers: [
			{
				saturation: -100
			},
			{
				lightness: '50'
			},
			{
				visibility: 'simplified'
			}
		]
	},
	{
		featureType: 'road',
		elementType: 'all',
		stylers: [
			{
				saturation: '-100'
			}
		]
	},
	{
		featureType: 'road.highway',
		elementType: 'all',
		stylers: [
			{
				visibility: 'simplified'
			}
		]
	},
	{
		featureType: 'road.arterial',
		elementType: 'all',
		stylers: [
			{
				lightness: '30'
			}
		]
	},
	{
		featureType: 'road.local',
		elementType: 'all',
		stylers: [
			{
				lightness: '40'
			}
		]
	},
	{
		featureType: 'transit',
		elementType: 'all',
		stylers: [
			{
				saturation: -100
			},
			{
				visibility: 'simplified'
			}
		]
	},
	{
		featureType: 'water',
		elementType: 'geometry',
		stylers: [
			{
				hue: '#ffff00'
			},
			{
				lightness: -25
			},
			{
				saturation: -97
			}
		]
	},
	{
		featureType: 'water',
		elementType: 'labels',
		stylers: [
			{
				lightness: -25
			},
			{
				saturation: -100
			}
		]
	}
];

function Map({ shopLocation, adsMaxRadius }) {
	return (
		<GoogleMap
			defaultOptions={{ styles: style, fullscreenControl: false, streetViewControl: false, mapTypeControl: false }}
			defaultZoom={10.5}
			defaultCenter={shopLocation}
		>
			<Circle
				center={shopLocation}
				radius={adsMaxRadius * 1000 || 3000}
				defaultOptions={{
					strokeColor: '#62A1F8',
					strokeOpacity: 1,
					strokeWeight: 2.5,
					fillColor: '#62A1F8',
					fillOpacity: 0.25
				}}
			/>
		</GoogleMap>
	);
}

const AdsRadius = withScriptjs(withGoogleMap(Map));

export default AdsRadius;
