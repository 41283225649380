import React from 'react';
import useSWR from 'swr';

import UpgradeModal from '../UpgradeModal';

import { getCustomer } from '@services/billing';
import { useShop } from '@services/ShopProvider';
import { AdditionalFeaturesList, AdvancedAnalyticsFeature, IndustryInsightsFeature, AdditionalChannelsFeature } from '../FeaturesList';
import { formatPlanString } from '@utils/formatPlanString';

const ReportsUpgradeModal = ({ showDialog, setShowDialog }) => {
	const { shop } = useShop();
	const { data: customer } = useSWR([shop.id, 'customer'], getCustomer);
	const planString = formatPlanString(customer?.subscription?.interval);

	return (
		<UpgradeModal feature="advanced data exports" showDialog={showDialog} setShowDialog={setShowDialog}>
			<p>
				Create and download additional data exports to build custom ROI reports and easily track performance metrics for all
				locations.
			</p>
			<br />
			<p>
				By upgrading your {planString}, you will get access to all <strong>advanced data exports</strong>, plus:
			</p>

			<AdditionalFeaturesList>
				<AdvancedAnalyticsFeature />
				<IndustryInsightsFeature />
				<AdditionalChannelsFeature />
			</AdditionalFeaturesList>
		</UpgradeModal>
	);
};

export default ReportsUpgradeModal;
