import React from 'react';
import { useOrganization, useUser } from '@clerk/clerk-react';
import { useParams, useNavigate } from 'react-router-dom';
import { Squares2X2Icon } from '@heroicons/react/20/solid';

import { UItils } from '@nearst/ui';

import styles from '../LocationSelector/LocationSelector.module.scss';

const OrganisationSelector = () => {
	const { organization: activeOrganization } = useOrganization();
	const { user } = useUser();
	const { shopId } = useParams();
	const navigate = useNavigate();

	return (
		<>
			{user?.organizationMemberships.map((mem) => {
				const isActive = activeOrganization?.id === mem.organization.id;
				const selected = isActive && !shopId;

				const select = async () => {
					if (!isActive) {
						navigate('/home?org=' + mem.organization.id);
					} else {
						navigate('/home');
					}
				};

				return (
					<div
						key={mem.organization.id}
						title={mem.organization.name}
						className={UItils.classnames(styles.shop, selected && styles.current)}
						onClick={select}
					>
						<div className={styles.shopImage}>
							<Squares2X2Icon />
						</div>
						<div className={styles.shopName}>
							<span>
								<span className={styles.truncate}>{mem.organization.name}</span>
							</span>
						</div>
					</div>
				);
			})}
		</>
	);
};

export default OrganisationSelector;
