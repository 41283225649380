import { format } from 'date-fns';
import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { Icon, Stack } from '@nearst/ui';
import { formatKUID, generatePaymentStatus } from '../../utils';
import OrderActions from '../OrderActions';
import OrderStatus from '../OrderStatus';
import LocationLabel from '../LocationLabel';

import styles from './OrderTable.module.scss';
import OrderActionsDropdown from '../OrderActionsDropdown';
import { Protect } from '@clerk/clerk-react';

const ProductID = ({ order, setOrder }) => {
	const orderId = order.pickupReference || formatKUID(order.id);
	return (
		<button className={styles.orderId} onClick={() => setOrder(order)}>
			{orderId}
			{order.notes && <Icon className={styles.orderStatusIcon}>description</Icon>}
		</button>
	);
};

const ProductName = ({ order }) => {
	const title = order.products[0].name || 'Item';
	const truncatedLength = 40;
	const truncatedTitle = title.substring(0, truncatedLength).trim();
	return title.length <= truncatedLength ? title : `${truncatedTitle}...`;
};

const MobileTable = ({ orders, setSelectedOrder, handleUpdate, chain = false }) => {
	return (
		<Table className={styles.mobileTable} role="table">
			<TableBody>
				{orders &&
					orders.map((order) => {
						return (
							<TableRow onClick={() => setSelectedOrder(order)} key={order.id}>
								<TableCell>
									<div className={styles.summary}>
										<Stack space="0.25rem">
											{chain && <LocationLabel>{order.location}</LocationLabel>}
											<ProductName order={order} />
											<p className={styles.greyText}>{order.customerName}</p>
											<p className={styles.greyText}>{generatePaymentStatus(order)}</p>
											<OrderStatus orderStatus={order.status} />
											<OrderActions order={order} handleUpdate={handleUpdate} />
										</Stack>
										<Icon className={styles.summaryIcon}>chevron_right</Icon>
									</div>
								</TableCell>
							</TableRow>
						);
					})}
			</TableBody>
		</Table>
	);
};

const DesktopTable = ({ orders, setSelectedOrder, handleUpdate, chain = false }) => {
	return (
		<Table className={styles.desktopTable} role="table">
			<TableHead>
				<TableRow>
					<TableCell>Order</TableCell>
					<TableCell>Date</TableCell>
					<TableCell>Customer</TableCell>
					<TableCell>Product</TableCell>
					<TableCell>Price</TableCell>
					<TableCell>Payment</TableCell>
					<TableCell>Order Status</TableCell>
					<TableCell>Actions</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{orders &&
					orders.map((order) => {
						const paymentStatus = generatePaymentStatus(order);
						return (
							<TableRow key={order.id} data-testid="inventory-table-row">
								<TableCell>
									<ProductID order={order} setOrder={setSelectedOrder}></ProductID>
									{chain && <LocationLabel className={styles.locationLabel}>{order.location}</LocationLabel>}
								</TableCell>
								<TableCell>
									<div className={styles.noWrap}>{format(new Date(order.createdAt), 'd MMM HH:mm')}</div>
								</TableCell>
								<TableCell>{order.customerName}</TableCell>
								<TableCell>
									<ProductName order={order}></ProductName>
								</TableCell>
								<TableCell>
									{order.products[0].price?.toLocaleString('en', {
										style: 'currency',
										currency: order.products[0].currency || 'GBP'
									})}
								</TableCell>
								<TableCell>
									<div className={styles.noWrap}>{paymentStatus}</div>
								</TableCell>
								<TableCell>
									<OrderStatus orderStatus={order.status} />
								</TableCell>
								<TableCell align="center">
									<Protect permission="org:orders:manage">
										<OrderActionsDropdown order={order} handleUpdate={handleUpdate} />
									</Protect>
								</TableCell>
							</TableRow>
						);
					})}
			</TableBody>
		</Table>
	);
};

const OrdersTable = (props) => {
	return (
		<>
			<MobileTable {...props} />
			<DesktopTable {...props} />
		</>
	);
};

export default OrdersTable;
