import React, { Suspense, useState } from 'react';
import { Link } from 'react-router-dom';
import * as Chart from 'recharts';

import { Page, Switcher } from '@nearst/ui';

import DatePicker from '@components/DatePicker';
import SpinnerCard from '@components/SpinnerCard';
import * as Stats from '@components/StatsCard';
import LocationFilter from '../../insights/components/LocationFilter';
import OptionsButton from '../components/OptionsButton';
import ReportFilters from '../components/ReportFilters';
import { TooltipContainer } from '@components/Graphs/Tooltip';
import { useStoredQuery } from '@services/insights';
import { useShop } from '@services/ShopProvider';

import styles from './OrganicVisibility.module.scss';
import NoData from '@components/Graphs/Placeholders/NoData';

const Tooltip = ({ active, payload }) => {
	if (!active || !payload || !payload.length) {
		return null;
	}

	const { name, gbp_views, local_listings_views } = payload[0].payload;

	return (
		<TooltipContainer>
			<strong>{name}</strong>
			<p>GBP views: {gbp_views.toLocaleString()}</p>
			<p>Local listings views: {local_listings_views.toLocaleString()}</p>
		</TooltipContainer>
	);
};

const OrganicVisibilityCard = ({ options, shopIds }) => {
	const { data: shops } = useShop();
	const { data } = useStoredQuery('local-impact', { shopIds }, { suspense: true });

	const totalViews = data.reduce((acc, { total_views }) => acc + total_views, 0);
	const localListingsViews = data.reduce((acc, { local_listings_views }) => acc + local_listings_views, 0);
	const averageGbpViews = data.reduce((acc, { gbp_views }) => acc + gbp_views, 0) / data.length;

	const hasLocalListings = data.filter((row) => row.local_listings_views > 0).map((row) => row.local_listings_views + row.gbp_views);
	const averageOrganicViews = hasLocalListings.reduce((acc, views) => acc + views, 0) / hasLocalListings.length;

	const visibilityIncrease = ((averageOrganicViews - averageGbpViews) / averageGbpViews) * 100;

	const chartData = data.map((item, index) => ({
		...item,
		index,
		name: shops.find((shop) => shop.id === item.shop_id)?.name
	}));

	if (!chartData.length) {
		return (
			<Stats.Card>
				<NoData />
			</Stats.Card>
		);
	}

	return (
		<Stats.Card>
			<Switcher space="2rem">
				<Stats.Figure>
					{totalViews.toLocaleString()}
					<Stats.FigureDescription>Total organic views</Stats.FigureDescription>
				</Stats.Figure>
				<Stats.Figure>
					{localListingsViews.toLocaleString()}
					<Stats.FigureDescription>Shoppers reached with local inventory</Stats.FigureDescription>
				</Stats.Figure>
				<Stats.Figure>
					{Math.round(averageGbpViews).toLocaleString()}
					<Stats.FigureDescription>Average GBP views</Stats.FigureDescription>
				</Stats.Figure>
				<Stats.Figure>
					{Math.round(averageOrganicViews).toLocaleString()}
					<Stats.FigureDescription>Average organic views with local inventory</Stats.FigureDescription>
				</Stats.Figure>
			</Switcher>

			<p className={styles.description}>
				Adding local inventory increased your average organic views{' '}
				<strong>by {Math.round(visibilityIncrease).toLocaleString()}%</strong>.
			</p>

			<div className={styles.chartContainer}>
				<Chart.ResponsiveContainer
					width={options.summaryView ? 650 : '100%'}
					maxHeight={options.summaryView ? 550 : undefined}
					minHeight={(options.summaryView ? 12 : 27) * chartData.length + 96}
				>
					<Chart.ComposedChart
						data={chartData}
						margin={{ top: 48, left: 0, right: 48, bottom: 64 }}
						layout="vertical"
						id="chart-container"
					>
						<Chart.XAxis type="number" hide />
						<Chart.YAxis
							dataKey="index"
							tickFormatter={(index) => chartData[index]?.name}
							type="number"
							width={240}
							stroke="translate"
							tickCount={chartData.length}
							domain={['dataMin', 'dataMax']}
						/>
						<Chart.Bar
							stackId={1}
							dataKey="gbp_views"
							radius={4}
							fill="var(--grey)"
							style={{ stroke: 'white', strokeWidth: 2 }}
						/>
						<Chart.Bar
							stackId={1}
							dataKey="local_listings_views"
							radius={4}
							fill="var(--green)"
							style={{ stroke: 'white', strokeWidth: 2 }}
							label={
								options.dataLabels
									? {
											position: 'right',
											fill: 'var(--grey-darkest)',
											formatter: (v) => v.toLocaleString()
										}
									: undefined
							}
						/>
						{options.referenceLines && chartData.length > 1 && (
							<>
								<Chart.ReferenceLine
									stroke="var(--grey-darker)"
									ifOverflow="visible"
									segment={[
										{
											x: averageGbpViews,
											y: -0.5
										},
										{
											x: averageGbpViews,
											y: chartData.length - 0.5
										}
									]}
									label={{
										position: 'bottom',
										style: { fontWeight: 'bold' },
										value: 'Avg. GBP views',
										fill: 'var(--grey-darkest)'
									}}
								/>
								<Chart.ReferenceLine
									stroke="var(--grey-darker)"
									ifOverflow="visible"
									segment={[
										{
											x: averageOrganicViews,
											y: -0.5
										},
										{
											x: averageOrganicViews,
											y: chartData.length
										}
									]}
									label={{
										position: 'bottom',
										style: { fontWeight: 'bold' },
										value: 'Avg. with local inventory',
										fill: 'var(--grey-darkest)'
									}}
								/>
							</>
						)}
						<Chart.Tooltip cursor={false} content={Tooltip} />
					</Chart.ComposedChart>
				</Chart.ResponsiveContainer>
			</div>
		</Stats.Card>
	);
};

export const OrganicVisibility = () => {
	const { data: shops } = useShop();

	const [shopIds, setShopIds] = useState(shops.map((shop) => shop.id));
	const [options, setOptions] = useState({
		dataLabels: true,
		referenceLines: false,
		summaryView: false
	});

	return (
		<>
			<Page.Header>
				<Link to="..">
					<Page.BackButton>Reports</Page.BackButton>
				</Link>
				<h1>Organic visibility impact</h1>
				<ReportFilters>
					<DatePicker />
					<LocationFilter shops={shops} selectedShops={shopIds} setSelectedShops={setShopIds} />
					<OptionsButton
						options={[
							{
								id: 'dataLabels',
								label: 'Data labels'
							},
							{
								id: 'referenceLines',
								label: 'Average lines'
							},
							{
								id: 'summaryView',
								label: 'Summary view'
							}
						]}
						values={options}
						setValues={setOptions}
					/>
				</ReportFilters>
			</Page.Header>
			<Page.Section>
				<Suspense fallback={<SpinnerCard message="Generating report..." />}>
					<OrganicVisibilityCard options={options} shopIds={shopIds} />
				</Suspense>
			</Page.Section>
		</>
	);
};
