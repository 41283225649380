import React, { forwardRef } from 'react';
import { useFormContext } from 'react-hook-form';

import { InputGroup, Textarea, UItils } from '@nearst/ui';

import ErrorMessage from '../ErrorMessage';
import schema from '../../EditForm/schema';

import styles from '../Fields.module.scss';

const TextArea = forwardRef(function TextArea({ className, name, required = false, ...rest }, ref) {
	const {
		register,
		formState: { errors }
	} = useFormContext();

	const capitalized = name[0].toUpperCase() + name.slice(1);

	return (
		<InputGroup className={className} label={capitalized} htmlFor={name} requiredField={required}>
			<>
				<Textarea
					id={name}
					className={UItils.classnames(`${errors?.[name] ? styles.invalid : ''}`, className)}
					spellCheck
					rows={10}
					ref={ref}
					{...register(name, { required: required ? 'Required' : '', validate: schema[name] })}
					{...rest}
				/>
				<ErrorMessage name={name} error={rest.error} />
			</>
		</InputGroup>
	);
});

export default TextArea;
