import React from 'react';

import './style.scss';

const Loader = ({ className = '' }) => {
	return (
		<div className={`loader-ring ${className}`}>
			<div />
			<div />
			<div />
			<div />
		</div>
	);
};
export default Loader;
