import React from 'react';
import { Switcher } from '@nearst/ui';
import * as Stats from '@components/StatsCard';
import { sumField } from '../../../insights/utils';

import styles from './SWIS.module.scss';

const LocalListingsFigures = ({ swisDailyCorrected, swisDailyCorrectedLookback, swisDaily, swisDailyLookback }) => {
	const impressions = sumField('impressions', swisDailyCorrected);
	const impressionsLookback = sumField('impressions', swisDailyCorrectedLookback);
	const clicks = sumField('clicks', swisDaily);
	const clicksLookback = sumField('clicks', swisDailyLookback);
	const ctr = clicks && impressions ? ((clicks / impressions) * 100).toFixed(2) : 0;
	const previousCtr = clicksLookback && impressionsLookback ? ((clicksLookback / impressionsLookback) * 100).toFixed(2) : 0;

	return (
		<>
			<Switcher space="2rem" className={styles.stats}>
				<Stats.Block description="Views" currentValue={Number(impressions)} previousValue={Number(impressionsLookback)} />
				<Stats.Block description="Product Clicks" currentValue={Number(clicks)} previousValue={Number(clicksLookback)} />

				<Stats.Figure>
					{`${ctr}%`}
					<Stats.FigureDescription>
						CTR
						<Stats.Tooltip>
							Your click-through rate is a measure of how relevant your products are to the search terms
						</Stats.Tooltip>
						<Stats.PercentageChange previousValue={previousCtr} currentValue={ctr} />
					</Stats.FigureDescription>
				</Stats.Figure>
			</Switcher>
		</>
	);
};

export default LocalListingsFigures;
