import React, { Suspense } from 'react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';

import Loader from '../Loader';
import { classnames } from '../../utils';

import styles from './Page.module.scss';

/**
 * Wraps all pages. Only to be used in our router, not in individual pages.
 *
 * @typedef PageMainProps
 * @property {React.ReactNode} children
 * @property {React.ReactNode} [fallback]
 * @property {string} [className]
 *
 * @param {PageMainProps} props
 * @returns JSX.Element
 */
export const Main = ({ className, children, fallback }) => {
	return (
		<main id="scroller" className={classnames(styles.main, className)}>
			<Suspense fallback={fallback}>{children}</Suspense>
		</main>
	);
};

/**
 * Page header - usually used for the title and description of the page.
 *
 * Example:
 * ```jsx
 * <Page.Header>
 *   <h1>Your channels</h1>
 *   <p>Choose where your products are shown online.</p>
 * </Page.Header>
 * ```
 *
 * @typedef PageHeaderProps
 * @property {React.ReactNode} children
 * @property {string} [className]
 *
 * @param {PageHeaderProps} props
 * @returns JSX.Element
 */
export const Header = ({ children, className = '' }) => {
	return (
		<div className={classnames(styles.headerSection, className)}>
			<div className={styles.header}>
				<Suspense fallback={<Loader />}>{children}</Suspense>
			</div>
		</div>
	);
};

/**
 * Header component to show a split title and CTA on the right.
 *
 * Example:
 * ```jsx
 * <Page.Header>
 * 	<Page.HeaderCta>
 *   <h1>Your channels</h1>
 *   <p>Choose where your products are shown online.</p>
 * 	</Page.HeaderCta>
 * </Page.Header>
 * ```
 * @typedef PageHeaderCtaProps
 * @property {React.ReactNode} children
 *
 * @param {PageHeaderCtaProps} props
 * @returns JSX.Element
 */
export const HeaderCta = ({ children }) => {
	return <div className={styles.headerCta}>{children}</div>;
};

/**
 * Page section - use for the body parts of the page.
 *
 * @typedef PageSectionProps
 * @property {React.ReactNode} children
 * @property {string} [className]
 * @property {boolean} [sidebarLayout=false]
 * @param {PageSectionProps} props
 * @returns JSX.Element
 */
export const Section = ({ children, className = '', sidebarLayout = false }) => {
	return (
		<div className={classnames(styles.section, sidebarLayout ? styles.sidebarLayout : '', className)}>
			<Suspense fallback={<Loader />}>{children}</Suspense>
		</div>
	);
};

/**
 * Styled back button, often used within a page header.
 *
 * Example:
 * ```jsx
 * <Link href="/channels">
 *   <BackButton>All channels</BackButton>
 * </Link>
 * ```
 *
 * @typedef {React.HTMLProps<HTMLSpanElement>} PageBackButtonProps
 * @property {React.ReactNode} children
 * @property {string} [className]
 *
 * @param {PageBackButtonProps} props
 * @returns JSX.Element
 */
export const BackButton = ({ children, className = '', ...props }) => {
	return (
		<span className={classnames(styles.backButton, className)} {...props}>
			{children} <ChevronRightIcon />
		</span>
	);
};

/**
 * Page sidebar - a vertical section to one side of the page. To be used with Content inside a Section
 *
 * Example:
 * ```jsx
  <Page.Section sidebarLayout>
	<Page.Section.Content>Important main contenty things</Page.Section.Content>
	<Page.Section.Sidebar position="right">
		<ul>
			<li>Some information</li>
			<li>Something else</li>
		</ul>
	</Page.Section.Sidebar>
</Page.Section>;
 * ```
 *
 * @typedef PageSidebarProps
 * @property {React.ReactNode} children
 * @property {"right"|"left"} [position="right"]
 * @property {string} [className]
 *
 * @param {PageSidebarProps} props
 * @returns JSX.Element
 */
const Sidebar = ({ children, className = '', position = 'right' }) => {
	return (
		<div className={classnames(styles.sidebar, styles[position], className)}>
			<Suspense fallback={<Loader />}>{children}</Suspense>
		</div>
	);
};

Section.Sidebar = Sidebar;

/**
 * Page content - the main content of the page. To be used with Sidebar inside a Section
 *
 * Example:
 * ```jsx
  <Page.Section sidebarLayout>
	<Page.Section.Content>Important main contenty things</Page.Section.Content>
	<Page.Section.Sidebar position="right">
		<ul>
			<li>Some information</li>
			<li>Something else</li>
		</ul>
	</Page.Section.Sidebar>
</Page.Section>
 * ```
 *
 * @typedef PageContentProps
 * @property {React.ReactNode} children
 * @property {string} [className]
 *
 * @param {PageContentProps} props
 * @returns JSX.Element
 */
const Content = ({ children, className = '' }) => {
	return (
		<div className={classnames(styles.content, className)}>
			<Suspense fallback={<Loader />}>{children}</Suspense>
		</div>
	);
};

Section.Content = Content;
