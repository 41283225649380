import React from 'react';
import { Card } from '@nearst/ui';

import { ICON_STATUS } from '../constants';
import OnboardingIcon from '../OnboardingIcon';

import styles from './OnboardingStep.module.scss';

const OnboardingStep = ({ iconText = '', status = ICON_STATUS.DISABLED, title = '', children }) => {
	const showChildren = status !== ICON_STATUS.DISABLED;
	return (
		<Card.Section>
			<div className={styles.onboardingStepContainer}>
				<OnboardingIcon status={status} text={iconText} />
				<div className={styles.onboardingStepBlock}>
					<div>{title}</div>
					{showChildren && <div className={styles.onboardingStepChildren}>{children}</div>}
				</div>
			</div>
		</Card.Section>
	);
};

export default OnboardingStep;
