import axios from 'axios';
import myShopClient from './nearlive';

export async function getMyShops() {
	const client = await myShopClient();
	const { data } = await client.get('/api/my-shop');
	return data;
}

export async function getHubspotAuth() {
	const client = await myShopClient();
	const { data } = await client.get('/api/hubspot-auth');
	return data;
}

export async function updateMyShop(id, update) {
	const client = await myShopClient();
	const { data } = await client.put(`/api/my-shop/${id}`, update);
	return data;
}

export async function updateLastLogin() {
	const client = await myShopClient();
	await client.put(`/api/last-login`);
}

export async function saveFileToS3(id, file) {
	const client = await myShopClient();
	const query = new URLSearchParams({ filename: file.name, shopUUID: id, ContentType: file.type });
	const {
		data: { url }
	} = await client.get(`/api/upload-image?${query.toString()}`);
	await axios.put(url, file, { headers: { 'Content-Type': file.type } });
	return url.split('?')[0];
}

export default {
	getMyShops,
	getHubspotAuth,
	updateMyShop,
	saveFileToS3,
	updateLastLogin
};
