import React from 'react';
import { format } from 'date-fns';
import ReportDownloadCard from '@components/ReportDownloadCard';
import { getMultipleInsights } from '@services/insights';
import { formatPrice } from '@utils/currency';

const LocalAdsPerformance = () => {
	const getData = async ({ ids, shops, startDate, endDate }) => {
		const from = format(startDate, 'yyyy-MM-dd');
		const to = format(endDate, 'yyyy-MM-dd');

		const query = {
			table: 'local_ads',
			from,
			to,
			id: ids,
			columns: ['clicks', 'impressions', 'cpc', 'shop_id'],
			sum: ['clicks', 'impressions'],
			avg: ['cpc']
		};

		const response = await getMultipleInsights([query]);

		return response.map((row) => {
			const shop = shops.find((s) => s.id === row.shop_id);
			const cpc = formatPrice(row.cpc, shop.billingCurrency);
			return {
				'Location name': shop.name,
				Impressions: row.impressions,
				Clicks: row.clicks,
				'Calculated footfall': Math.ceil(row.clicks * 0.13),
				'Average Cost Per Click': cpc
			};
		});
	};

	return (
		<ReportDownloadCard
			getData={getData}
			title="Local Ads performance"
			description="This includes your overall engagement generated by paid listings, as well as calculated footfall and average cost per click."
		/>
	);
};

export default LocalAdsPerformance;
