import OrganicVisibilityIllustration from '../../assets/images/reports/organic-visibility.svg';
import OrganicVisibilityLockedIllustration from '../../assets/images/reports/organic-visibility-locked.svg';
import AdsImpactIllustration from '../../assets/images/reports/ads-impact.svg';
import AdsImpactLockedIllustration from '../../assets/images/reports/ads-impact-locked.svg';
import IndustryInsightsIllustration from '../../assets/images/reports/industry-insights.svg';
import ClicksToSalesIllustration from '../../assets/images/reports/clicks-to-sales.svg';
import ComingSoonIllustration from '../../assets/images/reports/coming-soon.svg';

export const reports = [
	{
		id: 'organic-visibility',
		image: OrganicVisibilityIllustration,
		lockedImage: OrganicVisibilityLockedIllustration,
		title: 'Organic visibility impact',
		description: 'Track local inventory impact on overall organic visibility.',
		advanced: true
	},
	{
		id: 'ads-impact',
		image: AdsImpactIllustration,
		lockedImage: AdsImpactLockedIllustration,
		title: 'Local Ads impact',
		description: 'Track the uplift in local visibility generated by Local Ads.',
		advanced: true
	},
	{
		id: 'industry-insights',
		image: IndustryInsightsIllustration,
		title: 'Industry insights',
		description: 'Discover popular products in each retail category in the last 7 days.'
	},
	{
		id: 'clicks-to-sales',
		image: ClicksToSalesIllustration,
		title: 'Clicks to sales',
		description: 'These are your products that have been clicked on and sold in-store within 24 hours.'
	},
	{
		id: 'popular-products',
		image: ComingSoonIllustration,
		lockedImage: ComingSoonIllustration,
		title: 'Popular products',
		description: 'The most popular products across all your channels.',
		comingSoon: true,
		advanced: true
	}
];
