import React from 'react';
import { Link } from 'react-router-dom';

import { Button, Card, Pill, StatusIcon } from '@nearst/ui';
import { useShop } from '@services/ShopProvider';

import styles from './ChannelCard.module.scss';

const ChannelCard = ({ channel }) => {
	const { shop } = useShop();

	const enabled = channel.enabledShops.length > 0;
	const url = `/channels/${shop.id}/manage/${channel.id}`;

	return (
		<Card className={styles.card} sectioned>
			<div className={styles.header}>
				<div>
					<div className={styles.icon}>
						<img src={channel.iconUrl} alt={channel.label} role="presentation" />
					</div>
					{channel.label}
				</div>

				{channel.comingSoon && !enabled && (
					<Pill colour="blue" size="large">
						Coming soon
					</Pill>
				)}
				{enabled && <StatusIcon status="active" title="Enabled" />}
			</div>

			<p>{channel.description}</p>

			<div className={styles.actions}>
				<Button data-cy={`manage-${channel.id}-button`} as={Link} to={url} primary={!enabled} secondary={enabled} small>
					{enabled ? 'Manage connection' : 'Get started'}
				</Button>
			</div>
		</Card>
	);
};

export default ChannelCard;
