import React from 'react';
import Icon from '../Icon';
import { classnames } from '../../utils';

import styles from './TileIcon.module.scss';

/**
 * @param {Object} props
 * @param {'green' | 'blue' | 'grey' | 'yellow' | 'orange'} [props.colour='grey']
 * @param {string?} [props.className]
 * @param {string} props.children
 */
const TileIcon = ({ className = '', children, colour = 'grey' }) => {
	return <Icon className={classnames(styles.icon, styles[colour], className)}>{children}</Icon>;
};

export default TileIcon;
