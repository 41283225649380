import React from 'react';
import { format } from 'date-fns';

import { Button, Loader, Card } from '@nearst/ui';

import AuthorizationTooltip from '@components/AuthorizationTooltip';
import { useCheckPermission } from '@services/auth/hooks';
import AddOns from './AddOns';

const SubscriptionDetails = ({ customer }) => {
	const authorized = useCheckPermission('org:billing:manage');

	if (!customer) {
		return (
			<div className="card__placeholder">
				<Loader />
			</div>
		);
	}

	const sub = customer.subscription;

	return customer.subscription ? (
		<>
			<Card.Section>
				<div className="switcher--between">
					<div>
						<strong>
							{sub.title} ({sub.interval === 'month' ? 'monthly' : 'annual'})
						</strong>
						<br />
						{sub.amount.toLocaleString('en-US', {
							style: 'currency',
							currency: sub.currency
						})}{' '}
						/ {sub.interval} + tax, {sub.renews ? `renews ${format(new Date(sub.renews), 'MMM dd, yyyy')}` : 'not renewing'}
					</div>
					<div>
						<AuthorizationTooltip authorized={authorized}>
							<Button disabled={!authorized} href={`mailto:support@near.st?subject=Change%20plan`}>
								Change plan
							</Button>
						</AuthorizationTooltip>
						{/*<Button>Cancel subscription</Button>*/}
					</div>
				</div>
			</Card.Section>
			<AddOns />
		</>
	) : (
		<div className="card__placeholder">
			<p>No active subscription.</p>
		</div>
	);
};

export default SubscriptionDetails;
