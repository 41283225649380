const checkImageOrigin = (_images) => {
	// weird bug where Google doesn't like external urls - this keeps them out
	let images = [];
	if (_images && _images.length && !_images[0].startsWith(`https://shop-production-images-bucket.s3.eu-west-1.amazonaws.com/`)) {
		_images = [];
	} else {
		images = _images;
	}
	return images;
};

const getInvalidFields = (data, fields) => {
	return fields.filter((field) => data[field] === undefined || data[field].length === 0);
};

const removeCid = (barcode) => {
	if (!barcode.includes('cid')) {
		return barcode;
	}

	return barcode.split('-').slice(2).join('-');
};

export { checkImageOrigin, getInvalidFields, removeCid };
