import { eachDayOfInterval, format, addDays, formatDistanceToNowStrict, isToday, isYesterday, isAfter, subDays } from 'date-fns';

export const generateDays = (from, to, shift = false) => {
	return eachDayOfInterval({
		start: addDays(new Date(from), shift ? 2 : 1),
		end: new Date(to)
	});
};

export const getUploadsToday = (inventorySources) => {
	if (!inventorySources?.length) return [];
	return inventorySources
		?.filter((source) => isToday(new Date(source.latestIngest?.timestamp || source.latestIngest?.customProductTimestamp)))
		.map((source) => ({
			total_uploaded_barcodes: source.latestIngest?.stockIngested,
			in_stock_barcodes: source.latestIngest?.activeRecords,
			date: format(new Date(), 'yyyy-MM-dd')
		}));
};

const isDisconnected = (item) => item.total_uploaded_barcodes === 0;
const isConnected = (item) => item.total_uploaded_barcodes > 0;

export const sortInventoryByStatus = (date, dataSet) => {
	let disconnected = 0;
	let connected = 0;
	let total_barcodes = 0;
	let in_stock_barcodes = 0;

	const allShopsInventoryForDate = dataSet?.filter((i) => i.date === date);
	allShopsInventoryForDate?.forEach((item) => {
		if (isDisconnected(item)) {
			disconnected++;
			return;
		}
		if (isConnected(item)) {
			connected++;
			total_barcodes += item.total_uploaded_barcodes;
			in_stock_barcodes += item.in_stock_barcodes;
			return;
		}
	});

	return { connected, disconnected, total_barcodes, in_stock_barcodes, total: disconnected + connected };
};

export const getColor = (status) => {
	if (status.connected === 0 && status.disconnected > 0) return 'red';
	if (status.connected > 0 && status.disconnected > 0) return 'amber';
	if (status.connected > 0 && status.disconnected === 0) return 'green';

	return 'grey';
};

export const formatChartData = ({ dbData, from, to, uploadsToday }) => {
	if (!dbData) return;
	const days = generateDays(from, to, !!uploadsToday);

	const formattedDbData = days.map((date, index) => {
		const formattedDate = format(date, 'yyyy-MM-dd');

		const { total, connected, disconnected, total_barcodes, in_stock_barcodes } = sortInventoryByStatus(formattedDate, dbData);

		const status = { connected, disconnected, total };

		let dateLabel = '';
		if (index === 0) dateLabel = formatDistanceToNowStrict(date);
		if (index === days.length - 1 && !uploadsToday) {
			if (isToday(date)) dateLabel = 'Today';
			else if (isYesterday(date)) dateLabel = 'Yesterday';
			else {
				dateLabel = formatDistanceToNowStrict(date);
			}
		}

		const dataPoint = {
			date: formattedDate,
			total_barcodes,
			in_stock_barcodes,
			yData: 1,
			xLabel: dateLabel,
			status,
			color: getColor(status)
		};
		return dataPoint;
	});

	if (uploadsToday) {
		return [...formattedDbData, uploadsToday];
	}
	return formattedDbData;
};

const onboardingStatuses = [
	'shop.pos.pending',
	'shop.pos.first-upload',
	'shop.signup.handover',
	'shop.google.gmb-requested',
	'shop.google.gmb-approved',
	'shop.google.gmb-nearst-managed',
	'shop.google.setup-started',
	'shop.google.setup-completed',
	'shop.google.pos-link-ok'
];

export const checkOnboardingPermissions = (shop, status) => {
	const passedOnboarding = !onboardingStatuses.includes(shop.onboardingStatus?.type);
	const aheadOfStatus =
		onboardingStatuses.findIndex((e) => e === shop.onboardingStatus?.type) >= onboardingStatuses.findIndex((e) => e === status);
	return passedOnboarding || aheadOfStatus;
};

export const getInventorySourceStatus = (shops) => {
	const stock = { total: shops.length, connected: 0, disconnected: 0, onboarding: 0 };

	const products = { total: 0, connected: 0, disconnected: 0, onboarding: 0 };

	shops.forEach((shop) => {
		const hasStockUpload = shop.stockInventorySource?.latestIngest;
		const hasRecentStockUpload =
			hasStockUpload && isAfter(new Date(shop.stockInventorySource?.latestIngest?.timestamp), subDays(new Date(), 3));
		const hasProductSource = shop.productInventorySource;
		const hasProductUpload = shop.productInventorySource?.latestIngest;
		const hasRecentProductUpload =
			hasProductUpload &&
			isAfter(new Date(shop.productInventorySource?.latestIngest?.customProductTimestamp), subDays(new Date(), 3));

		if (hasRecentStockUpload) {
			stock.connected++;
		} else if (hasStockUpload) {
			stock.disconnected++;
		} else {
			stock.onboarding++;
		}

		if (hasProductSource) {
			products.total++;
		}
		if (hasRecentProductUpload) {
			products.connected++;
		} else if (hasProductUpload) {
			products.disconnected++;
		} else {
			products.onboarding++;
		}
	});

	return { products, stock };
};

export const formattedTodaysInventorySourceData = (inventorySources, shops) => {
	const uploadsToday = getUploadsToday(inventorySources);

	const date = new Date();
	const formattedDate = format(date, 'yyyy-MM-dd');

	if (!uploadsToday.length) {
		return {
			date: formattedDate,

			yData: 1,
			xLabel: 'Today',
			status: { total: 0 },
			color: 'white'
		};
	}

	const status = getInventorySourceStatus(shops).stock;

	const { total_barcodes, in_stock_barcodes } = sortInventoryByStatus(formattedDate, uploadsToday);

	const dataPoint = {
		date: formattedDate,
		total_barcodes,
		in_stock_barcodes,
		yData: 1,
		xLabel: 'Today',
		status,
		color: getColor(status)
	};
	return dataPoint;
};
