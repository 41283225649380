import React, { forwardRef } from 'react';

import { classnames } from '../../utils';
import styles from './Inputs.module.scss';

/**
 * One or more input fields grouped together with a label.
 *
 * @param {object} props
 * @param {string} props.label The display label for the input group
 * @param {string} props.htmlFor The ID of the input field the label relates to
 * @param {boolean} props.requiredField To add an asterisk to inputs which are required
 * @param {string} props.className To add styling to the outer div of the input group
 * @param {React.JSX.Element} props.children The input field(s) to be grouped
 * @returns React.JSX.Element
 */
export const InputGroup = ({ className, label, htmlFor, children, requiredField = false }) => {
	return (
		<div className={classnames(styles.inputGroup, className)}>
			<label htmlFor={htmlFor}>
				{label} {requiredField ? <span className={styles.required}>*</span> : ''}
			</label>
			<div className={styles.inputGroupContent}>{children}</div>
		</div>
	);
};

/**
 * Text field (renders as `<input>`).
 * @type {React.FC<TextInputProps>}
 * @typedef TextInputProps
 * @property {string} [type]
 * @property {string} [suffix] Optional suffix to display after the input field
 * @property {string} [className] Optional class to apply to the input field
 * @property {string} [containerClassName] Optional class to apply to the container
 *
 * @param {React.InputHTMLAttributes<HTMLInputElement> & TextInputProps} props
 *
 * @returns React.JSX.Element
 */

export const TextInput = forwardRef(function TextInput(
	{ type = 'text', suffix = '', className = '', containerClassName = '', ...props },
	ref
) {
	return (
		<div className={classnames(styles.inputContainer, containerClassName)}>
			<input className={classnames(styles.input, className)} type={type} {...props} ref={ref} />
			{suffix && <span className={styles.inputSuffix}>{suffix}</span>}
		</div>
	);
});

/**
 * Large text field (renders as `<textarea>`).
 *
 * @type {React.FC<Props>}
 * @typedef Props
 * @property {string} [className]
 * @param {React.TextareaHTMLAttributes<HTMLTextAreaElement> & Props} props
 * @returns React.JSX.Element
 */
export const Textarea = forwardRef(function Textarea({ className, ...props }, ref) {
	return <textarea className={classnames(styles.textarea, className)} {...props} ref={ref} />;
});

/**
 * Select field (renders as `<select>`).
 *
 * @typedef SelectProps
 * @property {string} [className]
 *
 * @param {React.InputHTMLAttributes<HTMLSelectElement> & SelectProps} props
 * @returns React.JSX.Element
 */
export const Select = ({ className = '', ...props }) => {
	return (
		<div className={styles.selectContainer}>
			<select className={classnames(styles.select, className)} {...props} />
		</div>
	);
};

/**
 * Checkbox field (renders as `<input>`).
 *
 * @typedef CheckboxProps
 * @property {string} [label] Label for the checkbox (e.g. 'send text message for new orders')
 *
 * @param {React.InputHTMLAttributes<HTMLInputElement> & CheckboxProps} props
 * @returns React.JSX.Element
 */
export const CheckboxInput = ({ label = '', ...props }) => {
	return (
		<label aria-label="checkbox-label" className={styles.checkboxLabel}>
			<input aria-labelledby="checkbox-label" className={styles.checkbox} type="checkbox" {...props} />
			{label}
		</label>
	);
};
