import React from 'react';
import useSWR from 'swr';

import { Page } from '@nearst/ui';
import LiaPerformanceCard from '../components/LiaPerformanceCard';
import PopularProductsCard from '../components/PopularProductsCard';
import DatePicker from '@components/DatePicker';
import ComparisonCard from '../components/ComparisonCard';
import LocalSearchTermsCard from '../LocalAds/InsightCards/LocalSearchTermsCard';
import { useShop } from '@services/ShopProvider';
import { useInsightsDate } from '@services/InsightsDateProvider';
import { checkStoreVisitsData, getLocalAdsData } from './queries';

import styles from './LocalAdsChain.module.scss';
import StoreVisitsCard from '../components/StoreVisitsCard';
import { getDimensions } from './dimensions';

const LocalAdsChain = () => {
	const { data: shops } = useShop();
	const { from, to, selectedRange } = useInsightsDate();

	const { data: hasStoreVisits } = useSWR('storeVisitsCheck', () => checkStoreVisitsData(shops.map((shop) => shop?.id)));

	return (
		<>
			<Page.Header>
				<h1>Local Ads</h1>
				<p>Track and compare the performance of your Local Ads campaigns across all locations.</p>
				<DatePicker showComparison showInterval />
			</Page.Header>
			<Page.Section>
				<div className={styles.fullWidthCard}>
					<LiaPerformanceCard shops={shops} />
					<StoreVisitsCard shops={shops} />
					<ComparisonCard
						subtitle={selectedRange}
						query={(filters) => getLocalAdsData({ from, to, ...filters })}
						dimensions={getDimensions(shops, hasStoreVisits)}
					/>
					<PopularProductsCard />
					<LocalSearchTermsCard shops={shops} />
				</div>
			</Page.Section>
		</>
	);
};

export default LocalAdsChain;
