import React from 'react';

import { Card, Page } from '@nearst/ui';
import ErrorBoundary from '@components/ErrorBoundary';
import { Protect, UserProfile } from '@clerk/clerk-react';

import styles from './Account.module.scss';
import AccessDenied from '../../../errors/AccessDenied';

const AccountProfile = () => {
	return (
		<Protect permission="org:user_profile:read" fallback={<AccessDenied />}>
			<div className={styles.container}>
				<Page.Header>
					<h1>My Account</h1>
					<p>Manage your account settings.</p>
				</Page.Header>

				<Page.Section>
					<Card>
						<ErrorBoundary>
							<UserProfile />
						</ErrorBoundary>
					</Card>
				</Page.Section>
			</div>
		</Protect>
	);
};

export default AccountProfile;
