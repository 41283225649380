import React from 'react';
import * as Stats from '@components/StatsCard';
import { Stack, InfoLink } from '@nearst/ui';
import VideoLink from '@components/VideoLinkV2';
import InsightCardContainer from '../../components/InsightCardContainer';

const Explainer = () => {
	return (
		<InsightCardContainer>
			<Stack>
				<Stats.Title>What is See What&apos;s In Store?</Stats.Title>
				<p>See What's In Store displays your in-store products on your Google Business Profile listing.</p>
				<p>
					Local listings appear not just in Google Search, but also within Images, Shopping, and Lens search results, as well as
					on Google Maps.
				</p>
				<VideoLink id="DaWKrSxYEgY" />
				<Stack>
					<Stats.Title>How can you improve your overall Google ranking?</Stats.Title>
					<p>Your local listings performance is impacted by information displayed on your Google Business Profile. </p>
					<p>To maximise impact make sure your profile is up to date, especially the store address and opening hours.</p>
					<InfoLink target="_blank" href="https://support.near.st/en/collections/3685453-google-see-what-s-in-store">
						FAQs
					</InfoLink>
				</Stack>
			</Stack>
		</InsightCardContainer>
	);
};

export default Explainer;
