import React, { useContext } from 'react';
import { MeasureContext } from '..';
import { DropdownItem, DropdownMenu } from '@nearst/ui';

const Toggle = ({ insightType }) => {
	const { selectedMeasure, setMeasure } = useContext(MeasureContext);
	const impressionsText = insightType === 'lia' ? 'Impressions' : 'Views';

	return (
		<DropdownMenu primary={false} buttonText={selectedMeasure === 'impressions' ? impressionsText : 'Clicks'}>
			<DropdownItem active={selectedMeasure === 'impressions'} onClick={() => setMeasure('impressions')}>
				{impressionsText}
			</DropdownItem>
			<DropdownItem active={selectedMeasure === 'clicks'} onClick={() => setMeasure('clicks')}>
				Clicks
			</DropdownItem>
		</DropdownMenu>
	);
};

export default Toggle;
