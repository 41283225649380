import { useAuth } from '@clerk/clerk-react';
import * as Sentry from '@sentry/react';
import { useUpdateIntercom } from '@services/Intercom/utils';
import { useShop } from '@services/ShopProvider';
import { getAuthSession } from '@services/auth';
import shopApi from '@services/shop';
import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import { useHubspot } from '../../utils/hubspot';

const usePageViews = () => {
	let location = useLocation();

	useEffect(() => {
		const page = location.pathname;

		// Strips the shops uuid out of the url so we can track pages correctly
		const title = page.replace(/\/[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}/, '');
		ReactGA.send({ hitType: 'pageview', page, title: `my_shop_${title}` });
		mixpanel.track('Page view', { distinct_id: mixpanel.get_distinct_id(), title, page });
	}, [location]);
};

const useAnalytics = () => {
	const { actor, isSignedIn } = useAuth();

	useEffect(() => {
		if (!actor && isSignedIn) {
			shopApi.updateLastLogin().catch(console.error);
		}
	}, [actor, isSignedIn]);
};

const useMixpanelIdentity = () => {
	const { shop } = useShop();
	const { actor } = useAuth();
	useEffect(() => {
		(async () => {
			if (!shop) {
				return;
			}
			const { email } = await getAuthSession();
			if (
				email.includes('@near.st') ||
				email.includes('@mailslurp.com') ||
				shop.featureFlags?.includes('mixpanel-exclude') ||
				!!actor
			) {
				mixpanel.opt_out_tracking();
			}
			mixpanel.identify(email);
			mixpanel.people.set({
				$email: email,
				USER_ID: email,
				'Shop ID': shop.legacyId,
				'Shop name': shop.name,
				'Shop city': shop.city,
				'Shop country': shop.country,
				'Shop category': shop.category || 'unknown',
				'Point of sale': shop.stockInventorySource?.pointOfSale || shop.stockInventorySource?.connector,
				'POS connector': shop.stockInventorySource?.connector || shop.stockInventorySource?.pointOfSale,
				'In setup': shop.inSetup ? 'true' : 'false',
				'PLUS plan': (shop.currentPlusPlan && shop.currentPlusPlan.title) || 'BASE',
				'PLUS billing cycle': (shop.currentPlusPlan && shop.currentPlusPlan.interval) || 'week',
				'Facebook setup': shop.facebookCatalogEnabled ? 'true' : 'false',
				'Last login': new Date().toISOString()
			});
		})();
	}, [shop, actor]);
};

const useSentry = () => {
	const { actor, isSignedIn, userId } = useAuth();
	useEffect(() => {
		if (!actor && isSignedIn) {
			Sentry.setUser({ id: userId });
		}
	}, [actor, isSignedIn, userId]);
};

const AnalyticsWrapper = ({ children }) => {
	useHubspot();
	useMixpanelIdentity();
	useUpdateIntercom();
	usePageViews();
	useAnalytics();
	useSentry();
	return children;
};

export default AnalyticsWrapper;
