import React from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';
import { format } from 'date-fns';

import { Button, Pill, Stack } from '@nearst/ui';
import * as Stats from '@components/StatsCard';
import VideoLinkV2 from '@components/VideoLinkV2';
import InsightCardContainer from '../../components/InsightCardContainer';

import styles from './PlanCard.module.scss';
import { Link } from 'react-router-dom';
import { formatPrice, parseCurrency } from '@utils/currency';

const PlanCard = ({ shop }) => {
	const date = format(new Date(shop.currentPlusPlan?.startDate || shop.createdAt), 'dd/MM/yyyy');
	const totalLiaAmount = shop.totalAdsBudget;
	const liaValue = parseCurrency(totalLiaAmount, { currency: shop.billingCurrency });

	return (
		<InsightCardContainer>
			<Stack>
				<Stats.Title>Your Local Ads Plan</Stats.Title>
				{totalLiaAmount > 0 ? (
					<>
						<p>
							<strong>LIA {liaValue.value}</strong> ({liaValue.format()} per day)
							{shop.subscriptionLiaBudget > 0 && (
								<Stats.Tooltip>
									{formatPrice(shop.subscriptionLiaBudget, shop.billingCurrency)} included as part of your NearSt{' '}
									{shop.plan || ''} subscription
								</Stats.Tooltip>
							)}
						</p>
						<p>
							<strong>Status:</strong> <Pill colour="green">Active</Pill>
						</p>
						<p>
							<strong>Start date:</strong> {date}
						</p>
					</>
				) : (
					<p>
						<strong>Status:</strong> <Pill colour="orange">Paused</Pill>
					</p>
				)}
				<p>
					<Button to={`/subscription/${shop.id}`} as={Link} primary className={styles.button}>
						Manage Plan
					</Button>
				</p>
				<Stats.Title>How do Local Ads work?</Stats.Title>
				<p>
					When you have NearSt Local Ads enabled, people using Google in the area around your shop will see your products at the
					top of the page.
				</p>
				<p>Using Local Ads on top of your free local listings can increase clicks by 50% and double your impressions.</p>
				<div>
					<VideoLinkV2 id="r7K34HVyPSg" />
					<Button link href={`https://support.near.st/en/collections/3685047-google-local-inventory-ads-lia`}>
						FAQs
					</Button>
				</div>
			</Stack>
		</InsightCardContainer>
	);
};

export default PlanCard;
