import { useOrganization, useUser } from '@clerk/clerk-react';
import { useEffect, useState } from 'react';
import { getOrganizationMemberships } from '.';
import useSWR from 'swr';

const useOrganizationMemberships = () => {
	const { organization } = useOrganization();

	return useSWR(`org-members-${organization?.id}`, () => organization && getOrganizationMemberships(organization.id));
};

export const useOrganisationUsers = () => {
	const [userState, setUserState] = useState([]);
	const { user } = useUser();
	const memberships = useOrganizationMemberships();
	const { isLoaded, invitations } = useOrganization({
		invitations: {
			pageSize: 100
		}
	});

	/**
	 * @callback UpdateFn
	 * @param {string} userId
	 * @param {object} update
	 * @returns {Promise<void>}
	 */

	/**
	 * @function optimisticUpdate
	 * @param {string} userId - Clerk User Id
	 * @param {object} update - update object
	 * @param {UpdateFn} updateFn - function to update
	 */
	const optimisticUpdate = async (userId, update, updateFn) => {
		setUserState((prevState) =>
			prevState.map((user) => {
				if (user.userId === userId) {
					return {
						...user,
						...update
					};
				}
				return user;
			})
		);

		try {
			await updateFn(userId, update);
		} catch (error) {
			// rollback
			setUserState(userState);
		}
	};

	useEffect(() => {
		if (memberships.data) {
			const newState = memberships.data.map((member) => ({
				...member,
				emailAddress: member.publicUserData.identifier,
				isMe: member.publicUserData.userId === user?.id,
				weeklyInsights: member.publicMetadata.emailWeeklyLiaUpdate,
				userId: member.publicUserData.userId
			}));

			if (invitations.data) {
				invitations.data.forEach((invite, i) => {
					newState.push({
						...invite,
						// @ts-ignore
						invited: true
					});
				});
			}

			setUserState(newState);
		}
	}, [invitations.data, memberships.data, user.id]);

	if (!isLoaded || memberships.isLoading) {
		return { isLoading: true };
	}
	return {
		users: userState,
		revalidate: () => {
			memberships.mutate();
			invitations?.revalidate();
		},
		optimisticUpdate
	};
};

export const useOrganisationRoles = () => {
	const { organization } = useOrganization();
	const { data } = useSWR(`org-available-roles-${organization?.id}`, () => organization?.getRoles());

	const roles = data?.data || [];

	// we don't use the default 'member' role
	return roles.sort((a, b) => a.permissions.length - b.permissions.length).filter((role) => role.key !== 'org:member');
};
