import { InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@mui/icons-material/Search';
import { debounce } from 'lodash';
import React from 'react';
import InventoryActions from '../../components/InventoryActions';
import InventoryFilter from '../Filter';
import { useInventory } from '@services/stock';
import styles from './InventoryViewer.module.scss';

const ActionBar = ({ shop, setBulkHideModal, setBulkUploadModalOpen, handleSearchChange, disabled }) => {
	const { isLoading: isInventoryLoading, isEmpty, filters, applyFilters, searchInput } = useInventory(shop.id);

	return (
		<div className={styles.actionContainer}>
			<TextField
				variant="outlined"
				className={styles.searchField}
				defaultValue={searchInput}
				onChange={debounce(handleSearchChange, 500)}
				placeholder="Search..."
				InputProps={{
					'data-testid': 'inventory-search-input',
					className: styles.searchInput,
					startAdornment: (
						<InputAdornment position="start">
							<SearchIcon className={styles.searchFieldIcon} />
						</InputAdornment>
					)
				}}
			/>
			<InventoryFilter className={styles.inventoryFilters} existingFilters={filters} onApply={applyFilters} />

			<div className={styles.actionsDropdown} data-cy="inventory-actions-dropdown">
				<InventoryActions
					setBulkHideModal={setBulkHideModal}
					setBulkUploadModalOpen={setBulkUploadModalOpen}
					disabled={isEmpty || isInventoryLoading || disabled}
				/>
			</div>
		</div>
	);
};

export default ActionBar;
