const Categories = require('../model/Categories');
const ChannelDefinition = require('../model/ChannelDefinition');

class UberEatsChannel extends ChannelDefinition {
	id = 'uber-eats';
	title = 'Uber Eats';
	description = 'Sync your inventory to Uber Eats and drive additional sales by offering quick delivery for in-store products.';
	category = Categories.LOCAL_COMMERCE;

	iconUrl = 'https://go.near.st/hubfs/channels/uber-eats.svg';
	coverImageUrl = 'https://go.near.st/hubfs/channels/headers/uber-eats.png';

	longDescription = `
### Overview
Connect your Uber Eats account and allow nearby customers to get products from your store delivered to them through the Uber Eats app.

### How it works
NearSt automatically creates and manages the menu for each of your locations. 

The menu is updated daily with the product information you provide in the dashboard, combined with real-time product availability from your POS system. This ensures you always have up to date menus, significantly reducing order cancellations and refunds.

You can manage all product-specific settings, such as category, custom prices and restrictions from the dashboard.

### Requirements
- Have an active Uber Eats Merchant account. [Sign up here](merchants.ubereats.com).
- Have a product data source connected to provide images and descriptions for your menu items

If you are a convenience sector retailer, we can help you source the right data. [Contact us](mailto:support@near.st).`;

	productSpecificVisibility = true;
	comingSoon = true;
	registerInterestUrl = 'https://share.hsforms.com/19injjLOlQSWETv6CHzZmXA16uos';
}

module.exports = UberEatsChannel;
