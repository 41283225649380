import { useShop } from '@services/ShopProvider';
import { Navigate } from 'react-router-dom';

const check = (shop) => {
	return !!shop.availableChannels?.['local-ads'];
};
const LocalAdsChecker = ({ children, to = '/home' }) => {
	const { shop, data: shops } = useShop();
	let liaAvailable = false;
	if (shop) {
		liaAvailable = check(shop);
	} else {
		liaAvailable = shops.some(check);
	}
	if (!liaAvailable) return <Navigate to={to} />;
	return children;
};
export default LocalAdsChecker;
