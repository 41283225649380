import currencyParser from 'currency.js';
import getSymbolFromCurrency from 'currency-symbol-map';

export const formatPrice = (value, currency = 'GBP') => {
	return currencyParser(value, {
		symbol: getSymbolFromCurrency(currency),
		precision: 2,
		separator: ','
	}).format();
};

export const parseCurrency = (value, options = {}) => {
	return currencyParser(value, {
		symbol: getSymbolFromCurrency(options.currency || 'GBP'),
		precision: 2,
		separator: ',',
		format: (valueInfo, symbolInfo) => {
			let value = valueInfo?.value;
			const symbol = symbolInfo?.symbol;
			if (options?.roundup) value = Math.ceil(value);
			return `${symbol}${value.toLocaleString()}`;
		}
	});
};
