import React from 'react';
import { skeleton } from './Skeleton.module.scss';
const Skeleton = ({ className = '', width = 512, height = 512 }) => {
	return (
		<svg
			className={`${skeleton} ${className}`}
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			preserveAspectRatio="none"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title>skeleton</title>

			<rect rx={4}>
				<animate attributeName="opacity" values="0.2;0.8;0.2" dur="2.5s" repeatCount="indefinite" />
			</rect>
		</svg>
	);
};
export default Skeleton;
