import React, { useState } from 'react';
import useSWRImmutable from 'swr/immutable';

import Dropdown from './Dropdown';
import SwitchPOS from './SwitchPOS';
import ConnectButton from '../ConnectButton';
import { getPosProviderDetail, isOverMininumCount } from '../utils';
import OnboardingStep from '../OnboardingStep';
import { CONTACT_US, ICON_STATUS, MIN_IN_STOCK_COUNT } from '../constants';
import { startInventoryConnection, getPosProviders, getInventoryConnection, INVENTORY_CONNECTION } from '@services/onboarding';

import styles from './POSConnect.module.scss';

const DEFAULT_TITLE = 'Connect your inventory through your point-of-sale system.';
const ICON_FALLBACK = '1';

const ConnectedPOS = ({ pos, latestIngest, onEdit, onSubmit, isActive }) => {
	const ingestIsValid = isOverMininumCount(latestIngest.inStockValidLines, MIN_IN_STOCK_COUNT);
	const isPartnerLead = pos?.connection === 'partner-lead';
	const isUnknownPOS = pos?.id === 'other' || !pos.connection;
	const showButton = !isPartnerLead || !isUnknownPOS;
	const label = pos?.label;

	if (isActive && !ingestIsValid) {
		return (
			<OnboardingStep
				iconText={ICON_FALLBACK}
				status={ICON_STATUS.ALERT}
				title="Your point-of-sale system is connected, but we haven’t received enough in-stock barcodes."
			>
				<div className={styles.container}>
					<SwitchPOS posName={label} onEdit={onEdit} />
					<p>{'Please ensure you have more than 50 barcodes in stock in your point-of-sale system.'}</p>
					{showButton && <ConnectButton text="Trigger new upload" onConnect={onSubmit} />}
				</div>
			</OnboardingStep>
		);
	}
	return (
		<OnboardingStep
			iconText={ICON_FALLBACK}
			status={ICON_STATUS.COMPLETED}
			title="Your point-of-sale system is successfully connected."
		>
			<div className={styles.container}>
				<SwitchPOS posName={label} onEdit={onEdit} hideEdit />
				<div>{`Received ${latestIngest?.numberOfLines || 0} products (${latestIngest?.inStockValidLines || 0} in stock).`}</div>
			</div>
		</OnboardingStep>
	);
};

const ConnectingPOS = ({ pos, posError, progress, onEdit, onSubmit }) => {
	const isDisconnected = progress === INVENTORY_CONNECTION.DISCONNECTED; // otherwise pending
	const isPartnerLead = pos?.connection === 'partner-lead';
	const isUnknownPOS = pos?.id === 'other' || !pos.connection;
	const label = pos?.label;

	const renderPosFeedback = () => {
		if (isPartnerLead) {
			return <p>We've emailed the POS provider to set up the integration. This usually takes 2-3 working days.</p>;
		}

		if (isUnknownPOS) {
			return <p>We will email you instructions on how to finish your inventory integration.</p>;
		}

		if (posError) {
			return (
				<>
					<p>An error occurred whilst trying to connect your inventory to NearSt. Please try again.</p>
					<ConnectButton text="Try again" onConnect={onSubmit} />
				</>
			);
		}

		return (
			<>
				{!isDisconnected && <p>Your inventory has not yet been connected.</p>}
				<ConnectButton text="Continue POS connection" onConnect={onSubmit} />
				{isDisconnected && <p>{CONTACT_US}</p>}
			</>
		);
	};

	return (
		<OnboardingStep
			iconText={ICON_FALLBACK}
			status={isDisconnected || posError ? ICON_STATUS.ALERT : ICON_STATUS.CURRENT}
			title={DEFAULT_TITLE}
		>
			<div className={styles.container}>
				<SwitchPOS posName={label} onEdit={onEdit} />
				{renderPosFeedback()}
			</div>
		</OnboardingStep>
	);
};

const Container = ({ isCurrentStage = false, shop }) => {
	const { data: fullPosList } = useSWRImmutable('pos', getPosProviders, { suspense: true });
	const posList = fullPosList.filter((pos) => pos.kinds.includes('stock'));

	const [editPOS, setEditPOS] = useState(false);
	const [selectedPOS, setSelectedPOS] = useState(null);
	const [errorMsg, setErrorMsg] = useState('');

	const inventoryProgress = getInventoryConnection(shop?.stockInventorySource?.latestIngest);
	const isPOSConnected = inventoryProgress.status === INVENTORY_CONNECTION.CONNECTED;
	const shopPOS = getPosProviderDetail({ value: shop?.stockInventorySource?.pointOfSale }, posList);

	const handleEditPOS = () => {
		setEditPOS(true);
	};

	const handleStartPOSConnect = async () => {
		try {
			const newPos = selectedPOS?.value || shop.stockInventorySource?.pointOfSale;
			const response = await startInventoryConnection({ shopId: shop.id, provider: newPos });
			if (response?.finishSetupLink) {
				window.location.href = response.finishSetupLink;
			} else {
				// reload to show state based on shop.pointOfSale
				window.location.reload();
			}
		} catch (e) {
			console.error('Error - failed to start pos connect', e);
			setErrorMsg('Unable to start your POS connection. Please try again.');
		}
	};

	if (errorMsg) {
		return (
			<OnboardingStep iconText={ICON_FALLBACK} status={ICON_STATUS.ALERT} title={DEFAULT_TITLE}>
				<p className={styles.errorMessage}>{errorMsg}</p>
			</OnboardingStep>
		);
	}

	return !shopPOS || editPOS ? (
		<OnboardingStep iconText={ICON_FALLBACK} status={ICON_STATUS.CURRENT} title={DEFAULT_TITLE}>
			<Dropdown selectedPOS={selectedPOS} posList={posList} onSelect={setSelectedPOS} onSubmit={handleStartPOSConnect} />
		</OnboardingStep>
	) : isPOSConnected ? (
		<ConnectedPOS
			pos={shopPOS}
			latestIngest={inventoryProgress.latestIngest}
			onEdit={handleEditPOS}
			onSubmit={handleStartPOSConnect}
			isActive={isCurrentStage}
		/>
	) : (
		<ConnectingPOS
			pos={shopPOS}
			posError={shop.stockInventorySource?.latestError}
			progress={inventoryProgress.status}
			onEdit={handleEditPOS}
			onSubmit={handleStartPOSConnect}
		/>
	);
};

export default Container;
