import mixpanel from 'mixpanel-browser';
import React, { useEffect, useRef } from 'react';

const useChartOptionTracking = (orgId, selector, option) => {
	const prevOption = useRef(option);

	useEffect(() => {
		if (prevOption.current !== option) {
			mixpanel.track('Comparison chart interaction', {
				selector,
				option,
				orgId
			});
		}

		prevOption.current = option;
	}, [orgId, selector, option]);
};

export default useChartOptionTracking;
