import React from 'react';
import { Outlet } from 'react-router-dom';

import { Protect } from '@clerk/clerk-react';

import AccessDenied from '../errors/AccessDenied';
import Orders from './Orders';

export const OrdersLayout = () => {
	return (
		<Protect permission="org:orders:view" fallback={<AccessDenied />}>
			<Outlet />
		</Protect>
	);
};

export const ordersRoutes = [{ index: true, element: <Orders /> }];
