import React, { useState } from 'react';
import { ActualButton as Button, Modal, Icon, Card, UItils } from '@nearst/ui';
import { CouponModal } from '../Modals';
import { useShop } from '@services/ShopProvider';
import styles from './PlanCard.module.scss';

const PlanCard = ({ dailyAmount, id, choosePlan, currencySymbol, averageResults }) => {
	const { shop } = useShop();
	const [showModal, setShowModal] = useState(false);
	const [promoModal, setPromoModal] = useState(false);
	const openModal = (e) => setShowModal(e.target.id);
	const closeModal = () => setShowModal(false);
	const openPromoModal = () => setPromoModal(true);
	const closePromoModal = () => {
		setPromoModal(false);
	};
	const confirm = () => {
		closeModal();
		choosePlan();
	};

	const [appliedCoupon, setAppliedCoupon] = useState();
	const primary = dailyAmount === 10;

	return (
		<Card className={UItils.classnames(styles.card, primary && styles.primary)} data-cy={`lia-plan-card-${dailyAmount}`}>
			{primary && <div className={styles.recommended}>Most Popular</div>}
			<h2>
				{currencySymbol}
				{dailyAmount}/day
			</h2>

			<p>
				From {(averageResults.impressions * dailyAmount).toLocaleString()}
				<br />
				impressions per week
			</p>
			<Button className={styles.button} data-cy={`plus-plan-${dailyAmount}`} secondary={primary} id={id} onClick={openModal}>
				Select budget
			</Button>

			<Modal
				isOpen={id === showModal}
				onDismiss={closeModal}
				className={styles.couponModalContainer}
				aria-label="Switching to Local Ads"
			>
				<div className={styles.titleContainer}>
					<h2>Updating your plan</h2>
					<button className={styles.exitButton} onClick={closeModal}>
						<Icon>clear</Icon>
					</button>
				</div>
				<div className={styles.contentContainer}>
					<p data-cy={'lia-modal-text'}>
						Your new {currencySymbol}
						{dailyAmount}/day plan will start from tomorrow
					</p>
				</div>
				{/* newly activated coupon */}
				{appliedCoupon && (
					<p className={styles.success}>
						<Icon>check_circle</Icon> Promo code active:
						<span className={styles.name}>
							{appliedCoupon?.name} ({currencySymbol}
							{appliedCoupon?.amount_off / 100} off)
						</span>
					</p>
				)}
				<div className={styles.actionsContainer}>
					<div className={styles.buttonsContainer}>
						<Button data-cy={`modal-confirm`} confirm onClick={confirm}>
							Confirm plan
						</Button>
						<Button onClick={closeModal}>Cancel</Button>
					</div>
					<button className={styles.promoCode} onClick={openPromoModal}>
						Have a promo code?
					</button>
				</div>
			</Modal>

			<CouponModal open={promoModal} close={closePromoModal} shopId={shop.id} setAppliedCoupon={setAppliedCoupon} />
		</Card>
	);
};

export default PlanCard;
