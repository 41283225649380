import { addDays, format, startOfWeek, endOfWeek, startOfMonth, endOfMonth } from 'date-fns';
import React, { createContext, useContext, useState } from 'react';
const store = createContext();

const useInsightsDate = () => useContext(store);
const { Provider } = store;

const InsightsDateProvider = ({
	children,
	defaultStartDate = addDays(new Date(), -31),
	defaultEndDate = addDays(new Date(), -1),
	defaultComparison = {
		startDate: addDays(new Date(), -62),
		endDate: addDays(new Date(), -32),
		label: 'Previous period'
	},
	defaultRange = 'Last 30 days'
}) => {
	const [startDate, setStartDate] = useState(defaultStartDate);
	const [endDate, setEndDate] = useState(defaultEndDate);
	const [selectedRange, setSelectedRange] = useState(defaultRange);
	const [comparison, setComparison] = useState(defaultComparison);
	const [interval, setInterval] = useState('day');

	/**
	 * These new helpers make stuff easier! :D
	 */

	const from = format(startDate, 'yyyy-MM-dd');
	const to = format(endDate, 'yyyy-MM-dd');
	const lookbackPeriod = {
		from: format(comparison.startDate, 'yyyy-MM-dd'),
		to: format(comparison.endDate, 'yyyy-MM-dd'),
		fromStartOfWeek: format(startOfWeek(comparison.startDate, { weekStartsOn: 1 }), 'yyyy-MM-dd'),
		toEndOfWeek: format(endOfWeek(comparison.endDate, { weekStartsOn: 1 }), 'yyyy-MM-dd')
	};
	const fromStartOfWeekStr = format(startOfWeek(startDate, { weekStartsOn: 1 }), 'yyyy-MM-dd');
	const toEndOfWeekStr = format(endOfWeek(endDate, { weekStartsOn: 1 }), 'yyyy-MM-dd');
	const startOfMonthStr = format(startOfMonth(startDate), 'yyyy-MM-dd');
	const endOfMonthStr = format(endOfMonth(endDate), 'yyyy-MM-dd');

	return (
		<Provider
			value={{
				startDate,
				endDate,
				to,
				from,
				setStartDate,
				setEndDate,
				selectedRange,
				setSelectedRange,
				comparison,
				setComparison,
				lookbackPeriod,
				fromStartOfWeekStr,
				toEndOfWeekStr,
				startOfMonthStr,
				endOfMonthStr,
				interval,
				setInterval
			}}
		>
			{children}
		</Provider>
	);
};

export { InsightsDateProvider, useInsightsDate };
