import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Icon, InputGroup, Page } from '@nearst/ui';
import { useShop } from '@services/ShopProvider';

import styles from './ProductReservations.module.scss';
import ConnectionStatus from '../../components/ConnectionStatus';
import MultipleEmailsInput from '@components/MultipleEmailsInput';
import ReservationEmailForm from '../../components/ReservationEmailForm';
import AuthorizationTooltip from '@components/AuthorizationTooltip';
import EmailConfiguration from '../../components/EmailConfiguration';
import DisableReservationsModal from './DisableReservationsModal';
import ChannelHeader from '../../components/ChannelHeader';

import { createChannel, disableChannel, useChannel } from '@services/channels';
import { createTicket } from '@services/reservations';
import { Protect } from '@clerk/clerk-react';
import { useCheckPermission } from '@services/auth/hooks';

const CHANNEL_ID = 'product-reservations';

const ReservationsEmailCreateForm = ({ setFirstTimeEnableEmails, firstTimeEnableEmails }) => {
	return (
		<InputGroup label="Notification email addresses" htmlFor="reservationEmail">
			<MultipleEmailsInput
				className={styles.input}
				defaultValue={firstTimeEnableEmails.join(',')}
				onChange={async (value) => {
					if (!value) return;
					setFirstTimeEnableEmails(value?.split(','));
				}}
			/>
		</InputGroup>
	);
};

const setupReservations = async (emails, setSubmitting, refetchReservation, shop) => {
	setSubmitting(true);

	const ticket = {
		content: `Shop has enabled product reservations, please finish setup: https://hq.near.st/shops/${shop.legacyId}/channels`,
		setup_required: 'reservations'
	};
	await createTicket(shop.id, ticket);
	await createChannel(shop.id, CHANNEL_ID, {
		notificationEmails: Array.isArray(emails) ? emails.toString() : emails,
		notificationPhoneNumbers: [],
		smsEnabled: false,
		status: 'enabled'
	});

	await refetchReservation();

	setSubmitting(false);
};

const ProductReservations = () => {
	const { shop } = useShop();
	const { data: reservationChannel, mutate: refetchReservation } = useChannel(CHANNEL_ID, shop.id);

	const [showDialog, setShowDialog] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [emails, setEmails] = useState(reservationChannel?.config?.notificationEmails || []);

	const navigate = useNavigate();
	const authorized = useCheckPermission('org:channels:manage');

	const reservationStatus = reservationChannel?.status || '';

	const handleEnable = async () => {
		if (!emails || emails.length === 0 || reservationStatus === 'enabled') {
			return;
		}

		await setupReservations(emails, setSubmitting, refetchReservation, shop);
	};

	const handleDisable = async () => {
		const ticket = {
			content: 'Shop wants to disable product reservations',
			setup_required: 'reservations'
		};
		await createTicket(shop.id, ticket);
		await disableChannel(CHANNEL_ID, shop.id);
		await refetchReservation();
	};

	return (
		<>
			<ChannelHeader channelId={CHANNEL_ID} status={reservationStatus} />
			<Page.Section>
				<Card title="Connection status">
					{!reservationStatus && (
						<ConnectionStatus
							connection="setup"
							status="Enable product reservations"
							summary="All you need to do is to add a reservation email for receiving customer requests."
							outcome="After that, we will begin the process of enabling product reservations on your SWIS."
							footer={
								<>
									<Protect permission="org:channels:manage">
										<ReservationsEmailCreateForm setFirstTimeEnableEmails={setEmails} firstTimeEnableEmails={emails} />
									</Protect>
									<AuthorizationTooltip authorized={authorized} tooltipContent="You don't have access to enable channels">
										<Button
											onClick={authorized ? handleEnable : undefined}
											primary
											disabled={submitting || !authorized}
										>
											<span>Get started</span>
											<Icon>chevron_right</Icon>
										</Button>
									</AuthorizationTooltip>
								</>
							}
						/>
					)}
					{reservationStatus === 'disabled' && (
						<ConnectionStatus
							connection="setup"
							status="Enable product reservations"
							summary="All you need to do is to add a reservation email for receiving customer requests."
							outcome="After that, we will begin the process of enabling product reservations on your SWIS."
							footer={
								<>
									<Protect permission="org:channels:manage">
										<ReservationEmailForm notificationEmails={emails} />
									</Protect>
									<AuthorizationTooltip authorized={authorized} tooltipContent="You don't have access to enable channels">
										<Button
											onClick={authorized ? handleEnable : undefined}
											primary
											disabled={submitting || !authorized}
										>
											<span>Get started</span>
											<Icon>chevron_right</Icon>
										</Button>
									</AuthorizationTooltip>
								</>
							}
						/>
					)}
					{reservationStatus === 'enabled' && (
						<ConnectionStatus
							connection="done"
							status="You're good to go!"
							summary="Product reservations are available for all in-stock products."
							footer={
								<div>
									<Protect permission="org:channels:manage">
										<ReservationEmailForm notificationEmails={emails} />
									</Protect>
									<Button primary onClick={() => navigate(`/orders/${shop.id}`)}>
										View orders
									</Button>
									<AuthorizationTooltip authorized={authorized} tooltipContent="You don't have access to enable channels">
										<Button onClick={authorized ? () => setShowDialog(true) : undefined} disabled={!authorized}>
											Disable reservations
										</Button>
									</AuthorizationTooltip>
									<Protect permission="org:channels:manage">
										<DisableReservationsModal
											shop={shop}
											showDialog={showDialog}
											setShowDialog={setShowDialog}
											handleDisableCallback={handleDisable}
										/>
									</Protect>
								</div>
							}
						/>
					)}
				</Card>

				{reservationStatus === 'enabled' && <EmailConfiguration shop={shop} channel={CHANNEL_ID} />}
			</Page.Section>
		</>
	);
};

export default ProductReservations;
