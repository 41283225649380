import React from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import Registration from './Pages/Registration';
import Confirmation from './Pages/Confirmation';

export const RegisterLayout = () => {
	const location = useLocation();
	let [searchParams] = useSearchParams(location.search.replace('?', ''));

	const email = searchParams.get('email');
	if (email) return <Confirmation email={email} />;
	return <Registration />;
};
