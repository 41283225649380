import React from 'react';
import * as Chart from 'recharts';
import { format } from 'date-fns';

const DefaultPerformanceChart = ({}) => {
	const data = [
		{ Date: '2023-07-18', 'Local Ads': 627 },
		{ Date: '2023-07-19', 'Local Ads': 495 },
		{ Date: '2023-07-20', 'Local Ads': 549 },
		{ Date: '2023-07-21', 'Local Ads': 1099 },
		{ Date: '2023-07-22', 'Local Ads': 1210 },
		{ Date: '2023-07-23', 'Local Ads': 1340 },
		{ Date: '2023-07-24', 'Local Ads': 1159 },
		{ Date: '2023-07-25', 'Local Ads': 1535 },
		{ Date: '2023-07-26', 'Local Ads': 903 },
		{ Date: '2023-07-27', 'Local Ads': 659 },
		{ Date: '2023-07-28', 'Local Ads': 1399 },
		{ Date: '2023-07-29', 'Local Ads': 1039 },
		{ Date: '2023-07-30', 'Local Ads': 815 },
		{ Date: '2023-07-31', 'Local Ads': 695 },
		{ Date: '2023-08-01', 'Local Ads': 1218 },
		{ Date: '2023-08-02', 'Local Ads': 1134 },
		{ Date: '2023-08-03', 'Local Ads': 1026 },
		{ Date: '2023-08-04', 'Local Ads': 1132 },
		{ Date: '2023-08-05', 'Local Ads': 1539 },
		{ Date: '2023-08-06', 'Local Ads': 1347 },
		{ Date: '2023-08-07', 'Local Ads': 1193 },
		{ Date: '2023-08-08', 'Local Ads': 1157 },
		{ Date: '2023-08-09', 'Local Ads': 851 },
		{ Date: '2023-08-10', 'Local Ads': 755 },
		{ Date: '2023-08-11', 'Local Ads': 859 },
		{ Date: '2023-08-12', 'Local Ads': 1664 },
		{ Date: '2023-08-13', 'Local Ads': 1036 },
		{ Date: '2023-08-14', 'Local Ads': 888 },
		{ Date: '2023-08-15', 'Local Ads': 701 },
		{ Date: '2023-08-16', 'Local Ads': 786 },
		{ Date: '2023-08-17', 'Local Ads': 2172 }
	];

	return (
		<Chart.ResponsiveContainer width="100%" height={160}>
			<Chart.LineChart
				aria-roledescription="Stacked area chart"
				style={{
					fontSize: '0.8rem',
					fontFamily: "'nearst-body', -apple-system, 'Helvetica Neue', 'Arial', sans-serif"
				}}
				margin={{ top: 5, right: 0, bottom: 0, left: -12 }}
				data={data}
			>
				<Chart.XAxis
					aria-roledescription="axis"
					aria-orientation="horizontal"
					aria-label="date"
					allowDuplicatedCategory
					dataKey="Date"
					interval="equidistantPreserveStart"
					tickFormatter={(date) => format(new Date(date), 'MMM d')}
				/>
				<Chart.YAxis aria-label="Impressions" aria-roledescription="axis" aria-orientation="vertical" allowDuplicatedCategory />
				<Chart.Line
					type="monotone"
					strokeWidth={3}
					dot={false}
					dataKey="Local Ads"
					stroke="#50a1ff"
					fillOpacity={1}
					aria-label="Local Ads"
				/>
			</Chart.LineChart>
		</Chart.ResponsiveContainer>
	);
};

const DefaultPerformanceBarChart = () => {
	const data = [
		{ id: 1, name: 'Mango', value: 140 },
		{ id: 2, name: 'Honeydew', value: 16 },
		{ id: 3, name: 'Grape', value: 84 },
		{ id: 4, name: 'Apple', value: 234 },
		{ id: 5, name: 'Plum', value: 254 },
		{ id: 6, name: 'Papaya', value: 100 },
		{ id: 7, name: 'Quince', value: 1 },
		{ id: 8, name: 'Banana', value: 128 },
		{ id: 9, name: 'Watermelon', value: 207 },
		{ id: 10, name: 'Orange', value: 10 }
	].sort((a, b) => b.value - a.value);
	return (
		<Chart.ResponsiveContainer width="100%" height={325}>
			<Chart.BarChart
				aria-roledescription="Bar chart"
				style={{
					fontSize: '0.8rem',
					fontFamily: "'nearst-body', -apple-system, 'Helvetica Neue', 'Arial', sans-serif"
				}}
				margin={{ top: 5, right: 0, bottom: 0, left: -12 }}
				data={data}
			>
				<Chart.XAxis aria-roledescription="axis" aria-orientation="horizontal" aria-label="Shop name" dataKey="name" interval={0} />
				<Chart.YAxis
					yAxisId="value"
					aria-label="label"
					aria-roledescription="axis"
					aria-orientation="vertical"
					tickFormatter={(value) => value.toLocaleString()}
				></Chart.YAxis>

				<Chart.Bar
					yAxisId="value"
					dataKey="value"
					fill="#50a1ff"
					fillOpacity={1}
					aria-label="value"
					radius={[4, 4, 0, 0]}
					maxBarSize={50}
				/>
			</Chart.BarChart>
		</Chart.ResponsiveContainer>
	);
};

export { DefaultPerformanceChart, DefaultPerformanceBarChart };
