import { useEffect, useState } from 'react';
import { useOrganization, useOrganizationList, useSession, useUser } from '@clerk/clerk-react';
import * as Sentry from '@sentry/browser';
import { useLocation } from 'react-router-dom';

import { switchToOrganization } from '@services/auth';

const useClerkLoginState = () => {
	const [state, setState] = useState('');

	const { membership } = useOrganization();
	const { session } = useSession();
	const { isLoaded: isOrganisationListLoaded } = useOrganizationList();
	const { user, isLoaded: isUserLoaded } = useUser();

	// Allows switching orgs by passing a query param: `?org={org-id}`
	const { search } = useLocation();
	const targetOrg = search.split('org=')[1]?.split('&')[0];

	useEffect(() => {
		if (!session || !isUserLoaded || !isOrganisationListLoaded) return;
		if (state === 'loading') return;

		if (membership && (!targetOrg || membership?.organization.id === targetOrg)) {
			// we already have an active organization - all good
			setState('logged-in');
			return;
		}

		const organisations = user.organizationMemberships;
		if (organisations.length === 0) {
			setState('no-organisation');
			Sentry.captureException(new Error('User does not have a relationship with an organisation.'));
			return;
		}

		(async () => {
			setState('loading');
			await switchToOrganization(targetOrg || organisations[0].organization.id);
			setState('logged-in');
		})();
	}, [session, isUserLoaded, isOrganisationListLoaded, membership, targetOrg]);

	return session && state;
};

export default useClerkLoginState;
