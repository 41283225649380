import React from 'react';

import { Switcher } from '@nearst/ui';

import * as Stats from '@components/StatsCard';
import styles from './BudgetSlider.module.scss';

const Figures = ({ subscriptionLiaBudget, figures }) => {
	return (
		<Stats.Card className={`${styles.card} ${styles.spend}`}>
			<Stats.Title>Your Local Ads spend</Stats.Title>
			<Stats.Subtitle>Current forecast</Stats.Subtitle>
			<Switcher space="1.5rem" className={styles.stats}>
				{subscriptionLiaBudget ? (
					<Switcher>
						<Stats.Figure className={styles.figure}>
							{figures.prePaidMonthly.format()}
							<Stats.FigureDescription>Pre-paid monthly budget</Stats.FigureDescription>
						</Stats.Figure>
						<Stats.Figure className={styles.figure}>
							{figures.liaMonthly.format()}
							<Stats.FigureDescription>Additional monthly budget</Stats.FigureDescription>
						</Stats.Figure>
					</Switcher>
				) : null}
				<Switcher>
					<Stats.Figure className={styles.figure}>
						{figures.totalMonthly.format()}
						<Stats.FigureDescription>Total monthly spend</Stats.FigureDescription>
					</Stats.Figure>
					<Stats.Figure className={styles.figure}>
						{figures.totalDaily.format()}
						<Stats.FigureDescription>Total daily spend</Stats.FigureDescription>
					</Stats.Figure>
				</Switcher>
			</Switcher>
		</Stats.Card>
	);
};

export default Figures;
