import React, { useState, useEffect, useMemo } from 'react';
import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { Icon } from '@nearst/ui';
import { Protect } from '@clerk/clerk-react';

import Pills from '../Pills';
import Issues from '../Issues';
import EditChannelVisibilityModal from '../../components/EditChannelVisibilityModal';
import { removeCid } from '../utils';
import { getChannels } from '@services/channels';
import { getChannelsForStockItems } from '@services/stockChannels';
import { hideProducts, useInventory } from '@services/stock';
import { formatPrice } from '@utils/currency';
import plurify from '@utils/plurify';

import styles from './InventoryViewer.module.scss';

const DesktopTable = ({ shop, setDisableActions }) => {
	let navigate = useNavigate();
	const { inventory, optimisticUpdate } = useInventory(shop.id);
	const { data: channels } = useSWR(`channels-${shop.id}`, () => getChannels([shop]));
	const [selectedBarcodes, setSelectedBarcodes] = useState([]);
	const isAllSelected = selectedBarcodes?.length === inventory?.length;

	useEffect(() => {
		if (selectedBarcodes.length >= 1) setDisableActions(true);
		if (selectedBarcodes.length === 0) setDisableActions(false);
	}, [selectedBarcodes]);

	const [editChannelsModalShown, setEditChannelsModalShown] = useState(false);

	const stockItems = inventory?.filter(({ barcode }) => selectedBarcodes.includes(barcode));
	const channelStatuses =
		editChannelsModalShown && selectedBarcodes?.length ? getChannelsForStockItems(shop.id, stockItems, channels) : [];

	const hideMode = useMemo(() => stockItems?.some((item) => item.enabled), [stockItems]);

	const handleSelect = (barcode) => {
		setSelectedBarcodes((existingValues) => [...existingValues, barcode]);
	};

	const handleDeselect = (barcode) => {
		setSelectedBarcodes((existingValues) => existingValues.filter((b) => b !== barcode));
	};

	const handleSelectAll = () => {
		setSelectedBarcodes(inventory.map((row) => row.barcode));
	};

	const handleDeselectAll = () => {
		setSelectedBarcodes([]);
	};

	const handleInventoryRowClick = (row) => {
		navigate(`/inventory/${shop.id}/items/${row.barcode}`);
	};

	const markHidden = () => {
		optimisticUpdate(
			(inventory) => inventory.map((item) => (selectedBarcodes.includes(item.barcode) ? { ...item, enabled_i: !hideMode } : item)),
			() => hideProducts(shop.id, selectedBarcodes, !hideMode)
		);
		setSelectedBarcodes([]);
	};

	return (
		<>
			<EditChannelVisibilityModal
				stockItems={stockItems}
				shopId={shop.id}
				channelStatuses={channelStatuses}
				onDismiss={() => {
					setSelectedBarcodes([]);
					return setEditChannelsModalShown(false);
				}}
				isOpen={editChannelsModalShown}
			/>
			{selectedBarcodes.length ? (
				<div className={styles.actionBar}>
					<span className={styles.barcodeSelected}>
						{selectedBarcodes.length} {plurify('barcode', selectedBarcodes.length)} selected
					</span>
					<Protect condition={(has) => has({ permission: 'org:channels:manage' }) || has({ permission: 'org:inventory:hide' })}>
						<span className={styles.actionsDivider}>|</span>
					</Protect>
					<Protect permission="org:channels:manage">
						<button className={styles.actionButton} onClick={() => setEditChannelsModalShown(true)}>
							<Icon>settings</Icon>Edit channels
						</button>
					</Protect>
					<Protect permission="org:inventory:hide">
						<button className={styles.actionButton} onClick={() => markHidden()}>
							{hideMode ? (
								<>
									<Icon>visibility_off</Icon> Mark as hidden
								</>
							) : (
								<>
									<Icon>visibility</Icon> Mark as visible
								</>
							)}
						</button>
					</Protect>
				</div>
			) : null}
			<Table className={styles.desktopTable} role="table">
				<TableHead>
					<TableRow>
						<TableCell>
							<Checkbox
								data-cy="inventory-table-checkbox-all"
								color="primary"
								className={styles.checkbox}
								indeterminate={selectedBarcodes.length && !isAllSelected}
								checked={isAllSelected}
								onChange={() => (isAllSelected ? handleDeselectAll() : handleSelectAll())}
								inputProps={{
									'aria-label': 'select all products'
								}}
							/>
						</TableCell>
						<TableCell>Item</TableCell>
						<TableCell>Price</TableCell>
						<TableCell>Quantity</TableCell>
						<TableCell>Status</TableCell>
						<TableCell>Issues</TableCell>
						<TableCell />
					</TableRow>
				</TableHead>
				<TableBody>
					{inventory &&
						inventory.map((inventory, index) => {
							const row = {
								...inventory,
								hidden: !inventory.enabled,
								edited: inventory.productSource === 'myshop',
								quantity: inventory.quantity_i,
								hasIssues: inventory.issues?.length > 0
							};

							const isSelected = selectedBarcodes.includes(row.barcode);
							const labelId = `inventory-table-checkbox-${index}`;

							return (
								<TableRow
									onClick={() => handleInventoryRowClick(row)}
									className={styles.clickMe}
									key={row.barcode}
									data-cy="inventory-table-row"
									data-barcode={row.barcode}
								>
									<TableCell>
										<Checkbox
											color="primary"
											className={styles.checkbox}
											checked={isSelected}
											onClick={(e) => {
												e.stopPropagation();
												isSelected ? handleDeselect(row.barcode) : handleSelect(row.barcode);
											}}
											inputProps={{
												'aria-labelledby': labelId
											}}
										/>
									</TableCell>
									<TableCell id={labelId} className={styles.titleCell}>
										<div>
											<p className={styles.itemBarcode}>{removeCid(row.barcode)}</p>
											<p className={styles.itemTitle}>{row.title}</p>
										</div>
									</TableCell>
									<TableCell>{formatPrice(row.price, row.currency)}</TableCell>
									<TableCell>{row.quantity}</TableCell>
									<TableCell>
										<Pills>{row}</Pills>
									</TableCell>
									<TableCell>
										<Issues>{row}</Issues>
									</TableCell>
									<TableCell>
										<Icon className={styles.summaryIcon}>chevron_right</Icon>
									</TableCell>
								</TableRow>
							);
						})}
				</TableBody>
			</Table>
		</>
	);
};

export default DesktopTable;
