import myShopClient from './nearlive';
import * as dateFns from 'date-fns';

/**
 * Get list of available POS providers.
 *
 * @returns {Promise<Array>}
 */
export async function getPosProviders() {
	const client = await myShopClient();
	const { data } = await client.get('/api/onboarding/pos-providers');
	return data;
}

/**
 * Trigger inventory connection setup.
 *
 * @param {Object} args
 * @param {string} args.shopId
 * @param {string} args.provider
 * @param {string} [args.redirectUrl]
 * @param {string} [args.kind]
 */
export async function startInventoryConnection({ shopId, provider, redirectUrl, kind }) {
	const client = await myShopClient();
	const { data } = await client.post('/api/onboarding/inventory-connection', { id: shopId, provider, redirectUrl, kind });
	return data;
}

export const INVENTORY_CONNECTION = {
	CONNECTED: 'connected',
	PENDING: 'pending',
	DISCONNECTED: 'disconnected'
};

export const CONNECTION_ICONS = {
	CONNECTED: 'done',
	PENDING: 'add_circle',
	DISCONNECTED: 'warning'
};

// TODO: extract logic into a module to be reused in partner-api/hq/myshop
export const getInventoryConnection = (latestIngest) => {
	let status = INVENTORY_CONNECTION.CONNECTED;
	let icon = CONNECTION_ICONS.CONNECTED;
	let formattedIngestAge = 'never';
	let ingestInfo;

	if (!latestIngest) {
		status = INVENTORY_CONNECTION.PENDING;
		icon = CONNECTION_ICONS.PENDING;
	}

	if (latestIngest && latestIngest.timestamp) {
		const ingestAgeInDays = dateFns.differenceInCalendarDays(new Date(), new Date(latestIngest.timestamp));
		if (ingestAgeInDays > 3) {
			status = INVENTORY_CONNECTION.DISCONNECTED;
			icon = CONNECTION_ICONS.DISCONNECTED;
		}

		formattedIngestAge = dateFns.formatDistance(new Date(latestIngest.timestamp), new Date(), {
			addSuffix: true
		});
		ingestInfo = {
			createdAt: latestIngest.timestamp,
			numberOfLines: latestIngest.stockIngested || 0,
			validLines: latestIngest.stockIngested - latestIngest.stockValidationFailed || 0,
			inStockValidLines: latestIngest.activeRecords || 0
		};
	}

	return {
		status,
		icon,
		latestIngest: ingestInfo,
		formattedIngestAge
	};
};
