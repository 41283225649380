import React, { useState } from 'react';
import Select, { components } from 'react-select';
import useSWRImmutable from 'swr/immutable';
import { ActualButton as Button, Cluster } from '@nearst/ui';
import { getPosProviders } from '@services/onboarding';
import styles from './ReconnectDropdown.module.scss';
import LoadingSpinner from '@components/LoadingSpinner';

const getPosProviderDetail = (selected, posList) => posList?.find(({ value }) => value === selected);

const SelectOption = (props) => (
	<components.Option {...props}>
		<div>{props.data.label}</div>
		<small>{props.data.vendor}</small>
	</components.Option>
);

const ReconnectDropdown = ({ inventorySource, kind, handlePosConnect }) => {
	const [selectedPOS, setSelectedPOS] = useState();
	const [loading, setLoading] = useState(false);
	const { data: posList, error } = useSWRImmutable('pos', getPosProviders, { suspense: true });

	const handleSelect = (selected) => {
		const pos = getPosProviderDetail(selected.value, posList);
		setSelectedPOS(pos);
	};

	if (!posList && !error) return null;

	const availablePos = posList.filter((pos) => pos.kinds.includes(inventorySource?.kind || kind));

	const handleConfigure = async () => {
		setLoading(true);
		await handlePosConnect(selectedPOS?.value);
		setLoading(false);
	};

	return (
		<>
			<div className={styles.reconnectContainer}>
				<Select
					id={`connector-status-pos-select-${kind}`}
					placeholder="Select a POS..."
					isLoading={!posList}
					options={availablePos || []}
					components={{
						ClearIndicator: () => null,
						IndicatorSeparator: () => null,
						Option: SelectOption
					}}
					styles={{
						control: (styles) => ({
							...styles,
							width: 240,
							borderColor: 'var(--grey-dark)',
							borderRadius: 4,
							color: 'var(--black)'
						})
					}}
					onChange={handleSelect}
					value={selectedPOS && getPosProviderDetail(selectedPOS, availablePos)}
				/>
				{selectedPOS ? (
					<Cluster>
						<Button disabled={loading} data-cy="connector-status-reconnect" primary onClick={handleConfigure}>
							Configure
						</Button>
						{loading ? <LoadingSpinner size={24} /> : null}
					</Cluster>
				) : null}
			</div>
		</>
	);
};

export default ReconnectDropdown;
