import React from 'react';
import * as Stats from '@components/StatsCard';
import { Stack } from '@nearst/ui';
import Lightbulb from '@mui/icons-material/Lightbulb';
import InsightCardContainer from '../../components/InsightCardContainer';

import styles from './HelpCard.module.scss';
import { showIntercomWithMessage } from '@services/Intercom/utils';

const HelpCard = () => {
	return (
		<InsightCardContainer>
			<Stack>
				<div className={styles.iconTitle}>
					<Lightbulb className={styles.lightBulb} />
					<Stats.Title>How can I use these insights?</Stats.Title>
				</div>
				<p>
					Exploring the most clicked products can help you make informed stock decisions. We also recommend understanding how
					shoppers find your products through search terms.
				</p>
				<p>
					You can also track where your ads are being seen by local shoppers compared to your target radius! This helps you
					identify ad optimisations based on shopper location.
				</p>
				<p>
					If you would like to expand your radius, please{' '}
					<span className={styles.supportLink} onClick={() => showIntercomWithMessage('Expanding my ads radius')}>
						contact the support team
					</span>
					.
				</p>
			</Stack>
		</InsightCardContainer>
	);
};

export default HelpCard;
