import React from 'react';
import { Loader } from '@nearst/ui';

import NoData from '@components/Graphs/Placeholders/NoData';
import ComparisonPieChart from './ComparisonPieChart';
import ComparisonBarChart from './ComparisonBarChart';
import ComparisonTableChart from './ComparisonTableChart';
import styles from '../ComparisonCard.module.scss';

const ChartRenderer = ({ data, total, chartType, dimension }) => {
	if (!data) {
		return (
			<div className={styles.loaderContainer} data-testid="comparison-loader">
				<Loader />
				<p>Plotting the data...</p>
			</div>
		);
	}

	if (!data.length) {
		return <NoData />;
	}

	if (chartType === 'pie') {
		return <ComparisonPieChart data={data} total={total} />;
	}

	if (chartType === 'bar') {
		return <ComparisonBarChart data={data} dimension={dimension} />;
	}

	if (chartType === 'table') {
		return <ComparisonTableChart data={data} dimension={dimension} />;
	}
};

export default ChartRenderer;
