import React from 'react';

import { CursorArrowRippleIcon } from '@heroicons/react/20/solid';

import { useShop } from '@services/ShopProvider';
import Sidebar from '@components/Sidebar';
import OnboardingAccessControl from '@components/OnboardingAccessControl';
import { Protect } from '@clerk/clerk-react';

const LocalAds = () => {
	const { shop, data: shops } = useShop();
	const shopSelected = !!shop?.id;

	if (!shopSelected) return null;

	const swisEnabled = shopSelected ? !!shop.swisUrl : shops.some((shop) => !!shop.swisUrl);
	const liaAvailable = shopSelected ? shop.availableChannels?.['local-ads'] : shops.some((shop) => shop.availableChannels?.['local-ads']);

	return (
		<OnboardingAccessControl allowedState={'shop.google.pos-link-ok'}>
			{swisEnabled && liaAvailable && (
				<Protect permission="org:channels_lia:manage">
					<Sidebar.Item to="/subscription">
						<CursorArrowRippleIcon />
						Local Ads plan
					</Sidebar.Item>
				</Protect>
			)}
		</OnboardingAccessControl>
	);
};

export default LocalAds;
