export const productCategories = [
	'Animals & Pet Supplies',
	'Arts & Entertainment',
	'Baby & Toddler',
	'Business & Industrial',
	'Cameras & Optics',
	'Clothing & Accessories',
	'Electronics',
	'Food, Beverages & Tobacco',
	'Furniture',
	'Hardware',
	'Health & Beauty',
	'Home & Garden',
	'Luggage & Bags',
	'Media',
	'Office Supplies',
	'Software',
	'Sporting Goods',
	'Toys & Games',
	'Vehicles & Parts'
];
