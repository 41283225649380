import React from 'react';
import Terms from '../../Components/Fields/Terms';
import Field from '../../Components/Fields/Field';
import { Stack } from '@nearst/ui';

import { sendAnalytics } from '../../../../utils/sendAnalytics';

import styles from '../Forms.module.scss';

const Shopkeeper = () => {
	const updateAnalytics = (e) => {
		sendAnalytics('Registration email entered', 'focuslost', e.target.value);
	};

	return (
		<>
			<form className={styles.shopkeeper}>
				<Stack space="0.25rem">
					<Field name="contactName" required>
						Your name
					</Field>
					<Field name="contactEmail" required type="email" onBlur={updateAnalytics} error="Must be a valid email address.">
						Email address
					</Field>
					<Field name="contactPhone">Contact number</Field>
					<Terms />
				</Stack>
			</form>
		</>
	);
};

export default Shopkeeper;
