export const getTopLocations = (data, dimension, shops) => {
	if (!data) return [];
	const shownShops = 20;

	const sortedData = data.filter(({ value }) => value > 0).sort((a, b) => b.value - a.value);

	const first = sortedData.slice(0, shownShops);
	const rest = getDimensionTotal(sortedData.slice(shownShops), dimension);

	return [
		...first.map(({ shopId, value }) => ({
			shopId,
			value,
			shopName: shops.find(({ id }) => id === shopId).name,
			dimension
		})),
		rest && {
			shopId: 'other',
			value: rest,
			shopName: 'Other',
			description: `${data.length - shownShops} locations`,
			dimension
		}
	].filter(Boolean);
};

export const groupByShop = (values, dimension, summaryType = 'sum') => {
	const groupedValues = values.reduce((acc, value) => {
		const shopId = value.shopId || value.shop_id;
		if (!acc[shopId]) acc[shopId] = [];
		acc[shopId].push({ value: value[dimension] });
		return acc;
	}, {});

	return Object.entries(groupedValues).map(([shopId, values]) => ({
		shopId,
		value: getDimensionTotal(values, { summaryType })
	}));
};

export const formatDimensionValue = (value, dimension) => {
	let result = value.toLocaleString('en', {
		maximumFractionDigits: dimension.digits || 0,
		minimumFractionDigits: dimension.digits || 0
	});
	if (dimension.valuePrefix) {
		result = dimension.valuePrefix + result;
	}
	if (dimension.valueSuffix) {
		result = result + dimension.valueSuffix;
	}

	return result;
};

export const getDimensionTotal = (data, dimension) => {
	if (!data?.length) return '';
	let total = data.reduce((acc, { value }) => acc + value, 0);
	if (dimension.summaryType === 'avg') total = total / data.length;

	return total;
};

export const pieChartColorPalette = [
	'#3b82f6',
	'#93c5fd',
	'#1d4ed8',
	'#38bdf8',
	'#0284c7',
	'#67e8f9',
	'#06b6d4',
	'#0e7490',
	'#2dd4bf',
	'#0d9488',
	'#818cf8',
	'#4f46e5',
	'#c4b5fd',
	'#8b5cf6',
	'#6d28d9',
	'#c084fc',
	'#9333ea'
];
