import React from 'react';
import Map, { Source } from 'react-map-gl';
import { MapPinIcon } from '@heroicons/react/20/solid';
import mapLib from 'mapbox-gl';
import { Card, UItils } from '@nearst/ui';

import { useStoredQuery } from '@services/insights';
import { useShop } from '@services/ShopProvider';
import { useInsightsDate } from '@services/InsightsDateProvider';

import Skeleton from '@components/Skeleton';
import * as Stats from '@components/StatsCard';
import { Pin } from './Pins';
import { generateInitialBounds } from './lib';
import * as Layers from './Layers';

import 'mapbox-gl/dist/mapbox-gl.css';
import styles from './EventLocations.module.scss';

const EventLocations = () => {
	const { data: shops } = useShop();
	const { selectedRange } = useInsightsDate();
	const { data: points, isLoading } = useStoredQuery('product-locator-locations', {}, { refreshInterval: 10_000 });

	return (
		<>
			<Card sectioned>
				<Card.Section>
					<Stats.Header>
						<div>
							<Stats.Title>Active customers by location</Stats.Title>
							<Stats.Subtitle>{selectedRange}</Stats.Subtitle>
						</div>
					</Stats.Header>
				</Card.Section>
				{isLoading ? (
					<Skeleton height={500} width={'100%'} />
				) : (
					<>
						<Map
							className={styles.map}
							mapLib={mapLib}
							initialViewState={{
								bounds: generateInitialBounds(shops)
							}}
							style={{ height: 500 }}
							mapStyle="mapbox://styles/devnearst/cm365buc601b901pi1hcv24j9"
							mapboxAccessToken="pk.eyJ1IjoiZGV2bmVhcnN0IiwiYSI6ImNseDRqYXJvOTE2Mm0ydnM0YnQxemEzajYifQ.pCibszFZL8Pi7k7OswvrpA"
						>
							{shops.map((shop) => (
								<Pin key={shop.id} shop={shop} />
							))}
							{points && (
								<Source
									id="geo-events"
									type="geojson"
									cluster={true}
									clusterMaxZoom={10}
									clusterMinPoints={1}
									data={{
										type: 'FeatureCollection',
										features: points.map((point) => {
											return {
												type: 'Feature',
												properties: { events: point.total_events },
												geometry: { type: 'Point', coordinates: [point.longitude, point.latitude] }
											};
										})
									}}
								>
									<Layers.ClusterLayer />
									<Layers.ClusterCountLayer />
									<Layers.UnclusteredPointsLayer />
								</Source>
							)}
							<MapKey />
						</Map>
					</>
				)}
			</Card>
		</>
	);
};

function MapKey() {
	return (
		<Card className={styles.key} sectioned>
			<ul>
				<li>
					<MapPinIcon className={UItils.classnames(styles.pin, styles.green)} />
					{' Your locations'}
				</li>
				<li>
					<svg className={UItils.classnames(styles.circle)} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
						<circle cx="50" cy="50" r="50" />
					</svg>
					{' Customers'} <Stats.Tooltip>Accurate to 1km</Stats.Tooltip>
				</li>
			</ul>
		</Card>
	);
}

export default EventLocations;
