import React from 'react';
import * as inventory from '@services/inventory';
import styles from './ConnectorIngestDetails.module.scss';

const ConnectorIngestDetails = ({ inventorySource }) => {
	const { kind, latestIngest } = inventorySource;
	const { ingestAge } = inventory.getUploadAge([inventorySource]);
	const label = inventory.getUploadLabel(ingestAge);

	return (
		<p className={styles.details}>
			Received {label}:{' '}
			{kind === 'stock' && latestIngest && (
				<>
					{latestIngest.stockIngested?.toLocaleString() || 0} lines, {latestIngest.activeRecords?.toLocaleString() || 0} in stock
				</>
			)}
			{kind === 'product' && latestIngest && <>{latestIngest.customProductProcessed?.toLocaleString() || 0} lines</>}
		</p>
	);
};

export default ConnectorIngestDetails;
