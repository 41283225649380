import React from 'react';
import { Modal, Icon, Button } from '@nearst/ui';

import styles from './UpgradeModal.module.scss';

import mixpanel from 'mixpanel-browser';
import { showIntercomWithMessage } from '@services/Intercom/utils';

const UpgradeModal = ({ feature, showDialog, setShowDialog, children }) => {
	const close = () => setShowDialog(false);

	const confirm = () => {
		if (!showIntercomWithMessage(`I want to upgrade my plan to enable ${feature}`)) {
			window.location.href = 'mailto:support@near.st?subject=Upgrade';
		}

		close();

		mixpanel.track('Upgrade subscription contact click');
	};

	return (
		<Modal className={styles.subscriptionModal} isOpen={showDialog} onDismiss={close} aria-label="subscription-modal">
			<div className={styles.subscriptionModalTitleContainer}>
				<h2 className={styles.subscriptionModalTitleText}>Upgrade your plan to enable {feature}</h2>
				<button className={styles.subscriptionModalExitButton} data-cy="subscription-modal-close-button" onClick={close}>
					<Icon>clear</Icon>
				</button>
			</div>
			<div className={styles.subscriptionModalContainer}>
				<div>
					{children}

					<Button onClick={confirm} primary className={styles.subscriptionModalActionButton}>
						Talk to our team
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default UpgradeModal;
