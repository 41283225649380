import React from 'react';

import { ShoppingCartIcon } from '@heroicons/react/20/solid';

import { useShop } from '@services/ShopProvider';
import Sidebar from '@components/Sidebar';
import useSWR from 'swr';
import { getOrderOptions, getOrderOptionsForOrganisation } from '@services/channels';
import OnboardingAccessControl from '@components/OnboardingAccessControl';
import { Protect } from '@clerk/clerk-react';

const Orders = () => {
	const { shop } = useShop();
	const shopSelected = !!shop?.id;

	const { data: orderOptionsSingle } = useSWR(shopSelected ? `order-options-${shop.id}` : null, () => getOrderOptions(shop.id));
	const { data: orderOptionsChain } = useSWR(!shopSelected ? `order-options` : null, getOrderOptionsForOrganisation);

	const orderOptions = shopSelected ? [orderOptionsSingle] : orderOptionsChain;

	const showOrdersMenu = orderOptions?.some((orderOptions) => orderOptions?.anyOptionHistoricallyEnabled);

	return (
		<OnboardingAccessControl allowedState={'shop.google.pos-link-ok'}>
			{showOrdersMenu && (
				<Protect permission="org:orders:view">
					<Sidebar.Item to="/orders">
						<ShoppingCartIcon />
						Orders
					</Sidebar.Item>
				</Protect>
			)}
		</OnboardingAccessControl>
	);
};

export default Orders;
