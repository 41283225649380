import React, { useState } from 'react';

import { Checkbox, ClickAwayListener, FormControlLabel, Popper } from '@material-ui/core';
import { ActualButton as Button, Icon, UItils } from '@nearst/ui';

import styles from './Filter.module.scss';

/**
 * Main component for our filters.
 *
 * @param {Object} props
 * @param {String} props.text
 * @param {Function} [props.handleOnReset]
 * @param {Function} [props.handleApplyClick]
 * @param {Function} [props.handleCheckAll]
 * @param {Boolean} [props.allChecked]
 * @param {React.ReactNode} [props.content]
 * @param {React.ReactNode} [props.children]
 * @param {String} [props.className]
 * @param {String} [props.popoverClassName]
 * @returns {JSX.Element}
 */
const Dropdown = ({
	text,
	handleOnReset,
	handleApplyClick,
	handleCheckAll,
	allChecked,
	content,
	children,
	className,
	popoverClassName
}) => {
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<ClickAwayListener onClickAway={handleClose}>
			<div>
				<Button primary onClick={handleClick} data-cy="filter-dropdown-trigger" className={className}>
					{text}
					<Icon className={styles.buttonIcon}>{anchorEl ? 'expand_less' : 'expand_more'}</Icon>
				</Button>
				<Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement="bottom">
					<div className={UItils.classnames(styles.popperContainer, popoverClassName)}>
						{handleCheckAll && <SelectAll checked={allChecked} onChange={handleCheckAll} />}
						<div className={styles.popperContent} data-testid="filter-dropdown-content">
							{content || children}
						</div>
						{handleApplyClick ? (
							<div className={styles.popperFooter}>
								{handleOnReset && (
									<Button link small onClick={handleOnReset}>
										Reset
									</Button>
								)}
								<Button
									small
									primary
									onClick={() => {
										handleApplyClick();
										handleClose();
									}}
									data-cy="filter-dropdown-apply"
								>
									Apply
								</Button>
							</div>
						) : null}
					</div>
				</Popper>
			</div>
		</ClickAwayListener>
	);
};

/**

 * @deprecated
 */
const Block = ({ title, filters, selectedFilters, handleFilterChange }) => {
	return (
		<>
			{title && <div className={styles.title}>{title}</div>}

			{Object.keys(filters).map((f) => {
				return (
					<div className={styles.checkbox} key={filters[f].value} data-cy="inventory-table-filter">
						<FormControlLabel
							control={
								<Checkbox
									color="primary"
									checked={!!selectedFilters[f]}
									onChange={handleFilterChange}
									name={filters[f].value}
								/>
							}
							label={filters[f].label}
						/>
					</div>
				);
			})}
		</>
	);
};

/**
 * @param {Object} props
 * @param {React.ReactNode} props.children
 * @param {String} [props.title]
 * @param {String} [props.className]
 * @returns {JSX.Element}
 */
const Fieldset = ({ title, className, children }) => {
	return (
		<>
			{title && <div className={UItils.classnames(styles.title, className)}>{title}</div>}
			{children}
		</>
	);
};

/**
 * @param {Object} props
 * @param {React.ReactNode} props.children
 * @param {String} [props.className]
 * @param {Boolean} props.checked
 * @param {String} props.value
 * @param {(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void} props.onChange
 * @returns {JSX.Element}
 */
const Field = ({ onChange, checked, children, value, className }) => {
	return (
		<div className={UItils.classnames(styles.checkbox, className)} data-testid={`inventory-table-filter${checked ? '-checked' : ''}`}>
			<FormControlLabel control={<Checkbox color="primary" checked={checked} onChange={onChange} value={value} />} label={children} />
		</div>
	);
};

/**
 * Search field for filter dropdown.
 *
 * @param {Object} props
 * @param {String} [props.value]
 * @param {(event: React.ChangeEvent<HTMLInputElement>) => void} props.onChange
 * @param {String} [props.className]
 * @param {String} [props.placeholder]
 * @returns {JSX.Element}
 */
const Search = ({ value, onChange, className, placeholder = 'Search' }) => {
	return (
		<div className={UItils.classnames(styles.noSpacing, styles.search, className)}>
			<Icon>search</Icon>
			<input type="search" placeholder={placeholder} value={value} onChange={onChange} autoFocus />
		</div>
	);
};

/**
 * 'Select all' checkbox for filter dropdown.
 *
 * @param {Object} props
 * @param {String} [props.className]
 * @param {Boolean} props.checked
 * @param {(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void} props.onChange
 * @returns
 */
const SelectAll = ({ className, checked, onChange }) => {
	return (
		<div className={UItils.classnames(styles.noSpacing, styles.selectAll, className)}>
			<Field value="all" checked={checked} onChange={onChange}>
				Select All
			</Field>
		</div>
	);
};

/**
 * Placeholder, to be used when there's no options available
 * in the filter dropdown.
 *
 * @param {Object} props
 * @param {String} props.text
 * @param {String} [props.className]
 * @returns
 */
const Empty = ({ text, className }) => {
	return <div className={UItils.classnames(styles.empty, className)}>{text}</div>;
};

/**
 *
 * @param {Object} props
 * @param {String} [props.className]
 * @returns {JSX.Element}
 */
const Divider = ({ className }) => {
	return <div className={UItils.classnames(styles.noSpacing, styles.divider, className)}></div>;
};

export { Block, Dropdown, Fieldset, Field, Divider, Search, SelectAll, Empty };
