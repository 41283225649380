import React from 'react';

import { Icon } from '@material-ui/core';

import styles from '../LocationManagement.module.scss';

const MobileLocationInsights = ({ currentTotal, type }) => {
	return (
		<div className={styles.insightValue}>
			<Icon className={styles.insightIcon}>{type === 'impressions' ? 'visibility' : 'ads_click'}</Icon>
			{currentTotal.toLocaleString()}
			<span>{type}</span>
		</div>
	);
};

export default MobileLocationInsights;
