import React, { useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import Uppy from '@uppy/core';
import Tippy from '@tippyjs/react';
import DropTarget from '@uppy/drop-target';

import { Button, Icon } from '@nearst/ui';
import AuthorizationTooltip from '@components/AuthorizationTooltip';
import { useShop } from '@services/ShopProvider';
import { saveFileToS3 } from '@services/shop';
import './style.css';

function ImageInput({ field, placeholder, displayName, containerId, imageClass, authorized }) {
	const { shop, updateShop } = useShop();
	const hiddenFileInput = useRef(null);

	const uppy = new Uppy({
		restrictions: {
			maxNumberOfFiles: 1,
			allowedFileTypes: ['image/*']
		},
		autoProceed: true,
		allowMultipleUploadBatches: false,
		maxFileSize: 5000
	});

	useEffect(() => {
		uppy.use(DropTarget, {
			target: `#${containerId}`
		});
	}, []);

	uppy.on('complete', (result) => {
		if (!authorized) return;
		formik.setFieldValue('file', result.successful[0].data) && formik.handleSubmit();
		uppy.reset();
	});

	const formik = useFormik({
		initialValues: { file: null },
		onSubmit: async (values, actions) => {
			const url = await saveFileToS3(shop.id, values.file);
			await updateShop(shop.id, { [field]: url });
			actions.setSubmitting(false);
		}
	});

	return (
		<div className="image__container" id={containerId}>
			<Tippy content="Saving" visible={formik.isSubmitting} theme={'light'} placement="right">
				<img className={imageClass} src={shop[field] || placeholder} alt={displayName} />
			</Tippy>
			<div className="image__btn-container">
				<AuthorizationTooltip authorized={authorized} tooltipContent="You don't have access to update this image">
					<Button
						className="image__btn"
						onClick={authorized ? () => hiddenFileInput.current.click() : undefined}
						disabled={!authorized}
					>
						<Icon>camera_alt</Icon>
						Replace
					</Button>
				</AuthorizationTooltip>
				<input
					ref={hiddenFileInput}
					placeholder={placeholder}
					id={field}
					name={field}
					accept="image/*"
					type="file"
					onChange={(e) => {
						formik.setFieldValue('file', e.target.files[0]) && formik.handleSubmit();
					}}
				/>
			</div>
		</div>
	);
}

export default ImageInput;
