import ReactGA from 'react-ga4';
import { getUserEmail } from '@services/user';

export const sendAnalytics = (element, interaction, identifier) => {
	getUserEmail()
		.then((email) => {
			ReactGA.event({
				category: element,
				action: interaction,
				label: `${email} - ${identifier}`
			});
		})
		.catch((e) => {
			console.error(e);
			ReactGA.event({
				category: element,
				action: interaction,
				label: identifier
			});
		});
};
