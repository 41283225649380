import { Button } from '@nearst/ui';
import Spinner from '../../../../../../components/LoadingSpinner';

const LoadingButton = ({ loading, disabled, onClick, children, warning = false, className }) => {
	return (
		<Button onClick={onClick} disabled={disabled} primary warning={warning} className={className}>
			{children}
			{loading && <Spinner size={20} />}
		</Button>
	);
};

export default LoadingButton;
