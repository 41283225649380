import React from 'react';
import { Stack } from '@nearst/ui';
import EducationalBlock from '../../../insights/LocalAds/EducationalBlock';
import UpgradeRequiredBanner from '@components/UpgradeRequiredBanner';

const FreemiumLocalAds = () => {
	return (
		<Stack>
			<UpgradeRequiredBanner>
				Upgrade your plan to boost the visibility of your products by appearing at the top of search results.
			</UpgradeRequiredBanner>

			<EducationalBlock />
		</Stack>
	);
};

export default FreemiumLocalAds;
