import { isAfter } from 'date-fns';
import React, { useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import useSwr from 'swr';

import { Button, Icon, Page, Stack } from '@nearst/ui';

import { useOrders } from '@services/orders';
import { getOrderOptions, useChannel } from '@services/channels';
import { useCheckPermission } from '@services/auth/hooks';

import { TablePlaceholder } from '@components/Table/TablePlaceholder';
import OrderFilter from '../components/OrderFilter';
import OrderSearch from '../components/OrderSearch';
import OrdersModal from '../components/OrdersModal';
import OrdersTable from '../components/OrderTable';
import TablePagination from '../components/TablePagination';
import OrdersUpdateModal from '../components/OrdersUpdateModal';

import styles from './Orders.module.scss';

const itemsPerPage = 20;

const sortDateDesc = (a, b) => (isAfter(new Date(a.createdAt), new Date(b.createdAt)) ? -1 : 1);

const filterOrders = (orders, appliedFilters, searchText) =>
	orders
		?.filter((order) => !appliedFilters.length || appliedFilters.includes(order.status))
		.filter((order) => JSON.stringify({ a: order.customerName, b: order.products, c: order.id }).toLowerCase().includes(searchText))
		.sort(sortDateDesc);

const Orders = () => {
	const { shopId } = useParams();
	const isChain = !shopId;
	const { data, update: updateAndRevalidate, isLoading: isLoadingOrders } = useOrders({}, { suspense: false });

	const { data: orderOptions, isLoading: isLoadingOrderOptions } = useSwr(
		isChain ? null : `order-options-${shopId}`,
		() => getOrderOptions(shopId),
		{ suspense: false }
	);
	const authorizedChannelsManage = useCheckPermission('org:channels:manage');
	const { data: localCheckoutChannel, isLoading: isLoadingCheckout } = useChannel('local-checkout', shopId, { suspense: false });
	const { data: productReservationChannel, isLoading: isLoadingReservations } = useChannel('product-reservations', shopId, {
		suspense: false
	});

	const isLoading = isLoadingOrders || isLoadingCheckout || isLoadingReservations;

	const [pendingUpdate, setPendingUpdate] = useState();
	const [selectedOrder, setSelectedOrder] = useState(false);
	const [searchText, setSearchText] = useState('');
	const [appliedFilters, setAppliedFilters] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);

	const orders = useMemo(() => filterOrders(data, appliedFilters, searchText), [data, appliedFilters, searchText]);

	const totalPages = Math.ceil(orders?.length / itemsPerPage);

	const ordersToDisplay = orders?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

	const isEmpty = orders?.length < 1;

	const handleUpdate = async (order) => {
		if (order.status === 'cancelled' || order.status === 'confirmed') {
			// these allow you to skip or customise email messages
			setPendingUpdate(order);
		} else {
			// everything else is updated immediately
			await updateAndRevalidate(order);
		}
	};

	const onModalClose = () => {
		setSelectedOrder(false);
	};

	return (
		<>
			<Page.Header>
				<div className={styles.header}>
					<h1>Orders</h1>
					{!isLoadingOrderOptions && orderOptions && !isChain && authorizedChannelsManage ? (
						<Link
							to={`/channels/${shopId}/manage/${orderOptions.localCheckoutEnabled ? 'local-checkout' : 'product-reservations'}`}
						>
							<Button>
								<Icon>settings</Icon>
								Settings
							</Button>
						</Link>
					) : null}
				</div>
				<p>Manage your customers' orders. New orders will show on the page automatically.</p>
			</Page.Header>
			<Page.Section>
				<Stack>
					<div className={styles.actionContainer}>
						<OrderSearch setSearchText={setSearchText} />
						<OrderFilter setAppliedFilters={setAppliedFilters} />
					</div>
					<div className={styles.inventoryContainer}>
						{isEmpty ? (
							appliedFilters.length || searchText.length ? (
								<p>No orders matching your search.</p>
							) : (
								<p>You have currently not received any orders from local customers.</p>
							)
						) : isLoading ? (
							<TablePlaceholder />
						) : (
							<>
								<OrdersTable
									orders={ordersToDisplay}
									setSelectedOrder={setSelectedOrder}
									handleUpdate={handleUpdate}
									chain={isChain}
								/>
								<TablePagination pages={totalPages} currentPage={currentPage} onPageChange={setCurrentPage} />
							</>
						)}
					</div>
				</Stack>
				<OrdersModal selectedOrder={selectedOrder} onModalClose={onModalClose} />
				<OrdersUpdateModal
					pendingUpdate={pendingUpdate}
					onModalClose={() => setPendingUpdate(undefined)}
					onSubmit={updateAndRevalidate}
					localCheckoutChannel={localCheckoutChannel}
					productReservationChannel={productReservationChannel}
				/>
			</Page.Section>
		</>
	);
};

export default Orders;
