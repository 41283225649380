import React from 'react';
import useSWR from 'swr';

import { Page, Stack } from '@nearst/ui';
import LocalAdsManager from '../LocalAdsManager';
import ErrorBoundary from '../../../components/ErrorBoundary';
import FreemiumLocalAds from './FreemiumLocalAds';
import BudgetSlider from './BudgetSlider';
import LiaCards from './Cards';
import { useCheckRedirectedFromStripe } from './utils';
import { useShop } from '@services/ShopProvider';
import { listPlans } from '@services/localAds';
import styles from './LocalAdsOverview.module.scss';

const LocalAdsOverview = () => {
	useCheckRedirectedFromStripe();
	const noManagerPlans = ['freemium', 'lite', 'lite-trial'];

	const { shop } = useShop();
	const { data: upcomingPlan, mutate } = useSWR(`${shop.id}-upcoming-plan`, () => listPlans({ id: shop.id, status: 'upcoming' }));
	const showBudgetSlider = shop.totalAdsBudget || upcomingPlan?.budget;

	return (
		<>
			<Page.Header>
				<h1>Local Ads</h1>
				<p>
					Activate or change your ads budget to appear at the top of Google results and promote your products to more local
					shoppers.
				</p>
			</Page.Header>
			<Page.Section>
				<ErrorBoundary>
					{shop.customerSuccessContact && !noManagerPlans.includes(shop.plan) && shop.totalAdsBudget ? (
						<LocalAdsManager contact={shop.customerSuccessContact} />
					) : null}
				</ErrorBoundary>

				{shop.plan === 'freemium' ? (
					<FreemiumLocalAds />
				) : (
					<>
						{showBudgetSlider ? (
							<Stack className={styles.container}>
								<ErrorBoundary>
									<div className={styles.container}>
										<BudgetSlider shop={shop} />
									</div>
								</ErrorBoundary>
							</Stack>
						) : null}
						{!showBudgetSlider ? (
							<ErrorBoundary>
								<LiaCards shop={shop} revalidate={mutate} />
							</ErrorBoundary>
						) : null}
					</>
				)}
			</Page.Section>
		</>
	);
};

export default LocalAdsOverview;
