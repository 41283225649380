import { useInventory } from '@services/stock';
import mixpanel from 'mixpanel-browser';
import React, { useState } from 'react';
import ProductUploadModal from '../../components/ProductUploadModal';
import HideBarcodeModal from '../../components/HideBarcodeModal';
import ActionBar from './ActionBar';
import DesktopTable from './DesktopTable';
import MobileTable from './MobileTable';
import TablePagination from './TablePagination';
import { EmptyResultPlaceHolder, TablePlaceholder } from '@components/Table/TablePlaceholder';

import styles from './InventoryViewer.module.scss';

const InventoryViewer = ({ shop }) => {
	const {
		page: currentPage,
		totalPages,
		changePage,
		isLoading: isInventoryLoading,
		isEmpty,
		setSearchInput,
		isValidating,
		mutate
	} = useInventory(shop.id);

	const [bulkHideModal, setBulkHideModal] = useState(false);
	const [bulkUploadModalOpen, setBulkUploadModalOpen] = useState(false);
	const [disableActions, setDisableActions] = useState(false);

	const handleSearchChange = (e) => {
		const input = e.target.value;
		setSearchInput(input);
		mixpanel.track('Inventory search', {
			input,
			distinct_id: shop.id,
			'Shop ID': shop.id,
			'Shop LegacyId': shop.legacyId
		});
	};

	return (
		<>
			<HideBarcodeModal open={bulkHideModal} close={() => setBulkHideModal(false)} shop={shop} mutate={mutate} />
			<ProductUploadModal open={bulkUploadModalOpen} close={() => setBulkUploadModalOpen(false)} />

			<ActionBar
				shop={shop}
				setBulkHideModal={setBulkHideModal}
				setBulkUploadModalOpen={setBulkUploadModalOpen}
				handleSearchChange={handleSearchChange}
				disabled={disableActions}
			/>

			<div className={styles.inventoryContainer}>
				{isEmpty ? (
					<EmptyResultPlaceHolder />
				) : isInventoryLoading || isValidating ? (
					<TablePlaceholder />
				) : (
					<>
						<DesktopTable shop={shop} setDisableActions={setDisableActions} />
						<MobileTable shop={shop} />
						<TablePagination pages={totalPages} currentPage={currentPage} onPageChange={changePage} />
					</>
				)}
			</div>
		</>
	);
};

export default InventoryViewer;
