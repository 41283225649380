import React from 'react';
import * as Chart from 'recharts';
import { format, eachDayOfInterval, eachMonthOfInterval, eachWeekOfInterval } from 'date-fns';

import MultiPeriodTooltip from './MultiPeriodTooltip';
import { numberFormat } from '../formatters';
import { useInsightsDate } from '@services/InsightsDateProvider';

const getTicks = (interval, from, to) => {
	if (interval === 'month') {
		return eachMonthOfInterval({ start: new Date(from), end: new Date(to) }).map((date) => date.getTime());
	}
	if (interval === 'week') {
		return eachWeekOfInterval({ start: new Date(from), end: new Date(to) }, { weekStartsOn: 1 }).map((date) => date.getTime());
	}
	return eachDayOfInterval({ start: new Date(from), end: new Date(to) }).map((date) => date.getTime());
};

/**
 * A time series graph that shows current and previous date range values.
 *
 * Comes with a tooltip that shows the difference between the current and previous
 * values, expressed as a percentage.
 *
 * @typedef {object} Props
 * @property {Array<{ date: string, value: number, previousDate?: string, previousValue?: number }>} series The data series to plot
 * @property {string} [lineColor] Color of the line, any valid CSS color
 * @property {string} [yAxisLabel] Label for the Y axis
 * @property {boolean} [dateAxis=false] Force the graph to use the full selected date range
 *
 * @param {Props} props
 */
const TimeSeriesGraph = ({ series, lineColor = '#50a1ff', yAxisLabel = 'Value', dateAxis = false, ...props }) => {
	const { to, from, interval } = useInsightsDate();
	if (!series || !series.length) {
		return null;
	}
	let controlledAxisConfig = {};
	if (dateAxis) {
		const ticks = getTicks(interval, from, to);
		const domain = ['dataMin', 'dataMax'];
		const type = 'number';
		controlledAxisConfig = { ticks, domain, type };
	}

	const data = series.map(({ date, previousDate, ...rest }) => ({
		date: new Date(date).getTime(),
		previousDate: previousDate && new Date(previousDate),
		...rest
	}));

	const Tooltip = ({ active, payload, contentStyle }) => (
		<MultiPeriodTooltip active={active} label={yAxisLabel} payload={payload} lineColor={lineColor} contentStyle={contentStyle} />
	);

	return (
		<Chart.ResponsiveContainer width="100%" {...props}>
			<Chart.LineChart
				aria-roledescription="Line chart"
				style={{
					fontSize: '0.8rem',
					fontFamily: "'nearst-body', -apple-system, 'Helvetica Neue', 'Arial', sans-serif"
				}}
				margin={{ top: 5, right: 0, bottom: 0, left: -12 }}
				data={data}
			>
				<Chart.XAxis
					dataKey="date"
					aria-roledescription="axis"
					aria-orientation="horizontal"
					aria-label="Date"
					allowDuplicatedCategory
					interval="preserveStartEnd"
					tickFormatter={(date) => format(new Date(date), 'MMM d')}
					{...controlledAxisConfig}
				/>
				<Chart.YAxis
					aria-label={yAxisLabel}
					aria-roledescription="axis"
					aria-orientation="vertical"
					allowDuplicatedCategory
					tickFormatter={numberFormat}
				/>
				<Chart.Tooltip content={Tooltip} />
				<Chart.Line
					dataKey="previousValue"
					type="monotone"
					strokeWidth={2}
					dot={false}
					stroke="var(--grey-light)"
					strokeDasharray="4 4"
					fillOpacity={1}
					name={yAxisLabel}
					aria-label={yAxisLabel}
				/>
				<Chart.Line
					dataKey="value"
					type="monotone"
					strokeWidth={3}
					dot={false}
					stroke={lineColor}
					fillOpacity={1}
					name={yAxisLabel}
					aria-label={yAxisLabel}
				/>
			</Chart.LineChart>
		</Chart.ResponsiveContainer>
	);
};

export default TimeSeriesGraph;
