import myShopClient from './nearlive';

/**
 * Enable or disable LIA weekly email reports for a user.
 *
 * @param {object} update
 * @param {string} update.userId
 * @param {boolean} update.emailWeeklyLiaUpdate
 * @returns {Promise<void>}
 */
export async function updateEmailPreferences(update) {
	const client = await myShopClient();
	await client.post(`/api/email-preferences`, update);
}
