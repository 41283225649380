const createCombinedInsights = (data) => {
	const combinedInsights = {};

	data.forEach((insight) => {
		combinedInsights[insight.shopId] = {
			shopId: insight.shopId,
			shopName: insight.shopName,
			swisClicks: 0,
			swisImpressions: 0,
			liaClicks: 0,
			liaImpressions: 0
		};
	});

	data.forEach((insight) => {
		if (insight.tableName === 'local_listings') {
			combinedInsights[insight.shopId].swisClicks = insight.clicks;
			combinedInsights[insight.shopId].swisImpressions = insight.impressions;
		}

		if (insight.tableName === 'local_ads') {
			combinedInsights[insight.shopId].liaClicks = insight.clicks;
			combinedInsights[insight.shopId].liaImpressions = insight.impressions;
		}
	});

	return Object.values(combinedInsights);
};

const sortCombinedInsights = (combinedInsights) => {
	return combinedInsights.sort((a, b) => {
		if (a.liaClicks === b.liaClicks) return b.swisImpressions - a.swisImpressions;
		else return b.liaClicks - a.liaClicks;
	});
};

const splitByType = (combinedInsights) => {
	const splitInsights = {
		clicks: [],
		impressions: []
	};

	combinedInsights.forEach((insight) => {
		splitInsights.clicks.push({ shopName: insight.shopName, lia: insight.liaClicks, swis: insight.swisClicks, shopId: insight.shopId });
		splitInsights.impressions.push({
			shopName: insight.shopName,
			lia: insight.liaImpressions,
			swis: insight.swisImpressions,
			shopId: insight.shopId
		});
	});

	return splitInsights;
};

const formatGraphOptions = (data, limit) => {
	const flattenedData = data.flat();

	const combinedInsights = createCombinedInsights(flattenedData);

	const sortedCombinedInsights = sortCombinedInsights(combinedInsights).slice(0, limit);

	const splitInsights = splitByType(sortedCombinedInsights);
	return splitInsights;
};

const labelCallback = (currentDataPoint, datasetContext) => {
	// datasetIndex in currentDataPoint refers to label / value relationship in datasetContext
	// use index to lookup label
	const index = currentDataPoint.datasetIndex;
	const measure = datasetContext.datasets[index].label;

	// if currentDataPoint.y is NaN, the shop does not have LIA switched on for the time period
	const dataUnavailable = isNaN(currentDataPoint.y);
	const value = dataUnavailable && measure.includes('LIA') ? 'LIA off' : currentDataPoint.value;

	return `${measure}: ${value}`;
};

function toTitleCase(str) {
	if (!str) return '';
	return str.replace(/\w\S*/g, function (txt) {
		return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
	});
}

const removeOrgPrefix = (shopName, org) => {
	if (org.trim().toLowerCase() === shopName.trim().toLowerCase()) {
		return shopName;
	}
	return shopName
		.replace(org + '-', '')
		.replace(org + ' -', '')
		.replace('-' + org, '')
		.replace('- ' + org, '')
		.replace(org, '')
		.trim();
};

export { formatGraphOptions, labelCallback, toTitleCase, removeOrgPrefix };
