import React from 'react';

import styles from './UpgradeContentContainer.module.scss';
import FeaturesList from '@components/Modals/Upgrade/FeaturesList';

const UpgradeContentContainer = ({ children }) => {
	return (
		<div className={styles.container}>
			<div className={styles.content}>{children}</div>
			<div className={styles.benefits}>
				<h2>You will have access to</h2>
				<FeaturesList />
			</div>
		</div>
	);
};

export default UpgradeContentContainer;
