import React, { createContext, Suspense, useContext } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import mixpanel from 'mixpanel-browser';
import LoadingContainer from '../components/LoadingContainer';
import shopApi from './shop';

const store = createContext({});
const { Provider } = store;

export const getShopData = async () => {
	const shops = await shopApi.getMyShops();
	// add inSetup and inTrial booleans

	// broken for chains
	if (shops[0] && shops[0].id) {
		mixpanel.identify(shops[0].id);

		mixpanel.register({
			distinct_id: shops[0].id,
			name: shops[0].name,
			plan: shops[0].plan
		});
	}

	return shops.map((shop) => ({
		...shop,
		inSetup: !shop.googleMerchantId && !shop.stockInventorySource?.latestIngest
	}));
};

const Shop = ({ children }) => {
	const { shopId: pathId } = useParams();
	const swr = useSWR('active-shops', () => getShopData(), {
		suspense: true,
		revalidateOnFocus: false,
		refreshWhenHidden: false
	});
	const shop = swr.data.find((shop) => shop.id === pathId) || {};
	const updateShop = async (id, update) => {
		try {
			await swr.mutate(async (data) => {
				const unchangedShops = data.filter((shop) => shop.id !== id);
				const updatedShop = await shopApi.updateMyShop(id, update);
				return [...unchangedShops, updatedShop];
			}, false);
		} catch (e) {
			alert("Something went wrong and we couldn't update your details.\nPlease try again.");
		}
	};

	return <Provider value={{ shop, ...swr, updateShop }}>{children}</Provider>;
};

// defaultShop is for test cases ONLY
const ShopProvider = ({ children, defaultShop = undefined }) => {
	if (defaultShop) {
		return <Provider value={{ shop: defaultShop }}>{children}</Provider>;
	}

	return (
		<Suspense fallback={<LoadingContainer />}>
			<Shop>{children}</Shop>
		</Suspense>
	);
};

/**
 * @typedef {import('swr').MutatorCallback} SWRMutator
 * @typedef {import('@nearst/shop-data/types').Shop} Shop
 * @typedef {Object} Shop.availableChannels
 * @typedef {Object} Return
 * @property {boolean} Return.isValidating
 * @property {boolean} Return.isLoading
 * @property {SWRMutator} Return.mutate
 * @property {Shop[]} Return.data
 * @property {Shop} Return.shop
 * @property {(id: string, update: object) => Promise<void>} Return.updateShop
 */

/**
 * Update and revalidate the shop data
 *
 * @async
 * @typedef {function} updateShop
 * @param {string} id The id of the shop to update
 * @param {Partial<Shop>} shop The shop update
 * @return {Promise<Shop[]>}
 */

/**
 * Retrieves shops.
 * @returns {Partial<Return>}
 */
const useShop = () => useContext(store);

export { useShop, ShopProvider, Provider as TestOnlyProvider };
