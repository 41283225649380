import React from 'react';

import { MapPinIcon } from '@heroicons/react/20/solid';
import { Protect } from '@clerk/clerk-react';
import Sidebar from '@components/Sidebar';
import { useShop } from '@services/ShopProvider';

const Locations = () => {
	const { shop } = useShop();
	const shopSelected = !!shop?.id;
	if (shopSelected) return null;

	return (
		<Protect permission="org:locations:manage">
			<Sidebar.Item to="/locations">
				<MapPinIcon />
				Locations
			</Sidebar.Item>
		</Protect>
	);
};

export default Locations;
