import React, { useState } from 'react';
import { DateRangePicker, DefinedRange } from 'react-date-range';
import { subDays, format, differenceInDays } from 'date-fns';
import { enGB } from 'date-fns/locale';
import useMedia from 'use-media';

import { CalendarIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import Popover from '@material-ui/core/Popover';

import { Button } from '@nearst/ui';
import IntervalSelector from './lib/IntervalSelector';
import { useInsightsDate } from '@services/InsightsDateProvider';
import { getDefaultComparisonRanges, getDefaultDateRanges } from './lib/defaultRanges';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import styles from './DatePicker.module.scss';

export default function PopoverDatePicker({ daysBehind = 1, showComparison = false, showInterval = false }) {
	const { startDate, setStartDate, endDate, setEndDate, selectedRange, setSelectedRange, comparison, setComparison } = useInsightsDate();
	const [pickerType, setPickerType] = useState('date');
	const [dateRange, setDateRange] = useState([{ startDate, endDate, key: 'selection' }]);
	const [anchorEl, setAnchorEl] = useState(null);

	const isWide = useMedia({ minWidth: '800px' });
	const staticRanges = getDefaultDateRanges({ daysBehind });
	const staticComparisonRanges = getDefaultComparisonRanges({ startDate, endDate });
	const ranges = pickerType === 'date' ? staticRanges : staticComparisonRanges;

	const handleClick = (event, pickerType) => {
		setPickerType(pickerType);
		setDateRange(pickerType === 'date' ? [{ startDate, endDate, key: 'selection' }] : [{ ...comparison, key: 'selection' }]);
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (range) => {
		range = range || dateRange;
		setAnchorEl(null);

		const { startDate, endDate } = range[0];
		const selected = ranges.find((option) => option.isSelected(range[0]) === true);
		const label = selected ? selected.label : `${format(startDate, 'PP')} – ${format(endDate, 'PP')}`;

		if (pickerType === 'date') {
			setSelectedRange(label);
			setStartDate(startDate);
			setEndDate(endDate);

			// reset the comparison date range
			const daysBetween = differenceInDays(endDate, startDate);
			setComparison({
				startDate: subDays(startDate, 1 + daysBetween),
				endDate: subDays(startDate, 1),
				label: 'Previous period'
			});
		}
		if (pickerType === 'comparison') {
			setComparison({
				startDate: range[0].startDate,
				endDate: range[0].endDate,
				label
			});
		}
	};

	const getDayIndex = (date) => Math.floor(date.valueOf() / 86400000);

	const handleSelect = (item) => {
		setDateRange([item.selection]);
		if (getDayIndex(item.selection.startDate) !== getDayIndex(item.selection.endDate)) {
			// automatically close after a new range has been selected
			handleClose([item.selection]);
		}
	};

	return (
		<div className={styles.container}>
			<Button className={styles.button} small onClick={(e) => handleClick(e, 'date')}>
				<CalendarIcon className={styles.icon} />
				{selectedRange}
			</Button>
			{showComparison ? (
				<>
					vs
					<Button className={styles.button} small onClick={(e) => handleClick(e, 'comparison')}>
						{comparison.label}
						<ChevronDownIcon className={styles.icon} />
					</Button>
				</>
			) : null}
			{showInterval && <IntervalSelector />}
			<Popover
				PaperProps={{ 'aria-label': 'Menu item', role: 'menuitem' }}
				open={!!anchorEl}
				anchorEl={anchorEl}
				onClose={() => handleClose()}
				className={styles.picker}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
				transformOrigin={{
					vertical: -8,
					horizontal: 'left'
				}}
			>
				{isWide ? (
					<DateRangePicker
						locale={enGB}
						onChange={handleSelect}
						minDate={new Date('2020-01-01')}
						maxDate={subDays(new Date(), daysBehind)}
						ranges={dateRange}
						shownDate={dateRange[0].endDate}
						inputRanges={pickerType === 'date' ? undefined : []}
						direction="horizontal"
						staticRanges={ranges}
					/>
				) : (
					<DefinedRange
						locale={enGB}
						onChange={handleSelect}
						showSelectionPreview
						minDate={new Date('2020-01-01')}
						maxDate={subDays(new Date(), daysBehind)}
						ranges={dateRange}
						shownDate={dateRange[0].endDate}
						direction="horizontal"
						staticRanges={staticRanges}
					/>
				)}
			</Popover>
		</div>
	);
}
