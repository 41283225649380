import { Protect } from '@clerk/clerk-react';
import { Button } from '@nearst/ui';
import React from 'react';

const UserActions = ({ user, revalidate, className }) => {
	// can't remove yourself from the org
	if (user.isMe) return null;

	return (
		<Protect permission="org:user_permissions:manage">
			<div className={className}>
				{user.invited ? (
					<Button
						small
						onClick={async () => {
							await user.revoke();
							await revalidate();
						}}
					>
						Revoke
					</Button>
				) : (
					<Button
						small
						onClick={async () => {
							await user.destroy();
							await revalidate();
						}}
					>
						Remove
					</Button>
				)}
			</div>
		</Protect>
	);
};

export default UserActions;
