import React, { useMemo, useState } from 'react';
import { TemplateModal, Icon, Button, Card, ProgressBar } from '@nearst/ui';
import Uppy from '@uppy/core';
import { DragDrop } from '@uppy/react';
import papa from 'papaparse';
import '@uppy/core/dist/style.min.css';
import '@uppy/drag-drop/dist/style.min.css';
import { useShop } from '../../../../services/ShopProvider';
import processFile from './processFile';

import styles from './ProductUploadModal.module.scss';

const ProcessingUpload = ({ filename, progress }) => {
	return (
		<>
			<Card className={styles.processingCard} sectioned>
				<p>
					<Icon>description</Icon>
					{filename}
				</p>
				<ProgressBar className={styles.processBar} value={progress.value} max={progress.max} />
			</Card>
			<div className={styles.processingInfo}>Processing {progress.max} barcodes...</div>
		</>
	);
};

const SuccessfulUpload = ({ processDetails }) => {
	const unsuccessfulCount = processDetails.incompleteData + processDetails.notFound;
	const successfulCount = processDetails.barcodesMatched - unsuccessfulCount;

	return (
		<div>
			<div className={`${styles.processResult}`}>
				<p>
					File uploaded: {successfulCount} updated, {unsuccessfulCount} failed
				</p>
				<div className={styles.processDetailsContainer}>
					{successfulCount ? (
						<dl>
							<dt>
								<span className={`${styles.successful}`}>
									<Icon>done</Icon>
								</span>
								<span>Barcode updated</span>
							</dt>
							<dd>{successfulCount}</dd>
						</dl>
					) : null}
					{processDetails.notFound ? (
						<dl>
							<dt>
								<span className={`${styles.unsuccessful}`}>
									<Icon>clear</Icon>
								</span>
								<span>Barcode not found in the latest stock file</span>
							</dt>
							<dd>{processDetails.notFound}</dd>
						</dl>
					) : null}
					{processDetails.incompleteData ? (
						<dl>
							<dt>
								<span className={`${styles.unsuccessful}`}>
									<Icon>clear</Icon>
								</span>
								<span>Barcode has incomplete data in file</span>
							</dt>
							<dd>{processDetails.incompleteData}</dd>
						</dl>
					) : null}
				</div>
			</div>
		</div>
	);
};

const UnsuccessfulUpload = ({ processDetails }) => {
	return (
		<div>
			<div className={`${styles.processResult} ${styles.unsuccessful}`}>
				<h3>
					<Icon>clear</Icon> Unable to process file
				</h3>
				<p>The .csv file you have uploaded does not have the expected format.</p>
				<div className={styles.processDetailsContainer}>
					{processDetails.missingHeaders ? (
						<>
							<span>Missing headers: </span>
							<span>{processDetails.missingHeaders.join(', ')}</span>
						</>
					) : null}
				</div>
			</div>
		</div>
	);
};

const ProductUploadModal = ({ close, open }) => {
	const { shop } = useShop();
	const [processStatus, setProcessStatus] = useState('');
	const [processDetails, setProcessDetails] = useState(null);
	const [filename, setFilename] = useState('');
	const [progress, setProgress] = useState({ value: 0, max: 0 });

	const handleClose = () => {
		if (processStatus === 'processing') return;
		close();
		setProcessStatus('');
	};

	const uppy = useMemo(() => {
		const uppy = new Uppy({
			restrictions: {
				maxNumberOfFiles: 1,
				allowedFileTypes: ['text/csv', '.csv', '.txt', '.tsv']
			},
			autoProceed: true,
			allowMultipleUploadBatches: false
		});

		uppy.on('file-added', (file) => {
			setFilename(file.name);

			papa.parse(file.data, {
				header: true,

				complete: async function (results) {
					setProcessStatus('processing');

					const requiredHeaders = ['barcode', 'title', 'brand', 'description', 'image_url'];
					const providedHeaders = results.meta.fields;
					const missingHeaders = requiredHeaders.filter((header) => !providedHeaders.includes(header));

					if (missingHeaders.length) {
						setProcessDetails({ missingHeaders });
						setProcessStatus('unsuccessful');
					} else {
						setProcessDetails(await processFile(shop.id, results.data, setProgress));
						setProcessStatus('successful');
					}

					uppy.cancelAll();
				},
				error: () => {
					setProcessStatus('unsuccessful');
					uppy.cancelAll();
				}
			});
		});

		return uppy;
	}, [shop.id]);

	return (
		<TemplateModal isOpen={open} onDismiss={handleClose} title={'Upload product data'} showDismissButton={false}>
			<div className="modal__content-container">
				<p>You can add product data for multiple barcodes at once by uploading a .csv file containing the required fields.</p>
				<p>Required fields: barcode, title, description, brand, image_url</p>

				{!processStatus && (
					<>
						<Button
							href={
								'data:text/csv;charset=utf-8,barcode,title,description,brand,image_url%0A12345,example title,example description,example brand,http://exampleimageurl.com'
							}
							download="template.csv"
							link
						>
							Download example file <Icon>download</Icon>
						</Button>
						<DragDrop className={styles.dragDrop} uppy={uppy} />
					</>
				)}
				{processStatus === 'processing' && <ProcessingUpload filename={filename} progress={progress} />}
				{processStatus === 'successful' && <SuccessfulUpload processDetails={processDetails} />}
				{processStatus === 'unsuccessful' && <UnsuccessfulUpload processDetails={processDetails} />}
			</div>
		</TemplateModal>
	);
};

export default ProductUploadModal;
