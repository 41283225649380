import React from 'react';
import { Protect } from '@clerk/clerk-react';

import DashboardContent from './Dashboard';
import AccessDenied from '../errors/AccessDenied';

export const Dashboard = () => {
	return (
		<Protect permission="org:insights:read" fallback={<AccessDenied />}>
			<DashboardContent />
		</Protect>
	);
};
