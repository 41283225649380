const Categories = require('../model/Categories');
const ChannelDefinition = require('../model/ChannelDefinition');
const Requirements = require('../model/Requirements');

class LocalCheckoutChannel extends ChannelDefinition {
	id = 'local-checkout';
	title = 'Local Checkout';
	description = 'Offer click-and-collect functionality on top of your online discovery journeys.';
	category = Categories.LOCAL_COMMERCE;

	iconUrl = 'https://go.near.st/hubfs/channels/nearst.png';
	coverImageUrl = 'https://go.near.st/hubfs/channels/headers/local-checkout.png';

	longDescription = `
## Overview
Connect your Stripe account and offer your customers a way to buy online and pick up in store from all the channels showing your inventory. NearSt creates product pages for all items, links them to channel listings, manages customer payments and order updates. 

## How it works
Customers will land on your NearSt hosted product pages from different channels, where they’ll be offered a click-and-collect buying option which accepts all modern payment methods, including Apple Pay and Google Pay.

Order management is available in the dashboard, where you can mark orders as cancelled or completed and access customer notes. Your store staff will be notified of new orders via email or SMS. Emails will be sent to your customers automatically when the status of an order changes.

## Requirements
- Have either Google Local Listings, Google Local Inventory Ads, or Meta Commerce enabled
- Have a product data source connected to show images and descriptions on your product pages
- Create or connect a Stripe merchant account to accept payments
	`;

	manageUrl = '/channels/{shopId}/settings/local-checkout';

	productSpecificVisibility = true;
	worksWith = ['google', 'local-ads', 'meta-commerce'];

	helpArticleUrls = [
		{
			title: 'Setting up Local Checkout',
			url: 'https://support.near.st/en/collections/3891918-local-checkout'
		}
	];

	requirements = new Requirements({
		plans: ['lite', 'standard', 'advanced']
	});
}

module.exports = LocalCheckoutChannel;
