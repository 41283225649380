import React from 'react';
import { Card } from '@nearst/ui';
import { FormControlLabel, Switch } from '@material-ui/core';
import mixpanel from 'mixpanel-browser';
import { hideProducts } from '@services/stock';
import useSWRMutation from 'swr/mutation';
import styles from './ItemStatus.module.scss';
import { useCheckPermission } from '@services/auth/hooks';

const getItemStatus = (stockItem) => {
	if (!stockItem.issues?.length) {
		if (stockItem.enabled) {
			return 'Active';
		} else {
			return 'Hidden';
		}
	} else {
		return 'Inactive';
	}
};

const ItemStatusCard = ({ stockItem, shopId }) => {
	const itemStatus = getItemStatus(stockItem);
	const { trigger } = useSWRMutation(`stock_data-${shopId}-${stockItem.barcode}`, async (_, { arg: enabled }) =>
		hideProducts(shopId, [stockItem.barcode], enabled)
	);

	const authorized = useCheckPermission('org:inventory:hide');

	const handleChange = async () => {
		const enabled = !stockItem.enabled;

		await trigger(enabled, {
			optimisticData: () => ({ ...stockItem, enabled }),
			rollbackOnError: true
		});

		mixpanel.track('Product visibility updated', { distinct_id: shopId, barcode: stockItem.barcode, enabled });
	};

	return (
		<Card title="Status" data-cy="inventory-item-status">
			<p>{`This product is ${itemStatus.toLowerCase()} in your feed.`}</p>
			{itemStatus !== 'Inactive' && (
				<div className={styles.toggleContainer}>
					<FormControlLabel
						control={<Switch className={styles.toggle} checked={itemStatus === 'Active'} onChange={handleChange} />}
						label={itemStatus}
						disabled={!authorized}
					/>
				</div>
			)}
		</Card>
	);
};

export default ItemStatusCard;
