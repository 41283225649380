import React from 'react';
import { format } from 'date-fns';
import _ from 'lodash';
import * as Stats from '../../../../../components/StatsCard';
import { colourScheme } from '../../utils';
import InsightsWrapper from '../../../../../components/Graphs/DataWrapper';
import { useInsightsDate } from '@services/InsightsDateProvider';
import DoughnutWithLegend from '../../DoughnutWithLegend';

import styles from '../Cards.module.scss';

export const ShareOfProfileViewsCard = ({ shopIds }) => {
	const { startDate, endDate, selectedRange } = useInsightsDate();
	const query = {
		table: 'gmb',
		from: format(startDate, 'yyyy-MM-dd'),
		to: format(endDate, 'yyyy-MM-dd'),
		id: shopIds,
		columns: ['map_views_desktop', 'map_views_mobile', 'search_views_desktop', 'search_views_mobile', 'date'],
		sort: [{ column: 'date', order: 'asc' }],
		sum: ['map_views_desktop', 'map_views_mobile', 'search_views_desktop', 'search_views_mobile']
	};

	return (
		<Stats.Card className={`${styles.card} ${styles.businessProfile}`}>
			<Stats.Title>
				<span>
					Share of profile views
					<Stats.Tooltip>
						The number of unique visitors to your profile. Multiple daily visits by the same user aren’t counted.
					</Stats.Tooltip>
				</span>
			</Stats.Title>
			<Stats.Subtitle>{selectedRange}</Stats.Subtitle>
			<InsightsWrapper query={query} hideError>
				{(data) => {
					const mapsViewsDesktop = _.sumBy(data, 'map_views_desktop');
					const mapsViewsMobile = _.sumBy(data, 'map_views_mobile');
					const mapsViews = mapsViewsDesktop + mapsViewsMobile;
					const searchViewsDesktop = _.sumBy(data, 'search_views_desktop');
					const searchViewsMobile = _.sumBy(data, 'search_views_mobile');
					const searchViews = searchViewsDesktop + searchViewsMobile;

					const series = [
						{
							label: 'Google Search views',
							value: searchViews,
							description: 'The number of times your Google Business Profile was viewed on Google Search by a unique user.'
						},
						{
							label: 'Google Maps views',
							value: mapsViews,
							description: 'The number of times your Google Business Profile was viewed on Google Maps by a unique user.'
						}
					]
						.map((obj, i) => {
							obj.colour = colourScheme[i];
							return obj;
						})
						.sort((a, b) => b.value - a.value);

					return <DoughnutWithLegend series={series} />;
				}}
			</InsightsWrapper>
		</Stats.Card>
	);
};
