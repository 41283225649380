import React from 'react';
import * as Chart from 'recharts';
import { format } from 'date-fns';
import { numberFormat } from '../formatters';
import { TooltipContainer } from '../Tooltip';

/**
 * A line graph component that uses recharts.
 *
 * Comes with a default tooltip that can be overridden, and is
 * responsive by default.
 *
 * @typedef {object} Props

 * @property {Array<{ date: string, [key: string]: any }>} series Data for each series
 * @property {Array<{ key: string, colour: string, label: string }>} seriesConfig Configuration for each series
 * @property {string} [yAxisLabel] Label for the Y axis
 * @property {function?} [tooltip] Optional custom tooltip content render function
 *
 * @param {Props} props
 */
const LineGraph = ({ series, seriesConfig, yAxisLabel = 'Value', tooltip = undefined, ...props }) => {
	if (!series || !series.length) {
		return null;
	}

	const data = series.map(({ date, ...row }) => ({ date: new Date(date), ...row }));

	const Tooltip = ({ active, payload, label, contentStyle }) => {
		if (!active || !payload || !payload.length) {
			return null;
		}
		return (
			<TooltipContainer contentStyle={contentStyle}>
				{tooltip ? (
					tooltip({ active, payload, label, contentStyle })
				) : (
					<>
						<p>
							<strong>{label instanceof Date ? format(new Date(label), 'MMM d') : label.toString()}</strong>
						</p>
						{payload.map((item) => (
							<p style={{ color: item.stroke }} key={item.name}>
								{item.name}: {item.value.toLocaleString()}
							</p>
						))}
					</>
				)}
			</TooltipContainer>
		);
	};

	return (
		<Chart.ResponsiveContainer width="100%" {...props}>
			<Chart.LineChart
				aria-roledescription="Line chart"
				style={{
					fontSize: '0.8rem',
					fontFamily: "'nearst-body', -apple-system, 'Helvetica Neue', 'Arial', sans-serif"
				}}
				margin={{ top: 5, right: 0, bottom: 0, left: -12 }}
				data={data}
			>
				<Chart.XAxis
					dataKey="date"
					aria-roledescription="axis"
					aria-orientation="horizontal"
					aria-label="Date"
					allowDuplicatedCategory
					interval="equidistantPreserveStart"
					tickFormatter={(date) => format(new Date(date), 'MMM d')}
				/>
				<Chart.YAxis
					tickFormatter={numberFormat}
					aria-label={yAxisLabel}
					aria-roledescription="axis"
					aria-orientation="vertical"
					allowDuplicatedCategory
				/>
				<Chart.Tooltip content={Tooltip} />
				{seriesConfig.map((series) => (
					<Chart.Line
						dataKey={series.key}
						key={series.key}
						type="monotone"
						strokeWidth={3}
						dot={false}
						stroke={series.colour}
						fillOpacity={1}
						name={series.label}
						aria-label={series.label}
					/>
				))}
			</Chart.LineChart>
		</Chart.ResponsiveContainer>
	);
};

export default LineGraph;
