import { differenceInHours } from 'date-fns';

export const shopConnectionStatus = (shop) => {
	let status = { text: 'disconnected', colour: 'orange' };

	if (!shop.stockInventorySource || !shop.stockInventorySource?.latestIngest?.timestamp) {
		status = { text: 'onboarding', colour: 'grey' };
	}

	if (shop.stockInventorySource) {
		const timeSinceIngest = differenceInHours(new Date(), new Date(shop.stockInventorySource?.latestIngest?.timestamp));
		if (timeSinceIngest <= 48) status = { text: 'live', colour: 'green' };
	}
	return status;
};
