import React from 'react';

import MockGraph from './MockGraph';

import './style.css';

const NoData = ({ className = '', text = 'No data available' }) => {
	// getting pre-existing stylesheet colour
	const graphColour = window.getComputedStyle(document.documentElement).getPropertyValue('--grey');
	return (
		<div className="placeholder__content-container">
			<MockGraph className={className} colour={graphColour} />
			<p className="placeholder__content-text--title">{text}</p>
		</div>
	);
};

export default NoData;
