const calcAnalyticInsights = (rawInsights) => {
	const analytics = rawInsights.reduce(
		(analyticsAcc, currentInsight) => {
			const { type, value } = currentInsight;
			if (type === 'product-page-view') {
				analyticsAcc.productPageViews += value;
			}

			if (type === 'product-page-directions-button-click') {
				analyticsAcc.directionClicks += value;
				analyticsAcc.productPageActions += value;
			}

			if (
				[
					'product-page-opening-hours-button-click',
					'product-page-call-button-click',
					'product-page-website-button-click',
					'product-page-opening-hours-button-click'
				].includes(type)
			) {
				analyticsAcc.productPageActions += value;
			}

			return analyticsAcc;
		},
		{
			productPageViews: 0,
			productPageActions: 0,
			directionClicks: 0
		}
	);

	return {
		productPageViews: analytics.productPageViews,
		productPageActions: analytics.productPageActions,
		directionClicks: analytics.directionClicks
	};
};

const plurify = (word, count) => {
	return count === 1 ? word : `${word}s`;
};

export { calcAnalyticInsights, plurify };
