import { Avatar } from '@material-ui/core';
import DoneIcon from '@mui/icons-material/Done';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import LensIcon from '@mui/icons-material/Lens';
import React from 'react';

import styles from './SubstepIcon.module.scss';

const SubstepIcon = ({ progress = 'to do' }) => {
	if (progress === 'in progress') {
		return <Avatar className={`${styles.substepStatusFont} ${styles.substepStatusCurrent}`}>{<HourglassBottomIcon />}</Avatar>;
	}
	if (progress === 'completed') {
		return <Avatar className={`${styles.substepStatusFont} ${styles.substepStatusComplete}`}>{<DoneIcon />}</Avatar>;
	}
	if (progress === 'to do') {
		return <Avatar className={styles.substepStatusFont}>{<LensIcon />}</Avatar>;
	}
};

export default SubstepIcon;
