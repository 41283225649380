import React from 'react';

import { Page } from '@nearst/ui';
import InventoryViewer from './InventoryViewer';
import { useShop } from '@services/ShopProvider';

const Inventory = () => {
	const { shop } = useShop();

	return (
		<>
			<Page.Header>
				<h1>Manage items</h1>
				<p>Customize images, descriptions and channel visibility per product.</p>
			</Page.Header>
			<Page.Section>
				<InventoryViewer shop={shop} />
			</Page.Section>
		</>
	);
};

export default Inventory;
