import React, { useState } from 'react';
import useSWR from 'swr';
import { Button, Card, Icon, ExpandableCard, Pill } from '@nearst/ui';
import HelpButton from '../../HelpButton';
import { showIntercomArticle } from '@services/Intercom/utils';
import { getOrderOptions } from '@services/channels';
import { useShop } from '@services/ShopProvider';
import MetaCommerceStepTwo from '../MetaCommerceStepTwo';
import CatalogStatus from '../../CatalogStatus';
import DisableMetaCommerce from './DisableMetaCommerce';

import InstagramIcon from '../../../../../../../assets/icons/instagram.svg?react';
import styles from './EnabledState.module.scss';

const EnabledState = ({ channel }) => {
	const { shop } = useShop();
	const [showDisabledModal, setShowDisabledModal] = useState(false);
	const [updateFlowActive, setUpdateFlowActive] = useState(false);
	const { data: orderOptions } = useSWR(`order-options-${shop.id}`, () => getOrderOptions(shop.id));
	const checkoutOrReservationsEnabled = orderOptions?.anyOptionEnabled;

	return (
		<>
			<Card title="Connection status">
				<CatalogStatus channel={channel} />
				<Button
					primary
					href={`https://business.facebook.com/commerce/catalogs/${channel?.config.catalogId}/home?business_id=${
						channel?.config.businessId || ''
					}`}
					target="_blank"
					rel="noreferrer"
				>
					Manage your shop
				</Button>
				<Button onClick={() => setShowDisabledModal(true)} className={styles.disableButton}>
					Disable integration
				</Button>
			</Card>
			<DisableMetaCommerce showDisabledModal={showDisabledModal} setShowDisabledModal={setShowDisabledModal} />
			{updateFlowActive ? (
				<MetaCommerceStepTwo channel={channel} finish={() => setUpdateFlowActive(false)} />
			) : (
				<>
					{!channel.config?.domainVerificationCode && (
						<Card>
							<div className={styles.cardTitle}>
								<strong>Update your integration to enable Instagram Shops</strong>
								<Pill colour="blue">New</Pill>
							</div>
							<p>
								As part of your Meta Commerce integration, you can use the same product catalog to enable Instagram Shops as
								an additional sales channel. <br />
								You need to complete one additional step to configure this.
							</p>
							<p>This should take no longer than 5 minutes.</p>
							<Button onClick={() => setUpdateFlowActive(true)}>
								<Icon>settings</Icon>Update integration
							</Button>
						</Card>
					)}

					{channel.config?.domainVerificationCode && !checkoutOrReservationsEnabled && (
						<Card>
							<div className={styles.cardTitle}>
								<span className={styles.noClickNCollectTitle}>
									<Icon className={styles.warningIcon}>warning</Icon>
									You don’t have a click & collect option enabled for your shop.
								</span>
							</div>
							<p>
								If you want to provide the best experience for your Facebook and Instagram customers, we highly recommend
								enabling a buying option.
								<br />
								Instagram Shops policies require you to provide a checkout option for your shoppers.
							</p>
							<p>You can enable product reservations or Local Checkout with just a few clicks.</p>
							<div className={styles.ropisAction}>
								<Button href="/channels">Manage channels</Button>
								<a
									onClick={(e) => {
										if (window.Intercom) {
											e.preventDefault();
											showIntercomArticle(8167181);
										}
									}}
									href={'https://support.near.st/en/articles/8167181-why-do-i-need-a-buying-option-for-instagram-shops'}
									target="_blank"
									rel="noreferrer"
									className={styles.learnMoreButton}
								>
									Learn more
								</a>
							</div>
						</Card>
					)}

					<ExpandableCard title="Tagging products in posts" icon={<Icon>local_offer</Icon>}>
						<HelpButton href="https://support.near.st/en/articles/6683661-how-to-tag-your-items-in-meta-posts">
							How can I tag products in Meta posts?
						</HelpButton>
						<HelpButton href="https://support.near.st/en/articles/8394663-why-is-it-important-to-tag-products-in-my-posts">
							Why is it important to tag products in my posts?
						</HelpButton>
					</ExpandableCard>
					<ExpandableCard title="Getting started with Facebook Shops" icon={<Icon>facebook</Icon>}>
						<HelpButton href="https://support.near.st/en/articles/7837924-how-to-create-a-facebook-page-to-share-your-products-on">
							How do I create a Facebook page to share & promote my products on?
						</HelpButton>
						<HelpButton href="https://support.near.st/en/articles/6683644-sharing-products-in-facebook-messenger">
							How do I share products in Messenger?
						</HelpButton>
					</ExpandableCard>
					<ExpandableCard title="Getting started with Instagram Shops" icon={<InstagramIcon className={styles.instagramIcon} />}>
						<HelpButton href="https://support.near.st/en/articles/8062038-how-to-create-an-instagram-business-account">
							How can I create an Instagram business account?
						</HelpButton>
						<HelpButton href="https://support.near.st/en/articles/8066497-how-do-i-enable-instagram-shops">
							How do I enable Instagram Shops?
						</HelpButton>
						<HelpButton href="https://support.near.st/en/articles/8066777-understanding-your-insights-with-instagram">
							Understanding your Instagram Shops insights
						</HelpButton>
					</ExpandableCard>
					<ExpandableCard title="Manage your catalog" icon={<Icon>settings</Icon>}>
						<HelpButton href="https://support.near.st/en/articles/6683600-how-to-delete-a-product-from-your-meta-shops">
							How to delete a product from your Meta shops?
						</HelpButton>
						<HelpButton href="https://support.near.st/en/articles/6683709-why-aren-t-all-my-products-showing-up-on-my-meta-shop">
							Why aren&apos;t all my products showing up in my catalog?
						</HelpButton>
						<HelpButton href="https://support.near.st/en/articles/6683635-what-items-are-restricted-by-facebook">
							What items are restricted by Meta?
						</HelpButton>
						<HelpButton href="https://support.near.st/en/articles/6683616-how-can-i-create-a-product-collection-usa-stores-only">
							How can I create a product collection? (US stores only 🇺🇸)
						</HelpButton>
						<HelpButton href="https://support.near.st/en/articles/6683618-how-to-cancel-your-nearst-connection-to-meta-commerce">
							How can I disable my Meta Commerce integration?
						</HelpButton>
					</ExpandableCard>
				</>
			)}
		</>
	);
};

export default EnabledState;
