const Categories = require('../model/Categories');
const ChannelDefinition = require('../model/ChannelDefinition');
const Requirements = require('../model/Requirements');

class ProductLocatorChannel extends ChannelDefinition {
	id = 'product-locator';
	title = 'Product Locator';
	description = 'Help customers browsing on your website find the nearest store with stock availability.';
	category = Categories.OMNICHANNEL_EXPERIENCE;

	iconUrl = 'https://go.near.st/hubfs/channels/nearst.png';
	coverImageUrl = 'https://go.near.st/hubfs/channels/headers/product-locator.png';

	longDescription = `
## Overview
The Product Locator is a widget designed to be embedded on your website product pages, allowing customers to see which of your locations currently have the specified product in stock.

## How it works
Embedding the widget in your website is very easy and only requires a few lines of code. You can see an example of the widget and try the available customizations [here](https://product-locator.near.st/builder).

Adding the Product Locator will enable the customer to easily check which of your stores have the product in stock near their current location, complementing your e-commerce purchase journey.

## Requirements
- Online website with product pages
- Consistent use of barcodes across website and NearSt stock feed
	`;

	manageUrl = '/channels/{shopId}/settings/product-locator';
	productSpecificVisibility = true;

	worksWith = ['google', 'local-ads'];

	helpArticleUrls = [
		{
			title: 'Developer documentation',
			url: 'https://developers.near.st/product-locator'
		}
	];

	requirements = new Requirements({
		plans: ['advanced']
	});
}

module.exports = ProductLocatorChannel;
