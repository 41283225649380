import React from 'react';
import { Pill } from '@nearst/ui';
import { shopConnectionStatus } from '../../../../utils/shopConnectionStatus';

const LocationStatus = ({ shop }) => {
	const status = shopConnectionStatus(shop);
	return (
		<div>
			<Pill colour={status.colour}>{status.text}</Pill>
		</div>
	);
};

export default LocationStatus;
