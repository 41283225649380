import React from 'react';
import { error } from './ErrorMessage.module.scss';

const ErrorMessage = ({ children, className = '' }) => {
	return (
		<p data-cy="error-message" className={`${error} ${className}`}>
			{children}
		</p>
	);
};

export default ErrorMessage;
