import React, { useState, useCallback, useMemo, createContext } from 'react';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import Toggle from './Toggle';

import { Performance } from './PerformanceGraph';

export const MeasureContext = createContext(null);

const ChainPerformance = ({ data, org, shops, insightType, plans = [] }) => {
	const [selectedMeasure, setSelectedMeasure] = useState('impressions');

	const setMeasure = useCallback((measure) => {
		setSelectedMeasure(measure);
	}, []);

	const contextValue = useMemo(
		() => ({
			selectedMeasure,
			setMeasure
		}),
		[selectedMeasure, setMeasure]
	);

	const metrics = shops.reduce(
		(acc, shop) => {
			const shopData = data.filter((cur) => cur.shop_id === shop.id || cur.shopId === shop.id);
			acc.clicks.push({
				shopId: shop.id,
				name: shop.name,
				value: shopData.reduce((acc, cur) => acc + cur.clicks, 0)
			});
			acc.impressions.push({
				shopId: shop.id,
				name: shop.name,
				value: shopData.reduce((acc, cur) => acc + cur.impressions, 0)
			});
			return acc;
		},
		{ clicks: [], impressions: [] }
	);

	return (
		<MeasureContext.Provider value={contextValue}>
			<ErrorBoundary>
				<div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
					<div></div>
					<Toggle insightType={insightType} />
				</div>

				<Performance metrics={metrics} org={org} insightType={insightType} plans={plans} />
			</ErrorBoundary>
		</MeasureContext.Provider>
	);
};

export { ChainPerformance };
