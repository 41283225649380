import React, { useMemo } from 'react';

import { format, subDays, startOfYesterday, isYesterday } from 'date-fns';
import { UItils } from '@nearst/ui';
import * as Stats from '@components/StatsCard';
import { useInsights } from '@services/insights';

import styles from './InventoryConnection.module.scss';
import InventorySource from './InventorySource';
import ConnectionStatus from './InventorySource/ConnectionStatus';
import { formatChartData, formattedTodaysInventorySourceData } from './utils';

const InventoryConnection = ({ shops }) => {
	const shopIds = shops.map((shop) => shop.id);
	const isChain = shopIds.length > 1;
	const stockInventorySources = shops.filter((shop) => shop.stockInventorySource).map((shop) => shop.stockInventorySource);
	const productInventorySources = shops.filter((shop) => shop.productInventorySource).map((shop) => shop.productInventorySource);
	const from = format(subDays(startOfYesterday(), 30), 'yyyy-MM-dd');
	const to = format(startOfYesterday(), 'yyyy-MM-dd');

	const query = {
		id: shopIds,
		table: 'stock_count',
		columns: ['in_stock_barcodes', 'total_uploaded_barcodes', 'shop_id', 'date'],
		from,
		to,
		sort: [{ column: 'date', order: 'asc' }],
		sum: ['in_stock_barcodes', 'total_uploaded_barcodes']
	};

	const { resultSet, isLoading } = useInsights(query);

	const stockChartData = useMemo(() => {
		// don't add extra data if time range is up until yesterday - only relevant in the future
		const uploadsToday = isYesterday(new Date(to)) ? formattedTodaysInventorySourceData(stockInventorySources, shops) : {};

		return formatChartData({ uploadsToday, dbData: resultSet, from, to });
	}, [resultSet, stockInventorySources, from, to, shops]);

	return (
		<Stats.Card sectioned className={styles.inventoryConnection}>
			<Stats.Section className={styles.section}>
				<Stats.Header>
					<Stats.Title>Inventory connection</Stats.Title>
				</Stats.Header>
				{isLoading && <InventorySource.Loader />}
				{stockChartData && (
					<InventorySource chartData={stockChartData} chainLength={shopIds.length} inventorySources={stockInventorySources} />
				)}
			</Stats.Section>
			{!!productInventorySources.length && (
				<Stats.Section className={styles.section}>
					{/* TODO: add data for second graph here when we have historic source info */}
					<InventorySource chainLength={shopIds.length} inventorySources={productInventorySources} />
				</Stats.Section>
			)}
			<Stats.Section className={UItils.classnames(styles.section, styles.bottom)}>
				<ConnectionStatus
					stockInventorySources={stockInventorySources}
					productInventorySources={productInventorySources}
					query={query}
					shops={shops}
				/>
				{!isChain ? (
					<div className={styles.cta}>
						<Stats.ViewMore to={`/inventory/${shops[0].id}`}>Manage inventory</Stats.ViewMore>
					</div>
				) : null}
			</Stats.Section>
		</Stats.Card>
	);
};

export default InventoryConnection;
