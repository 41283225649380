import React from 'react';
import { Card, Cluster, Icon, Loader } from '@nearst/ui';
import { differenceInCalendarDays, parseISO } from 'date-fns';

import UpdatePaymentLink from './UpdatePaymentLink';
import CustomerAddress from './CustomerAddress';

import styles from './CustomerDetails.module.scss';

export const CustomerPaymentDetails = ({ customer }) => {
	return (
		<Cluster>
			<div className={styles.paymentCardIllustration}>
				<Icon>{customer.paymentMethod.icon}</Icon>
			</div>
			<div>
				<strong>{customer.paymentMethod.title}</strong>
				<br />
				{customer.paymentMethod.description}
			</div>
		</Cluster>
	);
};

const CustomerDetails = ({ customer, mutate, shopId, shopPlan }) => {
	if (!customer) {
		return (
			<Card.Section className={styles.placeholder}>
				<Loader />
			</Card.Section>
		);
	}

	const { trialEnd: stripeTrialEnd } = customer.subscription || {};

	// Extra trial buffer of five days has been added in Stripe
	// This makes it look like the correct number by subtracting those days again
	const extraTrialBuffer = 5;
	const trialEndInDays = differenceInCalendarDays(parseISO(stripeTrialEnd), new Date()) - extraTrialBuffer;

	const isTrialPlan = !!shopPlan?.includes('-trial');

	return customer.paymentMethod ? (
		<>
			<Card.Section className={styles.section}>
				<div className={styles.switcherBetween}>
					<CustomerPaymentDetails customer={customer} />
					<UpdatePaymentLink shopId={shopId}>Update card</UpdatePaymentLink>
				</div>
			</Card.Section>
			<CustomerAddress shopId={shopId} customer={customer} mutate={mutate} />
		</>
	) : isTrialPlan ? (
		stripeTrialEnd ? (
			<Card.Section className={styles.trial}>
				<h3>{trialEndInDays <= 0 ? 'Your trial has expired' : `Your trial will automatically expire in ${trialEndInDays} days`}</h3>
				<p>
					Your account will be disabled at the end of the trial and we will stop processing your inventory uploads. Please add
					your payment details to continue your NearSt subscription.
				</p>
				<div className={styles.cta}>
					<UpdatePaymentLink shopId={shopId} primary>
						Add payment method
					</UpdatePaymentLink>
				</div>
			</Card.Section>
		) : (
			<Card.Section className={`${styles.trialNotStarted} ${styles.trial}`}>
				<h3>Your trial has not started</h3>
				<p>
					We are still in the process of setting up your account. <br />
					Our Customer Success team will notify you when your inventory is live!
				</p>

				<div className={styles.cta}>
					<UpdatePaymentLink shopId={shopId} primary>
						Add payment method
					</UpdatePaymentLink>
				</div>
			</Card.Section>
		)
	) : (
		<Card.Section className={styles.placeholder}>
			<p>No payment details added yet.</p>
			<div className={styles.cta}>
				<UpdatePaymentLink shopId={shopId} primary>
					Add payment method
				</UpdatePaymentLink>
			</div>
		</Card.Section>
	);
};

export default CustomerDetails;
