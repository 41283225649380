import React from 'react';
import useSWRMutation from 'swr/mutation';

import { Card, Cluster, InputGroup, TextInput } from '@nearst/ui';
import ClassificationsSelect from './fields/ClassificationsSelect';
import PriceMarkupInput from './fields/PriceMarkupInput';
import DeliveryCategoriesSelect from './fields/DeliveryCategoriesSelect';
import { useChannel, useChannelDefinitions } from '@services/channels';
import { updateProductAttribute } from '@services/stock';
import { useCheckPermission } from '@services/auth/hooks';

import styles from './DeliveryChannelsCard.module.scss';

const useDeliveryChannels = (shopId) => {
	const { data: channelDefinitions } = useChannelDefinitions();
	const { data: uberEats } = useChannel('uber-eats', shopId);
	const { data: deliveroo } = useChannel('deliveroo', shopId);
	const { data: justEat } = useChannel('just-eat', shopId);

	const deliveryChannels = [];
	if (uberEats?.status === 'enabled') {
		deliveryChannels.push({
			...channelDefinitions['uber-eats'],
			config: uberEats.config
		});
	}
	if (deliveroo?.status === 'enabled') {
		deliveryChannels.push({
			...channelDefinitions['deliveroo'],
			config: deliveroo.config
		});
	}
	if (justEat?.status === 'enabled') {
		deliveryChannels.push({
			...channelDefinitions['just-eat'],
			config: justEat.config
		});
	}

	return deliveryChannels;
};

const DeliveryChannelsCard = ({ stockItem, shopId }) => {
	const authorized = useCheckPermission('org:inventory:edit');
	const { trigger, isMutating } = useSWRMutation(`stock_data-${shopId}-${stockItem.barcode}`, async (_, { arg: { field, value } }) =>
		updateProductAttribute(stockItem.barcode, shopId, field, value)
	);

	const enabledDeliveryChannels = useDeliveryChannels(shopId);

	// Don't display form if no delivery channels are enabled for this shop
	if (!enabledDeliveryChannels?.length) return null;

	const updateField = async (field, target, numeric = false) => {
		let { value } = target;

		if (numeric) {
			if (value === '') return;
			value = Number(target.value);
			if (isNaN(value)) return;
			if (value < 0) {
				value = 0;
				target.value = value; // reset input to 0 as well
			}
		}

		if (value === stockItem[field]) return;

		return trigger({ field, value }, { optimisticData: () => ({ ...stockItem, [field]: target.value }), rollbackOnError: true });
	};

	const description = `Edit properties synced to ${enabledDeliveryChannels.map((channel) => channel.title).join(' and ')}.`;

	return (
		<Card data-cy="delivery-attributes" title="Delivery channels" description={description}>
			<Cluster space="2rem">
				{enabledDeliveryChannels.map((channel) => (
					<InputGroup
						key={channel.id}
						label={`${channel.title} price markup`}
						className={styles.markupField}
						htmlFor={`deliveryMarkup.${channel.id}`}
					>
						<PriceMarkupInput
							id={`deliveryMarkup.${channel.id}`}
							price={stockItem.price}
							disabled={!authorized || isMutating}
							currency={stockItem.currency}
							defaultValue={stockItem.deliveryMarkup?.[channel.id] || channel.config.defaultDeliveryMarkup || ''}
							onChange={(value) =>
								updateField('deliveryMarkup', { value: { ...(stockItem.deliveryMarkup || {}), [channel.id]: value } })
							}
						/>
					</InputGroup>
				))}
			</Cluster>
			<InputGroup label="Tax rate" htmlFor="name" className={styles.markupField}>
				<TextInput
					id="name"
					suffix="%"
					type="number"
					min="0"
					max="100"
					disabled={!authorized || isMutating}
					defaultValue={stockItem.taxPercentage || enabledDeliveryChannels[0].config.defaultTaxPercentage || ''}
					onBlur={({ target }) => updateField('taxPercentage', target, true)}
				/>
			</InputGroup>
			<InputGroup label="Categories" htmlFor="name">
				<DeliveryCategoriesSelect
					defaultValue={stockItem.deliveryCategories}
					onChange={(value) => updateField('deliveryCategories', { value })}
					disabled={!authorized}
				/>
			</InputGroup>
			<InputGroup label="Classifications" htmlFor="name">
				<ClassificationsSelect
					defaultValue={stockItem.classifications}
					onChange={(value) => updateField('classifications', { value })}
					disabled={!authorized}
				/>
			</InputGroup>
		</Card>
	);
};

export default DeliveryChannelsCard;
