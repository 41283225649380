import mixpanel from 'mixpanel-browser';
import { showIntercomWithMessage } from '@services/Intercom/utils';

const newLocationContact = () => {
	if (!showIntercomWithMessage("I'd like to sign up a new location")) {
		window.location.href = 'mailto:support@near.st?subject=New Location';
	}

	mixpanel.track('New location contact click');
};

export default newLocationContact;
