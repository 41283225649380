import React, { useState } from 'react';
import copy from 'copy-to-clipboard';
import ContentCopy from '@mui/icons-material/ContentCopy';
import * as Tooltip from '@radix-ui/react-tooltip';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import xml from 'react-syntax-highlighter/dist/cjs/languages/hljs/xml';
import { xcode } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { Page, Button, Card, InfoLink } from '@nearst/ui';

import ConnectionStatus from '../../components/ConnectionStatus';
import ChannelHeader from '../../components/ChannelHeader';

import styles from './ProductLocator.module.scss';
import { enableChannel, disableChannel, useChannel } from '@services/channels';
import { useCheckPermission } from '@services/auth/hooks';
import AuthorizationTooltip from '@components/AuthorizationTooltip';

SyntaxHighlighter.registerLanguage('xml', xml);

const CHANNEL_ID = 'product-locator';

const ProductLocator = () => {
	const { data: channel, mutate: refetchLocator } = useChannel(CHANNEL_ID);
	const [loading, setLoading] = useState(false);

	const [open, setOpen] = useState(false);
	const [copied, setCopied] = useState(false);

	const handleOpenChange = (open) => {
		setOpen(open);
		if (copied && !open) {
			setCopied(false);
		}
	};
	const copyToClipboard = (e) => {
		copy(channel.id);
		setOpen(true);
		setCopied(true);
	};

	const enable = async () => {
		setLoading(true);
		await enableChannel(CHANNEL_ID);
		await refetchLocator();
		setLoading(false);
	};

	const disable = async () => {
		setLoading(true);
		await disableChannel(CHANNEL_ID, undefined, { clearConfig: false });
		await refetchLocator();
		setLoading(false);
	};
	const authorized = useCheckPermission('org:channels:manage');

	const steps = {
		script: `<script src="https://product-locator.near.st/embed.js" async defer></script>`,
		html: `<div
    class="nearst-locator-button"
    data-license-key="${channel?.id}"
    data-barcode="{barcode}"
></div>`
	};

	return (
		<>
			<ChannelHeader channelId={CHANNEL_ID} status={channel?.status} />
			<Page.Section>
				<Card title="Connection status">
					{(!channel || channel?.status === 'disabled') && (
						<ConnectionStatus
							connection="setup"
							status="Enable your API key"
							summary="All you need to do to get started is to enable an API key and embed the widget on your website in two steps."
							outcome="This will give you access to the product search functionality used by the widget across all your locations."
							footer={
								<div>
									<AuthorizationTooltip authorized={authorized} tooltipContent="You don't have access to enable channels">
										<Button onClick={authorized ? enable : undefined} primary disabled={loading || !authorized}>
											<span>{loading ? 'Loading' : 'Generate API key'}</span>
										</Button>
									</AuthorizationTooltip>
								</div>
							}
						/>
					)}
					{channel?.status === 'enabled' && (
						<ConnectionStatus
							connection="done"
							status="You're good to go!"
							summary="Your API key is active and can be used to search for products across all of your locations."
							footer={
								<div>
									<AuthorizationTooltip authorized={authorized} tooltipContent="You don't have access to enable channels">
										<Button onClick={authorized ? disable : undefined} disabled={loading}>
											<span>{loading ? 'Loading' : 'Disable API key'}</span>
										</Button>
									</AuthorizationTooltip>
								</div>
							}
						>
							<div className={styles.apiKey}>
								<span>
									Your API key is <span className={styles.code}>{channel?.id}</span>
								</span>
								<Tooltip.Provider>
									<Tooltip.Root onOpenChange={handleOpenChange} open={open} delayDuration={100}>
										<Tooltip.Trigger asChild onClick={copyToClipboard} className={styles.copyButton}>
											<ContentCopy />
										</Tooltip.Trigger>
										<Tooltip.Content side="top" className={styles.copy}>
											{copied ? 'Copied!' : 'Copy'}
										</Tooltip.Content>
									</Tooltip.Root>
								</Tooltip.Provider>
							</div>
							<p>Please follow the steps below to install the Product Locator widget on your website.</p>
						</ConnectionStatus>
					)}
				</Card>

				<Card title="Embedding the widget">
					<p>
						<b>Step 1: Add NearSt script tag to your website</b>
					</p>
					<SyntaxHighlighter style={xcode} language="xml">
						{steps.script}
					</SyntaxHighlighter>
					<p>
						<b>Step 2: Add button to your product page</b>
					</p>
					<SyntaxHighlighter style={xcode} language="xml">
						{steps.html}
					</SyntaxHighlighter>
					<p>
						<b>If you need more help to configure or install the widget, please read the integration documentation:</b>
					</p>
					<p>
						<InfoLink target="_blank" href="https://developers.near.st/product-locator">
							Developer documentation
						</InfoLink>
					</p>

					<p>
						<InfoLink target="_blank" href="https://developers.near.st/product-locator/widget/customisation">
							Customisation options
						</InfoLink>
					</p>
					<p>
						<InfoLink target="_blank" href=" https://developers.near.st/product-locator/widget/embed#debugging-issues">
							Troubleshooting issues
						</InfoLink>
					</p>
				</Card>
			</Page.Section>
		</>
	);
};

export default ProductLocator;
