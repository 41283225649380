import { useEffect, useState } from 'react';

export const facebookApi = (...fields) =>
	new Promise((resolve, reject) =>
		window.FB.api(...fields, (response) =>
			!response || response.error ? reject(new Error(response.error?.message || 'Unknown error')) : resolve(response)
		)
	);

export const loadFacebookSdk = (callback) => {
	if (document.getElementById('facebook-jssdk')) {
		// already loaded
		callback?.();
		return;
	}

	window.fbAsyncInit = function () {
		window.FB.init({
			appId: import.meta.env.VITE_NEARST_APP_ID,
			autoLogAppEvents: true,
			xfbml: true,
			version: 'v18.0'
		});
		callback?.();
	};

	const js = document.createElement('script');
	const fjs = document.getElementsByTagName('script')[0];
	js.src = 'https://connect.facebook.net/en_US/sdk.js';
	js.id = 'facebook-jssdk';
	fjs.parentNode.insertBefore(js, fjs);
};

export const useFacebookSdk = () => {
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		loadFacebookSdk(() => setLoading(false));
	}, []);

	if (loading) {
		return null;
	}

	return facebookApi;
};

export const findPages = async (businessId) => {
	const { data: ownedPages } = await facebookApi(`/${businessId}/owned_pages`, 'GET');

	if (ownedPages && ownedPages.length) {
		return ownedPages.map((page) => page.id);
	}

	return [];
};

export const openFBLogin = (setError, setLoading, setBusinesses) => {
	const redirectUri = window.location.href;
	if (!window.FB) {
		setError("Sorry, we couldn't connect with Facebook. Please check your browser privacy settings.");
		return;
	}
	window.FB.login(
		(response) => {
			if (response.authResponse) {
				loadBusinesses(setLoading, setBusinesses);
			} else {
				console.error(response);
				setError('Could not authenticate with Facebook. Please try again.');
			}
		},
		{
			scope: 'catalog_management,business_management',
			redirect_uri: redirectUri,
			display: 'popup'
		}
	);
};

export const loadBusinesses = (setLoading, setBusinesses) => {
	setLoading(true);
	window.FB.api('/me/businesses', 'GET', { fields: 'id,name,profile_picture_uri', limit: 1000 }, (response) => {
		setBusinesses(response.data);
		setLoading(false);
	});
};
export const findOrCreateCatalog = async (businessId, shop) => {
	const catalogName = `Products from NearSt - ${shop.name}`;

	// First get all of our existing catalogs to see if one already exists for this store
	const { data: existingCatalogs } = await facebookApi(`/${businessId}/owned_product_catalogs`, 'GET');
	const existingCatalog = existingCatalogs.find(({ name }) => name === catalogName);
	if (existingCatalog) {
		return existingCatalog.id;
	}

	// Otherwise we create one
	const newCatalog = await facebookApi(`/${businessId}/owned_product_catalogs`, 'POST', {
		name: catalogName,
		vertical: 'commerce'
	});

	return newCatalog.id;
};
