import React from 'react';
import { useFormContext } from 'react-hook-form';
import { v4 } from 'uuid';
import ErrorMessage from '../ErrorMessage';
import schema from '../../../schema';

import styles from '../Fields.module.scss';

const Dropdown = ({ name, required = false, list, children, ...rest }) => {
	const {
		register,
		getValues,
		formState: { errors }
	} = useFormContext();
	let defaultValue;
	if (rest.defaultfieldname) {
		defaultValue = getValues(rest.defaultfieldname);
	}

	const listId = v4();

	return (
		<label>
			<p>
				{children} {required ? <span className={styles.required}>*</span> : ''}
			</p>
			<input
				type="text"
				className={`${styles.input} ${errors?.contactName ? styles.invalid : ''}`}
				{...register(name, { required: required ? 'Please fill in this field.' : '', validate: schema[name] })}
				defaultValue={defaultValue}
				list={listId}
				{...rest}
			/>
			<datalist id={listId}>
				{list.map(({ value, label }) => (
					<option key={value} value={value}>
						{label}
					</option>
				))}
			</datalist>
			<ErrorMessage name={name} error={rest.error} />
		</label>
	);
};

export default Dropdown;
