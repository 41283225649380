import { startOfDay, startOfMonth, startOfWeek } from 'date-fns';

export const calculatePercentageChange = (current, previous) => {
	const percentageChange = Math.round(((parseInt(current) - parseInt(previous)) / (parseInt(current) + parseInt(previous) / 2)) * 100);

	if (percentageChange < 0) {
		const regex = /([-])/g;

		return {
			change: percentageChange.toString().replace(regex, ''),
			direction: 'down'
		};
	}
	if (percentageChange === 0 || Number.isNaN(percentageChange)) {
		return {
			change: '0',
			direction: 'none'
		};
	}
	return {
		change: percentageChange.toString(),
		direction: 'up'
	};
};

export const parseNumber = (number) => {
	if (isNaN(+number)) return 0;
	return +number;
};

export const sumField = (fieldName, response) => {
	if (!response) return 0;
	return response.reduce((runningTotal, current) => runningTotal + parseNumber(current[fieldName]), 0);
};

const getDateForInterval = (date, interval) => {
	date = new Date(date);
	if (interval === 'day') {
		date = startOfDay(date);
	}
	if (interval === 'week') {
		date = startOfWeek(date, { weekStartsOn: 1 });
	}
	if (interval === 'month') {
		date = startOfMonth(date);
	}
	return date;
};

export const sumByDate = (arr, interval = 'day') => {
	return arr.reduce((acc, curr) => {
		const date = getDateForInterval(curr.date, interval).toISOString();
		const index = acc.findIndex((item) => item.date === date);
		if (index === -1) {
			acc.push({ ...curr, date });
		} else {
			Object.keys(curr).forEach((key) => {
				if (key !== 'date') {
					acc[index][key] += curr[key];
				}
			});
		}
		return acc;
	}, []);
};

// In cases where local listings have fewer than 20 views, we display '<20' instead of '0'.
// This change addresses a potential confusion where a product might show "0" views but still have clicks.
export const updateImpressions = (listing) => {
	listing.displayImpressions = listing.impressions.toLocaleString();
	if (listing.clicks > 0 && listing.impressions === 0) {
		listing.displayImpressions = '<20';
	}
	return listing;
};
