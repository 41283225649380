import React, { useEffect } from 'react';
import useSWR from 'swr';
import { useLocation } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

import { listPlans, createPlan } from '@services/localAds';
import { listPaymentMethods, getStripeCheckoutSession } from '@services/billing';
import stripePromise from '../../../utils/getStripePromise';
import getQueryParam from '../../../utils/getQueryParam';
import { useShop } from '@services/ShopProvider';

export const useCheckRedirectedFromStripe = () => {
	const { shop } = useShop();
	const { mutate: revalidateSubscriptions } = useSWR(`${shop.id}-upcoming-plan`, () => listPlans({ id: shop.id, status: 'upcoming' }));
	const location = useLocation();
	const budget = getQueryParam(location, 'budget');
	const origin = getQueryParam(location, 'origin');
	useEffect(() => {
		// when customer doesn't have a payment method saved we'll navigate them to Stripe's checkout form
		// after they provided their credit card information successfully we redirect them to the /subscription page
		// and we'll put the `active_plan` query param in the URL
		// which indicates that we need to update the customer's subscription
		if (shop.stripeCustomerId && Number.isInteger(parseInt(budget))) {
			const updatePlan = async () => {
				const paymentMethods = await listPaymentMethods(shop.id);
				if (paymentMethods && paymentMethods.length === 0) {
					alert(`We can't find your payment details. Please contact us`);
					return;
				}

				triggerLiaAnalyticsEvent(shop, parseInt(budget), origin, true);

				await createPlan({ id: shop.id, budget: parseInt(budget) });
				await revalidateSubscriptions();
			};
			updatePlan();
		}
	}, [location, revalidateSubscriptions, shop.id, shop.stripeCustomerId, shop.name, budget]);
};

export const updateLiaPlan = async ({ budget, shop, revalidate = undefined, origin = undefined }) => {
	if (budget !== 0) {
		const paymentMethods = await listPaymentMethods(shop.id);

		// If customer doesn't have a payment method saved in Stripe, navigate to the checkout form
		if (paymentMethods && paymentMethods.length === 0) {
			const { id } = await getStripeCheckoutSession(shop.id, { origin, budget, plan: import.meta.env.VITE_LIA_INSTANT_ADS_PRICE_ID });
			const stripe = await stripePromise;

			// If `redirectToCheckout` succeeds the rest of function below won't be running
			// because we redirect the customer to a success url
			const { error } = await stripe.redirectToCheckout({ sessionId: id });

			// If `redirectToCheckout` fails due to a browser or network
			// error, display the error message to the customer
			if (error) alert(`An error occurred during the checkout. More info: ${error.message}`);

			return;
		}
	}

	triggerLiaAnalyticsEvent(shop, budget, origin);

	await createPlan({ id: shop.id, budget: budget });
	revalidate && (await revalidate());
};

const triggerLiaAnalyticsEvent = (shop, newBudget, origin, afterStripeRedirect = false) => {
	mixpanel.track('Update LIA budget', {
		'Shop ID': shop.legacyId,
		'Shop name': shop.name,
		Origin: origin,
		'Old budget': shop.currentPlan?.amount || 0,
		'New budget': newBudget,
		'Stripe redirect': afterStripeRedirect
	});
};
