/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import mixpanel from 'mixpanel-browser';
export default class ErrorBoundary extends React.Component {
	static propTypes = {
		children: PropTypes.node
	};

	constructor(props) {
		super(props);
		this.state = {
			error: null,
			eventId: null
		};
	}

	componentDidCatch(error, errorInfo) {
		this.setState({ error });
		mixpanel.track('Error shown', { distinct_id: mixpanel.get_distinct_id(), 'Error message': error.message, ...errorInfo });
		Sentry.withScope((scope) => {
			scope.setExtra('errorInfo', errorInfo);
			const eventId = Sentry.captureException(error);
			this.setState({ eventId });
		});
	}

	render() {
		if (this.state.error) {
			return (
				<div className="error-overlay">
					<h1>Sorry!</h1>
					<p>An unexpected error occurred. Our team has been notified.</p>
					<div className="links">
						<a href="/">Go back</a>
						<a
							onClick={() =>
								Sentry.showReportDialog({
									eventId: this.state.eventId
								})
							}
						>
							Report feedback
						</a>
					</div>
				</div>
			);
		}

		return <>{this.props.children}</>;
	}
}
