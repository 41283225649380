import React from 'react';
import { format, addDays } from 'date-fns';
import * as Stats from '@components/StatsCard';
import { useInsightsDate } from '@services/InsightsDateProvider';
import InsightsWrapper from '@components/Graphs/DataWrapper';
import { sumBy } from 'lodash';
import { useShop } from '@services/ShopProvider';
import InsightCardContainer from '../../components/InsightCardContainer';

import styles from './GMBstats.module.scss';

const GMBPerformanceCard = () => {
	const { shop } = useShop();
	const { startDate, endDate, selectedRange } = useInsightsDate();

	const query = {
		table: 'gmb',
		from: format(startDate, 'yyyy-MM-dd'),
		to: format(endDate, 'yyyy-MM-dd'),
		id: shop.id,
		columns: [
			'website_clicks',
			'phone_clicks',
			'directions_clicks',
			'map_views_desktop',
			'map_views_mobile',
			'search_views_desktop',
			'search_views_mobile',
			'date'
		],
		sort: [{ column: 'date', order: 'asc' }],
		sum: [
			'map_views_desktop',
			'map_views_mobile',
			'search_views_desktop',
			'search_views_mobile',
			'website_clicks',
			'phone_clicks',
			'directions_clicks'
		]
	};

	return (
		<InsightCardContainer>
			<Stats.Title>Google Business Profile</Stats.Title>
			<Stats.Subtitle>
				{selectedRange}
				<Stats.Tooltip>Data is available until {format(addDays(new Date(), -4), 'MMM d')}</Stats.Tooltip>
			</Stats.Subtitle>

			<InsightsWrapper query={query} Placeholder={null} hideError hideLoader>
				{(data) => {
					const directionsClicks = sumBy(data, 'directions_clicks');
					const websiteClicks = sumBy(data, 'website_clicks');
					const phoneClicks = sumBy(data, 'phone_clicks');
					const mapsViewsDesktop = sumBy(data, 'map_views_desktop');
					const mapsViewsMobile = sumBy(data, 'map_views_mobile');
					const mapsViews = mapsViewsDesktop + mapsViewsMobile;
					const searchViewsDesktop = sumBy(data, 'search_views_desktop');
					const searchViewsMobile = sumBy(data, 'search_views_mobile');
					const searchViews = searchViewsDesktop + searchViewsMobile;

					return (
						<>
							<div className={styles.gmbStats}>
								<Stats.Figure>
									{mapsViews.toLocaleString()}
									<Stats.FigureDescription>Map views</Stats.FigureDescription>
								</Stats.Figure>
								<Stats.Figure>
									{searchViews.toLocaleString()}
									<Stats.FigureDescription>Search views</Stats.FigureDescription>
								</Stats.Figure>
								<Stats.Figure>
									{directionsClicks.toLocaleString()}
									<Stats.FigureDescription>Direction clicks</Stats.FigureDescription>
								</Stats.Figure>
								<Stats.Figure>
									{websiteClicks.toLocaleString()}
									<Stats.FigureDescription>Website clicks</Stats.FigureDescription>
								</Stats.Figure>
								<Stats.Figure>
									{phoneClicks.toLocaleString()}
									<Stats.FigureDescription>Phone clicks</Stats.FigureDescription>
								</Stats.Figure>
							</div>
						</>
					);
				}}
			</InsightsWrapper>
			<div className={styles.viewMoreWrapper}>
				<Stats.ViewMore to={`/insights/${shop.id}/business-profile`} />
			</div>
		</InsightCardContainer>
	);
};

export default GMBPerformanceCard;
