export const numberFormat = (value) => {
	if (Number.isNaN(value)) return value;

	if (value < 1000) {
		return value;
	}

	if (value < 1000000) {
		return `${Math.ceil(value / 1000)}k`;
	}

	return `${Math.ceil(value / 1000000)}m`;
};
