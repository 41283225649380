import { Button, Card, Icon, TileIcon } from '@nearst/ui';
import React from 'react';
import styles from './UpgradeRequiredBanner.module.scss';
import { useUpgradeModal } from '@services/UpgradeProvider';

/**
 * @param {Object} props
 * @param {function} [props.upgradeAction]
 * @param {(JSX.Element) | string} [props.children]
 * @param {string} [props.title]
 * @param {string} [props.customBtnText]
 */
const defaultHeader = 'This feature is not available on your current plan.';

const UpgradeRequiredBanner = ({ children, upgradeAction, title = defaultHeader, customBtnText = undefined }) => {
	const { openUpgradeModal } = useUpgradeModal();
	return (
		<Card className={styles.container} sectioned>
			<div className={styles.content}>
				<TileIcon className={styles.icon} colour="orange">
					warning
				</TileIcon>

				<h3>{title}</h3>
				{children}
			</div>
			<Button className={styles.button} onClick={upgradeAction || openUpgradeModal}>
				{!customBtnText ? (
					<>
						Upgrade to unlock <Icon>lock</Icon>
					</>
				) : (
					customBtnText
				)}
			</Button>
		</Card>
	);
};

export default UpgradeRequiredBanner;
