const Categories = require('../model/Categories');
const ChannelDefinition = require('../model/ChannelDefinition');
const Requirements = require('../model/Requirements');

class ProductReservationsChannel extends ChannelDefinition {
	id = 'product-reservations';
	title = 'Product Reservations';
	description = 'Allow local customers to reserve products online and pick-up in store.';
	category = Categories.LOCAL_COMMERCE;

	iconUrl = 'https://go.near.st/hubfs/channels/nearst.png';
	coverImageUrl = 'https://go.near.st/hubfs/channels/headers/product-reservations.png';

	longDescription = `
## Overview
Add another touchpoint for local shoppers by offering the option to reserve online and pick-up in store when they are looking for nearby items. NearSt creates product pages for all items, links them to channel listings, manages customer payments and order updates. 

## How it works
Customers will land on your NearSt hosted product pages from different channels, where they’ll be offered the option to reserve a product.

You will receive automatic notifications via email as soon as a reservation request comes through. Order management is available in the dashboard, where you can mark orders as cancelled or completed and access customer notes.

## Requirements
- Have either Google Local Listings, Google Local Inventory Ads, or Meta Commerce enabled
- Have a product data source connected to show images and descriptions on your product pages
	`;

	manageUrl = '/channels/{shopId}/settings/product-reservations';

	productSpecificVisibility = true;
	worksWith = ['google', 'local-ads', 'meta-commerce'];

	helpArticleUrls = [
		{
			title: 'About Product Reservations',
			url: 'https://support.near.st/en/articles/7950025-product-reservation'
		}
	];

	requirements = new Requirements({
		plans: ['lite', 'standard', 'advanced']
	});
}

module.exports = ProductReservationsChannel;
