import React from 'react';

import InsightsComparison from '../InsightsComparison';

import styles from '../LocationManagement.module.scss';

const DesktopLocationInsights = ({ currentTotal, previousTotal }) => {
	return (
		<>
			<div className={styles.insightValue}>{currentTotal.toLocaleString()}</div>
			<InsightsComparison currentValue={currentTotal} previousValue={previousTotal} />
		</>
	);
};

export default DesktopLocationInsights;
