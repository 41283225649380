const Categories = require('../model/Categories');
const ChannelDefinition = require('../model/ChannelDefinition');
const Requirements = require('../model/Requirements');

class ShopPageChannel extends ChannelDefinition {
	id = 'shop-page';
	title = 'Shop Page';
	description = 'Customize your NearSt storefront to provide accurate information to your audience.';
	category = Categories.OMNICHANNEL_EXPERIENCE;

	iconUrl = 'https://go.near.st/hubfs/channels/nearst.png';
	coverImageUrl = 'https://go.near.st/hubfs/channels/headers/shop-page.png';

	longDescription = `
## Overview
The Shop Page is a NearSt-hosted storefront for your locations. The location profile is linked to different channels to give customers an easy way to get up to date information about each store.

## How it works
This feature is automatically provided for all NearSt retailers. You can customise your bio, logo and links from the dashboard to display accurate information for your shoppers. When you enable additional channels (e.g. Product Reservations), product pages will be linked to the shop page.
	`;

	manageUrl = '/channels/{shopId}/settings/shop-page';
	productSpecificVisibility = false;

	worksWith = ['google'];

	helpArticleUrls = [
		{
			title: 'Editing shop details',
			url: 'https://support.near.st/en/articles/6675170-how-can-i-edit-my-shop-page-in-myshop'
		}
	];

	requirements = new Requirements({});
}

module.exports = ShopPageChannel;
