import * as s from 'superstruct';

const schema = {
	title: (value) => s.is(value, s.string()),
	brand: (value) => s.is(value, s.string()),
	description: (value) => s.is(value, s.string()),
	imageSrc: (value) => s.is(value, s.string())
};

export default schema;
