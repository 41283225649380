import { getDailySwisInsights, getMultipleInsights } from '@services/insights';
import { groupByShop } from '../components/ComparisonCard/lib/dataVisualization';

export const getLocalListingsData = async ({ shopIds, to, from, fromStartOfWeekStr, toEndOfWeekStr, dimension }) => {
	const queries = [
		{
			id: shopIds,
			table: 'local_listings',
			columns: ['impressions', 'clicks', 'shop_id', 'date'],
			to,
			from
		},
		{
			id: shopIds,
			table: 'local_listings_weekly',
			columns: ['impressions', 'start_date', 'end_date', 'shop_id'],
			filter: [
				['start_date', '>=', fromStartOfWeekStr],
				['end_date', '<=', toEndOfWeekStr]
			],
			sort: [{ column: 'start_date', order: 'desc' }]
		}
	];
	const [swisDaily, swisWeekly] = await getMultipleInsights(queries);
	const impressions = groupByShop(getDailySwisInsights(new Date(from), new Date(to), swisDaily, swisWeekly, shopIds), 'impressions');
	const clicks = groupByShop(swisDaily, 'clicks');

	if (dimension === 'impressions') return impressions;
	if (dimension === 'clicks') return clicks;
	return impressions.map(({ shopId, value }) => ({
		shopId,
		value: ((clicks.find((v) => v.shopId === shopId)?.value || 0) / value) * 100
	}));
};
