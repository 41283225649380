import { Icon } from '@nearst/ui';
import React from 'react';
import RestrictedPage from '@components/RestrictedPage/RestrictedPage';

export default function OnboardingInProgress() {
	return (
		<RestrictedPage>
			<Icon>construction</Icon>
			<h1>Onboarding</h1>
			<p>This shop is currently being set up by your admin. Please contact your admin if you think you should have access to this.</p>
		</RestrictedPage>
	);
}
