import React from 'react';
import { Button, Cluster } from '@nearst/ui';

import Fieldset from '../../../../../../components/Setup/Fieldset';
import HelpButton from '../HelpButton';

const BusinessSelector = ({ businesses, setSelectedBusiness, selectBusiness, selectedBusiness, setBusinesses }) => {
	if (!businesses || !businesses.length)
		return (
			<>
				<p>There is no business account currently linked to the email address you used.</p>
				<p>Please check you are logging in with the correct credentials.</p>
				<Cluster>
					<Button onClick={() => setBusinesses(null)}>Try again</Button>
				</Cluster>
			</>
		);
	const fbBusinesses = businesses.sort((a, b) => a.name.localeCompare(b.name));

	return (
		<>
			<p>Please select the account you would like to connect your inventory to:</p>
			<Fieldset onChange={(e) => setSelectedBusiness(e.target.value)}>
				{fbBusinesses.map((business) => (
					<Fieldset.Radio key={business.id} value={business.id} name="accounts">
						{business.name}
					</Fieldset.Radio>
				))}
			</Fieldset>
			<Cluster>
				<Button primary onClick={() => selectBusiness(selectedBusiness)}>
					Confirm
				</Button>

				<Button link onClick={() => setBusinesses(null)}>
					Try again
				</Button>
			</Cluster>

			<HelpButton href="https://support.near.st/en/articles/8061563-why-can-t-i-see-the-correct-account">
				Why can&apos;t I see the correct account?
			</HelpButton>
		</>
	);
};

export default BusinessSelector;
