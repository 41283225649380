import React from 'react';

import { DropdownMenu } from '@nearst/ui';

import styles from '../LocationManagement.module.scss';

const LocationChannelsSummary = ({ shop, mobile = false }) => {
	if (!shop.enabledChannels) return null;

	if (mobile) {
		return shop.enabledChannels.length ? <p>{shop.enabledChannels.length} channels enabled</p> : null;
	}

	if (!shop.enabledChannels.length) {
		return 'None enabled';
	}

	return (
		<DropdownMenu
			buttonText={`${shop.enabledChannels.length} enabled`}
			className={styles.channelsDropdown}
			innerClassName={styles.channelsDropdownInner}
		>
			{shop.enabledChannels.map((id) => {
				const channelInfo = shop.availableChannels?.[id];
				return (
					<li key={id}>
						<div className={styles.channelsDropdownIcon} style={{ backgroundImage: `url(${channelInfo?.iconUrl})` }} />
						{channelInfo?.label || id}
					</li>
				);
			})}
		</DropdownMenu>
	);
};

export default LocationChannelsSummary;
