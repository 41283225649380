import React from 'react';
import { Card } from '@nearst/ui';

import SubstepIcon from '../SubstepIcon';

import styles from './Substep.module.scss';

const SubStep = ({ progress = 'to do', title = '', children }) => {
	return (
		<Card.Section className={styles.subStepContainer}>
			<SubstepIcon progress={progress} />
			<div className={styles.subStepBlock}>
				{progress !== 'in progress' ? <div>{title}</div> : <strong>{title}</strong>}
				{children && <div className={styles.subStepChildren}>{children}</div>}
			</div>
		</Card.Section>
	);
};

export default SubStep;
