import React, { useState } from 'react';
import { Button, Cluster } from '@nearst/ui';
import SubStep from '../../Substep';
import LoadingButton from '../../LoadingButton';
import { enableChannel } from '@services/channels';
import { useShop } from '@services/ShopProvider';

import styles from './MetaCommerceStepTwo.module.scss';

const ClickVerifyStep = ({ setClickedVerify, finish }) => {
	const { shop } = useShop();
	const [loading, setLoading] = useState(false);

	const submit = async () => {
		setLoading(true);

		await enableChannel('meta-commerce', shop.id);

		finish?.();
	};

	return (
		<div className={styles.cardInner}>
			<SubStep progress="completed" title="Enter your NearSt website on the domain settings page" />
			<SubStep progress="completed" title="Enter verification code" />
			<SubStep progress="in progress" title={`Click "Verify domain"`}>
				<p>Click the "Verify domain" button displayed at the bottom of the instructions.</p>
				<p>You should now see a confirmation pop-up and the "Verified" tag next to your domain! </p>

				<Cluster className={styles.buttons}>
					<LoadingButton loading={loading} disabled={loading} onClick={submit} primary>
						Continue
					</LoadingButton>
					{!loading && (
						<Button link onClick={() => setClickedVerify(false)}>
							Go back
						</Button>
					)}
				</Cluster>
			</SubStep>
		</div>
	);
};

export default ClickVerifyStep;
