import React from 'react';
import { Icon, UItils } from '@nearst/ui';
import plurify from '@utils/plurify';

import styles from './Ranking.module.scss';

export const Ranking = ({ topproduct, diff, position }) => {
	if (topproduct) {
		return (
			<div className={UItils.classnames(styles.ranking, styles.blueIcon)}>
				<Icon>emoji_events</Icon>
				Top product for {topproduct} {plurify('week', topproduct)}
			</div>
		);
	}

	if (position === 'up') {
		return (
			<div className={UItils.classnames(styles.ranking, styles.greenIcon)}>
				<Icon>arrow_drop_up</Icon>
				Up {diff} from last week
			</div>
		);
	}

	if (position === 'down') {
		return (
			<div className={UItils.classnames(styles.ranking, styles.greyIcon)}>
				<Icon>arrow_drop_down</Icon>
				Down {diff * -1} from last week
			</div>
		);
	}

	return null;
};
