import React from 'react';
import { Icon } from '@nearst/ui';

import styles from './UnsupportedMessage.module.scss';

const UnsupportedMessage = ({ warning, description = [], callToAction = { label: '', href: '' } }) => {
	return (
		<div>
			<p className={styles.banner}>
				<Icon>warning</Icon>
				{warning}
			</p>
			{description.map((desc, i) => (
				<p key={i}>{desc}</p>
			))}
			<a href={callToAction.href}>{callToAction.label}</a>
		</div>
	);
};

export default UnsupportedMessage;
