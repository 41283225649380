import myShopClient from './nearlive';

/**
 * Set up a Stripe Connect merchant account for a shop.
 *
 * @param {string} id
 * @returns {Promise<{link: string}>}
 */
export async function createAccount(id) {
	const client = await myShopClient();
	const { data } = await client.post(`/api/checkout/${id}`);
	return data;
}
