import React from 'react';
import useSWR from 'swr';

import { useShop } from '@services/ShopProvider';
import { Page } from '@nearst/ui';
import LocationManagementTable from '../LocationManagement';
import { getChannels } from '@services/channels';

const Locations = () => {
	const { data: shops } = useShop();
	const { data: channels } = useSWR(`channels-data-organisation`, () => getChannels(shops));

	return (
		<>
			<Page.Header>
				<h1>Locations</h1>
				<p>View the status and top-level performance for all stores in your organisation.</p>
			</Page.Header>
			<Page.Section>
				<LocationManagementTable shops={shops} channels={channels} />
			</Page.Section>
		</>
	);
};

export default Locations;
