import React from 'react';

import { differenceInDays } from 'date-fns';
import ConnectButton from '../ConnectButton';
import OnboardingStep from '../OnboardingStep';
import { CONTACT_US, ICON_STATUS } from '../constants';
import qs from 'qs';

import styles from './GMBConnect.module.scss';

const ICON_FALLBACK = '2';
const DEFAULT_TITLE = 'Link your Google Business Profile account.';
const GMB_CONNECT_GENERATE_URI = 'https://connect.near.live/api/uri';

const LinkGMB = ({ isCurrentStage = false, shop }) => {
	const currentDate = new Date();
	const ingestTimestamp = shop?.stockInventorySource?.latestIngest?.timestamp
		? new Date(shop?.stockInventorySource?.latestIngest?.timestamp)
		: currentDate;
	// states
	const isPendingConnect = shop?.onboardingStatus?.type === 'shop.google.gmb-requested';
	const isDelayedConnect = differenceInDays(ingestTimestamp, currentDate) > 2;
	const isConnected = shop?.gmbConnection?.status === 'connected' || shop.googleMerchantId;
	// error status in gmb connect
	const shopGmbConnection = Boolean(shop.gmbConnection) || false;
	const isGmbError = shopGmbConnection && !isConnected;

	const buttonText = isGmbError ? 'Try again' : isPendingConnect ? 'Continue' : 'Start now';

	const status =
		isDelayedConnect || isGmbError
			? ICON_STATUS.ALERT
			: isCurrentStage || isPendingConnect
				? ICON_STATUS.CURRENT
				: ICON_STATUS.DISABLED;

	const handleOnStartGMB = () => {
		if (shop.legacyId) {
			const queryString = qs.stringify({
				id: shop.legacyId,
				redirectUrl: `${import.meta.env.VITE_MYSHOP_URL}/onboarding/${shop.id}`
			});
			window.location.href = `${GMB_CONNECT_GENERATE_URI}?${queryString}`;
			return;
		}

		console.error('unable to start gmb connect');
	};

	if (isConnected) {
		return (
			<OnboardingStep
				iconText={ICON_FALLBACK}
				status={ICON_STATUS.COMPLETED}
				title="Your Google Business Profile account is successfully linked."
			>
				<p>
					We have access to manage the following listings: <span className={styles.overviewName}>{shop.name || 'n/a'}</span>.
				</p>
			</OnboardingStep>
		);
	}

	if (!isCurrentStage) {
		return (
			<OnboardingStep iconText={ICON_FALLBACK} status={ICON_STATUS.DISABLED} title={DEFAULT_TITLE}>
				<></>
			</OnboardingStep>
		);
	}

	return (
		<OnboardingStep iconText={ICON_FALLBACK} status={status} title={DEFAULT_TITLE}>
			<p>
				{isGmbError
					? 'An error has occurred whilst trying to get access to your listing'
					: 'Before you connect, please ensure you can log in with the email address linked to your Google Business Profile listing.'}
			</p>
			<ConnectButton text={buttonText} onConnect={handleOnStartGMB} />
			{(isDelayedConnect || isGmbError) && <p>{CONTACT_US}</p>}
		</OnboardingStep>
	);
};

export default LinkGMB;
