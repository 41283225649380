import React from 'react';
import Select, { components } from 'react-select';

import classifications from '@nearst/custom-product-data/lib/classifications.json';
import styles from '../DeliveryChannelsCard.module.scss';

const ClassificationsSelectOption = (props) => {
	const { data } = props;
	return (
		<components.Option {...props}>
			{data.label}
			<div className={styles.classificationDescription}>{data.description}</div>
		</components.Option>
	);
};

const ClassificationsSelect = ({ defaultValue, onChange, disabled }) => {
	const options = classifications.map((classification) => ({
		value: classification.id,
		label: classification.title,
		description: classification.description
	}));

	return (
		<Select
			isMulti
			placeholder="Select classifications..."
			className={styles.select}
			options={options}
			isDisabled={disabled}
			components={{
				ClearIndicator: () => null,
				IndicatorSeparator: () => null,
				Option: ClassificationsSelectOption
			}}
			defaultValue={defaultValue?.map((value) => options.find((option) => option.value === value))}
			onChange={(values) => onChange(values.map((value) => value.value))}
		/>
	);
};

export default ClassificationsSelect;
