import React from 'react';
import { Outlet } from 'react-router-dom';
import { Protect } from '@clerk/clerk-react';

import Locations from './Locations';
import AccessDenied from '../errors/AccessDenied';
import { RedirectWithShopId } from '@utils/RedirectWithShopId';

export const LocationsLayout = () => {
	return (
		<Protect permission="org:locations:manage" fallback={<AccessDenied />}>
			<Outlet />
		</Protect>
	);
};

export const locationsRoutes = [
	{ index: true, element: <Locations /> },
	{
		path: ':shopId',
		element: <RedirectWithShopId to={'/home/:shopId'} />
	}
];
