import React, { Suspense } from 'react';
import * as Chart from 'recharts';
import { differenceInMinutes, format } from 'date-fns';

import { Card } from '@nearst/ui';
import * as Stats from '@components/StatsCard';
import SpinnerCard from '@components/SpinnerCard';
import { TooltipContainer } from '@components/Graphs/Tooltip';
import { useStoredQuery } from '@services/insights';
import plurify from '@utils/plurify';

const RealtimeEventsCardWrapper = () => {
	return (
		<Suspense fallback={<SpinnerCard message="Loading data" />}>
			<RealtimeEventsCard />
		</Suspense>
	);
};

const RealtimeEventsTooltip = ({ contentStyle, label, payload }) => {
	const count = payload[0]?.value || 0;

	return (
		<TooltipContainer contentStyle={contentStyle}>
			<strong>
				{count.toLocaleString()} {plurify('visitor', count)}
			</strong>
			<p>{differenceInMinutes(new Date(), new Date(label))} minutes ago</p>
		</TooltipContainer>
	);
};

const RealtimeEventsCard = () => {
	const { data } = useStoredQuery('product-locator-realtime-events', {}, { suspense: true, refreshInterval: 10_000 });
	const totalVisitors = data.reduce((acc, { visitors }) => acc + visitors, 0);

	// To be able to use type "number" in the XAxis, we need to convert the date to a timestamp
	const withTimestamps = data.map((item) => ({ ...item, timestamp: new Date(item.date).valueOf() }));

	return (
		<Card sectioned>
			<Card.Section>
				<Stats.Header>
					<div>
						<Stats.Title>Real-time events</Stats.Title>
						<Stats.Subtitle>{totalVisitors} active customers in the last hour</Stats.Subtitle>
					</div>
				</Stats.Header>
				<Chart.ResponsiveContainer width="100%" height={100}>
					<Chart.BarChart
						aria-roledescription="Bar chart showing real-time visitors in the last hour"
						margin={{ top: 12, bottom: -10, left: 7, right: 7 }}
						style={{ fontSize: '0.8rem' }}
						data={withTimestamps}
					>
						<Chart.YAxis hide domain={[0, (dataMax) => Math.max(4, dataMax)]} />
						<Chart.XAxis
							dataKey="timestamp"
							stroke="translate"
							domain={['dataMin', 'dataMax']}
							type={'number'}
							interval="preserveStartEnd"
							tickFormatter={(date) => format(new Date(date), 'HH:mm')}
						/>
						<Chart.Tooltip cursor={{ fill: 'var(--grey-lightest' }} content={RealtimeEventsTooltip} />

						<Chart.Bar dataKey="visitors" fill="var(--blue)" aria-label="value" radius={[2, 2, 0, 0]} minPointSize={1}>
							{withTimestamps.map((item) => (
								<Chart.Cell key={item.ts} fill={!item.visitors ? 'var(--grey-lighter)' : 'var(--blue)'} />
							))}
						</Chart.Bar>
					</Chart.BarChart>
				</Chart.ResponsiveContainer>
			</Card.Section>
			<Card.Section>
				<p>{/* Add content here in follow-up PRs */}</p>
			</Card.Section>
		</Card>
	);
};

export default RealtimeEventsCardWrapper;
