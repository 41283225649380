import React from 'react';
import { ClerkLoaded, ClerkLoading, SignIn } from '@clerk/clerk-react';
import { naiveIntercom } from '@services/Intercom/utils';

import LoadingContainer from '@components/LoadingContainer';

import styles from './Login.module.scss';

export const Login = () => {
	return (
		<main className={styles.container}>
			<ClerkLoading>
				<LoadingContainer />
			</ClerkLoading>
			<ClerkLoaded>
				<SignIn fallbackRedirectUrl="/home" routing="virtual" signUpUrl="/register" />
				<p className={styles.support}>
					If you need help, <button onClick={() => naiveIntercom()}>contact support</button>.
				</p>
			</ClerkLoaded>
		</main>
	);
};
