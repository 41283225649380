import React from 'react';

import Table from '@components/Table';
import { formatDimensionValue } from './dataVisualization';
import styles from '../ComparisonCard.module.scss';

const ComparisonTableChart = ({ data, dimension }) => {
	return (
		<div className={`${styles.chartContainer} ${styles.tableContainer}`}>
			<Table
				data={data}
				fields={[
					{
						title: 'Shop',
						sortable: true,
						sort: (row) => row.shopName,
						formatter: (row) => row.shopName
					},
					{
						title: dimension.label,
						sortable: true,
						sort: (row) => row.value,
						formatter: (row) => formatDimensionValue(row.value, dimension)
					}
				]}
			/>
		</div>
	);
};

export default ComparisonTableChart;
