import React from 'react';
import { useInsights } from '@services/insights';
import { format, subDays } from 'date-fns';
import LineGraph from '@components/Graphs/LineGraph';
import * as Stats from '@components/StatsCard';
import styles from './StockMovement.module.scss';
import NoData from '@components/Graphs/Placeholders/NoData';
import { Loader } from '@nearst/ui';

const StockMovement = ({ shop }) => {
	const query = {
		id: shop.id,
		table: 'stock_count',
		columns: ['in_stock_barcodes', 'total_uploaded_barcodes', 'shop_id', 'date'],
		from: format(subDays(new Date(), 31), 'yyyy-MM-dd'),
		to: format(subDays(new Date(), 1), 'yyyy-MM-dd'),
		sort: [{ column: 'date', order: 'asc' }],
		sum: ['in_stock_barcodes', 'total_uploaded_barcodes']
	};

	const { resultSet, isLoading } = useInsights(query);

	return (
		<Stats.Card className={`${styles.card}`}>
			<Stats.Column>
				<Stats.Header>
					<Stats.Title>Stock upload history</Stats.Title>
				</Stats.Header>
				<Stats.Subtitle>Last 30 days</Stats.Subtitle>
				{isLoading ? (
					<Loader />
				) : !resultSet.length ? (
					<NoData />
				) : (
					<div className={styles.graphContainer}>
						<LineGraph
							series={resultSet.map((row) => ({
								date: row.date,
								totalBarcodes: row.total_uploaded_barcodes,
								inStockBarcodes: row.in_stock_barcodes
							}))}
							seriesConfig={[
								{ key: 'totalBarcodes', colour: '#2aca79', label: 'Total uploaded barcodes' },
								{ key: 'inStockBarcodes', colour: '#50a1ff', label: 'In-stock barcodes' }
							]}
							height={180}
							lineColor="#50a1ff"
							yAxisLabel="Impressions"
						/>
					</div>
				)}
			</Stats.Column>
		</Stats.Card>
	);
};

export default StockMovement;
