import React from 'react';
import * as Accordion from '@radix-ui/react-accordion';
import Card from '../Card';
import Icon from '../Icon';

import styles from './ExpandableCard.module.scss';

const ExpandableCard = ({ children, title, icon = null }) => {
	return (
		<Accordion.Root type="single" collapsible>
			<Accordion.Item value={title}>
				<Card>
					<Accordion.Trigger className={styles.title}>
						<span>
							{icon}
							{title}
						</span>
						<Icon>chevron_right</Icon>
					</Accordion.Trigger>
					<Accordion.Content>{children}</Accordion.Content>
				</Card>
			</Accordion.Item>
		</Accordion.Root>
	);
};

export default ExpandableCard;
