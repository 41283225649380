import React from 'react';
import Tippy from '@tippyjs/react';

import { UItils } from '@nearst/ui';

import styles from './HorizontalBar.module.scss';
import Skeleton from '@components/Skeleton';

const Tooltip = ({ children, title, description }) => {
	return (
		<Tippy
			content={
				<div className={styles.tooltip}>
					<strong>{title}</strong>
					<p>{description}</p>
				</div>
			}
		>
			{children}
		</Tippy>
	);
};

const Loading = () => {
	return (
		<div className={styles.loadingState}>
			<Skeleton height={20} width={'100%'} />
			<Skeleton height={12} width={'30%'} />
		</div>
	);
};

export const HorizontalBar = ({ segments }) => {
	return (
		<>
			<div className={styles.progressBar}>
				{segments.map(({ title, percentage, count, description, style }) =>
					count ? (
						<Tooltip key={title} title={`${count.toLocaleString()} ${title}`} description={description}>
							<div className={UItils.classnames(styles.progressBarSegment, style)} style={{ width: `${percentage}%` }} />
						</Tooltip>
					) : null
				)}
			</div>
			<div className={styles.legend}>
				{segments.map(({ title, percentage, count, style }) =>
					count ? (
						<div key={title} className={styles.legendItem}>
							<div className={UItils.classnames(styles.legendItemIndicator, style)} />
							{percentage || '<1'}% {title}
						</div>
					) : null
				)}
			</div>
		</>
	);
};

HorizontalBar.Loading = Loading;

export default HorizontalBar;
