import { Protect } from '@clerk/clerk-react';
import { DropdownItem, DropdownMenu } from '@nearst/ui';

const InventoryActions = ({ setBulkHideModal, setBulkUploadModalOpen, disabled }) => {
	return (
		<Protect condition={(has) => has({ permission: 'org:inventory:hide' }) || has({ permission: 'org:inventory:upload' })}>
			<DropdownMenu primary={false} disabled={disabled} secondary={true} buttonText={'Actions'}>
				<Protect permission="org:inventory:hide">
					<DropdownItem onClick={() => setBulkHideModal(true)}>Hide products</DropdownItem>
				</Protect>
				<Protect permission="org:inventory:upload">
					<DropdownItem onClick={() => setBulkUploadModalOpen(true)}>Upload product data</DropdownItem>
				</Protect>
			</DropdownMenu>
		</Protect>
	);
};

export default InventoryActions;
