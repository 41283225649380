import React from 'react';
import OnboardingStep from '../OnboardingStep';
import { ICON_STATUS } from '../constants';

const ApproveInventory = ({ active = false }) => {
	return (
		<OnboardingStep
			iconText="3"
			status={active ? ICON_STATUS.CURRENT : ICON_STATUS.DISABLED}
			title={active ? 'We are getting your inventory live on Google.' : 'We will get your inventory live on Google.'}
		>
			<p>The approval process can take up to 7 days.</p>
			<p>You will receive an email when your Google See What&apos;s in Store is up and running!</p>
		</OnboardingStep>
	);
};

export default ApproveInventory;
