import React from 'react';
import * as Chart from 'recharts';

import Tooltip from './Tooltip';
import { formatDimensionValue, pieChartColorPalette } from './dataVisualization';
import styles from '../ComparisonCard.module.scss';

const ComparisonBarChart = ({ data, dimension }) => {
	return (
		<div className={styles.chartContainer}>
			<Chart.ResponsiveContainer width="100%" minHeight={30 * data.length + 96}>
				<Chart.ComposedChart data={data} margin={{ top: 48, left: 32, right: 64, bottom: 48 }} layout="vertical">
					<Chart.XAxis type="number" hide />
					<Chart.YAxis dataKey="shopName" type="category" width={240} stroke="translate" />
					<Chart.Bar
						dataKey="value"
						label={{ position: 'right', fill: 'var(--grey-darkest)', formatter: (v) => formatDimensionValue(v, dimension) }}
						barSize={20}
						radius={4}
					>
						{data.map((_, index) => (
							<Chart.Cell
								key={`cell-${index}`}
								activeBar={false}
								fill={pieChartColorPalette[index % pieChartColorPalette.length]}
							/>
						))}
					</Chart.Bar>
					<Chart.Tooltip cursor={false} content={Tooltip} />
				</Chart.ComposedChart>
			</Chart.ResponsiveContainer>
		</div>
	);
};

export default ComparisonBarChart;
