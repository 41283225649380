import React, { useState } from 'react';
import useSWR from 'swr';

import { listPlans } from '@services/localAds';
import * as Stats from '@components/StatsCard';
import styles from './BudgetSlider.module.scss';
import Actions from './Actions';
import Figures from './Figures';
import { LiaNumberInput, LiaSlider, QuickChoose } from './Inputs';
import { parseCurrency } from '@utils/currency';

const BudgetSlider = ({ shop }) => {
	const { data: livePlan } = useSWR(`${shop.id}-live-plan`, () => listPlans({ id: shop.id, status: 'live' }));
	const { data: upcomingPlan } = useSWR(`${shop.id}-upcoming-plan`, () => listPlans({ id: shop.id, status: 'upcoming' }));

	let plan = { budget: 0 };
	if (upcomingPlan && upcomingPlan.hasOwnProperty('budget')) {
		plan = { ...upcomingPlan };
	} else if (livePlan && livePlan.hasOwnProperty('budget')) {
		plan = { ...livePlan };
	} else if (shop.currentPlusPlan) {
		plan.budget = shop.currentPlusPlan.amount;
	}

	const changed = upcomingPlan && upcomingPlan.hasOwnProperty('budget');

	const [quickChooseValue, setQuickChooseValue] = useState();
	const [sliderValue, setSliderValue] = useState([plan.budget]);
	const [inputValue, setInputValue] = useState(plan.budget);

	const [newLiaBudget, setNewLiaBudget] = useState();

	const reset = (budget) => {
		setQuickChooseValue();
		setSliderValue([budget]);
		setNewLiaBudget();
		setInputValue(budget);
	};

	const handleQuickChooseClick = (value) => {
		setQuickChooseValue(value);
		setSliderValue([value]);
		if (value !== plan.budget) {
			setNewLiaBudget(value);
			setInputValue(value);
		} else {
			setNewLiaBudget();
		}
	};

	const handleSliderCommit = ([value]) => {
		value = value < 2 ? 0 : value;
		if (value !== plan.budget) {
			setNewLiaBudget(value);
			setInputValue(value);
		} else {
			setNewLiaBudget();
		}
	};

	const handleNumberInputChange = (e) => {
		const value = e.target.value;
		setInputValue(value);

		let newBudget = parseInt(value);
		if (newBudget < 2) newBudget = 0;
		setSliderValue([newBudget]);
		setNewLiaBudget(newBudget);
	};

	const prePaidDaily = parseCurrency(shop.subscriptionLiaBudget || 0, { currency: shop.billingCurrency });
	const prePaidMonthly = parseCurrency(shop.subscriptionLiaBudget || 0, { roundup: true, currency: shop.billingCurrency }).multiply(
		30.343
	);
	const liaDaily = parseCurrency(plan.budget || 0, { currency: shop.billingCurrency });
	const liaMonthly = liaDaily.multiply(30);
	const totalMonthly = prePaidMonthly.add(liaMonthly);
	const totalDaily = prePaidDaily.add(liaDaily);

	const figures = { prePaidMonthly, liaMonthly, totalMonthly, totalDaily };

	const newDaily =
		newLiaBudget || newLiaBudget === 0 ? parseCurrency(newLiaBudget, { currency: shop.billingCurrency }).add(prePaidDaily) : undefined;

	const budgetLabel = shop.subscriptionLiaBudget ? 'Additional daily budget:' : 'Daily budget:';

	const PrepaidBudget = () => {
		return (
			<>
				<p>
					Included in NearSt Subscription
					<Stats.Tooltip>Some pre-paid ads spend is included in your subscription</Stats.Tooltip>
				</p>
				<strong data-cy={`local-ads-prepaid-budget`}>{prePaidDaily.format()} per day</strong>
			</>
		);
	};

	const CurrentDailyBudget = () => {
		return (
			<>
				<p>{changed ? 'Upcoming' : 'Current'} daily budget:</p>
				<strong data-cy={`local-ads-current-budget`}>{totalDaily.format()} per day</strong>
			</>
		);
	};

	const NewDailyBudget = () => {
		return (
			<>
				<p className={styles.newDaily}>New total daily budget:</p>
				<strong data-cy={`local-ads-new-budget`} className={styles.newDaily}>
					{newDaily?.format()} per day
				</strong>
			</>
		);
	};

	return (
		<div className={styles.container}>
			<Figures subscriptionLiaBudget={shop.subscriptionLiaBudget} figures={figures} />
			<Stats.Card className={`${styles.card}`}>
				<Stats.Title>Daily budget breakdown</Stats.Title>
				{/* MOBILE SECTION */}
				<div className={`${styles.mobile} ${styles.grid}`}>
					{shop.subscriptionLiaBudget ? (
						<div className={`${styles.amount}`}>
							<PrepaidBudget />
						</div>
					) : null}

					<LiaNumberInput
						budgetLabel={budgetLabel}
						currency={shop.billingCurrency}
						value={inputValue}
						handleChange={handleNumberInputChange}
					/>
					<QuickChoose
						currency={shop.billingCurrency}
						quickChooseValue={quickChooseValue}
						handleQuickChooseClick={handleQuickChooseClick}
					/>
					<div className={styles.amount}>
						<CurrentDailyBudget />
					</div>
					{newDaily && (
						<>
							<div className={styles.amount}>
								<NewDailyBudget />
							</div>
							<Actions
								formattedBudget={newDaily.format()}
								reset={reset}
								shop={shop}
								oldBudget={plan.budget}
								newBudget={newLiaBudget}
							/>
						</>
					)}
				</div>
				{/* DESKTOP SECTION */}
				<div className={`${styles.desktop} ${styles.grid}`}>
					{shop.subscriptionLiaBudget ? (
						<>
							<PrepaidBudget />
						</>
					) : null}
					<div className={`${styles.fullWidth} ${styles.budgetSlider}`}>
						<span>
							<p>{budgetLabel}</p>
							<p className={styles.subtitle}>Use the slider to adjust your budget</p>
						</span>
						<LiaSlider
							sliderValue={sliderValue}
							setSliderValue={setSliderValue}
							handleSliderCommit={handleSliderCommit}
							currency={shop.billingCurrency}
						/>
					</div>
					<div className={`${styles.fullWidth}`}>
						<QuickChoose
							currency={shop.billingCurrency}
							quickChooseValue={quickChooseValue}
							handleQuickChooseClick={handleQuickChooseClick}
						/>
					</div>
					<CurrentDailyBudget />
					{newDaily && (
						<>
							<NewDailyBudget />
							<Actions
								formattedBudget={newDaily.format()}
								reset={reset}
								shop={shop}
								oldBudget={plan.budget}
								newBudget={newLiaBudget}
							/>
						</>
					)}
					{changed && (
						<p className={`${styles.subtitle} ${styles.fullWidth}`}>
							Your budget is being updated and changes will take effect from tomorrow
						</p>
					)}
				</div>
			</Stats.Card>
		</div>
	);
};

export default BudgetSlider;
