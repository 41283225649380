import { useLocation } from 'react-router-dom';

const uuidRegex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}/g;

const findUuid = (path) => {
	const matches = path.match(uuidRegex);
	const currentShop = matches ? matches[0] : undefined;
	return currentShop;
};

const spliceUrl = (path, newId) => {
	const [paths, query] = path.split('?');
	const splitPath = paths.split('/');
	splitPath.splice(2, 0, newId);
	path = splitPath.join('/');
	if (query) {
		path = path + '?' + query;
	}
	return path;
};

export const useIdSplice = (to) => {
	let path = to;
	const location = useLocation();
	const currentShop = findUuid(location.pathname);
	if (currentShop) {
		path = spliceUrl(path, currentShop);
	}
	return path;
};
