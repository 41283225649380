import React from 'react';
import { ICON_STATUS } from '../constants';
import { Avatar } from '@material-ui/core';
import DoneIcon from '@mui/icons-material/Done';
import WarningIcon from '@mui/icons-material/Warning';

import styles from './OnboardingIcon.module.scss';

const OnboardingIcon = ({ status, text }) => {
	if (status === ICON_STATUS.CURRENT) {
		return <Avatar className={`${styles.onboardingStatusFont} ${styles.onboardingStatusCurrent}`}>{text}</Avatar>;
	}
	if (status === ICON_STATUS.COMPLETED) {
		return <Avatar className={styles.onboardingStatusComplete}>{<DoneIcon />}</Avatar>;
	}
	if (status === ICON_STATUS.ALERT) {
		return <Avatar className={`${styles.onboardingStatusAlert}`}>{<WarningIcon />}</Avatar>;
	}

	return <Avatar className={styles.onboardingStatusFont}>{text}</Avatar>;
};

export default OnboardingIcon;
