const GoogleLocalListingsChannel = require('./definitions/GoogleLocalListingsChannel');
const GoogleLocalInventoryAdsChannel = require('./definitions/GoogleLocalInventoryAdsChannel');
const WebhookChannel = require('./definitions/WebhookChannel');
const MetaCommerceChannel = require('./definitions/MetaCommerceChannel');
const ProductReservationsChannel = require('./definitions/ProductReservationsChannel');
const ProductLocatorChannel = require('./definitions/ProductLocatorChannel');
const SearchWidgetChannel = require('./definitions/SearchWidgetChannel');
const ShopPageChannel = require('./definitions/ShopPageChannel');
const LocalCheckoutChannel = require('./definitions/LocalCheckoutChannel');
const UberEatsChannel = require('./definitions/UberEatsChannel');
const DeliverooChannel = require('./definitions/DeliverooChannel');
const JustEatChannel = require('./definitions/JustEatChannel');

/**
 * @type {(typeof import('./model/ChannelDefinition'))[]}
 */
const definitions = [
	GoogleLocalListingsChannel,
	GoogleLocalInventoryAdsChannel,
	WebhookChannel,
	MetaCommerceChannel,
	ProductLocatorChannel,
	SearchWidgetChannel,
	ShopPageChannel,
	ProductReservationsChannel,
	LocalCheckoutChannel,
	UberEatsChannel,
	DeliverooChannel,
	JustEatChannel
];

/**
 * @type {import('./model/ChannelDefinition')[]}
 */
module.exports = Object.fromEntries(
	definitions.map((definition) => {
		const channel = new definition();
		return [channel.id, channel.toJSON()];
	})
);
