import React from 'react';

import { Icon } from '@material-ui/core';

import styles from '../LocationManagement.module.scss';

const InsightsComparison = ({ currentValue, previousValue }) => {
	let percentageDifference = 0;
	if (currentValue && previousValue) percentageDifference = Math.round(((currentValue - previousValue) / previousValue) * 100);
	const arrow = percentageDifference > 0 ? 'arrow_drop_up' : 'arrow_drop_down';

	return (
		percentageDifference !== 0 && (
			<p className={styles[arrow]}>
				<Icon>{arrow}</Icon> {percentageDifference.toLocaleString()}% last 30 days
			</p>
		)
	);
};

export default InsightsComparison;
