import React from 'react';
const graphColour = window.getComputedStyle(document.documentElement).getPropertyValue('--grey');
const MockGraph = ({ colour = graphColour, outline, className }) => {
	return (
		<svg className={className} viewBox="0 0 102 102" xmlns="http://www.w3.org/2000/svg">
			<rect y="70" x="1" width="25" height="30" rx="1" stroke={colour} strokeWidth="1" fill={outline ? 'none' : colour} />
			<rect y="40" x="37.5" width="25" height="60" rx="1" stroke={colour} strokeWidth="1" fill={outline ? 'none' : colour} />
			<rect y="1" x="75" width="25" height="100" rx="1" stroke={colour} strokeWidth="1" fill={outline ? 'none' : colour} />
		</svg>
	);
};
export default MockGraph;
