import React from 'react';
import { format, eachDayOfInterval, isSameDay } from 'date-fns';
import { getDailySwisInsights } from '@services/insights';
import { useInsightsDate } from '@services/InsightsDateProvider';
import * as Stats from '../../../../../components/StatsCard';
import InsightsWrapper from '../../../../../components/Graphs/DataWrapper';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import { showIntercomArticle } from '@services/Intercom/utils';

import LocalListingsPerformanceChart from './PerformanceChart';
import LocalListingsFigures from '../../SWIS/Figures';
import { shopIsOnSwis } from '@services/channels';

import styles from '../Performance.module.scss';
import { ChainPerformance } from '../ChainPerformance';

const LocalListingsPerformance = ({ shops }) => {
	const shop = shops.find((shop) => shopIsOnSwis(shop)) || shops[0];
	const singleGraph = shops.length === 1;
	const shopIds = shops.map((shop) => shop.id);
	const { startDate, endDate, to, from, selectedRange, fromStartOfWeekStr, toEndOfWeekStr, lookbackPeriod } = useInsightsDate();
	const allDates = eachDayOfInterval({ start: startDate, end: endDate });

	const swisQuery = {
		id: shopIds,
		table: 'local_listings',
		columns: ['impressions', 'clicks', 'shop_id', 'date'],
		to,
		from
	};

	const swisWeeklyQuery = {
		id: shopIds,
		table: 'local_listings_weekly',
		columns: ['start_date', 'end_date', 'impressions', 'shop_id'],
		filter: [
			['start_date', '>=', fromStartOfWeekStr],
			['end_date', '<=', toEndOfWeekStr]
		],
		sort: [{ column: 'start_date', order: 'desc' }]
	};

	const swisQueryPrevious = {
		id: shopIds,
		table: 'local_listings',
		columns: ['impressions', 'clicks', 'shop_id', 'date'],
		from: lookbackPeriod.from,
		to: lookbackPeriod.to
	};

	const swisWeeklyPrevious = {
		id: shopIds,
		table: 'local_listings_weekly',
		columns: ['start_date', 'end_date', 'impressions', 'shop_id'],
		filter: [
			['start_date', '>=', lookbackPeriod.fromStartOfWeek],
			['end_date', '<=', lookbackPeriod.toEndOfWeek]
		],
		sort: [{ column: 'start_date', order: 'desc' }]
	};

	return (
		<>
			<Stats.Card className={`${styles.card} ${singleGraph && styles.performance} ${!singleGraph && styles.chainPerformance}`}>
				<Stats.Column>
					<Stats.Header>
						<Stats.Title>
							Local Listings
							<Stats.Tooltip>Your products shown across Google Search, Images, Shopping and Maps.</Stats.Tooltip>
						</Stats.Title>
					</Stats.Header>
					<Stats.Subtitle>
						{selectedRange} {!singleGraph ? '| Top performing locations' : ''}
					</Stats.Subtitle>

					<ErrorBoundary>
						<InsightsWrapper query={[swisQuery, swisWeeklyQuery, swisQueryPrevious, swisWeeklyPrevious]}>
							{([swisDaily, swisWeekly, swisDailyLookback, swisWeeklyLookback]) => {
								const swisDailyCorrected = getDailySwisInsights(startDate, endDate, swisDaily, swisWeekly, shopIds);
								const swisDailyCorrectedLookback = getDailySwisInsights(
									new Date(lookbackPeriod.fromStartOfWeek),
									new Date(lookbackPeriod.toEndOfWeek),
									swisDailyLookback,
									swisWeeklyLookback,
									shopIds
								);
								let anyEstimates = false;
								const matchToDates = allDates.map((date) => {
									const swis = swisDailyCorrected.find((row) => isSameDay(new Date(row['date']), new Date(date)));
									const swisEstimate = swis?.estimate;
									if (swisEstimate) anyEstimates = true;

									return {
										Date: format(new Date(date), 'yyyy-MM-dd'),
										"See What's In Store": swis ? +swis['impressions'] : 0,
										swisEstimate
									};
								});

								return (
									<>
										<LocalListingsFigures
											swisDailyCorrected={swisDailyCorrected}
											swisDailyCorrectedLookback={swisDailyCorrectedLookback}
											swisDaily={swisDaily}
											swisDailyLookback={swisDailyLookback}
										/>

										{singleGraph ? (
											<LocalListingsPerformanceChart data={matchToDates} />
										) : (
											<ChainPerformance
												data={swisDailyCorrected}
												org={shops[0].organisation}
												shops={shops}
												insightType={'swis'}
											/>
										)}
										{singleGraph && anyEstimates && (
											<span className={styles.estimate}>
												Some data is calculated from your weekly average.{' '}
												<a
													onClick={(e) => {
														if (window.Intercom) {
															e.preventDefault();
															showIntercomArticle(7983283);
														}
													}}
													href="https://support.near.st/en/articles/7983283-why-is-my-see-what-s-in-store-data-reflecting-a-weekly-averages"
													target="_blank"
													rel="noreferrer"
												>
													Why?
												</a>
											</span>
										)}
									</>
								);
							}}
						</InsightsWrapper>
					</ErrorBoundary>
					<div className={styles.viewMoreWrapper}>
						{shops.length > 1 ? (
							<Stats.ViewMore to={`/insights/local-listings`} />
						) : (
							<Stats.ViewMore to={`/insights/${shop.id}/local-listings`} />
						)}
					</div>
				</Stats.Column>
			</Stats.Card>
		</>
	);
};

export default LocalListingsPerformance;
