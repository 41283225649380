import React, { useMemo } from 'react';
import { format } from 'date-fns';
import useSWR from 'swr';

import { ActualButton, Card } from '@nearst/ui';
import Table from '@components/Table';
import Download from '@components/Graphs/Download';
import { getCSVData } from '@components/Graphs/DataWrapper';
import { useShop } from '@services/ShopProvider';
import { getInvoices } from '@services/billing';

const Invoices = () => {
	const { shop, data } = useShop();
	const { data: invoices } = useSWR([shop.id || data[0].id, 'invoices'], getInvoices, { suspense: true });

	const csv = useMemo(() => {
		if (!invoices) return;
		const rows = invoices
			.map((row) => ({
				Status: row.status,
				Amount: row.amount.toLocaleString('en-US', {
					style: 'currency',
					currency: row.currency
				}),
				Description: row.description,
				Date: format(new Date(row.date), 'dd/MM/yyyy'),
				'Invoice number': row.number
			}))
			.sort((a, b) => (b.date - a.date ? -1 : 1));
		return getCSVData(rows);
	}, [invoices]);

	return (
		<Card title="Invoice history" sectioned>
			<Card.Section className="invoices-table">
				{invoices && (
					<Table
						data={invoices}
						defaultSort={{
							direction: 'desc',
							orderBy: 'date',
							handler: (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
						}}
						fields={[
							{
								id: 'status',
								title: 'Status',
								sortFn: {
									asc: (a, b) => a.status - b.status,
									desc: (a, b) => b.status - a.status
								},
								formatter: (row) => <div className={`badge badge--${row.status}`}>{row.status}</div>
							},
							{
								title: 'Date',
								id: 'date',
								sortFn: {
									asc: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
									desc: (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
								},
								formatter: (row) => format(new Date(row.date), 'MMM dd, yyyy')
							},
							{
								title: 'Description',
								id: 'description',
								formatter: (row) => row.description
							},
							{
								title: 'Amount',
								id: 'amount',
								formatter: (row) =>
									row.amount.toLocaleString('en-US', {
										style: 'currency',
										currency: row.currency
									})
							},
							{
								title: '',
								id: 'button',
								formatter: (row) =>
									row.href ? <ActualButton onClick={() => window.open(row.href)}>Download</ActualButton> : ''
							}
						]}
					/>
				)}
			</Card.Section>
			{csv ? (
				<Card.Section>
					<Download csv={csv} filename="nearst-billing-report" title="Download billing report" />
				</Card.Section>
			) : null}
		</Card>
	);
};

export default Invoices;
