import React from 'react';
import styles from '../../InventoryConnection.module.scss';
import * as inventory from '@services/inventory';
import plurify from '../../../../../../utils/plurify';
import * as Stats from '../../../../../../components/StatsCard';
import { getInventorySourceStatus } from '../../utils';

const ConnectionStatus = ({ stockInventorySources, productInventorySources, shops }) => {
	const isChain = shops.length > 1;
	const stockIngest = inventory.getUploadAge(stockInventorySources, 'stock');
	const productIngest = inventory.getUploadAge(productInventorySources, 'product');

	const { stock: allShopsStockStatus, products: allShopsProductStatus } = getInventorySourceStatus(shops);
	let totalStock;
	if (stockIngest?.liveSources) {
		totalStock = inventory.getRecentlyConnectedLocations(stockInventorySources).reduce((total, current) => {
			return total + current.latestIngest?.stockIngested;
		}, 0);
	}

	const anyError = allShopsStockStatus.disconnected > 0 || (allShopsProductStatus.total > 0 && allShopsProductStatus.disconnected > 0);
	return (
		<div className={styles.statusContainer}>
			<Stats.Subheading className={styles.statusTitle}>Status</Stats.Subheading>

			{allShopsStockStatus.disconnected > 0 && (
				<>
					<p className={styles.errorText}>
						We are not receiving stock updates for {allShopsStockStatus.disconnected}{' '}
						{plurify('location', allShopsStockStatus.disconnected)}.
					</p>
					{allShopsStockStatus.disconnected === allShopsStockStatus.total && (
						<p className={styles.errorText}>
							We have not received stock data {stockIngest?.liveSources ? `for ${stockIngest.ingestAge}` : 'from you yet'}.
						</p>
					)}
				</>
			)}
			{allShopsStockStatus.connected > 0 && (
				<>
					<p>
						We are receiving stock updates for {allShopsStockStatus.connected}{' '}
						{plurify('location', allShopsStockStatus.connected)}.
					</p>
					{Number.isInteger(totalStock) && totalStock > 0 && (
						<p>
							We received {totalStock.toLocaleString()} {plurify('barcode', totalStock)} in the latest stock upload.
						</p>
					)}
				</>
			)}

			{allShopsProductStatus.total > 0 && (
				<>
					{allShopsProductStatus.disconnected > 0 && (
						<>
							<p className={styles.errorText}>
								We are not receiving product updates for {allShopsProductStatus.disconnected}{' '}
								{plurify('location', allShopsProductStatus.disconnected)}.
							</p>
							<p className={styles.errorText}>
								We have not received product data{' '}
								{productIngest?.liveSources ? `for ${productIngest.ingestAge}` : 'from you yet'}.
							</p>
						</>
					)}
					{allShopsProductStatus.connected > 0 && (
						<p>
							We are receiving product updates for {allShopsProductStatus.connected}{' '}
							{plurify('location', allShopsProductStatus.connected)}.
						</p>
					)}
				</>
			)}
			{allShopsStockStatus.onboarding > 0 && (
				<p>
					{allShopsStockStatus.onboarding} {plurify('location', allShopsStockStatus.onboarding)} still in setup.
				</p>
			)}

			{anyError && (
				<p>
					Please follow the instructions on the{' '}
					<a className={styles.link} href={`/inventory/${shops[0].id}`}>
						inventory page
					</a>
					{isChain ? ' of each location ' : ' '}
					to reconnect your POS.
				</p>
			)}
		</div>
	);
};

export default ConnectionStatus;
