import { format, subDays } from 'date-fns';

import { listAllPlans } from '@services/localAds';
import { getMultipleInsights } from '@services/insights';
import { getTotalAdsSpend } from '@utils/localAdsPlans';

// We consider store visits data preliminary if it's from the last 21 days
const STORE_VISITS_PRELIMINARY_DAYS = 21;

const createQuery = (ids, dateHelpers) => {
	const { startDate, endDate, interval } = dateHelpers;
	return [
		{
			table: 'local_ads_store_visits',
			from: format(startDate, 'yyyy-MM-dd'),
			to: format(endDate, 'yyyy-MM-dd'),
			id: ids,
			interval,
			columns: ['date', 'store_visits', 'shop_id'],
			sum: ['store_visits'],
			sort: [{ column: 'date', order: 'asc' }]
		}
	];
};

const mapStoreVisits = (storeVisits) => {
	const cutoff = subDays(new Date(), STORE_VISITS_PRELIMINARY_DAYS).valueOf();
	const groupedByDate = Object.entries(
		storeVisits.reduce((acc, cur) => {
			acc[cur.date] = acc[cur.date] || 0;
			acc[cur.date] += cur.store_visits;
			return acc;
		}, {})
	);

	return groupedByDate.map(([date, storeVisits]) => {
		const processedDate = new Date(date).valueOf();
		return {
			date: processedDate,
			preliminary: processedDate > cutoff,
			store_visits: storeVisits
		};
	});
};

export const fetcher = async ({ shops, startDate, endDate, interval }) => {
	// First get insights data
	const ids = shops.map((shop) => shop.id);
	const query = createQuery(ids, { startDate, endDate, interval });
	const results = await getMultipleInsights(query);

	// Calculate total spend on local ads for shops with visits
	const plans = await listAllPlans();
	const shopsWithVisits = new Set(results.map((item) => item.shop_id));
	const { spend, shopsWithLocalAds } = getTotalAdsSpend(plans, startDate, endDate, shops, Array.from(shopsWithVisits));

	return [mapStoreVisits(results), shopsWithVisits, shopsWithLocalAds, spend];
};
