import React from 'react';
import { Pill, Switcher } from '@nearst/ui';
import * as Stats from '@components/StatsCard';
import { useInsightsDate } from '@services/InsightsDateProvider';
import DataWrapper from '@components/Graphs/DataWrapper';
import Skeleton from '@components/Skeleton';
import { calcAnalyticInsights, plurify } from './utils';
import { getOrderInsights } from '@services/orders';

import styles from './LocalCheckout.module.scss';
import useSWR from 'swr';
import { getOrderOptionsForOrganisation } from '@services/channels';
import { Protect } from '@clerk/clerk-react';

const LocalCheckout = ({ shops }) => {
	const linkToChainOrders = shops.length > 1;
	const { to, from, selectedRange } = useInsightsDate();
	const shopIds = shops.map((shop) => shop.id);

	const { data: orderInfo, isLoading: ordersLoading } = useSWR(['orders_insights', from, to, ...shopIds], () =>
		getOrderInsights({ shopIds, start: from, end: to, currency: shops[0].billingCurrency })
	);

	const { data: orderOptions } = useSWR(`order-options`, () => getOrderOptionsForOrganisation(), { suspense: true });
	const matchingShops = orderOptions.filter((orderOption) => shopIds.includes(orderOption.shopId));

	const enabledLocations = matchingShops.filter((orderOption) => orderOption.anyOptionEnabled);
	const hasReservations = matchingShops.some((orderOptions) => orderOptions.productReservationEnabled);
	const hasCheckout = matchingShops.some((orderOptions) => orderOptions.localCheckoutEnabled);

	const title = hasReservations ? 'Local checkout and reservations' : 'Local checkout';

	return enabledLocations.length ? (
		<Stats.Card className={styles.card}>
			<div className={styles.viewMorePartner}>
				<Stats.Header>
					<div>
						<Stats.Title>{title}</Stats.Title>
						<Stats.Subtitle>{selectedRange}</Stats.Subtitle>
					</div>
					<Pill colour="green">
						{shops.length > 1 ? `${enabledLocations.length} ${plurify('shop', enabledLocations.length)} Live` : 'Live'}
					</Pill>
				</Stats.Header>
				<Switcher space="2rem" className={styles.stats}>
					<DataWrapper
						LoaderPlaceholder={() => (
							<>
								<Skeleton width={128} height={40} />
								<Skeleton width={128} height={40} />
								<Skeleton width={128} height={40} />
							</>
						)}
						query={{
							id: shopIds,
							table: 'shopping_service_insights',
							columns: ['shop_id', 'barcode', 'date', 'type', 'value'],
							to,
							from
						}}
					>
						{(shoppingServiceInsights) => {
							const { productPageViews, productPageActions, directionClicks } = calcAnalyticInsights(shoppingServiceInsights);

							return (
								<>
									<Stats.Figure>
										{productPageViews}
										<Stats.FigureDescription>
											Product page views
											<Stats.Tooltip>
												Total number of shoppers clicking on individual products from your See What’s in Store
											</Stats.Tooltip>
										</Stats.FigureDescription>
									</Stats.Figure>
									<Stats.Figure>
										{productPageActions}
										<Stats.FigureDescription>
											{`Product page ${plurify('action', productPageActions)}`}
											<Stats.Tooltip>
												This includes all interactions on your product page, such as direction clicks, website
												clicks, call clicks and opening hours clicks
											</Stats.Tooltip>
										</Stats.FigureDescription>
									</Stats.Figure>
									<Stats.Figure>
										{directionClicks}
										<Stats.FigureDescription>{`Direction ${plurify(
											'click',
											directionClicks
										)}`}</Stats.FigureDescription>
									</Stats.Figure>
								</>
							);
						}}
					</DataWrapper>

					{ordersLoading ? (
						<>
							<Skeleton width={128} height={40} />
							{hasReservations && <Skeleton width={128} height={40} />}
							{hasCheckout && <Skeleton width={128} height={40} />}
						</>
					) : (
						orderInfo && (
							<>
								{hasReservations && (
									<Stats.Figure>
										{orderInfo.reservationsPlaced}
										<Stats.FigureDescription>{`${plurify('Reservation', orderInfo.reservationsPlaced)} placed`}</Stats.FigureDescription>
									</Stats.Figure>
								)}

								{hasCheckout && (
									<Stats.Figure>
										{orderInfo.ordersPlaced}
										<Stats.FigureDescription>{`${plurify('Order', orderInfo.ordersPlaced)} placed`}</Stats.FigureDescription>
									</Stats.Figure>
								)}

								<Stats.Figure>
									{orderInfo.totalSales}
									<Stats.FigureDescription>Total sales</Stats.FigureDescription>
								</Stats.Figure>
							</>
						)
					)}
				</Switcher>
				<Protect permission="org:orders:view">
					<div className={styles.viewMoreWrapper}>
						<Stats.ViewMore to={linkToChainOrders ? '/orders' : `/orders/${shops[0].id}`}>View orders</Stats.ViewMore>
					</div>
				</Protect>
			</div>
		</Stats.Card>
	) : null;
};

export default LocalCheckout;
