import React, { useState } from 'react';

import { Filter } from '@nearst/ui';

import { defaultFilters, availabilityFilters, statusFilters } from '@services/stock';

const countFilters = (filters) => Object.values(filters).filter(Boolean).length;

const InventoryFilter = ({ onApply, existingFilters, className }) => {
	const [filterCount, setFilterCount] = useState(countFilters(existingFilters));
	const [filters, setFilters] = useState(existingFilters || defaultFilters);

	const handleFilterChange = (event) => {
		setFilters({ ...filters, [event.target.name]: event.target.checked });
	};

	const handleApplyClick = () => {
		onApply(filters);
		const numFilterSelected = countFilters(filters);
		setFilterCount(numFilterSelected);
	};

	const handleOnReset = () => {
		setFilters({ ...defaultFilters });
	};

	return (
		<Filter.Dropdown
			className={className}
			text={`Filters${filterCount ? ` (${filterCount})` : ''}`}
			handleOnReset={handleOnReset}
			handleApplyClick={handleApplyClick}
			content={
				<>
					<Filter.Block
						title="Availability"
						filters={availabilityFilters}
						selectedFilters={filters}
						handleFilterChange={handleFilterChange}
					/>
					<Filter.Block
						title="Status"
						filters={statusFilters}
						selectedFilters={filters}
						handleFilterChange={handleFilterChange}
					/>
				</>
			}
		/>
	);
};

export default InventoryFilter;
