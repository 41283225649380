import React from 'react';
import { Loader, Switcher } from '@nearst/ui';
import InsightsWrapper, { getCSVData } from '../../../../../components/Graphs/DataWrapper';
import AdsRadius from '../AdsRadius';
import * as Stats from '../../../../../components/StatsCard';
import { useInsightsDate } from '@services/InsightsDateProvider';
import { format } from 'date-fns';
import Download from '../../../../../components/Graphs/Download';
import InsightCardContainer from '../../../components/InsightCardContainer';
import Table from '@components/Table';
import styles from './RadiusCard.module.scss';
import mixpanel from 'mixpanel-browser';

const jsonSafe = (jsonString) => {
	try {
		return JSON.parse(jsonString);
	} catch (e) {
		return;
	}
};

const MapRadius = ({ shopLocation, adsMaxRadius }) => {
	return (
		<AdsRadius
			shopLocation={shopLocation}
			adsMaxRadius={adsMaxRadius}
			googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyADuIrZikRI5Nid3RkecNY0c4VIsXioBhk&v=3.exp&libraries=geometry,drawing"
			loadingElement={
				<div className={styles.mapLoader}>
					<Loader />
				</div>
			}
			containerElement={<div className={styles.mapContainer} />}
			mapElement={<div className={styles.map} />}
		/>
	);
};

const PopularLocations = ({ data }) => {
	return (
		<div className={styles.popularLocations}>
			<Table
				onExpand={(expanded) =>
					mixpanel.track(expanded ? 'Expand insights table' : 'Collapse insights table', {
						'Table name': 'Local Ads Popular Locations'
					})
				}
				initialLength={6}
				maxLength={40}
				expandable
				data={data}
				defaultSort={{ orderBy: 'clicks', direction: 'desc', handler: (a, b) => b.clicks - a.clicks }}
				fields={[
					{
						id: 'locations',
						title: 'Locations',
						width: '65%',
						formatter: (row) => {
							return row.name;
						}
					},
					{
						id: 'clicks',
						title: 'Clicks',
						width: '15%',
						formatter: (row) => {
							return row.clicks?.toLocaleString();
						},
						sortFn: { asc: (a, b) => a.clicks - b.clicks, desc: (a, b) => b.clicks - a.clicks }
					},
					{
						id: 'views',
						title: 'Views',
						width: '20%',
						formatter: (row) => {
							return row.impressions?.toLocaleString();
						},
						sortFn: {
							asc: (a, b) => a.impressions - b.impressions,
							desc: (a, b) => b.impressions - a.impressions
						}
					}
				]}
			/>
		</div>
	);
};

const RadiusCard = ({ shop }) => {
	const geo = jsonSafe(shop.geolocation);
	const { startDate, endDate, selectedRange } = useInsightsDate();

	const maxRadius = shop.adCriterions
		? shop.adCriterions.filter((el) => el.type === 'proximity').sort((a, b) => b.radius - a.radius)[0]
		: undefined;

	const query = {
		table: 'local_ads_geo',
		from: format(startDate, 'yyyy-MM-dd'),
		to: format(endDate, 'yyyy-MM-dd'),
		id: shop.id,
		columns: ['clicks', 'impressions', 'name'],
		sum: ['clicks', 'impressions'],
		sort: [{ column: 'impressions', order: 'desc' }]
	};

	return (
		<>
			<InsightCardContainer>
				<Stats.Title>
					<span className={styles.title}>
						Where your ads are appearing
						<Stats.Tooltip>Ads will be displayed to your customers who are located within the circle on the map</Stats.Tooltip>
					</span>
				</Stats.Title>
				<Stats.Subtitle>{selectedRange}</Stats.Subtitle>
				<InsightsWrapper query={query}>
					{(data) => {
						const csv = getCSVData(data);
						return (
							<>
								<Stats.Download filename="google-popular-locations" csv={csv} />

								<div className={styles.graphTableWrap}>
									{maxRadius && (
										<div className={styles.graphWrap}>
											<MapRadius shopLocation={geo} adsMaxRadius={maxRadius.radius} />
										</div>
									)}
									<PopularLocations data={data} />
								</div>
							</>
						);
					}}
				</InsightsWrapper>
			</InsightCardContainer>
		</>
	);
};

export default RadiusCard;
