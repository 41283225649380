const { marked } = require('marked');
const defaultIconUrl = 'https://go.near.st/hubfs/channels/default.svg';

/**
 * Abstract class for channel definitions.
 *
 * @abstract
 */
class ChannelDefinition {
	/**
	 * The ID of the channel.
	 * @type {string}
	 */
	id;

	/**
	 * The issues channel ID of the channel.
	 * @type {string}
	 * @default this.id
	 */
	issuesChannelId;

	/**
	 * The title of the channel.
	 * @type {string}
	 */
	title;

	/**
	 * The description of the channel.
	 * @type {string}
	 */
	description;

	/**
	 * The category of the channel.
	 * @type {{
	 * 	title: string,
	 * 	description: string
	 * }}
	 */
	category;

	/**
	 * The long description of the channel.
	 * @type {string}
	 */
	longDescription;

	/**
	 * The icon url of the channel.
	 * @type {string}
	 */
	iconUrl;

	/**
	 * The cover image url of the channel.
	 * @type {string}
	 */
	coverImageUrl;

	/**
	 * The channels this channel works with.
	 * @type {string[]}
	 */
	worksWith;

	/**
	 * The help article urls of the channel.
	 * @type {{
	 * 	title: string,
	 * 	url: string
	 * }[]}
	 */
	helpArticleUrls;

	/**
	 * The url of the channel's manage page.
	 * @type {string}
	 */
	manageUrl;

	/**
	 * Whether the channel has product specific visibility.
	 * @type {boolean}
	 */
	productSpecificVisibility;

	/**
	 * The requirements of the channel.
	 * @type {import('./Requirements')}
	 */
	requirements;

	/**
	 * Whether the channel is coming soon.
	 * @type {boolean}
	 */
	comingSoon = false;

	/**
	 * The url of the channel's register interest form.
	 * @type {string}
	 */
	registerInterestUrl;

	/**
	 * Whether the channel is available in the partner API.
	 * @type {boolean}
	 */
	isAvailableInPartnerApi = false;

	/**
	 * Whether the channel has a feed export option.
	 * @type {boolean}
	 */
	feedExport = false;

	/**
	 * The pricing of the channel.
	 * @type {string}
	 */
	get pricing() {
		const formatRequiredPlansString = (plans) => {
			return plans.map((plan) => plan.charAt(0).toUpperCase() + plan.slice(1)).join(', ');
		};

		if (this.comingSoon && !this.requirements?.plans) return null;

		if (!this.requirements?.plans) return 'Free to enable for all plans';

		return `${formatRequiredPlansString(this.requirements.plans)} plans`;
	}

	/**
	 * HTML version of markdown long description.
	 */
	get longDescriptionHtml() {
		if (!this.longDescription) return '';

		return marked.parse(this.longDescription);
	}

	toJSON() {
		return {
			id: this.id,
			issuesChannelId: this.issuesChannelId || this.id,
			title: this.title,
			description: this.description,
			longDescription: this.longDescription,
			longDescriptionHtml: this.longDescriptionHtml,
			iconUrl: this.iconUrl || defaultIconUrl,
			category: this.category?.title,
			categorySubtitle: this.category?.description,
			coverImageUrl: this.coverImageUrl,
			worksWith: this.worksWith,
			helpArticleUrls: this.helpArticleUrls,
			manageUrl: this.manageUrl,
			productSpecificVisibility: this.productSpecificVisibility,
			requirements: this.requirements,
			comingSoon: this.comingSoon,
			feedExport: this.feedExport,
			registerInterestUrl: this.registerInterestUrl,
			pricing: this.pricing
		};
	}
}

module.exports = ChannelDefinition;
