import React, { useState } from 'react';
import * as Sentry from '@sentry/browser';
import papa from 'papaparse';
import mixpanel from 'mixpanel-browser';
import { format } from 'date-fns';
import { useAuth } from '@clerk/clerk-react';

import { ActualButton as Button, Icon, Card, Cluster } from '@nearst/ui';

import Spinner from '../LoadingSpinner';
import UpgradeRequired from '../UpgradeRequired';

import { useInsightsDate } from '@services/InsightsDateProvider';
import { useShop } from '@services/ShopProvider';
import AuthorizationTooltip from '@components/AuthorizationTooltip';
import { useCheckPermission } from '@services/auth/hooks';
import { download } from '../Graphs/Download';

import styles from './ReportDownloadCard.module.scss';

const ReportDownloadCard = ({ getData, title, description, accessLevel = null, downloadAsURL = false }) => {
	const { startDate, endDate } = useInsightsDate();
	const { data: shops } = useShop();
	const { actor } = useAuth();
	const ids = shops.map(({ id }) => id);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [cache, setCache] = useState({});

	const handleDownload = async () => {
		setError('');
		setLoading(true);
		const key = `${title} ${format(startDate, 'yyyy-MM-dd')} ${format(endDate, 'yyyy-MM-dd')}.csv`.toLowerCase().replace(/ /g, '_');

		try {
			if (cache[key]) {
				download(key, cache[key], downloadAsURL);
				setLoading(false);
				return;
			}

			const data = await getData({ ids, shops, startDate, endDate });
			if (!data.length) {
				setError("There's no data available for these dates. Please try a different date range");
				setLoading(false);
				return;
			}
			let csv = downloadAsURL ? data : papa.unparse(data);
			download(key, csv, downloadAsURL);
			setCache({ ...cache, [key]: csv });

			mixpanel.track(`Report download`, {
				title,
				startDate,
				endDate,
				organisation: shops[0].organisation
			});
		} catch (err) {
			console.error(err);
			Sentry.captureException(err.message);
			setError('Something went wrong generating your report. Please try a different date range, or contact support.');
		}
		setLoading(false);
	};

	const DownloadButton = ({ children, admin = false }) => {
		const hasPermission = useCheckPermission('org:reports:download');
		const authorized = admin || hasPermission;

		return (
			<AuthorizationTooltip authorized={authorized} tooltipContent="You don't have access to download reports">
				<Button onClick={authorized && handleDownload} className={styles.downloadButton} disabled={loading || !authorized} primary>
					{children}
					{loading ? <Spinner size={20} /> : <Icon>download</Icon>}
				</Button>
			</AuthorizationTooltip>
		);
	};

	const downloadButton = <DownloadButton>Download</DownloadButton>;
	const showAdminButton = accessLevel === 'nearst-admin' || (accessLevel === 'plan' && actor);

	return (
		<Card title={title} description={description}>
			<Cluster>
				{!accessLevel && downloadButton}
				{accessLevel === 'plan' && <UpgradeRequired availableOnPlans={['standard', 'advanced']}>{downloadButton}</UpgradeRequired>}
				{showAdminButton ? <DownloadButton admin>Download (NearSt Admin)</DownloadButton> : null}
				{error && (
					<p className={styles.error}>
						<Icon>error_outline</Icon>
						{error}
					</p>
				)}
			</Cluster>
		</Card>
	);
};

export default ReportDownloadCard;
