import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import Tippy from '@tippyjs/react';
import mixpanel from 'mixpanel-browser';
import { Page, Stack, Button, Pill } from '@nearst/ui';

import { useShop } from '@services/ShopProvider';
import { showIntercomWithMessage } from '@services/Intercom/utils';
import { useCheckPlan } from '@components/UpgradeRequired';

import styles from './ChannelDetails.module.scss';
import ChannelUpgradeModal from '@components/Modals/Upgrade/ChannelUpgradeModal';
import ChannelHeader from '../../components/ChannelHeader';
import useChannelInfo from '../useChannelInfo';

const ChannelEnableButton = ({ channel, enabled, shop }) => {
	const requiredPlans = channel.requirements?.plans || [];
	const { unlocked, upgrade, showDialog, setShowDialog } = useCheckPlan(requiredPlans, 'Channel card');

	const isAvailable = shop.availableChannels[channel.id];
	const url = channel.manageUrl?.replace('{shopId}', shop.id);

	if (!channel.manageUrl) return null;

	if (!isAvailable && !enabled) return <p>Not available for your region or plan type.</p>;

	if (!unlocked && !enabled) {
		return (
			<>
				<ChannelUpgradeModal showDialog={showDialog} setShowDialog={setShowDialog} channel={channel} />
				<Button className={styles.ctaButton} onClick={upgrade} primary>
					Upgrade
				</Button>
			</>
		);
	}

	return (
		<Button data-cy="channel-enable-button" className={styles.ctaButton} primary as={Link} to={url}>
			{enabled ? 'Manage connection' : 'Enable'}
		</Button>
	);
};

const RegisterInterestButton = ({ channel, shop }) => {
	const registerInterest = () => {
		mixpanel.track(`Register interest click - ${channel.label}`);

		if (channel.registerInterestUrl) {
			window.open(`${channel.registerInterestUrl}?organistion_id=${shop.organisationId}`, '_blank');
		} else if (!showIntercomWithMessage(`I'd like to learn more about ${channel.label}.`)) {
			window.open(`mailto:support@near.st?subject=${channel.label}`, '_blank');
		}
	};

	return (
		<Button className={styles.ctaButton} onClick={registerInterest} primary>
			Register interest
		</Button>
	);
};

const ChannelDetails = () => {
	const { shop } = useShop();

	const { channelId, channelDefinitions, channelDefinition, status } = useChannelInfo();

	if (!channelDefinition) {
		return <Navigate to={`/channels/${shop.id}`} />;
	}

	return (
		<Stack space="2rem">
			<ChannelHeader channelId={channelId} status={status} />
			<Page.Section sidebarLayout>
				<Page.Section.Sidebar>
					<ul className={styles.featureList}>
						{channelDefinition.comingSoon ? (
							<RegisterInterestButton channel={channelDefinition} shop={shop} />
						) : (
							<ChannelEnableButton channel={channelDefinition} enabled={status === 'enabled'} shop={shop} />
						)}
						{channelDefinition.pricing && (
							<li>
								<h4>Pricing</h4>
								<p>{channelDefinition.pricing}</p>
							</li>
						)}
						{channelDefinition.category && (
							<li>
								<h4>Category</h4>
								<span>
									<Tippy content={channelDefinition.categorySubtitle}>
										<span>
											<Pill colour="grey">{channelDefinition.category}</Pill>
										</span>
									</Tippy>
								</span>
							</li>
						)}
						{channelDefinition.worksWith && channelDefinition.worksWith.length > 0 && (
							<li>
								<h4>Works with</h4>
								<ul>
									{channelDefinition.worksWith.map((channelId) => {
										const linkedChannel = channelDefinitions[channelId];
										if (!linkedChannel) return null;
										return (
											<li key={channelId}>
												<Link className={styles.link} to={`/channels/${shop.id}/details/${channelId}`}>
													{linkedChannel.title}
												</Link>
											</li>
										);
									})}
								</ul>
							</li>
						)}
						{channelDefinition.helpArticleUrls && (
							<li>
								<h4>Support</h4>
								<ul>
									{channelDefinition.helpArticleUrls.map((link) => (
										<li key={link.url}>
											<Link className={styles.link} to={link.url} target="_blank" rel="noreferrer">
												{link.title}
											</Link>
										</li>
									))}
								</ul>
							</li>
						)}
					</ul>
				</Page.Section.Sidebar>
				<Page.Section.Content>
					{channelDefinition.coverImageUrl && (
						<img className={styles.coverImage} src={channelDefinition.coverImageUrl} alt="Cover" />
					)}
					<div className={styles.longDescription} dangerouslySetInnerHTML={{ __html: channelDefinition.longDescriptionHtml }} />
				</Page.Section.Content>
			</Page.Section>
		</Stack>
	);
};

export default ChannelDetails;
