import React from 'react';
import { Button, Cluster } from '@nearst/ui';
import SubStep from '../../Substep';
import HelpButton from '../../HelpButton';
import { useShop } from '@services/ShopProvider';

import styles from './MetaCommerceStepTwo.module.scss';

const DomainVerificationStep = ({ channel, setDomainAdded }) => {
	const { shop } = useShop();
	const shopUrl = `${shop.slug}.near.st`;
	return (
		<div className={styles.cardInner}>
			<SubStep progress="in progress" title="Enter your NearSt website on the domain settings page">
				<p>
					In your Business Manager Settings, go to{' '}
					<a
						href={`https://business.facebook.com/settings/owned-domains?business_id=${channel.config.businessId}`}
						target="_blank"
						rel="noreferrer"
						className={styles.link}
					>
						Domains
					</a>
					.
				</p>
				<p>
					Click on <strong>Add</strong> &rarr; <strong>Create a new domain</strong> and enter{' '}
					<code className={styles.domainName}>{shopUrl}</code> in the <strong>Your domain</strong> field.
				</p>
				<Cluster className={styles.buttons}>
					<Button onClick={() => setDomainAdded(true)} primary>
						Continue
					</Button>
				</Cluster>
				<HelpButton href="https://support.near.st/en/articles/8106146-how-can-i-verify-my-website-domain">
					How can I find this page?
				</HelpButton>
			</SubStep>
			<SubStep title="Enter verification code" />
			<SubStep title={`Click "Verify domain"`} />
		</div>
	);
};

export default DomainVerificationStep;
