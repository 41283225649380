import * as s from 'superstruct';
import countryList from 'react-select-country-list';

const countries = countryList().getData();

const Email = s.define('Email', (value) => {
	const matcher =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	if (!value || value.length > 320) return false;
	return matcher.test(value);
});

const Country = s.define('Country', (value) => !!countries.find((country) => country.value === value));

const schema = {
	name: (value) => s.is(value, s.string()),
	addressLine1: (value) => s.is(value, s.string()),
	addressLine2: (value) => s.is(value, s.optional(s.any())),
	city: (value) => s.is(value, s.optional(s.any())),
	country: (value) => s.is(value, Country),
	googlePlaceId: (value) => s.is(value, s.optional(s.any())),
	postcode: (value) => s.is(value, s.string()),
	publicPhoneNumber: (value) => s.is(value, s.optional(s.any())),
	types: (value) => s.is(value, s.optional(s.any())),
	contactPhone: (value) => s.is(value, s.optional(s.string())),
	contactEmail: (value) => s.is(value, Email),
	contactName: (value) => s.is(value, s.string()),
	terms: (value) => s.is(value, s.boolean()),
	partnerstackPartnerKey: (value) => s.is(value, s.optional(s.string()))
};

export const hoursSchema = s.object({
	open: s.object({
		day: s.number(),
		hours: s.number(),
		minutes: s.number(),
		time: s.string()
	}),
	close: s.object({
		day: s.number(),
		hours: s.number(),
		minutes: s.number(),
		time: s.string()
	})
});

export default schema;
