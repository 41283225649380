import React from 'react';
import styles from './Address.module.scss';

import { useFormContext } from 'react-hook-form';

import { lookupCountryName } from '../../utils';

const Address = ({ setStage }) => {
	const { getValues } = useFormContext();

	const handleClick = () => {
		setStage('edit_address');
	};
	const details = getValues();
	return (
		<>
			<div className={styles.label}>Store address</div>
			<div className={styles.location}>
				<p className={styles.bold}>{details.name}</p>
				<p>{details.addressLine1}</p>
				<p>{details.addressLine2}</p>
				<p>{details.postcode}</p>
				<b>{lookupCountryName(details.country)}</b>
				<p>{details.publicPhoneNumber}</p>
				<button className={styles.manual} onClick={handleClick}>
					Edit details
				</button>
			</div>
		</>
	);
};

export default Address;
