import React, { useState } from 'react';
import { FormControlLabel, FormGroup } from '@mui/material';
import { Checkbox } from '@material-ui/core';
import { Button, StatusIcon, TemplateModal } from '@nearst/ui';
import mixpanel from 'mixpanel-browser';
import { updateChannelsForStockItems } from '@services/stockChannels';
import LoadingSpinner from '@components/LoadingSpinner';
import plurify from '@utils/plurify';
import styles from './EditChannelVisibilityModal.module.scss';

const nonEditableChannels = ['local-ads'];
export const checkEditableChannels = (channelStatuses) => {
	return channelStatuses.filter(({ id }) => !nonEditableChannels.includes(id));
};

const getChannelsObject = (channelStatuses) => {
	return channelStatuses.reduce((acc, { id, enabled }) => {
		acc[id] = enabled;
		return acc;
	}, {});
};

/**
 * Shows a modal that lets the user select the channels they want to show their product in,
 * using a list of checkboxes.
 *
 * @typedef {Object} Props
 * @property {string} shopId
 * @property {Object} stockItems
 * @property {Object[]} channelStatuses
 * @property {boolean} isOpen
 * @property {() => void} onDismiss
 *
 * @param {Props} props
 * @returns
 */
const EditChannelVisibilityModal = ({ stockItems, shopId, channelStatuses, isOpen, onDismiss }) => {
	const onUpdate = async (newChannels) => {
		const barcodes = stockItems.map((stockItem) => stockItem.barcode);
		updateChannelsForStockItems(shopId, barcodes, newChannels);
		mixpanel.track('Channel visibility updated', { distinct_id: shopId, barcodes, newChannels });
		onDismiss();
	};

	return (
		<TemplateModal
			className={styles.modal}
			title="Edit channel visibility"
			onDismiss={onDismiss}
			isOpen={isOpen}
			showDismissButton={false}
		>
			<EditChannelVisibilityModalContent stockItems={stockItems} channelStatuses={channelStatuses} onUpdate={onUpdate} />
		</TemplateModal>
	);
};

export const EditChannelVisibilityModalContent = ({ stockItems, channelStatuses, onUpdate }) => {
	const editableChannels = checkEditableChannels(channelStatuses);
	const [newChannels, setNewChannels] = useState(getChannelsObject(editableChannels));
	const [loading, setLoading] = useState(false);

	const toggleChannel = (id) => {
		setNewChannels((currentChannels) => ({ ...currentChannels, [id]: !newChannels[id] }));
	};

	const submit = async () => {
		setLoading(true);
		await onUpdate(newChannels);
		setLoading(false);
	};

	const invalidItems = stockItems.filter((item) => item.issues?.length);

	return (
		<>
			<p>Select the channels where you want to display your product.</p>
			{stockItems.length > 1 && (
				<p className={styles.changesPrompt}>
					{`You are applying these changes to ${stockItems.length} ${plurify('item', stockItems.length)}.`}
				</p>
			)}
			<FormGroup>
				{editableChannels.map(({ id, label }) => {
					return (
						<FormControlLabel
							key={id}
							label={label}
							control={<Checkbox className={styles.checkbox} checked={newChannels[id]} onClick={() => toggleChannel(id)} />}
						/>
					);
				})}
			</FormGroup>
			<div className={styles.messageContainer}>
				<div>
					{!loading && invalidItems.length ? (
						<p className={styles.warning}>
							<StatusIcon status="warning" />
							{`${invalidItems.length} selected ${plurify('product', invalidItems.length)} require${
								invalidItems.length === 1 ? 's' : ''
							} fixes to become active.`}
						</p>
					) : null}
				</div>

				<div className={styles.confirmContainer}>
					{loading && <LoadingSpinner size={20} />}
					<Button primary className={styles.confirmButton} disabled={loading} onClick={submit}>
						Confirm
					</Button>
				</div>
			</div>
		</>
	);
};

export default EditChannelVisibilityModal;
