class Requirements {
	/**
	 * Creates new channel requirements.
	 * @param {Object} requirements - The requirements for the channel.
	 * @param {string[]} [requirements.plans] - The plans that the channel is available for.
	 * @param {string[]} [requirements.countries] - The countries that the channel is available for.
	 */
	constructor({ plans, countries }) {
		this.plans = plans;
		this.countries = countries;
	}

	/**
	 * Checks if the channel requirements are met by the given shop.
	 * @param {import('@nearst/shop-data/types').Shop} shop - The shop to check the requirements for.
	 * @returns {boolean} Whether the channel requirements are met.
	 */
	evaluate(shop) {
		if (this.plans && !this.plans.includes(shop.plan)) {
			return false;
		}

		if (this.countries && !this.countries.includes(shop.country)) {
			return false;
		}

		return true;
	}
}

module.exports = Requirements;
