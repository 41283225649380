import React from 'react';
import { DropdownItem, DropdownMenu } from '@nearst/ui';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

import { useInsightsDate } from '@services/InsightsDateProvider';
import styles from '../DatePicker.module.scss';

const intervalOptions = [
	{ label: 'Daily', value: 'day' },
	{ label: 'Weekly', value: 'week' },
	{ label: 'Monthly', value: 'month' }
];

const IntervalSelector = () => {
	const { interval, setInterval } = useInsightsDate();
	const selectedOption = intervalOptions.find((option) => option.value === interval);

	return (
		<DropdownMenu
			className={styles.button}
			buttonText={
				<>
					{selectedOption.label}
					<ChevronDownIcon className={styles.icon} />
				</>
			}
		>
			{intervalOptions.map((option) => (
				<DropdownItem key={option.value} active={option.value === interval} onClick={() => setInterval(option.value)}>
					{option.label}
				</DropdownItem>
			))}
		</DropdownMenu>
	);
};

export default IntervalSelector;
