import React from 'react';
import { format, startOfWeek, endOfWeek } from 'date-fns';
import { useAuth } from '@clerk/clerk-react';

import ReportDownloadCard from '@components/ReportDownloadCard';
import { getMultipleInsights, getDailySwisInsights } from '@services/insights';
import { formatPrice } from '@utils/currency';

const formatLocalListingsDailyQuery = (startDate, endDate, ids) => ({
	id: ids,
	table: 'local_listings',
	columns: ['impressions', 'clicks', 'shop_id', 'date'],
	from: format(startDate, 'yyyy-MM-dd'),
	to: format(endDate, 'yyyy-MM-dd')
});

const formatLocalListingsWeeklyQuery = (startDate, endDate, ids) => {
	const fromStartOfWeekStr = format(startOfWeek(startDate, { weekStartsOn: 1 }), 'yyyy-MM-dd');
	const toEndOfWeekStr = format(endOfWeek(endDate, { weekStartsOn: 1 }), 'yyyy-MM-dd');

	return {
		id: ids,
		table: 'local_listings_weekly',
		columns: ['start_date', 'end_date', 'impressions', 'shop_id'],
		filter: [
			['start_date', '>=', fromStartOfWeekStr],
			['end_date', '<=', toEndOfWeekStr]
		],
		sort: [{ column: 'start_date', order: 'desc' }]
	};
};

const formatLocalAdsQuery = (startDate, endDate, ids) => ({
	table: 'local_ads',
	from: format(startDate, 'yyyy-MM-dd'),
	to: format(endDate, 'yyyy-MM-dd'),
	id: ids,
	columns: ['clicks', 'impressions', 'cpc', 'shop_id'],
	sum: ['clicks', 'impressions'],
	avg: ['cpc']
});

const formatGbpQuery = (startDate, endDate, ids) => ({
	table: 'gmb',
	from: format(startDate, 'yyyy-MM-dd'),
	to: format(endDate, 'yyyy-MM-dd'),
	id: ids,
	columns: [
		'website_clicks',
		'phone_clicks',
		'directions_clicks',
		'map_views_desktop',
		'map_views_mobile',
		'search_views_desktop',
		'search_views_mobile',
		'shop_id'
	],
	sum: [
		'map_views_desktop',
		'map_views_mobile',
		'search_views_desktop',
		'search_views_mobile',
		'website_clicks',
		'phone_clicks',
		'directions_clicks'
	]
});

const formatStoreVisitsQuery = (startDate, endDate, ids) => ({
	table: 'local_ads_store_visits',
	from: format(startDate, 'yyyy-MM-dd'),
	to: format(endDate, 'yyyy-MM-dd'),
	id: ids,
	columns: ['store_visits', 'shop_id'],
	sum: ['store_visits']
});

const calculateCtr = (clicks, views) => {
	return (clicks && views ? ((clicks / views) * 100).toFixed(2) : 0) + '%';
};

const formatLocalListingsResponse = (shop, dailyResponse, weeklyResponse, startDate, endDate) => {
	const localListingsDailyCorrected = getDailySwisInsights(startDate, endDate, dailyResponse, weeklyResponse, [shop.id]);
	const websiteViews = localListingsDailyCorrected.reduce((acc, cur) => (acc += cur.impressions), 0);
	const websiteClicks = dailyResponse.filter((item) => item.shop_id === shop.id).reduce((acc, cur) => (acc += cur.clicks), 0);
	const ctr = calculateCtr(websiteClicks, websiteViews);

	return {
		'Local Listings views': websiteViews,
		'Local Listings clicks': websiteClicks,
		'Local Listings CTR': ctr
	};
};

const formatLocalAdsResponse = (shop, responses) => {
	const response = responses.find((response) => shop.id === response.shop_id);
	const cpc = response ? formatPrice(response.cpc, shop.billingCurrency) : 0;
	const ctr = response ? calculateCtr(response.clicks, response.impressions) : 0;

	return {
		'Local Ads impressions': response?.impressions || 0,
		'Local Ads clicks': response?.clicks || 0,
		'Local Ads CTR': ctr,
		'Local Ads CPC': cpc
	};
};

const formatStoreVisitsResponse = (shop, localAds, storeVisits) => {
	const adsResponse = localAds.find((response) => shop.id === response.shop_id);
	const visitResponse = storeVisits.find((response) => shop.id === response.shop_id);

	return {
		'Local Ads store visits': visitResponse?.store_visits ? visitResponse?.store_visits : '',
		'Local Ads estimated footfall': !visitResponse?.store_visits ? Math.floor((adsResponse?.clicks || 0) * 0.13) : ''
	};
};

const formatGbpResponse = (shop, responses) => {
	const response = responses.find((response) => shop.id === response.shop_id);

	return {
		'GBP Search views': response ? response['search_views_desktop'] + response['search_views_mobile'] : 0,
		'GBP Maps views': response ? response['map_views_desktop'] + response['map_views_mobile'] : 0,
		'GBP Directions clicks': response?.['directions_clicks'] || 0,
		'GBP Phone clicks': response?.['phone_clicks'] || 0,
		'GBP Website clicks': response?.['website_clicks'] || 0
	};
};

const getTotalsRow = (rows, shops) => {
	if (!rows.length) return null;

	const totals = {
		'Location name': 'All locations'
	};

	for (const key of Object.keys(rows[0])) {
		if (key === 'Location name') continue;

		const isAverage = key.includes('CPC') || key.includes('CTR');
		const values = rows.map((row) => Number(row[key].toString().replace(/[^\d.]+/g, '')));
		const total = values.reduce((acc, cur) => acc + cur, 0);

		let formattedValue = isAverage ? total / rows.length : total;
		if (key.includes('CPC')) {
			formattedValue = formatPrice(formattedValue, shops[0].billingCurrency);
		}
		if (key.includes('CTR')) {
			formattedValue = formattedValue.toFixed(2) + '%';
		}

		totals[key] = formattedValue;
	}

	return totals;
};

const formatResults = (shops, results, startDate, endDate) => {
	const [localListingsDailyResponse, localListingWeeklyResponse, localAdsResponse, storeVisitsResponse, gbpResponse] = results;

	const rows = shops.map((shop) => ({
		'Location name': shop.name,
		...formatLocalListingsResponse(shop, localListingsDailyResponse, localListingWeeklyResponse, startDate, endDate),
		...formatLocalAdsResponse(shop, localAdsResponse),
		...formatStoreVisitsResponse(shop, localAdsResponse, storeVisitsResponse),
		...formatGbpResponse(shop, gbpResponse)
	}));

	const totals = getTotalsRow(rows, shops);
	if (totals) rows.unshift(totals);

	return rows;
};

const getData = async ({ ids, shops, startDate, endDate }) => {
	const queries = [
		formatLocalListingsDailyQuery(startDate, endDate, ids),
		formatLocalListingsWeeklyQuery(startDate, endDate, ids),
		formatLocalAdsQuery(startDate, endDate, ids),
		formatStoreVisitsQuery(startDate, endDate, ids),
		formatGbpQuery(startDate, endDate, ids)
	];

	const results = await getMultipleInsights(queries);

	return formatResults(shops, results, startDate, endDate);
};

const PerformanceOverview = () => {
	// only show for admins
	const { actor } = useAuth();
	if (!actor) return null;

	return (
		<ReportDownloadCard
			getData={getData}
			title="Performance overview"
			description="This includes all of the analytics data points used in monthly and quarterly reports."
			accessLevel="nearst-admin"
		/>
	);
};

export default PerformanceOverview;
