import React, { forwardRef } from 'react';
import { useFormContext } from 'react-hook-form';
import ErrorMessage from '../ErrorMessage';
import schema from '../../EditForm/schema';
import { InputGroup, TextInput, UItils } from '@nearst/ui';

import styles from '../Fields.module.scss';

const Text = forwardRef(function Text({ name, required = false, ...rest }, ref) {
	const {
		register,
		formState: { errors }
	} = useFormContext();

	const capitalized = name[0].toUpperCase() + name.slice(1);

	return (
		<InputGroup label={capitalized} htmlFor={name} requiredField={required}>
			<>
				<TextInput
					id={name}
					className={UItils.classnames(`${errors?.[name] ? styles.invalid : ''}`)}
					containerClassName={styles.fullWidthInput}
					{...register(name, { required: required ? 'Required' : '', validate: schema[name] })}
					{...rest}
					ref={ref}
				/>
				<ErrorMessage name={name} error={rest.error} />
			</>
		</InputGroup>
	);
});

export default Text;
