import React from 'react';
import { StatusIcon, UItils } from '@nearst/ui';
import * as inventory from '@services/inventory';

import styles from './InventorySourceInformation.module.scss';

const PosStatus = ({ className = '', inventorySources, ...props }) => {
	const status = inventory.getStatus(inventorySources);
	const posLabel = inventory.getPosLabel(inventorySources);
	return (
		<div className={UItils.classnames(styles.label, className)} {...props}>
			<StatusIcon status={status} />
			<p data-cy="connector-status-pos-title">{posLabel}</p>
		</div>
	);
};

const Kind = ({ className = '', inventorySources, ...props }) => {
	const kind = inventory.getKind(inventorySources);
	const kindLabel = kind === 'stock' ? 'Stock data' : 'Product data';

	return (
		<p className={UItils.classnames(styles.kind, className)} {...props}>
			{kindLabel}
		</p>
	);
};

// for chains only!!
const Locations = ({ className = '', locationCount = 1, inventorySources, ...props }) => {
	if (locationCount === 1) return null;
	const locationsConnected = inventory.getRecentlyConnectedLocations(inventorySources).length;
	const locationsRatio = locationsConnected < locationCount ? `${locationsConnected} / ${locationCount}` : locationsConnected;

	return (
		<p className={UItils.classnames(className)} {...props}>
			{locationsRatio} locations connected
		</p>
	);
};

// for indies only!
const UploadStatus = ({ className = '', locationCount = 1, inventorySources, ...props }) => {
	// not for chains
	if (locationCount > 1) return null;
	const { ingestAge } = inventory.getUploadAge(inventorySources);

	return (
		<p className={UItils.classnames(className, styles.latestUpload)} {...props}>
			{inventory.getUploadLabel(ingestAge)}
		</p>
	);
};

const InventorySourceInformation = ({ inventorySources, children }) => {
	if (!inventorySources.length) return null;
	const kind = inventory.getKind(inventorySources);
	if (!kind) return null;
	return <div className={styles.container}>{children}</div>;
};

InventorySourceInformation.PosStatus = PosStatus;
InventorySourceInformation.Kind = Kind;
InventorySourceInformation.Locations = Locations;
InventorySourceInformation.UploadStatus = UploadStatus;

export default InventorySourceInformation;
