import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Icon, UItils } from '@nearst/ui';

import ErrorMessage from '../ErrorMessage';
import schema from '../../EditForm/schema';

import styles from '../Fields.module.scss';

const Image = ({ className, name = 'imageSrc', required = false, ...rest }) => {
	const {
		register,
		formState: { errors },
		getValues,
		setValue,
		trigger
	} = useFormContext();

	const { onChange } = register(name, { required: required ? 'Required' : '', validate: schema[name] });
	const formState = getValues();

	const handleImageUpload = async (e) => {
		const file = e.target.files[0];
		if (!file?.name) {
			return;
		}
		const value = URL.createObjectURL(file);
		setValue(name, value);
		setValue('image', file);
		onChange(e);
		trigger(name);
	};
	const hasImage = formState.imageSrc;
	return (
		<label className={UItils.classnames(styles.imageBorder, errors?.[name] ? styles.invalid : '', className)}>
			{/* pretty div to show the image */}
			<div className={UItils.classnames(styles.imageContainer, errors?.[name] ? styles.invalid : '')}>
				{hasImage ? <img src={formState.imageSrc} alt={formState.title} /> : <Icon>insert_photo</Icon>}
			</div>

			<div className={styles.uploadMessage}>
				<span>{hasImage ? 'Edit image' : 'Upload image'}</span>
				{required ? <span className={styles.required}>*</span> : ''}
				<ErrorMessage name={name} error={rest.error} />
			</div>

			{/* hidden input does the actual functionality */}
			<input
				className={styles.hidden}
				accept="image/*"
				type="file"
				{...rest}
				name={name}
				onChange={handleImageUpload}
				onBlur={() => {}}
			/>
		</label>
	);
};

export default Image;
