import React, { useState } from 'react';

import { Select } from '@nearst/ui';
import { useShop } from '@services/ShopProvider';
import { useOrganisationRoles } from '@services/auth/organisation';
import { useCheckPermission } from '@services/auth/hooks';
import AuthorizationTooltip from '@components/AuthorizationTooltip';

/**
 * Hook to determine if the user can edit roles, based on the current shop's plan
 * and the user's permissions.
 *
 * @returns {{canEditRoles: boolean, hasAdvancedPlan: boolean}}
 */
export const useCanEditRoles = () => {
	const { data } = useShop();
	const hasEditPermission = useCheckPermission('org:user_permissions:manage');
	const hasAdvancedPlan = data?.find((shop) => shop.plan === 'advanced' || shop.plan === 'enterprise');
	const canEditRoles = hasAdvancedPlan && hasEditPermission;

	return { canEditRoles, hasAdvancedPlan };
};

/**
 * Role selector
 *
 * Loads roles from Clerk for the current organisation and allows the user to
 * select one if the user has permission to change roles and the shop is on
 * an advanced or enterprise plan.
 *
 * @typedef RoleSelectProps
 * @property {string} role The currently selected role key
 * @property {boolean} [disabled=false] Whether the select is disabled
 * @property {(role: string) => Promise<void> | void} onChange Callback when the role is changed
 *
 * @param {RoleSelectProps & React.HTMLProps<HTMLSelectElement>} props
 * @returns JSX.Element
 */
const RoleSelect = ({ role, disabled = false, onChange, ...props }) => {
	const roles = useOrganisationRoles();
	const [loading, setLoading] = useState(false);
	const { canEditRoles, hasAdvancedPlan } = useCanEditRoles();

	const updateRole = async (role) => {
		setLoading(true);
		await onChange(role);
		setLoading(false);
	};

	return (
		<AuthorizationTooltip
			authorized={canEditRoles}
			tooltipContent={!hasAdvancedPlan ? 'You need to upgrade to use this feature' : undefined}
		>
			<Select
				{...props}
				title="User role in the organisation"
				value={role}
				disabled={!canEditRoles || loading || disabled}
				onChange={(e) => updateRole(e.target.value)}
				data-cy="role-select"
			>
				{roles.map((role) => (
					<option key={role.key} value={role.key} title={role.description}>
						{role.name}
					</option>
				))}
			</Select>
		</AuthorizationTooltip>
	);
};

export default RoleSelect;
