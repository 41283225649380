import React from 'react';
import { format } from 'date-fns';

import styles from './Tooltip.module.scss';

const getLabelColor = (item) => {
	const { stroke } = item;

	// one colour we use in the GBP graph is too dark - fall back to a lighter colour
	if (stroke === '#102f52') return '#ddd';

	return stroke;
};

const Tooltip = ({ active, payload, label, contentStyle }) => {
	if (!active || !payload || !payload.length) {
		return null;
	}

	return (
		<TooltipContainer contentStyle={contentStyle}>
			{label && (
				<p>
					<strong>{label instanceof Date ? format(new Date(label), 'MMM d') : label.toString()}</strong>
				</p>
			)}
			{payload.map((item) => (
				<p style={{ color: getLabelColor(item) }} key={item.name}>
					{item.name}: {item.value.toLocaleString()}
				</p>
			))}
		</TooltipContainer>
	);
};

export const TooltipContainer = ({ children, contentStyle = {} }) => {
	return (
		<div className={styles.tooltip} style={contentStyle} role="tooltip">
			{children}
		</div>
	);
};

export default Tooltip;
