import React from 'react';
import { Outlet } from 'react-router-dom';
import { Protect } from '@clerk/clerk-react';

import { Card, Page } from '@nearst/ui';

import UpgradeNavigation from './components/UpgradeNavigation';
import UpgradeContentContainer from './components/UpgradeContentContainer';
import UpgradeReviewPlan from './routes/UpgradeReviewPlan';
import UpgradeConfirm from './routes/UpgradeConfirm';
import PaymentStep from './routes/UpgradePaymentInformation';

import AccessDenied from '../errors/AccessDenied';
import { getShopData } from '@services/ShopProvider';
import { fetchPlans } from '@services/UpgradeProvider';

export const UpgradeLayout = () => {
	return (
		<Protect permission="org:billing:manage" fallback={<AccessDenied />}>
			<Page.Header>
				<h1>Upgrade plan</h1>
				<p>Unlock new features by upgrading your plan.</p>
			</Page.Header>
			<Page.Section>
				<Card sectioned>
					<UpgradeNavigation />
					<UpgradeContentContainer>
						<Outlet />
					</UpgradeContentContainer>
				</Card>
			</Page.Section>
		</Protect>
	);
};

export const plansLoader = async ({ params }) => {
	const shops = await getShopData();
	const currency = shops.find((s) => s.billingCurrency)?.billingCurrency || 'USD';
	const pricing = await fetchPlans({ currency, plan: params.productId, orgSize: shops.length });

	return { pricing };
};

export const upgradeRoutes = [
	{ path: 'confirm', element: <UpgradeConfirm /> },
	{ path: 'payment', element: <PaymentStep /> },
	{ index: true, element: <UpgradeReviewPlan /> }
];
