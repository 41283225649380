import React from 'react';
import * as Chart from 'recharts';

import Tooltip from './Tooltip';
import { pieChartColorPalette } from './dataVisualization';
import styles from '../ComparisonCard.module.scss';

const ComparisonPieChart = ({ data, total }) => {
	return (
		<div className={styles.chartContainer}>
			<div className={styles.chartTotal}>{total}</div>
			<Chart.ResponsiveContainer width="100%" height={600}>
				<Chart.PieChart margin={{ top: 48, left: 32, right: 32, bottom: 48 }}>
					<Chart.Pie
						isAnimationActive={false}
						dataKey="value"
						nameKey="shopId"
						innerRadius={100}
						data={data}
						label={({ shopName }) => shopName}
					>
						{data.map((_, index) => (
							<Chart.Cell key={`cell-${index}`} fill={pieChartColorPalette[index % pieChartColorPalette.length]} />
						))}
					</Chart.Pie>
					<Chart.Tooltip content={Tooltip} />
				</Chart.PieChart>
			</Chart.ResponsiveContainer>
		</div>
	);
};

export default ComparisonPieChart;
