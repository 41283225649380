import React, { useEffect, useMemo, useState } from 'react';
import { Cluster, DropdownItem, DropdownMenu } from '@nearst/ui';
import * as Stats from '@components/StatsCard';
import ChartTypeSelector from './lib/ChartTypeSelector';
import ChartRenderer from './lib/ChartRenderer';
import { useShop } from '@services/ShopProvider';
import LocationFilter from '../LocationFilter';
import { formatDimensionValue, getDimensionTotal, getTopLocations } from './lib/dataVisualization';
import useChartOptionTracking from './lib/useChartOptionTracking';
import styles from './ComparisonCard.module.scss';

/**
 * Comparison insights card, allowing retailers to compare their locations across different dimensions.
 *
 * Example usage:
 *
 * ```jsx
 * <ComparisonCard
 * 	dimensions={[
 * 		{
 * 			id: 'avg-temp',
 * 			label: 'Average temperature'
 * 		}
 * 	]}
 * 	query={({shopIds, dimension}) => ([{shopId: '', value: 0}, ...])}
 * />
 * ```
 *
 * @typedef {Object} Dimension
 * @property {string} id - A unique ID for the dimension
 * @property {string} label - The label to display in the dropdown menu
 * @property {string} [summaryType='sum'] - Method of calculating the total, either 'sum' or 'avg' (default: 'sum')
 * @property {string|React.ReactNode} [description] - A description of the dimension shown below the dropdown menu
 * @property {string} [valuePrefix] - A prefix to add to the value in the tooltip
 * @property {string} [valueSuffix] - A suffix to append to the value in the tooltip
 * @property {number} [digits] - The number of digits to show for this value (default: 0)
 *
 * @typedef {Object} Props
 * @property {Dimension[]} dimensions - The dimensions to display in the dropdown menu
 * @property {({dimension, shopIds}) => Promise<{shopId: string, value: number}[]>} [query] - Query function
 * @property {string} [subtitle] - The subtitle to display in the chart header
 *
 * @param {Props} props
 */
const ComparisonCard = ({ dimensions, query, subtitle }) => {
	const { data: shops } = useShop();
	const [data, setData] = useState();
	const [selectedDimension, setSelectedDimension] = useState(dimensions[0].id);
	const [selectedShops, setSelectedShops] = useState(shops.map((shop) => shop.id));
	const [chartType, setChartType] = useState('pie');
	useChartOptionTracking(shops[0].pk, 'dimension', selectedDimension);
	useChartOptionTracking(shops[0].pk, 'chart type', chartType);
	useChartOptionTracking(shops[0].pk, 'locations', selectedShops);

	const dimension = useMemo(() => dimensions.find((dimension) => dimension.id === selectedDimension), [dimensions, selectedDimension]);
	const total = useMemo(() => formatDimensionValue(getDimensionTotal(data, dimension), dimension), [data, dimension]);

	useEffect(() => {
		const handleDataUpdate = async () => {
			setData(null);
			const data = await query({
				shopIds: selectedShops,
				dimension: selectedDimension
			});
			setData(getTopLocations(data, dimension, shops));
		};

		handleDataUpdate();
	}, [dimension, selectedDimension, selectedShops, shops, query]);

	return (
		<Stats.Card className={styles.card}>
			<div className={styles.content}>
				<div>
					<Stats.Header className={styles.header}>
						<div>
							<Stats.Title>Compare locations</Stats.Title>
							<Stats.Subtitle>{subtitle}</Stats.Subtitle>
						</div>
					</Stats.Header>

					<Cluster space="1rem">
						<DropdownMenu primary={false} buttonText={dimension.label}>
							{dimensions.map((dim) => (
								<DropdownItem active={dim.id === dimension.id} key={dim.id} onClick={() => setSelectedDimension(dim.id)}>
									{dim.label}
								</DropdownItem>
							))}
						</DropdownMenu>
						<ChartTypeSelector chartType={chartType} setChartType={setChartType} />

						<LocationFilter shops={shops} selectedShops={selectedShops} setSelectedShops={setSelectedShops} />
					</Cluster>

					<p>{dimension.description}</p>
				</div>

				<div className={styles.chartContainer}>
					<ChartRenderer data={data} total={total} chartType={chartType} dimension={dimension} />
				</div>
			</div>
		</Stats.Card>
	);
};

export default ComparisonCard;
