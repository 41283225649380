import React, { useState, useEffect } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { useFormContext } from 'react-hook-form';

import { countryValidation, setValues, parseOpeningHours, getGooglePlaceDetails } from '../../../utils';
import schema from '../../../schema';

import styles from './AutoComplete.module.scss';

const Autocomplete = ({ setStage }) => {
	const methods = useFormContext();

	const {
		reset,
		setValue,
		register,
		formState: { errors }
	} = methods;

	const [place, setPlace] = useState();
	const setEmptyAddress = () => {
		reset();
		setStage('edit_address');
	};

	useEffect(() => {
		if (place) {
			const { value } = place;
			getGooglePlaceDetails(value.place_id).then((placeDetails) => {
				const companyName = (placeDetails.name || value.structured_formatting.main_text).replace(/(Ltd\.?|B\.?V\.?)\s+?$/, '');
				const addressParts = placeDetails.formatted_address.split(', ');

				const components = placeDetails.address_components;
				const getComponentsString = (chosenTypes, short = false) =>
					components
						.filter(({ types }) => types.some((type) => chosenTypes.includes(type)))
						.map(({ long_name, short_name }) => (short ? short_name : long_name))
						.join(' ');
				const addressLine1 = addressParts[0] || getComponentsString(['street_number', 'street', 'street_address', 'route']);
				const postcode = getComponentsString(['postal_code']);
				const addressLine2 = addressParts.length > 1 ? addressParts[1].split(' ' + postcode)[0] : undefined;
				const geolocation = { lat: placeDetails.geometry.location.lat(), lng: placeDetails.geometry.location.lng() };

				const details = {
					name: companyName,
					addressLine1,
					addressLine2,
					postcode,
					city: getComponentsString(['postal_town', 'locality']), // TODO
					publicPhoneNumber: placeDetails.formatted_phone_number,
					country: countryValidation(getComponentsString(['country'], true)),
					googlePlaceId: value.place_id,
					types: placeDetails.types,
					contactPhone: placeDetails.formatted_phone_number,
					openingHours: parseOpeningHours(placeDetails.opening_hours),
					geolocation
				};

				setStage('address_complete');

				// register the fields first so that validation works
				Object.keys(details).forEach((fieldName) => register(fieldName, { validate: schema[fieldName] }));

				// if we're missing something, open the form so user can fix it
				if (Object.keys(errors).length) {
					setStage('autocomplete_error');
				} else {
					setValues(details, setValue);
				}
			});
		}
	}, [place, errors, register, setValue, setStage]);

	return (
		<>
			<div id="google-places-autocomplete-helper" />
			<label className={styles.label} htmlFor="places-autocomplete">
				Store name
			</label>
			<GooglePlacesAutocomplete
				apiKey="AIzaSyBrgpv8pbX_2Y8MnAKlmndOXar_QvKxG9M"
				autocompletionRequest={{
					types: ['establishment']
				}}
				// debounce={500}
				selectProps={{
					id: 'places-autocomplete',
					placeholder: 'Find your store...',
					styles: {
						control: (provided) => ({
							...provided,
							borderRadius: 0
						})
					},
					className: styles.placesAutoComplete,
					components: {
						ClearIndicator: () => null,
						IndicatorSeparator: () => null,
						DropdownIndicator: () => null
					},
					value: place,
					onChange: setPlace,
					autoFocus: true
				}}
			/>
			<div className={styles.right}>
				<button className={styles.manual} data-cy="location-manual" onClick={setEmptyAddress}>
					Enter address manually
				</button>
			</div>
		</>
	);
};

export default Autocomplete;
