import { eachDayOfInterval, format } from 'date-fns';
import Decimal from 'decimal.js';

/**
 * Calculate day-by-day ad spend for a given period.
 *
 * @param {object[]} plans
 * @param {Date|string} startDate
 * @param {Date|string} endDate
 * @param {object[]} shops
 * @returns {{ date: string, budget: number, shopIds: Set<string>, perShop: object }[]}
 */
export const getDailyAdsSpend = (plans, startDate, endDate, shops) => {
	const sortedPlans = plans.sort((a, b) => (a.date < b.date ? 1 : -1));

	const formatDate = (date) => format(date, 'yyyy-MM-dd');
	const days = eachDayOfInterval({
		start: new Date(startDate),
		end: new Date(endDate)
	});

	const dailyBudget = [];
	for (const day of days) {
		let budget = new Decimal(0);
		let shopIds = new Set();
		const perShop = {};
		for (const shop of shops) {
			const addOnPlan = sortedPlans.find((plan) => plan.shop === shop.id && plan.date <= formatDate(day) && !plan.prepaid);
			const prepaidPlan = sortedPlans.find((plan) => plan.shop === shop.id && plan.date <= formatDate(day) && plan.prepaid);

			if (addOnPlan?.budget) budget = budget.plus(addOnPlan.budget);
			if (prepaidPlan?.budget) budget = budget.plus(prepaidPlan.budget);

			if (addOnPlan?.budget || prepaidPlan?.budget) {
				shopIds.add(shop.id);
				perShop[shop.id] = new Decimal(addOnPlan?.budget || 0).plus(prepaidPlan?.budget || 0).toNumber();
			}
		}
		dailyBudget.push({ date: formatDate(day), budget: budget.toNumber(), shopIds, perShop });
	}

	return dailyBudget;
};

/**
 * Calculate total ads spend for a given period, across all shops.
 *
 * @param {object[]} plans
 * @param {Date|string} startDate
 * @param {Date|string} endDate
 * @param {object[]} shops
 * @param {string[]|null} [shopIdsToInclude]
 * @returns {{ spend: number, shopsWithLocalAds: Set<string> }}
 */
export const getTotalAdsSpend = (plans, startDate, endDate, shops, shopIdsToInclude = null) => {
	const dailyAdSpend = getDailyAdsSpend(plans, startDate, endDate, shops);

	let spend = new Decimal(0);
	let shopsWithLocalAds = new Set();
	for (const day of dailyAdSpend) {
		day.shopIds.forEach((shopId) => shopsWithLocalAds.add(shopId));
		if (!shopIdsToInclude) {
			spend = spend.plus(day.budget);
		} else {
			for (const [shopId, budget] of Object.entries(day.perShop)) {
				if (shopIdsToInclude.includes(shopId)) {
					spend = spend.plus(budget);
				}
			}
		}
	}

	return {
		spend: spend.toNumber(),
		shopsWithLocalAds
	};
};
