import { addHours, format, startOfYesterday, subDays, startOfWeek, endOfWeek } from 'date-fns';
import { getMultipleInsights, getDailySwisInsights } from '@services/insights';

export async function getCombinedInsights(shopIds, endDate, startDate) {
	if (!endDate) endDate = addHours(startOfYesterday(), 6);
	if (!startDate) startDate = subDays(endDate, 30);
	const fromStartOfWeekStr = format(startOfWeek(startDate, { weekStartsOn: 1 }), 'yyyy-MM-dd');
	const toEndOfWeekStr = format(endOfWeek(endDate, { weekStartsOn: 1 }), 'yyyy-MM-dd');

	const previousPeriodStartDate = subDays(startDate, 31);
	const previousPeriodEndDate = subDays(endDate, 31);
	const fromStartOfWeekStrPrevious = format(startOfWeek(previousPeriodStartDate, { weekStartsOn: 1 }), 'yyyy-MM-dd');
	const toEndOfWeekStrPrevious = format(endOfWeek(previousPeriodEndDate, { weekStartsOn: 1 }), 'yyyy-MM-dd');

	const queries = [
		{
			id: shopIds,
			table: 'local_listings',
			columns: ['impressions', 'clicks', 'shop_id', 'date'],
			from: format(startDate, 'yyyy-MM-dd'),
			to: format(endDate, 'yyyy-MM-dd')
		},
		{
			id: shopIds,
			table: 'local_listings_weekly',
			columns: ['start_date', 'end_date', 'impressions', 'shop_id'],
			filter: [
				['start_date', '>=', fromStartOfWeekStr],
				['end_date', '<=', toEndOfWeekStr]
			],
			sort: [{ column: 'start_date', order: 'desc' }]
		},
		{
			id: shopIds,
			table: 'local_ads',
			columns: ['impressions', 'clicks', 'shop_id'],
			from: format(startDate, 'yyyy-MM-dd'),
			to: format(endDate, 'yyyy-MM-dd'),
			sum: ['impressions', 'clicks']
		}
	];

	const previousPeriodQueries = [
		{
			id: shopIds,
			table: 'local_listings',
			columns: ['impressions', 'clicks', 'shop_id', 'date'],
			from: format(previousPeriodStartDate, 'yyyy-MM-dd'),
			to: format(previousPeriodEndDate, 'yyyy-MM-dd')
		},
		{
			id: shopIds,
			table: 'local_listings_weekly',
			columns: ['start_date', 'end_date', 'impressions', 'shop_id'],
			filter: [
				['start_date', '>=', fromStartOfWeekStrPrevious],
				['end_date', '<=', toEndOfWeekStrPrevious]
			],
			sort: [{ column: 'start_date', order: 'desc' }]
		},
		{
			id: shopIds,
			table: 'local_ads',
			columns: ['impressions', 'clicks', 'shop_id'],
			from: format(previousPeriodStartDate, 'yyyy-MM-dd'),
			to: format(previousPeriodEndDate, 'yyyy-MM-dd'),
			sum: ['impressions', 'clicks']
		}
	];

	const results = await getMultipleInsights(queries);
	const previousResults = await getMultipleInsights(previousPeriodQueries);

	const [dailySwis, weeklySwis, lia] = results;
	const [previousDailySwis, previousWeeklySwis, previousLia] = previousResults;

	let swisDailyImpressionsCorrected;
	if (dailySwis && weeklySwis) swisDailyImpressionsCorrected = getDailySwisInsights(startDate, endDate, dailySwis, weeklySwis, shopIds);

	let previousSwisDailyCorrected;
	if (previousDailySwis && previousWeeklySwis)
		previousSwisDailyCorrected = getDailySwisInsights(
			previousPeriodStartDate,
			previousPeriodEndDate,
			previousDailySwis,
			previousWeeklySwis,
			shopIds
		);

	return { swisDailyImpressionsCorrected, dailySwis, lia, previousSwisDailyCorrected, previousDailySwis, previousLia };
}

export function formatInsights(shopId, insights) {
	// impressions:
	const swisImpressions = insights.swisDailyImpressionsCorrected
		?.filter((dayResult) => dayResult.shopId === shopId)
		.reduce((acc, cur) => (acc += cur.impressions), 0);
	const liaImpressions = insights.lia?.filter((liaResult) => liaResult['shop_id'] === shopId)[0]?.impressions || 0;
	const previousSwisImpressions = insights.previousSwisDailyCorrected
		?.filter((dayResult) => dayResult.shopId === shopId)
		.reduce((acc, cur) => (acc += cur.impressions), 0);
	const previousLiaImpressions = insights.previousLia?.filter((liaResult) => liaResult['shop_id'] === shopId)[0]?.impressions || 0;

	// clicks:
	const swisClicks = insights.dailySwis?.filter((result) => result['shop_id'] === shopId).reduce((acc, cur) => (acc += cur.clicks), 0);
	const liaClicks = insights.lia?.filter((liaResult) => liaResult['shop_id'] === shopId)[0]?.clicks || 0;
	const previousSwisClicks = insights.previousDailySwis
		?.filter((result) => result['shop_id'] === shopId)
		.reduce((acc, cur) => (acc += cur.clicks), 0);
	const previousLiaClicks = insights.previousLia?.filter((liaResult) => liaResult['shop_id'] === shopId)[0]?.clicks || 0;

	return {
		currentImpressions: swisImpressions + liaImpressions,
		previousImpressions: previousSwisImpressions + previousLiaImpressions,
		currentClicks: swisClicks + liaClicks,
		previousClicks: previousSwisClicks + previousLiaClicks
	};
}
