import React from 'react';
import Props from 'prop-types';

import './style.scss';

/**
 * Display an icon.
 *
 * @typedef IconProps
 * @property {string} children The icon name - see https://material.io/icons
 * @property {string} [color] The color of the icon
 * @property {string} [className] Additional classes
 * @property {object} [style] Additional styles
 * @property {import('react').MouseEventHandler<HTMLElement>} [onClick] Click event handler
 *
 * @param {IconProps} props
 * @returns JSX.Element
 */
const Icon = ({ color, className, onClick, children, style, ...rest }) => {
	style = { color, ...style };
	const classes = 'material-icons' + (className ? ' ' + className : '');

	return (
		<i {...rest} className={classes} data-icon={children} style={style} onClick={onClick}>
			{children}
		</i>
	);
};

Icon.propTypes = {
	children: Props.string.isRequired,
	color: Props.string,
	className: Props.string,
	style: Props.object,
	onClick: Props.func,
	rest: Props.object
};

export default Icon;
