import Tippy from '@tippyjs/react';
import React from 'react';

import { ActualButton as Button } from '@nearst/ui';

import { useCheckPermission } from '@services/auth/hooks';
import AuthorizationTooltip from '@components/AuthorizationTooltip';

import styles from './OrderActions.module.scss';

const OrderActionButton = ({ tooltip, children, ...props }) => {
	const authorized = useCheckPermission('org:orders:manage');

	if (!authorized) {
		return (
			<AuthorizationTooltip authorized={authorized}>
				<Button className={styles.actionBtn} disabled>
					{children}
				</Button>
			</AuthorizationTooltip>
		);
	}

	return (
		<Tippy content={tooltip}>
			<span>
				<Button className={styles.actionBtn} {...props}>
					{children}
				</Button>
			</span>
		</Tippy>
	);
};

const OrderActions = ({ order, handleUpdate }) => {
	switch (order.status) {
		case 'paid':
		case 'reserved':
			return (
				<div className={styles.buttonContainer}>
					<OrderActionButton
						tooltip="Confirm item is set aside for customer"
						primary
						onClick={(e) => {
							e.stopPropagation();
							handleUpdate({
								...order,
								status: 'confirmed'
							});
						}}
					>
						Confirm
					</OrderActionButton>
					<OrderActionButton
						tooltip={order.status === 'reserved' ? 'Cancel order' : 'Cancel order and issue refund'}
						onClick={(e) => {
							e.stopPropagation();
							handleUpdate({
								...order,
								status: 'cancelled'
							});
						}}
					>
						Cancel
					</OrderActionButton>
				</div>
			);
		case 'confirmed':
			return (
				<div className={styles.buttonContainer}>
					<OrderActionButton
						tooltip="Mark order as collected by customer"
						primary
						onClick={(e) => {
							e.stopPropagation();
							handleUpdate({
								...order,
								status: 'completed'
							});
						}}
					>
						Collected
					</OrderActionButton>
					<OrderActionButton
						tooltip="Cancel order and issue refund"
						onClick={(e) => {
							e.stopPropagation();
							handleUpdate({
								...order,
								status: 'cancelled'
							});
						}}
					>
						Cancel
					</OrderActionButton>
				</div>
			);
		case 'completed':
			return null;
		case 'cancelled':
			return null;
		case 'refunded':
			return null;
		case 'refund_failed':
			return <p className={styles.csAction}>Contact Customer Success</p>;
		default:
			return null;
	}
};

export default OrderActions;
