import React from 'react';
import { Protect } from '@clerk/clerk-react';

import { FallbackWithShopId } from '@utils/RedirectWithShopId';

import LocalAdsChecker from '@components/LocalAdsChecker';

import LocalAdsOverview from './LocalAdsOverview';
import AccessDenied from '../errors/AccessDenied';

export const SubscriptionRouter = () => {
	return (
		<FallbackWithShopId>
			<Protect permission="org:channels_lia:manage" fallback={<AccessDenied />}>
				<LocalAdsChecker>
					<LocalAdsOverview />
				</LocalAdsChecker>
			</Protect>
		</FallbackWithShopId>
	);
};
