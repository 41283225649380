import React from 'react';
import * as Chart from 'recharts';
import { format, isToday } from 'date-fns';

import styles from '../../InventoryConnection.module.scss';

import { UItils } from '@nearst/ui';
const Tooltip = ({ active, payload, contentStyle }) => {
	if (active && payload && payload.length) {
		return (
			<div style={contentStyle} className={styles.tooltip}>
				<p>{format(new Date(payload[0].payload.date), 'MMM d')}</p>
				{payload[0].payload.status.total ? (
					<>
						<p>
							<b>In Stock Barcodes</b>: {(payload?.[0]?.payload?.in_stock_barcodes || 0).toLocaleString()}
						</p>
						<p>
							<b>Total Barcodes</b>: {(payload?.[0]?.payload?.total_barcodes || 0).toLocaleString()}
						</p>

						<p>
							<b>Status</b>:{' '}
							{[
								payload[0].payload.status.connected > 0 && payload[0].payload.status.connected + ' connected',
								payload[0].payload.status.disconnected > 0 && payload[0].payload.status.disconnected + ' disconnected'
							]
								.filter(Boolean)
								.join(', ')}
						</p>
					</>
				) : (
					<p>No data{isToday(new Date(payload[0].payload.date)) ? ' yet today' : ''}</p>
				)}
			</div>
		);
	}
	return null;
};

const InventoryGraph = ({ chartData }) => {
	return (
		<Chart.ResponsiveContainer width="100%" height={64}>
			<Chart.BarChart
				aria-roledescription="Stacked area chart"
				style={{
					fontSize: '0.8rem',
					fontFamily: "'nearst-body', -apple-system, 'Helvetica Neue', 'Arial', sans-serif"
				}}
				margin={{ top: 0, right: -2, bottom: 16, left: -2 }}
				data={chartData}
			>
				<Chart.XAxis
					aria-roledescription="axis"
					aria-orientation="horizontal"
					aria-label="date"
					allowDuplicatedCategory
					dataKey="xLabel"
					ticks={[chartData[0].xLabel, chartData[chartData.length - 1].xLabel]}
					interval="preserveStartEnd"
					tickLine={false}
					axisLine={false}
				/>
				<Chart.YAxis
					dataKey="yData"
					aria-roledescription="axis"
					aria-orientation="vertical"
					tickFormatter={(value) => value.toLocaleString()}
					hide
				></Chart.YAxis>

				<Chart.Tooltip cursor={{ fill: 'transparent' }} content={Tooltip} />

				<Chart.Bar
					background={false}
					type="monotone"
					strokeWidth={3}
					dot={false}
					dataKey="yData"
					fillOpacity={1}
					aria-label="In Stock Barcodes"
				>
					{chartData.map((entry) => {
						return <Chart.Cell className={UItils.classnames(styles.bar, styles[entry.color])} key={entry.date} />;
					})}
				</Chart.Bar>
			</Chart.BarChart>
		</Chart.ResponsiveContainer>
	);
};

export default InventoryGraph;
