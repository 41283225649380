const Categories = require('../model/Categories');
const ChannelDefinition = require('../model/ChannelDefinition');
const Requirements = require('../model/Requirements');

class GoogleLocalListingsChannel extends ChannelDefinition {
	id = 'google';
	title = 'Google Local Listings';
	description = `Drive organic growth by offering full visibility of your in-store product range across Google surfaces.`;
	category = Categories.DISCOVERY_CHANNELS;

	iconUrl = 'https://go.near.st/hubfs/channels/google.svg';
	coverImageUrl = 'https://go.near.st/hubfs/channels/headers/google-local-listings.png';

	longDescription = `
## Overview
Making local inventory data visible online will boost online visibility and discoverability for your stores, driving footfall from local shoppers.

Google Local Listings show products you stock for relevant search terms to nearby shoppers. These are organic results, shown in Google See What’s In Store, Search, Images, Maps, and Shopping.

## How it works
NearSt automatically manages and optimises your product catalog, account setup and targeting for your product listings. Once you enable Google Local Listings, your products will start showing up for local queries in Google within a few days.

## Requirements
- At least 50 in-stock products
- Provide Google Business Profile access
- Have physical store locations in one of the [supported countries](https://support.google.com/merchants/answer/9825611?hl=en-GB#:~:text=types-,Availability)
	`;

	productSpecificVisibility = true;
	isAvailableInPartnerApi = true;

	worksWith = ['local-ads', 'product-reservations', 'local-checkout'];

	helpArticleUrls = [
		{
			title: 'Google Local Listings documentation',
			url: 'https://support.near.st/en/collections/3685453-google-see-what-s-in-store'
		}
	];

	requirements = new Requirements({
		countries: [
			'AU',
			'AT',
			'BE',
			'CA',
			'CZ',
			'DK',
			'FI',
			'FR',
			'DE',
			'GR',
			'HK',
			'HU',
			'IN',
			'IE',
			'IL',
			'IT',
			'JP',
			'MX',
			'NL',
			'NZ',
			'NO',
			'PL',
			'PT',
			'KR',
			'RO',
			'SA',
			'SG',
			'SK',
			'ES',
			'SE',
			'CH',
			'TW',
			'TR',
			'GB',
			'US',
			'AE',
			'UA',
			'VN'
		]
	});
}

module.exports = GoogleLocalListingsChannel;
