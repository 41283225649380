import React, { useMemo } from 'react';
import { Switcher } from '@nearst/ui';

import { useShop } from '@services/ShopProvider';
import { useInsightsDate } from '@services/InsightsDateProvider';
import * as Stats from '../../../../../components/StatsCard';

import styles from './Figures.module.scss';
import { getTotalAdsSpend } from '../../../../../utils/localAdsPlans';
import Skeleton from '@components/Skeleton';

const Figures = ({ liaCurrentPeriod, liaPreviousPeriod, showSpend, plans }) => {
	const { data: shops } = useShop();
	const { startDate, endDate } = useInsightsDate();
	const budget = useMemo(() => plans && getTotalAdsSpend(plans, startDate, endDate, shops), [plans, startDate, endDate, shops]);

	const { clicks, impressions } = liaCurrentPeriod.reduce(
		(acc, cur) => {
			acc.clicks += cur.clicks;
			acc.impressions += cur.impressions;
			return acc;
		},
		{ clicks: 0, impressions: 0 }
	);
	const { previousClicks, previousImpressions } = liaPreviousPeriod.reduce(
		(acc, cur) => {
			acc.previousClicks += cur.clicks;
			acc.previousImpressions += cur.impressions;
			return acc;
		},
		{
			previousClicks: 0,
			previousImpressions: 0
		}
	);

	const ctr = !clicks || !impressions ? 0 : ((clicks / impressions) * 100).toFixed(2);
	const previousCtr = !previousClicks || !previousImpressions ? 0 : ((previousClicks / previousImpressions) * 100).toFixed(2);

	return (
		<Switcher space="2rem" className={styles.stats}>
			<Stats.Block description="Impressions" currentValue={Number(impressions)} previousValue={Number(previousImpressions)} />
			<Stats.Block description="Clicks" currentValue={Number(clicks)} previousValue={Number(previousClicks)} />

			<Stats.Figure>
				{ctr}%
				<Stats.FigureDescription>
					CTR
					<Stats.Tooltip>
						Your click-through rate is a measure of how relevant your products are to the search terms
					</Stats.Tooltip>
					<Stats.PercentageChange previousValue={previousCtr} currentValue={ctr} />
				</Stats.FigureDescription>
			</Stats.Figure>

			{showSpend && (
				<Stats.Figure>
					{budget ? (
						budget.spend.toLocaleString('en', { style: 'currency', currency: shops[0].billingCurrency })
					) : (
						<Skeleton width={100} height={32} />
					)}
					<Stats.FigureDescription>
						Ad spend
						<Stats.Tooltip>
							The budget spent for the selected period across {budget.shopsWithLocalAds.size} locations with Local Ads
						</Stats.Tooltip>
					</Stats.FigureDescription>
				</Stats.Figure>
			)}
		</Switcher>
	);
};

export default Figures;
