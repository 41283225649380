import React, { createContext, useContext, useState, lazy } from 'react';

import { listPlans } from './billing';
import { useShop } from './ShopProvider';
import { parseCurrency } from '@utils/currency';

const UpgradeModal = lazy(() => import('@components/Modals/Upgrade/FreemiumUpgradeModal'));

const store = createContext();

// lite price in stripe
const litePlanProductId = import.meta.env.VITE_LITE_PLAN_PRODUCT_ID;

const useUpgradeModal = () => useContext(store);
const { Provider } = store;

const fetchPlans = async ({ currency, plan, orgSize }) => {
	let plans;
	try {
		plans = await listPlans({ currency, plan });
		if (!plans.length) {
			throw Error(`No plans found for currency: ${currency}`);
		}
	} catch (e) {
		console.error(e);
		plans = await listPlans({ currency: 'USD', plan });
	}

	if (plans) {
		const annualPlan = plans?.find((plan) => plan.recurring.interval === 'year');
		const monthlyPlan = plans?.find((plan) => plan.recurring.interval === 'month');
		const monthlyPricePerShop = parseCurrency(monthlyPlan.unit_amount, { currency: monthlyPlan.currency }).divide(100);
		const totalMonthlyPrice = monthlyPricePerShop.multiply(orgSize);
		const annualPricePerShop = parseCurrency(annualPlan.unit_amount, { currency: annualPlan.currency }).divide(100);
		const totalAnnualPrice = annualPricePerShop.multiply(orgSize);
		const originalPrice = monthlyPricePerShop.multiply(12);
		const annualDiscount = originalPrice.subtract(annualPricePerShop).divide(originalPrice).multiply(100);

		const prices = {
			title: annualPlan.metadata?.title,
			description: annualPlan.metadata?.description,
			product: annualPlan.product,
			annually: {
				price: annualPricePerShop.format(),
				totalPrice: totalAnnualPrice.format(),
				discount: annualDiscount.value,
				originalPrice: originalPrice.format(),
				id: annualPlan.id,
				billingCycle: 'annually'
			},
			monthly: {
				price: monthlyPricePerShop.format(),
				totalPrice: totalMonthlyPrice.format(),
				id: monthlyPlan.id,
				billingCycle: 'monthly'
			}
		};
		return prices;
	}
};

const UpgradeModalProvider = ({ children, defaultOpen = false }) => {
	const [isOpen, setIsOpen] = useState(defaultOpen);
	const { data: shops, shop } = useShop();

	// cope with chains here - find first shop with currency attribute
	const currency = shop ? shop.billingCurrency : shops.find((s) => s.billingCurrency);

	const openUpgradeModal = () => setIsOpen(true);
	const closeUpgradeModal = () => setIsOpen(false);
	return (
		<Provider
			value={{
				openUpgradeModal,
				closeUpgradeModal,
				isOpen,
				currency,
				fetchPrices: () => fetchPlans({ currency, plan: litePlanProductId, orgSize: shops.length })
			}}
		>
			<UpgradeModal />
			{children}
		</Provider>
	);
};

export { UpgradeModalProvider, useUpgradeModal, fetchPlans };
