import React from 'react';
import PropTypes from 'prop-types';
import { classnames } from '../../utils';

import styles from './Button.module.scss';

const Button = ({
	className = '',
	success = false,
	warning = false,
	as = null,
	href = undefined,
	onClick = undefined,
	small = false,
	mini = false,
	primary = false,
	secondary = false,
	link = false,
	disabled = false,
	confirm = false,
	children,
	...props
}) => {
	props = {
		...props,
		disabled,
		onClick,
		className: classnames(
			styles.btn,
			primary && styles.primary,
			secondary && styles.secondary,
			link && styles.link,
			(success || confirm) && styles.success,
			warning && styles.warning,
			small && styles.small,
			mini && styles.mini,
			disabled && styles.disabled,
			className
		)
	};

	if (as) {
		return React.createElement(as, props, children);
	}

	if (href || props.to) {
		return (
			<a href={href || props.to} {...props}>
				{children}
			</a>
		);
	}

	return <button {...props}>{children}</button>;
};

Button.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	to: PropTypes.string,
	href: PropTypes.string,
	onClick: PropTypes.func,
	primary: PropTypes.bool,
	secondary: PropTypes.bool,
	success: PropTypes.bool,
	small: PropTypes.bool,
	mini: PropTypes.bool,
	disabled: PropTypes.bool,
	warning: PropTypes.bool,
	confirm: PropTypes.bool
};

export default Button;
