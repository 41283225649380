import React from 'react';
import { classnames } from '../../utils';

import styles from './Pill.module.scss';

/**
 * @param {Object} props
 * @param {'green' | 'blue' | 'grey' | 'yellow' | 'orange' | 'red'} [props.colour='green']
 * @param {'normal' | 'large'} [props.size='normal']
 * @param {string?} [props.className]
 * @param {string} props.children
 */
const Pill = ({ className = '', size = 'normal', children, colour = 'green', ...props }) => {
	return (
		<span className={classnames(styles.pill, styles[size], styles[colour], className)} {...props}>
			{children}
		</span>
	);
};

export default Pill;
