import { Icon } from '@nearst/ui';
import styles from './OrderStatus.module.scss';

const OrderStatus = ({ orderStatus }) => {
	const statusText = {
		paid: 'Pending',
		confirmed: 'Confirmed',
		cancelled: 'Cancelled',
		completed: 'Completed',
		refunded: 'Cancelled',
		refund_failed: 'Cancelled',
		reserved: 'Pending'
	};
	const statusIcon = {
		paid: 'panorama_fish_eye',
		confirmed: 'timelapse',
		cancelled: 'cancel',
		completed: 'check_circle',
		refunded: 'cancel',
		refund_failed: 'cancel',
		reserved: 'panorama_fish_eye'
	};

	return (
		<div className={`${styles[statusText[orderStatus].toLowerCase()]} ${styles.orderStatus}`}>
			<Icon className={`${styles[statusText[orderStatus].toLowerCase()]} ${styles.orderStatusIcon}`}>{statusIcon[orderStatus]}</Icon>{' '}
			<b>{statusText[orderStatus]}</b>
		</div>
	);
};

export default OrderStatus;
