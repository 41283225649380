import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tabs, TabList, Tab, TabPanels, TabPanel, useTabsContext } from '@reach/tabs';
import { Menu, MenuList, MenuButton, MenuItem } from '@reach/menu-button';

import Icon from '../Icon';
import './style.scss';

const ModernTabContent = ({ children, className = '', ...props }) => {
	return (
		<TabPanel className={`modern-tabs__content ${className}`} {...props}>
			{children}
		</TabPanel>
	);
};

const MyMenu = ({ tabTitles, IconComponent, children }) => {
	const { selectedIndex } = useTabsContext();
	return (
		<Menu>
			<div className="modern-tabs__menu-container">
				<MenuButton className="modern-tabs__menu-button">
					{tabTitles[selectedIndex]}
					<Icon>keyboard_arrow_down</Icon>
				</MenuButton>
				<IconComponent />
			</div>
			{children}
		</Menu>
	);
};

const MyMenuItem = ({ onChange, children }) => {
	return (
		<MenuItem className={`modern-tabs__menu-item`} onSelect={onChange}>
			{children}
		</MenuItem>
	);
};

const MyMenuList = ({ children }) => {
	return <MenuList className="modern-tabs__menu-list">{children}</MenuList>;
};

const ModernTabContainer = ({ children, tabTitles, IconComponent = () => null, className = '', navigate, location, ...props }) => {
	const activeTabName = decodeURIComponent(location.hash.split('#')[1]);

	const findIndex = (activeTabName) => {
		let index = tabTitles.findIndex((n) => n.key === activeTabName);
		if (index < 0) {
			index = 0;
		}
		return index;
	};

	const [tabIndex, setTabIndex] = useState(findIndex(activeTabName));

	const handleTabsChange = (index) => {
		setTabIndex(index);
	};

	useEffect(() => {
		const newTabIndex = findIndex(activeTabName);
		if (!newTabIndex) return;

		setTabIndex(newTabIndex);
	}, [activeTabName]);

	const MobileTab = ({ id, index, children }) => {
		return (
			<Tab
				key={id}
				aria-selected={index === tabIndex}
				data-selected={index === tabIndex}
				onChange={() => handleTabsChange(index)}
				as={MyMenuItem}
			>
				<span onClick={() => navigate(`#${id}`, { replace: true })} id={id}>
					{children}
				</span>
			</Tab>
		);
	};

	const DesktopTab = ({ id, children }) => {
		return (
			<Tab key={id}>
				<span onClick={() => navigate(`#${id}`, { replace: true })} id={id}>
					{children}
				</span>
			</Tab>
		);
	};

	return (
		<Fragment>
			{/* Mobile section */}
			<div className={`modern-tabs modern-tabs--small ${className}`}>
				<Tabs
					as={MyMenu}
					tabTitles={tabTitles}
					IconComponent={IconComponent}
					index={tabIndex}
					onChange={handleTabsChange}
					{...props}
				>
					<TabList as={MyMenuList}>
						{tabTitles.map((Component, index) => {
							return (
								<MobileTab id={Component.key} key={Component.key} index={index}>
									{Component}
								</MobileTab>
							);
						})}
					</TabList>
					<TabPanels>{children}</TabPanels>
				</Tabs>
			</div>
			{/* Wider screen section */}
			<Tabs index={tabIndex} onChange={handleTabsChange} className={`modern-tabs modern-tabs--wide ${className}`} {...props}>
				<div className="modern-tabs__tab-container">
					<TabList>
						{tabTitles.map((Component) => {
							return (
								<DesktopTab id={Component.key} key={Component.key}>
									{Component}
								</DesktopTab>
							);
						})}
					</TabList>
					<IconComponent />
				</div>
				<TabPanels>{children}</TabPanels>
			</Tabs>
		</Fragment>
	);
};

ModernTabContainer.propTypes = {
	tabTitles: PropTypes.array.isRequired,
	className: PropTypes.string,
	IconComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
	children: PropTypes.node
};

export { ModernTabContainer, ModernTabContent };
