import React, { useState } from 'react';
import useSWR from 'swr';
import getSymbolFromCurrency from 'currency-symbol-map';
import { Loader } from '@nearst/ui';

import PlanCard from '../../PlanCard';
import { getAverageResults } from '@services/insights';
import { ConfirmLIAModal } from '../../Modals';
import { updateLiaPlan } from '../utils';
import styles from '../LocalAdsOverview.module.scss';

const budgets = [3, 5, 10, 15, 20, 30, 50, 80];

const LiaCards = ({ shop, revalidate }) => {
	const [confirmationModal, setConfirmationModal] = useState(0);
	const { data: averageResults } = useSWR('local-ads-avg-results', getAverageResults, { suspense: true });
	const currencySymbol = getSymbolFromCurrency(shop.billingCurrency || 'GBP');

	if (!averageResults) return <Loader />;

	return (
		<>
			<h3>Select a daily budget</h3>
			<div className={styles.plans} data-cy="plus-plan-cards-wrapper">
				{budgets.map((budget) => (
					<PlanCard
						key={budget}
						id={shop.id}
						dailyAmount={budget}
						choosePlan={async () => {
							await updateLiaPlan({ budget, shop });
							setConfirmationModal(budget);
						}}
						currencySymbol={currencySymbol}
						averageResults={averageResults}
					/>
				))}
			</div>
			<ConfirmLIAModal
				show={confirmationModal > 0}
				close={() => {
					setConfirmationModal(0);
					revalidate();
				}}
			/>
		</>
	);
};

export default LiaCards;
