import React from 'react';

import PI from 'react-phone-input-2';
import 'react-phone-input-2/lib/high-res.css';

import styles from './PhoneInput.module.scss';

// react-phone-input causes 'Minified React error' in production - https://github.com/bl00mber/react-phone-input-2/issues/533, below is a workaround:
const PhoneInput = PI.default ? PI.default : PI;

export const TelephoneInput = (props) => {
	return <PhoneInput className={styles.phoneInput} {...props} />;
};
