import React, { useCallback, useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';

import { createOption, validateEmail } from './inputHelpers';
import styles from './MultipleEmailsInput.module.scss';

const components = {
	DropdownIndicator: null
};

/**
 * Input field for multiple email addresses. Displayed as tags, with the ability to add new ones
 * by pressing enter, tab or comma. Will display an error message if the input is not a valid email.
 *
 * @typedef {Object} Props
 * @property {string} [defaultValue] - The default value for the input.
 * @property {string} [className] - The class name for the input.
 * @property {function} [onBlur] - The blur handler for the input.
 * @property {function} onChange - The change handler for the input.
 *
 * @param {Props} props
 * @returns {React.ReactElement}
 */
const MultipleEmailsInput = ({ onChange, defaultValue = '', onBlur = undefined, className = '', ...props }) => {
	const [inputValue, setInputValue] = useState('');
	const [isInvalid, setIsInvalid] = useState(false);
	const [value, setValue] = useState(defaultValue?.split(',').filter(Boolean).map(createOption) || []);

	useEffect(() => {
		onChange(value.map((v) => v.value).join(','));
	}, [value]);

	const handleKeyDown = useCallback(
		(event) => {
			setIsInvalid(false);

			if (!inputValue) return;
			if (event.key && !['Enter', 'Tab', ',', ';'].includes(event.key)) return;

			if (!validateEmail(inputValue)) {
				// only show error if there is at least one email, otherwise the parent form
				// will do the error handling
				setIsInvalid(!!value.length);
				return;
			}

			setValue((prev) => [...prev, createOption(inputValue.trim())]);
			setInputValue('');
			event.preventDefault();
		},
		[inputValue]
	);

	return (
		<div className={className} data-testid="multiple-emails-input">
			<CreatableSelect
				placeholder="Enter one or multiple email addresses..."
				{...props}
				isMulti
				isClearable={false}
				menuIsOpen={false}
				inputValue={inputValue}
				onChange={(newValue) => setValue(newValue)}
				onInputChange={(newValue) => setInputValue(newValue)}
				onBlur={(event) => {
					handleKeyDown(event);
					onBlur?.();
				}}
				onKeyDown={handleKeyDown}
				components={components}
				value={value}
			/>
			{isInvalid && (
				<p className={styles.error} data-testid="multiple-emails-input-error">
					The email address is invalid.
				</p>
			)}
		</div>
	);
};

export default MultipleEmailsInput;
