import { differenceInDays, formatDistanceToNowStrict, isAfter } from 'date-fns';
import myShopClient from './nearlive';

export const hasRecentUpload = (inventorySource, days = 3) => {
	if (inventorySource?.latestIngest?.timestamp || inventorySource?.latestIngest?.customProductTimestamp) {
		const uploadDate = new Date(inventorySource.latestIngest.timestamp || inventorySource.latestIngest.customProductTimestamp);
		return differenceInDays(new Date(), uploadDate) < days;
	}
	return false;
};

export const hasUpload = (inventorySource) => {
	if (inventorySource?.latestIngest?.timestamp || inventorySource?.latestIngest?.customProductTimestamp) {
		return true;
	}
	return false;
};

export const getConnectedLocations = (inventorySources) => inventorySources.filter((source) => hasUpload(source));
export const getRecentlyConnectedLocations = (inventorySources) => inventorySources.filter((source) => hasRecentUpload(source));

export const getStatus = (inventorySources) => {
	if (!inventorySources.length) return 'warning';
	const locationsConnected = getConnectedLocations(inventorySources).length;
	const recentlyConnected = getRecentlyConnectedLocations(inventorySources).length;
	if (locationsConnected === 0) return 'waiting';
	if (recentlyConnected === locationsConnected) return 'active';
	return 'warning';
};

export const getPosLabel = (inventorySources) => {
	if (!inventorySources.length) return null;
	return inventorySources.find((source) => source.providerLabel)?.providerLabel;
};

export const getKind = (inventorySources) => {
	if (!inventorySources.length) return null;
	return inventorySources.find((source) => source.kind)?.kind;
};

export const getUploadLabel = (ingestAge) => {
	let latestUploadLabel = 'No recent uploads';

	if (ingestAge) {
		latestUploadLabel = ingestAge + ' ago';
	}

	return latestUploadLabel;
};

export const getUploadAge = (inventorySources) => {
	if (!inventorySources.length) return null;
	const timestampNormalise = (source) => source.latestIngest?.timestamp || source.latestIngest?.customProductTimestamp;
	const liveSources = inventorySources.filter(timestampNormalise);
	let latestUpload;
	let latestUploadDate;
	let ingestAge;
	let ingestDaysAgo;
	if (liveSources.length) {
		latestUpload = liveSources.reduce(
			(prev, current) => (isAfter(new Date(timestampNormalise(prev)), new Date(timestampNormalise(current))) ? prev : current),
			0
		);

		latestUploadDate = new Date(timestampNormalise(latestUpload));
		ingestAge = formatDistanceToNowStrict(latestUploadDate);
		ingestDaysAgo = differenceInDays(new Date(), latestUploadDate);
	}

	return { latestUploadDate, ingestAge, ingestDaysAgo, latestUpload, liveSources: liveSources.length > 0 };
};

/**
 * Download an inventory file for a given shop and inventory source.
 *
 * @param {string} shopId Shop ID
 * @param {string} inventorySourceId Inventory source ID
 * @returns {Promise<void>}
 */
export async function downloadInventory(shopId, inventorySourceId) {
	const client = await myShopClient();
	const { data } = await client.get(`/api/inventory-file/${shopId}/${inventorySourceId}`);

	window.location.href = data.url;

	return data;
}

async function getJustEatFeedDownloadLink(shopId) {
	const client = await myShopClient();
	const url = `/api/channels/${shopId}/just-eat/export`;
	const { data } = await client.post(url);

	return data.url;
}

/**
 * Export a channel inventory feed for a shop
 *
 * @param {string} type The type of channel to generate an inventory feed for
 * @param {string} shopId Shop id
 * @returns {Promise<string>} An s3 signed url to download the file
 */
export async function getChannelFeedDownloadLink(type, shopId) {
	const lookup = { 'just-eat': getJustEatFeedDownloadLink };
	const downloadFunction = lookup[type];
	if (downloadFunction) {
		return downloadFunction(shopId);
	} else {
		throw Error('Feed export for this channel is not implemented yet.');
	}
}
