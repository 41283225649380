import React from 'react';
import styles from './GetHelpBlock.module.scss';

const GetHelpBlock = ({ information = [], linksTitle = '', links = [] }) => {
	return (
		<>
			{information.length
				? information.map((text) => {
						return (
							<p className={styles.information} key={text}>
								{text}
							</p>
						);
					})
				: null}
			{linksTitle && (
				<p className={styles.linksTitle}>
					<b>{linksTitle}</b>
				</p>
			)}
			{links.length ? (
				<ul className={styles.links}>
					{links.map(({ text, href }) => {
						return (
							<li key={href}>
								<a href={href}>{text}</a>
							</li>
						);
					})}
				</ul>
			) : null}
		</>
	);
};

export default GetHelpBlock;
