import React, { useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useUser } from '@clerk/clerk-react';
import * as Popover from '@radix-ui/react-popover';
import { BuildingStorefrontIcon, ChevronDownIcon, Squares2X2Icon } from '@heroicons/react/20/solid';

import { UItils } from '@nearst/ui';
import { useShop } from '@services/ShopProvider';
import styles from './LocationSelector.module.scss';
import OrganisationSelector from '../OrganisationSelector';

const spliceUrl = (path, newId) => {
	const [paths, query] = path.split('?');
	const splitPath = paths.split('/');
	if (newId) splitPath.splice(2, 0, newId);
	path = splitPath.join('/');
	if (query) {
		path = path + '?' + query;
	}

	return path;
};

const useSwapId = () => {
	const { pathname: currentLocation } = useLocation();
	const { shopId: currentId } = useParams();

	return (newId) => {
		let path = currentLocation;
		// going from error states
		if (path.includes('/404')) {
			path = '/home';
		}

		// going from org level
		if (!currentId) {
			// to org level - this logic is taken care of in OrganisationSelector

			// to single
			if (typeof path === 'string') {
				path = spliceUrl(path, newId);
			}
		}

		// going from single
		if (currentId) {
			// to org
			if (!newId) {
				path = path.replace('/' + currentId, '');
			}
			// to single
			else {
				path = path.replace(currentId, newId);
			}
		}
		return path;
	};
};

const LocationSelector = () => {
	const { data: shops, shop } = useShop();
	const { user } = useUser();
	const [open, setOpen] = useState(false);

	if (!shops.length) return null;

	if (shops.length < 2 && user?.organizationMemberships.length < 2) {
		return (
			<div className={styles.button}>
				<LocationSelectorButton shop={shop} />
			</div>
		);
	}

	return (
		<Popover.Root open={open} onOpenChange={setOpen}>
			<Popover.Trigger asChild>
				<button className={styles.button} aria-label={`Select store: ${shop?.name || shops[0].organisation}`}>
					<LocationSelectorButton shop={shop} showArrow />
				</button>
			</Popover.Trigger>
			<Popover.Portal>
				<div>
					<div className={styles.backdrop} />
					<Popover.Content className={styles.list} align="start" sideOffset={8}>
						<LocationSelectorList onClose={() => setOpen(false)} />
					</Popover.Content>
				</div>
			</Popover.Portal>
		</Popover.Root>
	);
};

const LocationSelectorList = ({ onClose }) => {
	let navigate = useNavigate();
	const { data: shops } = useShop();
	const swapId = useSwapId();

	const sortByName = (a, b) => (a.name > b.name ? 1 : -1);
	const [shopList, setShopList] = useState(shops.sort(sortByName));
	const resetList = () => setShopList(shops.sort(sortByName));

	const handleInputChange = (e) => {
		const newShopList = shops.filter((shop) => shop.name.toLowerCase().includes(e.target.value.toLowerCase())).sort(sortByName);
		setShopList(newShopList);
	};

	const selectLocation = (newId) => {
		const newPath = swapId(newId);
		navigate(newPath);
		onClose();
		resetList();
	};

	return (
		<>
			<input type="search" className={styles.searchInput} onChange={handleInputChange} placeholder="Search locations..." />
			<div className={styles.shops}>
				<div className={styles.divider}>Organisations</div>
				<OrganisationSelector />
				<div className={styles.divider}>Locations</div>
				{shopList.map((item) => (
					<LocationSelectorButton key={item.id} shop={item} onClick={() => selectLocation(item.id)} />
				))}
				{!shopList.length ? <p className={styles.noResults}>No matches found.</p> : null}
			</div>
		</>
	);
};

const LocationSelectorButton = ({ shop, showArrow = false, ...props }) => {
	const { data, shop: current } = useShop();

	if (shop?.id) {
		return (
			<div title={shop.name} className={UItils.classnames(styles.shop, shop?.id === current?.id && styles.current)} {...props}>
				<div className={styles.shopImage}>
					{shop.profileImageUrl ? <img alt={shop.name} src={shop.profileImageUrl} /> : <BuildingStorefrontIcon />}
				</div>
				<div className={styles.shopName}>
					{data.length > 1 && showArrow && (
						<div className={UItils.classnames(styles.truncate, styles.organisationName)}>{shop.organisation}</div>
					)}
					<span>
						<span className={styles.truncate}>{shop.name}</span>
						{showArrow && <ChevronDownIcon />}
					</span>
				</div>
			</div>
		);
	} else {
		return (
			<div title={data?.[0]?.organisation} className={styles.shop} {...props}>
				<div className={styles.shopImage}>
					<Squares2X2Icon />
				</div>
				<div className={styles.shopName}>
					<span>
						<span className={styles.truncate}>{data?.[0]?.organisation}</span>
						{showArrow && <ChevronDownIcon />}
					</span>
				</div>
			</div>
		);
	}
};

export default LocationSelector;
