import React from 'react';
import * as Chart from 'recharts';
import Legend from '../Legend';

import Tooltip from '@components/Graphs/Tooltip';
import styles from './DoughnutWithLegend.module.scss';

const DoughnutWithLegend = ({ series }) => {
	return (
		<div className={styles.doughnutContainer}>
			<div className={`${styles.graphContainer} ${styles.doughnutGraph}`}>
				<Chart.ResponsiveContainer width={200} height={200}>
					<Chart.PieChart
						style={{
							fontSize: '0.8rem',
							fontFamily: "'nearst-body', -apple-system, 'Helvetica Neue', 'Arial', sans-serif"
						}}
					>
						<Chart.Pie innerRadius={40} dataKey="value" nameKey="label" data={series} paddingAngle={1}>
							{series.map((row) => (
								<Chart.Cell key={row.colour} fill={row.colour} />
							))}
						</Chart.Pie>
						<Chart.Tooltip content={Tooltip} />
					</Chart.PieChart>
				</Chart.ResponsiveContainer>
			</div>
			<Legend series={series} />
		</div>
	);
};

export default DoughnutWithLegend;
