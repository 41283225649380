import React from 'react';
import { InputGroup } from '@nearst/ui';

import MultipleEmailsInput from '@components/MultipleEmailsInput';
import { useShop } from '@services/ShopProvider';
import { updateChannel } from '@services/channels';

import styles from './ReservationEmailForm.module.scss';

const ReservationEmailForm = ({ notificationEmails = [] }) => {
	const { shop } = useShop();

	const emails = Array.isArray(notificationEmails) ? notificationEmails.toString() : notificationEmails;

	return (
		<InputGroup label="Notification email addresses" htmlFor="reservationEmail">
			<MultipleEmailsInput
				className={styles.input}
				id="reservationEmail"
				defaultValue={emails}
				onChange={async (value) => {
					if (!value) return;
					await updateChannel(shop.id, 'product-reservations', { notificationEmails: value?.split(',') || [] });
					await updateChannel(shop.id, 'local-checkout', { notificationEmails: value?.split(',') || [] });
				}}
			/>
		</InputGroup>
	);
};

export default ReservationEmailForm;
