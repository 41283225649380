import React from 'react';
import CreatableSelect from 'react-select/creatable';
import useSWR from 'swr';

import { getAutocompleteDeliveryCategories } from '@services/stock';
import { useShop } from '@services/ShopProvider';

import styles from '../DeliveryChannelsCard.module.scss';

const DeliveryCategoriesSelect = ({ defaultValue, onChange, disabled }) => {
	const { shop } = useShop();
	const { data: options } = useSWR(['autocomplete-delivery-categories', shop.id], () => getAutocompleteDeliveryCategories(shop.id));

	return (
		<CreatableSelect
			className={styles.select}
			isMulti
			placeholder="E.g. Alcohol"
			noOptionsMessage={() => 'Type to create a category...'}
			formatCreateLabel={(inputValue) => `Use: "${inputValue}"`}
			defaultValue={defaultValue ? defaultValue.map((value) => ({ value, label: value })) : []}
			onChange={(value) => onChange(value.map((item) => item.value))}
			isDisabled={disabled}
			options={options}
			isLoading={!options}
			components={{
				ClearIndicator: () => null,
				IndicatorSeparator: () => null,
				DropdownIndicator: () => null
			}}
		/>
	);
};

export default DeliveryCategoriesSelect;
