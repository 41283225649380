import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Icon, Stack, Pill, Card } from '@nearst/ui';

import plurify from '@utils/plurify';
import Table from '@components/Table';
import { batchGetStock } from '@services/stock';
import { Ranking } from './Ranking';
import { StockPill } from './Pills';

import styles from './IndustryInsights.module.scss';

const Mobile = ({ row, index, stock, hideStock = false }) => {
	return (
		<details>
			<summary className={styles.summary}>
				<span>{index + 1}</span>
				{row.position === 'up' && <Icon className={`${styles.diff} ${styles.greenIcon}`}>arrow_drop_up</Icon>}
				{row.position === 'down' && <Icon className={`${styles.diff} ${styles.greyIcon}`}>arrow_drop_down</Icon>}
				{row.position === 'same' && <Icon className={`${styles.diff} ${styles.greyIcon}`}>remove</Icon>}
				<p>
					{row.productname} <span className={styles.greyText}>- {row.brand}</span>
				</p>
				<Icon className={styles.summaryIcon}>chevron_right</Icon>
			</summary>
			<Stack space="0.5rem">
				<div className={styles.pills}>
					{row.topproduct && (
						<Pill colour="blue">
							Top product for {row.topproduct} {plurify('week', row.topproduct)}
						</Pill>
					)}
					{row.quickriser && <Pill colour="blue">Quick riser</Pill>}
					{row.isnew && <Pill colour="blue">New top product</Pill>}
					{!hideStock && <StockPill stock={stock} row={row} />}
					{row.position === 'up' && <Pill colour="yellow">Up {row.diff}</Pill>}
					{row.position === 'down' && <Pill colour="orange">Down {row.diff * -1}</Pill>}
				</div>
			</Stack>
		</details>
	);
};

const IndustryInsightsTable = ({ products, limit = 20, hideStock = false }) => {
	const [stock, setStock] = useState();
	const [hideStockCount, setHideStockCount] = useState(true);
	const { shopId } = useParams();

	useEffect(() => {
		if (shopId && !hideStock) {
			const barcodes = [...new Set(products.map((row) => row.barcode))];

			batchGetStock(barcodes, shopId)
				.then((data) => {
					if (data.length) {
						setHideStockCount(false);
					}
					setStock(data);
				})
				.catch(console.error);
		}
	}, [shopId, products, hideStock]);

	return (
		<Card>
			<span className={styles.mobile}>
				<Table
					data={products}
					maxLength={limit}
					fields={[
						{
							title: 'Product',
							id: 'product',
							formatter: (row, index) => {
								return <Mobile row={row} index={index} stock={stock} hideStock={hideStockCount} />;
							}
						}
					]}
				/>
			</span>
			<span className={styles.desktop}>
				<Table
					data={products}
					maxLength={limit}
					fields={[
						{
							title: '',
							id: 'index',
							formatter: (row, index) => {
								return <span> {index + 1} </span>;
							}
						},
						{
							title: 'Product',
							id: 'product',
							formatter: (row) => {
								return (
									<p>
										{row.productname} <span className={styles.greyText}>- {row.brand}</span>
									</p>
								);
							}
						},

						{
							title: 'Ranking',
							id: 'ranking',
							formatter: (row) => {
								return <Ranking {...row} />;
							}
						},
						!hideStockCount
							? {
									title: 'Your inventory',
									id: 'inventory',

									formatter: (row) => <StockPill stock={stock} row={row} />
								}
							: undefined
					].filter(Boolean)}
				/>
			</span>
		</Card>
	);
};

export { IndustryInsightsTable };
