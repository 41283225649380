import React from 'react';
import { Protect } from '@clerk/clerk-react';
import { Navigate } from 'react-router-dom/dist';

import { useOnboardingShops } from '@components/OnboardingAccessControl';

import Onboarding from './Onboarding';
import OnboardingInProgress from './OnboardingInProgress';

export const OnboardingLayout = () => {
	return (
		<Protect permission="org:connections:manage" fallback={<OnboardingInProgress />}>
			<Onboarding />
		</Protect>
	);
};

export const OnboardingFallback = () => {
	const onboardingShops = useOnboardingShops();
	if (onboardingShops.length > 0) {
		return <Navigate to={`/onboarding/${onboardingShops[0].id}`} />;
	}
	return <Navigate to="/home" />;
};
