import React, { useState } from 'react';

import { Protect } from '@clerk/clerk-react';
import Tippy from '@tippyjs/react';

import AuthorizationTooltip from '@components/AuthorizationTooltip';
import AllChannelsButton from '../../components/AllChannelsButton';
import ImageInput from '@components/ImageInput';
import { useCheckPermission } from '@services/auth/hooks';
import { useShop } from '@services/ShopProvider';

import { Button, Card, Icon, Page, TextInput, InputGroup, Textarea, CheckboxInput } from '@nearst/ui';

import defaultHeader from '../../../../assets/default-header.jpg';

import styles from './ShopPage.module.scss';

const ShopPage = () => {
	const { shop, updateShop } = useShop();
	const authorized = useCheckPermission('org:channels:manage');

	const [shopBio, setShopBio] = useState(shop.bio || '');
	const [shopWebsite, setShopWebsite] = useState(shop.website || '');
	const [shopFacebook, setShopFacebook] = useState(shop.facebook || '');
	const [shopMessenger, setShopMessenger] = useState(shop.facebookMessenger || null);
	const [shopInstagram, setShopInstagram] = useState(shop.instagram || '');
	const [shopTwitter, setShopTwitter] = useState(shop.twitter || '');

	return (
		<>
			<Page.Header>
				<AllChannelsButton />
				<Page.HeaderCta>
					<div>
						<h1>NearSt shop page</h1>
						<p>Edit the appearance of your NearSt shop page.</p>
					</div>
					<Button primary href={`https://near.st/shop/${shop.slug || shop.legacyId}`} target="_blank">
						View your shop page
					</Button>
				</Page.HeaderCta>
			</Page.Header>
			<Page.Section className={styles.profile}>
				<Card title="Header image" description="Gives shoppers an impression of your shop">
					<ImageInput
						displayName="Header image"
						field="headerImageUrl"
						placeholder={defaultHeader}
						containerId="header-image"
						imageClass={styles.profileHeaderPicture}
						authorized={authorized}
					/>
				</Card>

				<Card title="Bio" description="This bio will appear on your NearSt shop page to give it an extra personal touch.">
					<Textarea
						className={styles.profileTextArea}
						placeholder="Enter a short bio"
						defaultValue={shopBio}
						onBlur={async (e) => {
							setShopBio(e.target.value);
							if (shop.bio !== e.target.value) {
								await updateShop(shop.id, { bio: e.target.value });
							}
						}}
						disabled={!authorized}
					/>
				</Card>

				<Card title="Social media links" description="Here you can add any links you want to show up on your shop page.">
					<AuthorizationTooltip authorized={authorized} tooltipContent="You don't have access update this link">
						<InputGroup label="Website" htmlFor="website">
							<TextInput
								className={styles.profileInputs}
								name="website"
								defaultValue={shopWebsite}
								placeholder="https://example.com"
								disabled={!authorized}
								onBlur={async (e) => {
									setShopWebsite(e.target.value);
									if (shopWebsite !== e.target.value) {
										await updateShop(shop.id, { website: e.target.value });
									}
								}}
							/>
						</InputGroup>
					</AuthorizationTooltip>
					<InputGroup label="Facebook" htmlFor="facebook">
						<div className="styles.facebookInputs">
							<AuthorizationTooltip authorized={authorized} tooltipContent="You don't have access update this link">
								<TextInput
									className={styles.profileInputs}
									name="facebook"
									disabled={!authorized}
									placeholder="https://facebook.com/example"
									defaultValue={shopFacebook}
									onBlur={async (e) => {
										setShopFacebook(e.target.value);
										if (shopFacebook !== e.target.value) {
											await updateShop(shop.id, { facebook: e.target.value });
										}
									}}
								/>
							</AuthorizationTooltip>
							{shop.facebook ? (
								<Protect permission="org:channels:manage">
									<div className={styles.checkboxContainer}>
										<CheckboxInput
											label={'Enable Facebook Messenger'}
											defaultChecked={!!shopMessenger}
											onChange={async (e) => {
												const messengerUrl = `https://m.me/${shop.facebook.split('/').pop()}`;
												if (e.target.checked) {
													setShopMessenger(messengerUrl);
													await updateShop(shop.id, { facebookMessenger: messengerUrl });
												}
												if (!e.target.checked) {
													setShopMessenger(null);
													await updateShop(shop.id, { facebookMessenger: null });
												}
											}}
										/>
										<div className={styles.tippyIcon}>
											<Tippy
												interactive
												content={
													<div>
														The Facebook Messenger integration makes it easier for shoppers to reach you. Enter
														a Facebook link to get started.{' '}
														<a
															target="_blank"
															rel="noopener noreferrer"
															href="https://support.near.st/en/articles/6682418-how-do-i-add-facebook-messenger-to-my-nearst-shop-page"
														>
															Learn more &rarr;
														</a>
													</div>
												}
											>
												<span>
													<Icon>info</Icon>
												</span>
											</Tippy>
										</div>
									</div>
								</Protect>
							) : null}
						</div>
					</InputGroup>
					<AuthorizationTooltip authorized={authorized} tooltipContent="You don't have access update this link">
						<InputGroup label="Instagram" htmlFor="instagram">
							<TextInput
								className={styles.profileInputs}
								name="instagram"
								value={shopInstagram}
								placeholder="https://instagram.com/example"
								disabled={!authorized}
								onBlur={async (e) => {
									setShopInstagram(e.target.value);
									if (shopInstagram !== e.target.value) {
										await updateShop(shop.id, { instagram: e.target.value });
									}
								}}
							/>
						</InputGroup>
					</AuthorizationTooltip>
					<AuthorizationTooltip authorized={authorized} tooltipContent="You don't have access update this link">
						<InputGroup label="Twitter" htmlFor="twitter">
							<TextInput
								className={styles.profileInputs}
								name="twitter"
								value={shopTwitter}
								placeholder="https://twitter.com/example"
								disabled={!authorized}
								onBlur={async (e) => {
									setShopTwitter(e.target.value);
									if (shopTwitter !== e.target.value) {
										await updateShop(shop.id, { twitter: e.target.value });
									}
								}}
							/>
						</InputGroup>
					</AuthorizationTooltip>
				</Card>
			</Page.Section>
		</>
	);
};

export default ShopPage;
