import React from 'react';
import { Button, Icon } from '@nearst/ui';
import { Link } from 'react-router-dom';
import RestrictedPage from '@components/RestrictedPage/RestrictedPage';

export default function AccessDenied() {
	return (
		<RestrictedPage>
			<Icon>key</Icon>
			<h1>Restricted page</h1>
			<p>You don't have access to view this page. Please contact your organisation admin.</p>
			<Button as={Link} to="/" primary>
				Back home
			</Button>
		</RestrictedPage>
	);
}
