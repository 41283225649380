import React from 'react';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage as HookformErrorMessage } from '@hookform/error-message';

import styles from '../Fields.module.scss';

const ErrorMessage = ({ name, error = undefined }) => {
	const {
		formState: { errors }
	} = useFormContext();

	return (
		<HookformErrorMessage
			errors={errors}
			name={name}
			render={({ message }) => <p className={styles.validation}>{error || message}</p>}
		/>
	);
};

export default ErrorMessage;
