import React from 'react';
import { TileIcon } from '@nearst/ui';

import styles from './FeaturesList.module.scss';

const PlanFeature = ({ icon, children }) => {
	return (
		<div className={styles.feature}>
			<TileIcon>{icon}</TileIcon>
			<div className={styles.featureInfo}>{children}</div>
		</div>
	);
};

export default PlanFeature;
