import React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { format, subDays } from 'date-fns';

import { sendAnalytics } from '../../../../utils/sendAnalytics';
import { getCSVData } from '@components/Graphs/DataWrapper';
import DataWrapper from '@components/Graphs/DataWrapper';
import Download from '@components/Graphs/Download';

import styles from './Enabled.module.scss';

const addS = (num) => (num === 1 ? '' : 's');

const Enabled = ({ shop }) => {
	const query = {
		id: [shop.id],
		table: 'clicks_to_sales',
		sort: [
			{ column: 'sales_date', order: 'desc' },
			{ column: 'sales_from_clicks', order: 'desc' },
			{ column: 'attributed_clicks', order: 'desc' }
		],
		from: format(subDays(new Date(), 31), 'yyyy-MM-dd'),
		to: format(subDays(new Date(), 1), 'yyyy-MM-dd')
	};

	return (
		<DataWrapper query={query}>
			{(data) => {
				const csv = getCSVData(data);
				if (data.length === 0) {
					return (
						<div className={styles.noResultsCard}>
							<h3>No suspected sales yet</h3>
							<p>We don&rsquo;t have any data to show yet. Please check back here in a few days.</p>
						</div>
					);
				}
				return (
					<>
						<Download csv={csv} filename={`clicks_to_sales_${shop.name.toLowerCase().split(' ').join('_')}_last_30_days`} />
						<Table aria-label="clicks to sales table" className={styles.table} size="small">
							<TableHead>
								<TableRow>
									<TableCell>
										<div className={styles.tableHeader}>Date </div>
									</TableCell>
									<TableCell>
										<div className={styles.tableHeader}>Product </div>
									</TableCell>
									<TableCell>
										<div className={styles.tableHeader}>Clicks & stock data</div>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{data.map((product, index) => {
									return (
										<TableRow
											key={`clicks-to-sales-${index}`}
											onMouseEnter={sendAnalytics(`Clicks to sales: ${product.sales_date}`, 'mouseover', shop.name)}
										>
											<TableCell>
												<div className={styles.tableCellColumnStart}>
													<p className={styles.tableText}>{format(new Date(product.sales_date), 'dd-MM-yyyy')}</p>
												</div>
											</TableCell>
											<TableCell>
												<div className={styles.tableCellColumnStart}>
													<p className={styles.tableTextBold}>{product.product_name}</p>
													<p className={styles.tableTextGrey}>
														{product.barcode} -{' '}
														{Number(product.price).toLocaleString('en-GB', {
															style: 'currency',
															currency: shop.currency
														})}
													</p>
												</div>
											</TableCell>
											<TableCell>
												<div className={styles.tableCellColumnStart}>
													<p className={styles.tableTextGrey}>
														{product.attributed_clicks} click
														{addS(product.attributed_clicks)}
													</p>
													<p className={styles.tableTextGrey}>
														{product.sales_from_clicks} suspected sale
														{addS(product.sales_from_clicks)}
													</p>
												</div>
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</>
				);
			}}
		</DataWrapper>
	);
};

export default Enabled;
