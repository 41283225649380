import React from 'react';
import { useParams } from 'react-router-dom';

import { useShop } from '@services/ShopProvider';
import { useChannelDefinitions, useChannel } from '@services/channels';
import { shopIsOnSwis } from '@services/channels';

const useChannelInfo = (channel) => {
	const { shop } = useShop();
	const params = useParams();

	const channelId = channel ? channel : params?.channel;

	// product-locator is a special case because it's organisation-wide
	const shopId = channelId === 'product-locator' ? undefined : shop.id;
	const { data: shopChannelConfig } = useChannel(channelId, shopId);

	const googleEnabled = shopIsOnSwis(shop) ? 'enabled' : 'disabled';
	const liaEnabled = shop.totalAdsBudget ? 'enabled' : 'disabled';

	let status = 'disabled';
	if (channelId === 'google') status = googleEnabled;
	else if (channelId === 'local-ads') status = liaEnabled;
	else if (shopChannelConfig) status = shopChannelConfig?.status;

	const { data: channelDefinitions } = useChannelDefinitions();

	const channelDefinition = channelDefinitions[channelId];

	return { channelId, channelDefinitions, channelDefinition, shopChannelConfig, status };
};

export default useChannelInfo;
