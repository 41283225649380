import { Icon, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { shopConnectionStatus } from '../../../../utils/shopConnectionStatus';
import TablePagination from '../../../orders/components/TablePagination';
import Actions from '../Actions';
import LocationChannelsSummary from '../LocationChannelsSummary';
import LocationInventorySources from '../LocationInventorySources';
import LocationName from '../LocationName';
import LocationStatus from '../LocationStatus';
import LocationAdsBudget from '../LocationAdsBudget';
import Skeleton from '@components/Skeleton';

import styles from '../LocationManagement.module.scss';
import DesktopLocationInsights from '../DesktopLocationInsights';
import MobileLocationInsights from '../MobileLocationInsights';
import { formatInsights } from '../helpers';

const enrichShops = (shops, channels, insights) => {
	return shops.map((shop) => {
		shop.status = shopConnectionStatus(shop).text;
		shop.enabledChannels = channels?.reduce((accumulator, channel) => {
			if (channel.enabledShops.find((shopId) => shopId === shop.id)) {
				accumulator.push(channel.id);
			}
			return accumulator;
		}, []);

		let formattedInsights = {};

		if (insights.swisDailyImpressionsCorrected && insights.dailySwis) {
			formattedInsights = formatInsights(shop.id, insights);
		}

		return { ...shop, ...formattedInsights };
	});
};

const filterLocations = (shops, filters, searchQuery) => {
	const normalisedSearchQuery = searchQuery?.toLowerCase() || '';
	return shops
		.filter((shop) => !filters.status.length || filters.status.includes(shop.status))
		.filter((shop) => !filters.channels.length || filters.channels.every((c) => shop.enabledChannels.includes(c)))
		.filter(
			(shop) =>
				!searchQuery ||
				shop.name?.toLowerCase().includes(normalisedSearchQuery) ||
				shop.city?.toLowerCase().startsWith(normalisedSearchQuery)
		);
};

const sortData = (data, order, property) => {
	const sortedData = [...data].sort((a, b) => {
		if (order === 'asc') {
			return typeof a[property] === 'string' ? a[property].localeCompare(b[property]) : a[property] - b[property];
		} else {
			return typeof a[property] === 'string' ? b[property].localeCompare(a[property]) : b[property] - a[property];
		}
	});

	return sortedData;
};

const DesktopLocationTable = ({ rows, shopsSorted, setShopsSorted }) => {
	const [orderBy, setOrderBy] = useState('');
	const [order, setOrder] = useState('asc');

	const handleSort = (property) => {
		const isDesc = orderBy === property && order === 'desc';
		const newOrder = isDesc ? 'asc' : 'desc';

		setOrderBy(property);
		setOrder(newOrder);
		setShopsSorted(sortData(shopsSorted, newOrder, property));
	};

	return (
		<div className={styles.desktopTableContainer}>
			<Table className={styles.desktopTable} role="table">
				<TableHead data-testid="desktop-table-head">
					<TableRow className={styles.columnHeaders}>
						<TableCell>
							<TableSortLabel
								active={orderBy === 'name'}
								direction={orderBy === 'name' ? order : 'desc'}
								onClick={() => handleSort('name')}
							>
								Name
							</TableSortLabel>
						</TableCell>
						<TableCell>Status</TableCell>
						<TableCell>Inventory connection</TableCell>
						<TableCell>Channels</TableCell>
						<TableCell>
							<TableSortLabel
								active={orderBy === 'totalAdsBudget'}
								direction={orderBy === 'totalAdsBudget' ? order : 'desc'}
								onClick={() => handleSort('totalAdsBudget')}
							>
								Ads budget
							</TableSortLabel>
						</TableCell>
						<TableCell>
							<TableSortLabel
								active={orderBy === 'currentImpressions'}
								direction={orderBy === 'currentImpressions' ? order : 'desc'}
								onClick={() => handleSort('currentImpressions')}
							>
								Impressions
							</TableSortLabel>
						</TableCell>
						<TableCell>
							<TableSortLabel
								active={orderBy === 'currentClicks'}
								direction={orderBy === 'currentClicks' ? order : 'desc'}
								onClick={() => handleSort('currentClicks')}
							>
								Clicks
							</TableSortLabel>
						</TableCell>
						<TableCell />
					</TableRow>
				</TableHead>
				<TableBody data-testid="desktop-table-body">
					{rows.map((shop) => {
						if (shop.currentImpressions === undefined || shop.currentClicks === undefined) {
							return (
								<TableRow key={shop.id}>
									<TableCell colSpan={7}>
										<Skeleton width="100%" height={54} />
									</TableCell>
								</TableRow>
							);
						}

						return (
							<TableRow data-testid={`locations-table-row-${shop.id}`} key={shop.id}>
								<TableCell>
									<LocationName shop={shop} />
								</TableCell>
								<TableCell>
									<LocationStatus shop={shop} />
								</TableCell>
								<TableCell>
									<LocationInventorySources shop={shop} />
								</TableCell>
								<TableCell>
									<LocationChannelsSummary shop={shop} />
								</TableCell>
								<TableCell>
									<LocationAdsBudget shop={shop} />
								</TableCell>
								<TableCell>
									<DesktopLocationInsights
										currentTotal={shop.currentImpressions}
										previousTotal={shop.previousImpressions}
									/>
								</TableCell>
								<TableCell>
									<DesktopLocationInsights currentTotal={shop.currentClicks} previousTotal={shop.previousClicks} />
								</TableCell>
								<TableCell align="center">
									<Actions shop={shop} />
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</div>
	);
};

const MobileLocationTable = ({ rows }) => {
	return (
		<div className={styles.mobileTableContainer}>
			<Table>
				<TableBody data-testid="mobile-table-body">
					{rows.map((shop) => {
						return (
							<TableRow key={shop.id}>
								<TableCell>
									<Link className={styles.mobileTableRow} to={`/home/${shop.id}`}>
										<div className={styles.mobileTableShop}>
											<div className={styles.mobileTableInfo}>
												<LocationName shop={shop} />
												<LocationStatus shop={shop} />
											</div>
											<div className={styles.mobileTableInventory}>
												<LocationInventorySources shop={shop} />
											</div>
											<LocationChannelsSummary shop={shop} mobile />
											<LocationAdsBudget shop={shop} mobile />
											<div className={styles.mobileTableInsights}>
												{shop.currentImpressions ? (
													<MobileLocationInsights currentTotal={shop.currentImpressions} type="impressions" />
												) : null}
												{shop.currentClicks ? (
													<MobileLocationInsights currentTotal={shop.currentClicks} type="clicks" />
												) : null}
											</div>
										</div>

										<Icon className={styles.mobileTableChevron}>chevron_right</Icon>
									</Link>
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</div>
	);
};

const LocationManagementTable = ({ shops, insights, appliedFilters, searchQuery, channels, itemsPerPage = 25 }) => {
	const shopsEnriched = useMemo(() => enrichShops(shops, channels, insights), [shops, channels, insights]);

	const shopsFiltered = useMemo(
		() => filterLocations(shopsEnriched, appliedFilters, searchQuery),
		[shopsEnriched, appliedFilters, searchQuery]
	);

	const [currentPage, setCurrentPage] = useState(1);
	const [shopsSorted, setShopsSorted] = useState(shopsFiltered);
	const shopsToShow = shopsSorted?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
	const totalPages = Math.ceil(shopsFiltered.length / itemsPerPage);

	useEffect(() => {
		// when a filter is applied, jump back to the first page
		setCurrentPage(1);
	}, [appliedFilters, shopsSorted]);

	useEffect(() => {
		setShopsSorted(sortData(shopsFiltered, 'asc', 'name'));
	}, [shopsFiltered]);

	return (
		<>
			{shopsToShow.length === 0 ? (
				<div className={styles.noResults}>No locations matching your search.</div>
			) : (
				<>
					<DesktopLocationTable rows={shopsToShow} shopsSorted={shopsSorted} setShopsSorted={setShopsSorted} />
					<MobileLocationTable rows={shopsToShow} />
					<TablePagination pages={totalPages} currentPage={currentPage} onPageChange={setCurrentPage} />
				</>
			)}
		</>
	);
};

export default LocationManagementTable;
