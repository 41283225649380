import React from 'react';
import { Loader } from '@nearst/ui';
import { Elements } from '@stripe/react-stripe-js';
import stripePromise from '../../../../utils/getStripePromise';
import { setupIntent } from '@services/billing';
import { useShop } from '@services/ShopProvider';
import useSWR from 'swr';
import UpgradePaymentForm from '../../components/UpgradePaymentForm';

const UpgradePaymentInformation = () => {
	const { data: shops } = useShop();
	const { data: intent } = useSWR([shops.find((shop) => shop.stripeCustomerId).id, 'setup-intent'], setupIntent);

	if (!intent) return <Loader />;

	const options = {
		clientSecret: intent.client_secret,
		appearance: {
			rules: {
				'.AccordionItem': {
					borderColor: 'transparent',
					boxShadow: 'none',
					paddingLeft: '0px'
				}
			}
		}
	};

	return (
		<div>
			<h2>Add payment method</h2>
			<Elements stripe={stripePromise} options={options}>
				<UpgradePaymentForm />
			</Elements>
		</div>
	);
};

export default UpgradePaymentInformation;
