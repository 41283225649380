import React from 'react';
import { format } from 'date-fns';
import _ from 'lodash';
import * as Stats from '../../../../../components/StatsCard';
import { colourScheme } from '../../utils';
import InsightsWrapper from '../../../../../components/Graphs/DataWrapper';
import { useInsightsDate } from '@services/InsightsDateProvider';
import DoughnutWithLegend from '../../DoughnutWithLegend';

import styles from '../Cards.module.scss';

export const ShareOfProfileInteractionsCard = ({ shopIds }) => {
	const { startDate, endDate, selectedRange } = useInsightsDate();
	const query = {
		table: 'gmb',
		from: format(startDate, 'yyyy-MM-dd'),
		to: format(endDate, 'yyyy-MM-dd'),
		id: shopIds,
		columns: ['website_clicks', 'phone_clicks', 'directions_clicks', 'date'],
		sort: [{ column: 'date', order: 'asc' }],
		sum: ['website_clicks', 'phone_clicks', 'directions_clicks']
	};

	return (
		<Stats.Card className={`${styles.card} ${styles.businessProfile}`}>
			<Stats.Title>Share of profile interactions</Stats.Title>
			<Stats.Subtitle>{selectedRange}</Stats.Subtitle>
			<InsightsWrapper query={query} hideError>
				{(data) => {
					const series = [
						{
							label: 'Directions clicks',
							value: _.sumBy(data, 'directions_clicks'),
							description:
								'The number of times driving directions to your shop were requested by customers on your Google Business Profile.'
						},
						{
							label: 'Phone clicks',
							value: _.sumBy(data, 'phone_clicks'),
							description: 'The number of times the phone number on your Google Business Profile was clicked.'
						},
						{
							label: 'Website clicks',
							value: _.sumBy(data, 'website_clicks'),
							description: 'The number of times the website on your Google Business Profile was clicked.'
						}
					]
						.map((obj, i) => {
							obj.colour = colourScheme[i];
							return obj;
						})
						.sort((a, b) => b.value - a.value);

					return <DoughnutWithLegend series={series} />;
				}}
			</InsightsWrapper>
		</Stats.Card>
	);
};
