import React from 'react';
import { eachDayOfInterval, format, isSameDay } from 'date-fns';

import LineGraph from '@components/Graphs/LineGraph';
import { getDailyAdsSpend } from '@utils/localAdsPlans';

const getLiaChartData = (allDates, liaCurrentPeriod) => {
	const parseEmpty = (data) => (data === 'empty' || data === 'error' ? [] : data);
	const matchToDates = allDates.map((date) => {
		const lia = parseEmpty(liaCurrentPeriod).find((row) => isSameDay(new Date(row['date']), new Date(date)));

		return {
			Date: format(new Date(date), 'yyyy-MM-dd'),
			'Local Ads': lia ? +lia['impressions'] : 0
		};
	});
	return matchToDates;
};

const labelFormatter = (date, totalAdsBudgetToDisplay) => {
	const niceDate = format(new Date(date), 'MMM d, yyyy');

	try {
		return `${niceDate}${totalAdsBudgetToDisplay ? ` - LIA ${totalAdsBudgetToDisplay}` : ''}`;
	} catch (e) {
		console.error(e);
		return niceDate;
	}
};

const LocalAdsPerformanceChart = ({ liaCurrentPeriod, plans, startDate, endDate, shops, ...props }) => {
	const allDates = eachDayOfInterval({ start: startDate, end: endDate });
	const data = getLiaChartData(allDates, liaCurrentPeriod);
	const totalAdsBudgetForDay = getDailyAdsSpend(plans, startDate, endDate, shops);

	return (
		<LineGraph
			series={data.map((row) => ({ localAds: row['Local Ads'], date: row.Date }))}
			seriesConfig={[{ key: 'localAds', colour: '#50a1ff', label: 'Impressions' }]}
			width="100%"
			height={160}
			tooltip={({ label: date, payload }) => {
				const totalAdsBudget = totalAdsBudgetForDay.find((budget) => budget.date === date)?.budget;

				return (
					<>
						<p>{labelFormatter(date, totalAdsBudget)}</p>
						{payload.map((item) => (
							<p style={item} key={item.name}>
								{totalAdsBudget === 0 && !item.value ? `Off` : `${item.value.toLocaleString()} impressions`}
							</p>
						))}
					</>
				);
			}}
			{...props}
		/>
	);
};

export default LocalAdsPerformanceChart;
