import React from 'react';

import { Logo } from '@nearst/ui';

import styles from './PageLayout.module.scss';

const PageLayout = ({ children }) => {
	return (
		<div className={styles.container}>
			<div className={styles.formContainer}>
				<div className={styles.header}>
					<Logo />
				</div>
				<div className={styles.form}>{children}</div>
			</div>
			<div className={styles.quoteContainer}>
				<blockquote className={styles.customerQuote}>
					“With the nature of physical shopping changing and footfall decreasing, I wanted people to come into the stores. NearSt
					was able to provide the added view of what we have in-stock, targeting those looking online.”
					<div className={styles.customerAttribution}>
						<strong>Paul Thompson</strong>Retail Manager, Lyle &amp; Scott
					</div>
				</blockquote>
				<div className={styles.graphic}></div>
			</div>
			{/* to help with places api */}
			<div id="google-places-autocomplete-helper" />
		</div>
	);
};

export default PageLayout;
