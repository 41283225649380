import React from 'react';
import { Link } from 'react-router-dom';

import styles from '../LocationManagement.module.scss';

const LocationName = ({ shop }) => {
	return (
		<div>
			<Link to={`/home/${shop.id}`} className={styles.link}>
				{shop.name}
			</Link>
			<p className={styles.shopCity}>{shop.city}</p>
		</div>
	);
};

export default LocationName;
