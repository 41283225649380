const ChannelDefinition = require('../model/ChannelDefinition');

class WebhookChannel extends ChannelDefinition {
	id = 'webhook';
	title = 'Webhook';

	productSpecificVisibility = false;
	isAvailableInPartnerApi = true;
}

module.exports = WebhookChannel;
