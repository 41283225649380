import React from 'react';
import copy from 'copy-to-clipboard';
import mixpanel from 'mixpanel-browser';

import { Button, Card, Page } from '@nearst/ui';

import AllChannelsButton from '../../components/AllChannelsButton';
import { useShop } from '@services/ShopProvider';
import { sendAnalytics } from '../../../../utils/sendAnalytics';

import styles from './SearchWidget.module.scss';

const SearchWidget = () => {
	const { shop } = useShop();
	const org = shop.pk.split('#')[1];
	const iframeLink = `https://embed.near.st/search?o=${org}&s=${shop.id}`;
	const codeSnippet = `<iframe title="NearSt search widget" src="${iframeLink}" height="44" width="100%" frameborder="0"></iframe>`;

	const copyCode = () => {
		sendAnalytics('Search Embed', 'Copied', shop.name);
		mixpanel.track('Search Embed copied', { link: iframeLink });
		copy(codeSnippet);
	};

	const emailMyDeveloper = () => {
		sendAnalytics('Search Embed', 'Send to Developer', shop.name);
		mixpanel.track('Search Embed code send to developer', { link: iframeLink });

		const code = encodeURIComponent(
			`Hi!\n\nI want to embed the NearSt search widget on my website, to allow shoppers to search my in-store products. This is the code needed to add the widget embed:\n\n${codeSnippet}`
		);
		// Opens draft email in Mail client - if user permissions allow
		window.location.href = `mailto:?subject=NearSt search widget&body=${code}`;
	};

	return (
		<>
			<Page.Header>
				<AllChannelsButton />
				<h1>Website search widget</h1>
				<p>Embed a search widget on your website. This will enable customers to search your in-store products.</p>
			</Page.Header>
			<Page.Section>
				<Card title="Widget preview">
					<iframe title="Preview of NearSt search widget" src={iframeLink} className={styles.widgetPreview} />
				</Card>

				<Card title="Embed code">
					<p className={styles.widgetSubtitle}>
						Copy and paste this code below and add it to the web page where you want this search widget to be visible.
						Alternatively, email the snippet to your web developer!
					</p>
					<pre id="search-widget" className={styles.widgetCodeSnippet}>
						{codeSnippet}
					</pre>
					<div className={styles.widgetGroup}>
						<Button primary onClick={copyCode}>
							Copy code
						</Button>
						<Button onClick={emailMyDeveloper}>Email my developer</Button>
					</div>
				</Card>
			</Page.Section>
		</>
	);
};
export default SearchWidget;
