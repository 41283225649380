const Categories = require('../model/Categories');
const ChannelDefinition = require('../model/ChannelDefinition');

class JustEatChannel extends ChannelDefinition {
	id = 'just-eat';
	title = 'Just Eat';
	description = 'Create Just Eat menus for each store to reach customers looking for easy access to your products.';
	category = Categories.LOCAL_COMMERCE;

	iconUrl = 'https://go.near.st/hubfs/channels/just-eat.svg';
	coverImageUrl = 'https://go.near.st/hubfs/channels/headers/just-eat.png';

	longDescription = `
### Overview
Export a feed containing inventory for Just Eat and allow nearby customers to get products from your store delivered to them through the Just Eat app.

### How it works
NearSt will export your inventory, formatted as a menu and ready to send to Just Eat.

The menu export will have the latest product information you provide in the dashboard, combined with real-time product availability from your POS system.

You can manage all product-specific settings, such as category, custom prices and restrictions from the dashboard.

### Requirements
- Have an active Just Eat partner account. [Sign up here](https://restaurants.just-eat.co.uk).
- Have a product data source connected to provide images and descriptions for your menu items

If you are a convenience sector retailer, we can help you source the right data. [Contact us](mailto:support@near.st).`;

	productSpecificVisibility = true;
	comingSoon = true;
	registerInterestUrl = 'https://share.hsforms.com/1zYUSFD9ySuKRi93NRKK9bg16uos';
	feedExport = true;
}

module.exports = JustEatChannel;
