import React, { useState } from 'react';
import { Button, Card, Details, Icon } from '@nearst/ui';
import { Protect } from '@clerk/clerk-react';

import InventorySourceInformation from '@components/InventorySourceInformation';
import ConnectorIngestDetails from '../ConnectorIngestDetails';
import Reconnect from '../ConnectorStatus/Reconnect';
import { downloadInventory, hasRecentUpload } from '@services/inventory';
import { getInventoryConnection, INVENTORY_CONNECTION } from '@services/onboarding';
import { useShop } from '@services/ShopProvider';
import { useCheckPermission } from '@services/auth/hooks';
import AuthorizationTooltip from '@components/AuthorizationTooltip';

import styles from './InventorySource.module.scss';

const DownloadButton = ({ shopId, inventorySourceId }) => {
	const [loading, setLoading] = useState(false);
	const authorized = useCheckPermission('org:inventory:download');

	const download = async () => {
		setLoading(true);
		await downloadInventory(shopId, inventorySourceId);
		setLoading(false);
	};

	return (
		<AuthorizationTooltip authorized={authorized} tooltipContent={`You don't have permission to download this`}>
			<Button onClick={download} disabled={loading || !authorized} mini data-cy="inventory-download-button">
				Last upload <Icon>download</Icon>
			</Button>
		</AuthorizationTooltip>
	);
};

const Configure = ({ inventorySource, kind }) => {
	return (
		<Details title="Configure" className={styles.configure} data-cy={`connector-configure-${kind}`}>
			<Reconnect inventorySource={inventorySource} kind={kind} />
		</Details>
	);
};

const InventorySourceHeader = ({ inventorySource }) => {
	const { shop } = useShop();
	const showsDownloadButton = inventorySource?.latestIngest && hasRecentUpload(inventorySource, 30);

	return (
		<div className={styles.container}>
			<InventorySourceInformation inventorySources={[inventorySource]}>
				<InventorySourceInformation.PosStatus inventorySources={[inventorySource]} />
			</InventorySourceInformation>
			{showsDownloadButton && <DownloadButton shopId={shop.id} inventorySourceId={inventorySource.id} />}
		</div>
	);
};

const StockInventorySource = ({ inventorySource }) => {
	const { status } = getInventoryConnection(inventorySource?.latestIngest);

	return (
		<Card.Section>
			<Card.Title>Stock data source</Card.Title>
			{inventorySource && <InventorySourceHeader inventorySource={inventorySource} />}
			{inventorySource?.locationName && <p>POS location name: ${inventorySource.locationName}</p>}
			{status !== INVENTORY_CONNECTION.PENDING && <ConnectorIngestDetails inventorySource={inventorySource} />}
			{status !== INVENTORY_CONNECTION.CONNECTED && (
				<Protect permission="org:connections:manage">
					<Configure inventorySource={inventorySource} kind="stock" />
				</Protect>
			)}
		</Card.Section>
	);
};

const ProductInventorySource = ({ inventorySource }) => {
	const { status } = getInventoryConnection(inventorySource?.latestIngest);

	return (
		<Card.Section>
			<Card.Title>Product data source</Card.Title>
			{inventorySource && <InventorySourceHeader inventorySource={inventorySource} />}
			{inventorySource?.locationName && <p>POS location name: ${inventorySource.locationName}</p>}
			{status !== INVENTORY_CONNECTION.PENDING && <ConnectorIngestDetails inventorySource={inventorySource} />}
			{[INVENTORY_CONNECTION.DISCONNECTED, INVENTORY_CONNECTION.PENDING].includes(status) && (
				<Protect permission="org:connections:manage">
					<Configure inventorySource={inventorySource} kind="product" />
				</Protect>
			)}
		</Card.Section>
	);
};
export { StockInventorySource, ProductInventorySource };
