import useSWR from 'swr';
import { useShop } from '@services/ShopProvider';
import myShopClient from './nearlive';

export async function getOrders({ shopIds, start, end }) {
	const client = await myShopClient();
	const orders = await Promise.all(
		shopIds.map(async (shopId) => {
			return (await client.get(`/api/orders/${shopId}`, { params: { start, end } })).data;
		})
	);
	return orders.flat();
}

export async function getOrderInsights({ shopIds, start, end, currency }) {
	const client = await myShopClient();
	if (shopIds.length === 1) {
		return (await client.get(`/api/orders/insights/${shopIds[0]}`, { params: { start, end, currency } })).data;
	} else {
		return (await client.get(`/api/orders/insights`, { params: { start, end, currency } })).data;
	}
}

export async function updateOrders({ shopId, order }) {
	const client = await myShopClient();
	return (await client.post(`/api/orders/${shopId}`, order)).data;
}

const ordersSwrConfig = {
	refreshInterval: 60000,
	suspense: true
};
const defaultParameters = { shopIds: [], start: undefined, end: undefined };

/**
 * @typedef {import('swr').SWRResponse} SWRResponse
 * @typedef {import('swr').SWRConfiguration} SWRConfiguration
 * @typedef {Object} KeyType
 * @typedef {SWRConfiguration & KeyType} RequestConfig
 */

/**
 * Update and revalidate the orders data
 *
 * @async
 * @typedef {function} update
 * @param {string} order The new version of the order
 * @return {Promise<null>}
 */

/**
 * Retrieves orders.
 * @param {Object} [parameters=defaultParameters]
 * @param {string[]} [parameters.shopIds]
 * @param {string} [parameters.start]
 * @param {string} [parameters.end]
 * @param {RequestConfig} [options=ordersSwrConfig]
 * @typedef {Object} UpdateType
 * @property {update} UpdateType.update A function to update the orders table, and mutate
 * @property {string} KeyType.key The key of the request
 * @returns {SWRResponse & KeyType & UpdateType}
 */
export const useOrders = (parameters = defaultParameters, options = ordersSwrConfig) => {
	const { shopIds, start, end } = parameters;
	let assumedShopIds = shopIds;
	const { shop, data: shops } = useShop();
	// find active shop/s if no param - so we don't have to pass this in
	if (!shopIds || !shopIds.length) {
		if (shop?.id) {
			assumedShopIds = [shop.id];
		} else {
			assumedShopIds = shops.map(({ id }) => id);
		}
	}
	const key = options.key ? options.key : `orders_${assumedShopIds.join('-')}_${start}_${end}`;

	const swrResponse = useSWR(key, () => getOrders({ shopIds: assumedShopIds, start, end }), options);

	// add handy update function which will update and revalidate instantly
	const update = async (newOrder) => {
		const shopId = newOrder.pk.replace('shop#', '');
		await updateOrders({ shopId, order: newOrder });

		// update the local data immediately and revalidate (refetch)
		const ordersArray = [...swrResponse.data];
		let existingOrderIndex = swrResponse.data.findIndex((order) => order.id === newOrder.id);
		ordersArray.splice(existingOrderIndex, 1, newOrder);
		swrResponse.mutate(ordersArray);
	};

	return { ...swrResponse, key, update };
};
