import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

//pages
import { ChannelsLayout, channelsRoutes } from './pages/channels';
import { insightsRoutes, InsightsLayout } from './pages/insights';
import { inventoryRoutes, InventoryLayout } from './pages/inventory';
import { locationsRoutes, LocationsLayout } from './pages/locations';
import NotFound from './pages/errors/NotFound';
import { ordersRoutes, OrdersLayout } from './pages/orders';
import { RegisterLayout } from './pages/register';

import { ReportsLayout, reportsRoutes } from './pages/reports';
import { settingsRoutes } from './pages/settings';
import { SubscriptionRouter } from './pages/subscription';
import { upgradeRoutes, UpgradeLayout, plansLoader } from './pages/upgrade';
import { OnboardingLayout, OnboardingFallback } from './pages/onboarding';

import { Login } from './pages/login';
import { Logout } from './pages/logout';

import { Home } from './pages/home';
import { Dashboard } from './pages/dashboard';
import { Impersonate } from './pages/impersonate';

import { RootLayout } from './pages/rootLayout';
import { RedirectWithShopId } from '@utils/RedirectWithShopId';

export const router = createBrowserRouter(
	[
		{ path: '/login', element: <Login /> },
		{ path: '/impersonate', element: <Impersonate /> },
		{ path: '/register', element: <RegisterLayout /> },
		{
			path: '/',
			element: <RootLayout />,
			children: [
				// both chain & singles
				{ index: true, element: <Home /> },
				{ path: 'home/:shopId?', element: <Home /> },
				{ path: 'dashboard/:shopId?', element: <Dashboard /> },
				{ path: 'orders/:shopId?', element: <OrdersLayout />, children: ordersRoutes },
				{ path: 'insights', element: <InsightsLayout />, children: insightsRoutes },
				{ path: 'settings', children: settingsRoutes },
				{ path: 'reports/:shopId?', element: <ReportsLayout />, children: reportsRoutes },
				{ path: 'logout/:shopId?', element: <Logout /> },
				{
					path: 'upgrade/:productId/:period',
					element: <UpgradeLayout />,
					children: upgradeRoutes,
					id: 'upgrade',
					loader: plansLoader
				},

				// singles only
				{ path: 'inventory/:shopId?', element: <InventoryLayout />, children: inventoryRoutes },
				{ path: 'channels/:shopId?', element: <ChannelsLayout />, children: channelsRoutes },
				{ path: 'subscription/:shopId?', element: <SubscriptionRouter /> },
				{ path: 'onboarding/:shopId', element: <OnboardingLayout /> },

				// chain only
				{ path: 'locations', element: <LocationsLayout />, children: locationsRoutes },

				// chain fallbacks & redirects
				{ path: 'onboarding', element: <OnboardingFallback /> },

				// singles fallbacks & redirects
				{ path: 'locations', element: <RedirectWithShopId to="/home:shopId" /> },

				// fallback
				{
					path: '*',
					element: <NotFound />
				}
			]
		},
		// fallback
		{ path: '*', element: <NotFound /> }
	],
	{
		//  future flags we should opt into now to make our eventual v7 upgrade smoother
		future: {
			v7_skipActionStatusRevalidation: true,
			v7_fetcherPersist: true,
			v7_startTransition: true,
			// v7_relativeSplatPath: true,
			v7_normalizeFormMethod: true,
			v7_partialHydration: true
		}
	}
);
