import React, { useState } from 'react';

import { Page } from '@nearst/ui';
import { useShop } from '@services/ShopProvider';
import { useChannel } from '@services/channels';

import MetaCommerceStepOne from './components/steps/MetaCommerceStepOne';
import MetaCommerceStepTwo from './components/steps/MetaCommerceStepTwo';
import MetaCommerceStepThree from './components/steps/MetaCommerceStepThree';
import EnabledState from './components/steps/EnabledState';
import ChannelHeader from '../../components/ChannelHeader';

import styles from './MetaCommerce.module.scss';
import { Protect } from '@clerk/clerk-react';
import { Card, Icon } from '@nearst/ui';

const CHANNEL_ID = 'meta-commerce';

const ActiveStep = ({ channel }) => {
	// Making sure we only show 'step 3' when the user has just enabled the channel
	// by comparing to the channel status when the page loaded
	const [showIntermediary, setShowIntermediary] = useState(channel?.status !== 'enabled');

	if (channel?.status === 'pending' && channel?.config?.catalogId) {
		// regardless of the status being 'pending' or 'enabled', we show this step
		// if there is no domainVerificationCode set, to allow us to ask shops already on FB
		// to verify their domain for Instagram
		return <MetaCommerceStepTwo channel={channel} />;
	}

	if (channel?.status === 'enabled' && showIntermediary) {
		// when first enabling, we need to show an intermediate step
		// on the next page visit, we skip this and go to the normal 'enabled' state
		return <MetaCommerceStepThree channel={channel} finish={() => setShowIntermediary(false)} />;
	}

	if (channel?.status === 'enabled') {
		return <EnabledState channel={channel} />;
	}

	return <MetaCommerceStepOne />;
};

const MetaCommerce = () => {
	const { shop } = useShop();
	const { data: channel } = useChannel(CHANNEL_ID, shop.id);

	return (
		<>
			<ChannelHeader channelId={CHANNEL_ID} status={channel?.status} />
			<Page.Section>
				<Protect
					permission="org:channels:manage"
					fallback={
						<Card className={styles.disabledState}>
							<div className={styles.disabledState}>
								<Icon>lock</Icon>
								<h3>You don't have permission to enable Meta Commerce</h3>
							</div>
						</Card>
					}
				>
					<ActiveStep channel={channel} />
				</Protect>
			</Page.Section>
		</>
	);
};

export default MetaCommerce;
