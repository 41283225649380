const Categories = require('../model/Categories');
const ChannelDefinition = require('../model/ChannelDefinition');
const Requirements = require('../model/Requirements');

class MetaCommerceChannel extends ChannelDefinition {
	id = 'meta-commerce';
	title = 'Meta Commerce';
	description = `Drive engagement on your Instagram and Facebook pages by enabling consumers to explore your product catalog.`;
	category = Categories.SOCIAL_COMMERCE;

	iconUrl = 'https://go.near.st/hubfs/channels/meta.svg';
	coverImageUrl = 'https://go.near.st/hubfs/channels/headers/meta-commerce.png';

	longDescription = `
## Overview
Connecting your inventory to Meta Commerce will allow you to use a single product catalog to run ads, tag products in posts on Facebook and Instagram and send products in WhatsApp Business conversations.

Make your products visible to an audience you’ve already built on social media and get new customers through Facebook’s and Instagram’s discovery features.

## How it works
Follow our guided step-by-step process to automatically set up a Meta Commerce account and start syncing your catalog in less than 15 minutes.

After your catalog is set up, all Facebook pages, Instagram profiles and WhatsApp Business accounts connected to your Meta Business Manager account will be able to use the local products in your catalog in posts and messages.

We’ll create a Meta Commerce catalog for each of the stores on NearSt, so that you can pick and choose products from the right stores for the related Facebook pages and Instagram profiles.

## Requirements
- At least 50 in-stock products, with product titles, images and descriptions
- Manager-level access to your Meta Business Manager account
- At least one Facebook page or Instagram profile for your stores
	`;

	manageUrl = '/channels/{shopId}/settings/meta-commerce';
	productSpecificVisibility = true;

	worksWith = ['google', 'shop-page'];

	helpArticleUrls = [
		{
			title: 'Meta Commerce documentation',
			url: 'https://support.near.st/en/collections/3666558-meta-commerce'
		}
	];

	requirements = new Requirements({
		plans: ['standard', 'advanced'],
		countries: [
			'US',
			'CA',
			'GB',
			'AR',
			'BO',
			'BR',
			'CL',
			'CO',
			'CR',
			'DO',
			'EC',
			'SV',
			'GT',
			'NI',
			'MX',
			'PA',
			'PY',
			'PE',
			'PR',
			'UY',
			'VE',
			'DZ',
			'AM',
			'AT',
			'BE',
			'BG',
			'KH',
			'CI',
			'HR',
			'CY',
			'CZ',
			'DK',
			'EG',
			'EE',
			'FI',
			'FR',
			'GE',
			'DE',
			'GR',
			'GH',
			'GG',
			'HU',
			'IR',
			'IE',
			'IM',
			'IL',
			'IT',
			'JE',
			'KZ',
			'KE',
			'LV',
			'LB',
			'LT',
			'MT',
			'MD',
			'MA',
			'NL',
			'NO',
			'PK',
			'PL',
			'PT',
			'SA',
			'RS',
			'SI',
			'SK',
			'ZA',
			'ES',
			'SE',
			'CH',
			'TN',
			'TR',
			'UA',
			'AE',
			'GB',
			'AU',
			'BD',
			'KH',
			'HK',
			'ID',
			'JP',
			'KR',
			'LA',
			'MY',
			'NP',
			'NZ',
			'PK',
			'PH',
			'SG',
			'LK',
			'TW',
			'TH'
		]
	});
}

module.exports = MetaCommerceChannel;
