import { Card, Loader } from '@nearst/ui';
import styles from './SpinnerCard.module.scss';

const SpinnerCard = ({ message, title = '', className = '' }) => {
	return (
		<Card title={title} className={className}>
			<div className={styles.container}>
				<div>
					<div className={styles.loaderContainer}>
						<Loader />
					</div>
					{message && <p className={styles.loadingMessage}>{message}</p>}
				</div>
			</div>
		</Card>
	);
};

export default SpinnerCard;
