import React, { useState, useMemo } from 'react';
import { Icon, UItils } from '@nearst/ui';
import { Table as MatTable, TableBody, TableCell, TableFooter, TableHead, TableRow } from '@material-ui/core';

import styles from './Table.module.scss';

const SortLabel = ({ active, direction, onClick, children }) => {
	return (
		<span onClick={onClick} className={UItils.classnames(styles.sortLabel, active ? styles.active : '')}>
			{children}
			<Icon className={UItils.classnames(styles.direction, styles[direction], active ? styles.active : '')}>arrow_drop_down</Icon>
		</span>
	);
};

// example Field:
// {
//     id: 'ranking',
//     title: 'Ranking',
//     formatter: (row) => {
//         return <Ranking {...row} />;
//     },
//     sortFn: { asc: (a, b) => a.impressions - b.impressions, desc: (a, b) => b.impressions - a.impressions }
// }

/**
 * @callback SortFn
 * @param {Object} rowA
 * @param {Object} rowB
 *
 * @callback Format
 * @param {Object} row
 * @param {number} [index]
 *
 * @typedef {Object} Sort
 * @property {String} Sort.orderBy - The id of the field to order by
 * @property {'asc'|'desc'} [Sort.direction='asc'] - The direction of the sort
 * @property {SortFn} Sort.handler - The sort handler
 *
 * @typedef {Object} Field
 * @property {String} Field.id
 * @property {String} Field.title
 * @property {String} [Field.width]
 * @property {Format} Field.formatter
 * @property {Object} [Field.sortFn]
 * @property {SortFn} Field.sortFn.asc
 * @property {SortFn} Field.sortFn.desc
 *
 * Wrapper for MaterialUI Table
 * @param {Object} props
 * @param {Object[]} props.data - The rows of data
 * @param {Number }[props.initialLength=6] - The starting number of rows shown in the table
 * @param {Number }[props.maxLength=Math.min(data.length, 100)] - The upper limit of rows to be shown.
 * @param {Field[]} props.fields - Array of fields to configure table
 * @param {Boolean} [props.expandable=false] - Allow the table to expland
 * @param {Function} [props.onExpand] - Function called when expand is clicked - usually for analytics tracking
 * @param {Sort} [props.defaultSort] - Default field and handler to sort by
 */
const Table = ({ data, fields, initialLength = 6, maxLength = Math.min(data.length, 100), defaultSort, expandable = false, onExpand }) => {
	const [order, setOrder] = useState(defaultSort ? defaultSort : { orderBy: '1', direction: 'desc' });
	const [expanded, setExpanded] = useState(false);
	const visibleRows = useMemo(() => {
		let rows = data;
		if (order.handler) {
			rows = data.sort(order.handler);
		}
		rows = rows.slice(0, maxLength);
		if (expandable && !expanded) {
			rows = rows.slice(0, initialLength);
		}

		return rows;
	}, [data, order.handler, initialLength, maxLength, expanded, expandable]);

	const handleExpand = () => {
		setExpanded((expanded) => !expanded);
		onExpand?.(!expanded);
	};

	return (
		<MatTable
			className={styles.table}
			role="table"
			// stickyHeader
		>
			<TableHead className={styles.head}>
				<TableRow>
					{fields.map((field) => {
						return (
							<TableCell key={field.id} width={field.width}>
								{field.sortFn ? (
									<SortLabel
										active={order?.orderBy === field.id}
										direction={order?.orderBy === field.id ? order?.direction : 'desc'}
										onClick={() =>
											setOrder((existingOrder) => {
												let direction = 'desc';
												if (existingOrder.orderBy === field.id) {
													direction = existingOrder.direction === 'asc' ? 'desc' : 'asc';
												}
												return { orderBy: field.id, direction, handler: field.sortFn[direction] };
											})
										}
									>
										{field.title}
									</SortLabel>
								) : (
									field.title
								)}
							</TableCell>
						);
					})}
				</TableRow>
			</TableHead>
			<TableBody>
				{visibleRows &&
					visibleRows.map((row, rowIndex) => {
						return (
							<TableRow key={row.id || row.barcode || rowIndex}>
								{fields.map((field) => {
									return <TableCell key={field.id || field.title}>{field.formatter(row, rowIndex)}</TableCell>;
								})}
							</TableRow>
						);
					})}
			</TableBody>
			{expandable && (
				<TableFooter>
					<TableRow>
						<TableCell align="center" colSpan={fields.length} style={{ border: 'none' }}>
							{data.length <= initialLength || (
								<span className={styles.expand} onClick={handleExpand}>
									{expanded ? 'View less' : 'View more'}
								</span>
							)}
						</TableCell>
					</TableRow>
				</TableFooter>
			)}
		</MatTable>
	);
};

export default Table;
