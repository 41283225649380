import React from 'react';
import PropTypes from 'prop-types';
import Loader from '../Loader';
import './style.scss';

/**
 * @deprecated Use `<Page.Section>` instead where possible.
 */
const Block = ({ title, description, className = '', before, wide, loading, children, headerComponent }) => {
	return (
		<div className="block-wrapper">
			<div className="block-container" style={{ maxWidth: `${wide}` }}>
				{before ? before() : null}
				<div className="block-header">
					<h1 className="block-title">{title}</h1>
					{headerComponent}
				</div>
				<p className="block-description">{description}</p>
				{loading ? (
					<div className="loading-wrapper">
						<Loader />
					</div>
				) : (
					<div className={`${className} block-content`}>{children}</div>
				)}
			</div>
		</div>
	);
};

Block.propTypes = {
	title: PropTypes.node,
	description: PropTypes.node,
	className: PropTypes.string,
	children: PropTypes.node,
	loading: PropTypes.bool,
	before: PropTypes.func,
	wide: PropTypes.string,
	headerComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
};

export default Block;
