import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useRouteLoaderData, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import useSWR from 'swr';

import { useShop } from '@services/ShopProvider';
import { getCustomer, setPaymentMethodAsDefault, subscribeToPlan } from '@services/billing';

import styles from './UpgradeConfirm.module.scss';
import { CustomerPaymentDetails } from '../../../settings/components/CustomerDetails';
import { Button, Stack } from '@nearst/ui';
import LoadingSpinner from '@components/LoadingSpinner';

const retrievePaymentMethodParams = () => {
	const queryParams = new URLSearchParams(window.location.search);
	const setupIntentId = queryParams.get('setup_intent');
	const paymentMethodId = queryParams.get('payment_method');

	return { setupIntentId, paymentMethodId };
};

const UpgradeConfirm = () => {
	const { pricing } = useRouteLoaderData('upgrade');
	const { period, productId } = useParams();
	const plan = pricing[period];
	const title = pricing.title;

	let navigate = useNavigate();
	const [submitLoading, setSubmitLoading] = useState(false);
	const [error, setError] = useState('');

	const { data: shops } = useShop();
	const shopId = shops.find((shop) => shop.stripeCustomerId).id;
	const { data: customer, mutate } = useSWR([shopId, 'customer'], getCustomer);
	const { setupIntentId, paymentMethodId } = retrievePaymentMethodParams();

	const paymentPageUrl = `/upgrade/${productId}/${plan.billingCycle}/payment`;

	useEffect(() => {
		if (customer && !customer.paymentMethod && !setupIntentId && !paymentMethodId) {
			// Navigate to the payment setup page if no default payment method or setup intent:
			navigate(paymentPageUrl, { replace: true });
		}
	}, [customer, navigate, paymentPageUrl, setupIntentId, paymentMethodId]);

	useEffect(() => {
		if (setupIntentId || paymentMethodId) {
			// If a new payment method has been setup we set it manually as the default method, using the query params from the payment setup page:
			setPaymentMethodAsDefault(shopId, { paymentMethodId, setupIntentId }).then(mutate);
		}
	}, [setupIntentId, paymentMethodId, shopId, mutate]);

	const submit = async () => {
		setSubmitLoading(true);
		setError('');
		try {
			await subscribeToPlan(plan.id);
			navigate(`/settings/${shopId}?upgradeSuccess#billing`);
		} catch (e) {
			setError(e.response.data || e.message);
		} finally {
			setSubmitLoading(false);
		}
	};

	return (
		<>
			<h2>Confirm upgrade</h2>

			<p className={styles.planDetails}>
				<strong>NearSt {title}</strong>
				<br />
				{plan.totalPrice}
				{shops.length > 1 ? ` for ${shops.length} locations` : ''}, billed {plan.billingCycle}
				<br />
				Subscription starts {format(new Date(), 'MMMM do, yyyy')}
			</p>
			{customer?.paymentMethod ? (
				<>
					<div className={styles.paymentMethodHeader}>
						<strong>Payment method</strong>
						<Link to={paymentPageUrl}>Edit ›</Link>
					</div>
					<Stack>
						<CustomerPaymentDetails customer={customer} />
						{error && <p className={styles.error}>{error}</p>}
						<div>
							<Button onClick={submit} primary className={styles.button} disabled={submitLoading}>
								Upgrade
							</Button>
						</div>
					</Stack>
				</>
			) : (
				<LoadingSpinner size={40} />
			)}
		</>
	);
};

export default UpgradeConfirm;
