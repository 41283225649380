import React, { useState } from 'react';
import { Button, InputGroup, TemplateModal, Textarea } from '@nearst/ui';
import * as Sentry from '@sentry/browser';
import { hideProducts } from '@services/stock';

import { sendAnalytics } from '../../../../utils/sendAnalytics';

import styles from './HideBarcodeModal.module.scss';

const HideBarcodeModal = ({ open, close, shop, mutate }) => {
	const [errorMsg, setErrorMsg] = useState();
	const [barcodeInput, setBarcodeInput] = useState();
	const [loading, setLoading] = useState(false);
	const [updatedBarcodes, setUpdatedBarcodes] = useState([]);
	const [invalidBarcodes, setInvalidBarcodes] = useState([]);

	const clear = () => {
		setLoading(false);
		setBarcodeInput(null);
		setUpdatedBarcodes([]);
		setInvalidBarcodes([]);
	};

	const handleChange = (e) => {
		setBarcodeInput(e.target.value);
		setUpdatedBarcodes([]);
		setInvalidBarcodes([]);
	};

	const handleHideSubmit = () => {
		if (!barcodeInput) setErrorMsg('Please enter a valid barcode');
		setLoading(true);
		const barcodes = barcodeInput.split('\n').filter((str) => str);

		hideProducts(shop.id, barcodes, false)
			.then((response) => {
				sendAnalytics('Barcode Form', 'Submit barcode', shop.name);
				const failures = response.filter((r) => !r.success);
				setInvalidBarcodes(failures.map((r) => r.barcode));
				setUpdatedBarcodes(response.filter((r) => r.success));
				setLoading(false);
				mutate();

				if (failures.length === 0) {
					clear();
					close();
				}
			})
			.catch((e) => {
				console.error(e);
				Sentry.captureException(e);
				setErrorMsg('Something went wrong processing your request');
				setLoading(false);
			})
			.finally(() => setLoading(false));
	};

	return (
		<TemplateModal isOpen={open} onDismiss={close} title={'Hide products'} showDismissButton={false}>
			<div className={styles.container}>
				<InputGroup label="Enter one barcode per line" htmlFor="barcodes">
					<Textarea
						disabled={loading}
						value={barcodeInput}
						onChange={handleChange}
						placeholder="barcode001&#10;barcode002&#10;barcode003"
					/>
				</InputGroup>

				<p>{errorMsg}</p>

				<div className={styles.buttonContainer}>
					<Button disabled={!barcodeInput || loading} onClick={handleHideSubmit} confirm>
						Hide products
					</Button>
				</div>

				{updatedBarcodes.length || invalidBarcodes.length ? (
					<div>
						{invalidBarcodes.length > 0 && <p>⚠️ We couldn't hide some products:</p>}
						{updatedBarcodes.length ? (
							<div>
								✅ {updatedBarcodes.length} updated barcode
								{updatedBarcodes.length === 1 ? '' : 's'}
							</div>
						) : null}
						{invalidBarcodes.length > 0 && <div>❌ Invalid barcodes: {invalidBarcodes.join(', ')} </div>}
					</div>
				) : null}

				<p className={styles.disclaimer}>It may take up to an hour before these changes appear across your channels.</p>
			</div>
		</TemplateModal>
	);
};

export default HideBarcodeModal;
