import { Protect } from '@clerk/clerk-react';
import React from 'react';
import { Outlet } from 'react-router-dom';

import AccessDenied from '../errors/AccessDenied';
import { ClicksToSales } from './ClicksToSales';
import { ExportData } from './ExportData';
import { OrganicVisibility } from './OrganicVisibility';
import { ReportsOverview } from './ReportsOverview';
import { IndustryInsightsPage } from './IndustryInsights';
import { AdsImpact } from './AdsImpact';

export const ReportsLayout = () => {
	return (
		<Protect permission="org:reports:read" fallback={<AccessDenied />}>
			<Outlet />
		</Protect>
	);
};

export const reportsRoutes = [
	{ path: 'export-data', element: <ExportData /> },
	{ path: 'organic-visibility', element: <OrganicVisibility /> },
	{ path: 'clicks-to-sales', element: <ClicksToSales /> },
	{ path: 'ads-impact', element: <AdsImpact /> },
	{ path: 'industry-insights', element: <IndustryInsightsPage /> },
	{ index: true, element: <ReportsOverview /> }
];
