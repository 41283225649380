import React, { useState } from 'react';
import { useOrganization } from '@clerk/clerk-react';

import { Button, Icon, InputGroup, TemplateModal, TextInput } from '@nearst/ui';
import RoleSelect, { useCanEditRoles } from './RoleSelect';
import styles from './TeamInviteModal.module.scss';

const TeamInviteModal = ({ revalidate }) => {
	const [open, setOpen] = useState(false);
	const [emailAddress, setEmailAddress] = useState('');
	const [role, setRole] = useState('org:admin');
	const [disabled, setDisabled] = useState(false);
	const { organization } = useOrganization();
	const { canEditRoles } = useCanEditRoles();

	const onSubmit = async (e) => {
		e.preventDefault();
		if (!emailAddress) {
			return;
		}

		setDisabled(true);

		try {
			await organization.inviteMember({ emailAddress, role });
			setEmailAddress('');
			setOpen(false);
			revalidate();
		} catch (e) {
			alert(e.errors?.[0].longMessage || e.message);
		}

		setDisabled(false);
	};

	return (
		<>
			<TemplateModal
				title="Invite team member"
				className={styles.modal}
				isOpen={open}
				onDismiss={() => setOpen(false)}
				showDismissButton={false}
			>
				<p>Send an invite to a team member to give them access to your dashboard.</p>
				<form onSubmit={onSubmit}>
					<InputGroup label="Email address" htmlFor="email">
						<TextInput
							id="email"
							name="email"
							type="text"
							value={emailAddress}
							onChange={(e) => setEmailAddress(e.target.value)}
						/>
					</InputGroup>

					{canEditRoles ? (
						<InputGroup label="Role" htmlFor="role">
							<RoleSelect role={role} onChange={(role) => setRole(role)} id="role" />
						</InputGroup>
					) : null}

					<Button primary type="submit" disabled={!emailAddress || disabled}>
						Send invite
					</Button>
				</form>
			</TemplateModal>
			<Button primary onClick={() => setOpen(true)}>
				<Icon>person_add</Icon>
				Invite
			</Button>
		</>
	);
};

export default TeamInviteModal;
