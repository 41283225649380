import React from 'react';
import { StatusIcon } from '@nearst/ui';

import styles from './Issues.module.scss';

const Issues = ({ children: product }) => {
	return (
		<div className={styles.cell}>
			{product.issues?.includes('MissingProductData') ? (
				<span className={styles.issuesContainer} data-cy="inventory-viewer-missing-data">
					<StatusIcon status="error" /> Additional data required
				</span>
			) : null}
		</div>
	);
};

export default Issues;
