import React from 'react';
import { Link } from 'react-router-dom';

import { Page } from '@nearst/ui';
import { useShop } from '@services/ShopProvider';

const AllChannelsButton = () => {
	const { shop } = useShop();
	return (
		<Link to={`/channels/${shop.id}`}>
			<Page.BackButton>Channels</Page.BackButton>
		</Link>
	);
};

export default AllChannelsButton;
