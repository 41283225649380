import React from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { useIsOnboarding } from '@components/OnboardingAccessControl';
import { useCheckPermission } from '@services/auth/hooks';
import { useShop } from '@services/ShopProvider';

export const Home = () => {
	const params = useParams();
	const { data: shops } = useShop();
	const hasInsightsPermission = useCheckPermission('org:insights:read');
	const isOnboarding = useIsOnboarding();
	const homepage = hasInsightsPermission ? '/dashboard' : '/orders';

	let shopId;
	const isIndie = shops.length === 1;
	if (isIndie) shopId = shops[0].id;
	if (params?.shopId) shopId = params.shopId;

	if (isOnboarding) {
		return <Navigate replace to={`/onboarding${shopId ? '/' + shopId : ''}`} />;
	}

	return <Navigate replace to={`${homepage}${shopId ? '/' + shopId : ''}`} />;
};
