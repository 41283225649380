import React from 'react';

import { Layer } from 'react-map-gl';

export const ClusterLayer = () => {
	return (
		<Layer
			{...{
				id: 'clusters',
				type: 'circle',
				source: 'geo-events',
				filter: ['has', 'point_count'],
				paint: {
					'circle-color': '#50a1ff',
					'circle-radius': ['step', ['get', 'point_count'], 20, 10, 30, 50, 40],
					'circle-opacity': 0.75,
					'circle-stroke-width': 2,
					'circle-stroke-color': '#50a1ff'
				}
			}}
		/>
	);
};
export const ClusterCountLayer = () => {
	return (
		<Layer
			{...{
				id: 'cluster-count',
				type: 'symbol',
				source: 'geo-events',
				filter: ['has', 'point_count'],
				layout: {
					'text-field': ['get', 'point_count_abbreviated'],
					'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
					'text-size': 12
				}
			}}
		/>
	);
};

export const UnclusteredPointsLayer = () => {
	return (
		<Layer
			{...{
				id: 'unclustered-point',
				type: 'circle',
				source: 'geo-events',
				filter: ['!', ['has', 'point_count']],
				paint: {
					'circle-color': '#50a1ff',
					'circle-radius': 5,
					'circle-opacity': 0.75,
					'circle-stroke-width': 2,
					'circle-stroke-color': '#50a1ff'
				}
			}}
		/>
	);
};
