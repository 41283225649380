import concurrency from 'p-limit';
import { customiseProduct } from '@services/stock';

const processFile = async (shopId, productData, setProgress) => {
	const barcodesMatched = productData.length;
	const processResult = {
		barcodesMatched,
		notFound: 0,
		incompleteData: 0
	};

	setProgress({ value: 0, max: barcodesMatched });

	const limit = concurrency(6);

	await Promise.all(
		productData.map((result) =>
			limit(async () => {
				try {
					result.images = [result.image_url];
					await customiseProduct(result.barcode, shopId, result);
				} catch (e) {
					if (e.response.data === 'Barcode not found') {
						processResult.notFound++;
					} else {
						processResult.incompleteData++;
					}
				}
				setProgress(({ value, max }) => ({ value: value + 1, max }));
			})
		)
	);

	return processResult;
};

export default processFile;
