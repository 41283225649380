import React, { useState } from 'react';
import { Button, Card, StatusIcon } from '@nearst/ui';

import EditChannelVisibilityModal, { checkEditableChannels } from '../../components/EditChannelVisibilityModal';
import { getChannelsForStockItem } from '@services/stockChannels';
import styles from './ChannelVisibilityCard.module.scss';
import { useCheckPermission } from '@services/auth/hooks';
import AuthorizationTooltip from '@components/AuthorizationTooltip';

const getDescription = (stockItem) => {
	if (stockItem.issues?.length > 0) return 'You need to fix all product issues before your product is shown in channels.';
	if (!stockItem.enabled) return 'Your product is currently hidden across all enabled channels.';
	if (stockItem.enabled) return 'Your product is displayed in all enabled channels.';
	return undefined;
};

const getStatus = (stockItem, channelStatus) => {
	if (stockItem.issues?.length > 0) return 'hidden';
	if (!stockItem.enabled) return 'hidden';
	if (!channelStatus) return 'hidden';
	return 'active';
};

const canEditVisibility = (stockItem, channelStatuses) => {
	if (stockItem.issues?.length > 0) return false;
	if (!stockItem.enabled) return false;
	const editableChannels = checkEditableChannels(channelStatuses);
	if (!editableChannels.length) return false;
	return true;
};

const ChannelVisibilityItem = ({ stockItem, channelName, enabled }) => {
	const channelStatus = getStatus(stockItem, enabled);

	return (
		<>
			<StatusIcon status={channelStatus}></StatusIcon>
			<span data-status={channelStatus}>{channelName}</span>
		</>
	);
};

const ChannelVisibilityCard = ({ shopId, stockItem, channels }) => {
	const [editModalShown, setEditModalShown] = useState(false);

	const authorized = useCheckPermission('org:channels:manage');

	const channelDescription = getDescription(stockItem);
	const channelStatuses = getChannelsForStockItem(shopId, stockItem, channels);

	return (
		<Card title="Channel visibility" data-cy="inventory-item-channel-visibility">
			<EditChannelVisibilityModal
				stockItems={[stockItem]}
				isOpen={editModalShown}
				shopId={shopId}
				channelStatuses={channelStatuses}
				onDismiss={() => setEditModalShown(false)}
			/>

			{channelDescription && <p className={styles.channelDescription}>{channelDescription}</p>}
			{channelStatuses?.map(({ label, enabled }) => {
				return (
					<div key={label} className={styles.channelItem}>
						<ChannelVisibilityItem stockItem={stockItem} channelName={label} enabled={enabled} />
					</div>
				);
			})}
			{canEditVisibility(stockItem, channelStatuses) && (
				<div className={styles.buttonContainer}>
					<AuthorizationTooltip authorized={authorized} tooltipContent={`You don't have permission to edit this`}>
						<Button onClick={() => setEditModalShown(true)} disabled={!authorized}>
							Edit
						</Button>
					</AuthorizationTooltip>
				</div>
			)}
		</Card>
	);
};

export default ChannelVisibilityCard;
