import React from 'react';
import styles from './InsightTeaserOverlay.module.scss';
import { Button, Icon } from '@nearst/ui';
import { useUpgradeModal } from '@services/UpgradeProvider';

/**
 * @param {Object} props
 * @param {function} [props.upgradeAction]
 * @param {boolean} [props.showButton=false]
 * @param {(JSX.Element) | string} [props.children]
 */
const InsightTeaserOverlay = ({ children, showButton = false, upgradeAction }) => {
	const { openUpgradeModal } = useUpgradeModal();

	return (
		<div className={styles.overlay}>
			<div className={styles.gradientOverlay}></div>
			<div className={styles.textOverlay}>
				<h2>More data. More insights.</h2>
				<p>
					Upgrade your NearSt plan to better understand your local customers with insights into product popularity, what local
					shoppers are searching for, and more.
				</p>
				{showButton ? (
					<Button primary className={styles.button} onClick={upgradeAction || openUpgradeModal}>
						Upgrade to unlock <Icon>lock</Icon>
					</Button>
				) : null}
			</div>
			{children}
		</div>
	);
};

export default InsightTeaserOverlay;
