import React from 'react';
import { addDays, format } from 'date-fns';
import { Page, Stack } from '@nearst/ui';
import DatePicker from '../../../components/DatePicker';
import { ShareOfProfileViewsCard } from './Cards/ShareOfProfileViewsCard/ShareOfProfileViewsCard';
import { ShareOfProfileInteractionsCard } from './Cards/ShareOfProfileInteractionsCard';
import { ProfileViewsPerformanceCard } from './Cards/ProfileViewsPerformanceCard';
import { ProfileInteractionsPerformanceCard } from './Cards/ProfileInteractionsPerformanceCard';
import { GBPSearchKeywords } from './Cards/GBPSearchKeywordsCard';
import { useShop } from '@services/ShopProvider';

import styles from './BusinessProfile.module.scss';
import { TotalPerformanceCard } from './Cards/TotalPerformanceCard';

const BusinessProfile = () => {
	const { data, shop } = useShop();
	const shopIds = shop.id ? [shop.id] : data.map(({ id }) => id);

	return (
		<>
			<Page.Header>
				<h1>Google Business Profile</h1>
				<p>
					A breakdown of the interactions with your Google Business Profile. Data available up to{' '}
					{format(addDays(new Date(), -4), 'MMM d')}.
				</p>
				<DatePicker showComparison showInterval />
			</Page.Header>
			<Page.Section>
				<Stack space="1.5rem">
					<TotalPerformanceCard shopIds={shopIds} />
					<div className={styles.container}>
						<ShareOfProfileViewsCard shopIds={shopIds} />
						<ProfileViewsPerformanceCard shopIds={shopIds} />
					</div>
					<div className={styles.container}>
						<ShareOfProfileInteractionsCard shopIds={shopIds} />
						<ProfileInteractionsPerformanceCard shopIds={shopIds} />
					</div>
					<GBPSearchKeywords shopIds={shopIds} />
				</Stack>
			</Page.Section>
		</>
	);
};

export default BusinessProfile;
