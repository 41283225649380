import { useAuth } from '@clerk/clerk-react';

export const useCheckPermission = (permission) => {
	const { has } = useAuth();
	return has({ permission });
};

export const useCheckRole = (role) => {
	const { has } = useAuth();
	return has({ role });
};
