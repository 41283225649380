import React, { useState } from 'react';

import { Button, Icon } from '@nearst/ui';

import { getPaymentUpdateUrl } from '@services/billing';
import { useCheckPermission } from '@services/auth/hooks';
import AuthorizationTooltip from '@components/AuthorizationTooltip';

const UpdatePaymentLink = ({ shopId, children, ...props }) => {
	const [loading, setLoading] = useState(false);
	const authorized = useCheckPermission('org:billing:manage');

	const submit = async () => {
		setLoading(true);
		const response = await getPaymentUpdateUrl(shopId);
		window.location.href = response.url;
	};

	return (
		<AuthorizationTooltip authorized={authorized}>
			<Button {...props} disabled={!authorized || loading} onClick={authorized ? submit : undefined}>
				{loading ? <Icon>sync</Icon> : children}
			</Button>
		</AuthorizationTooltip>
	);
};

export default UpdatePaymentLink;
