import React from 'react';
import { Protect } from '@clerk/clerk-react';
import { DocumentChartBarIcon } from '@heroicons/react/20/solid';

import Sidebar from '@components/Sidebar';
import { useShop } from '@services/ShopProvider';

const Reports = () => {
	const { shop, data: shops } = useShop();
	const shopSelected = !!shop?.id;

	const onFreemium = shopSelected ? shop.plan === 'freemium' : shops.every((shop) => shop.plan === 'freemium');
	if (onFreemium) return null;

	return (
		<Protect permission="org:reports:read">
			<Sidebar.Item to="/reports">
				<DocumentChartBarIcon />
				Reports
			</Sidebar.Item>
		</Protect>
	);
};

export default Reports;
