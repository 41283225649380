import React from 'react';
import Tippy from '@tippyjs/react';

const defaultTooltipContent = "You don't have permission to edit this";

/**
 * Wrap this around items to add a tooltip if the user doesn't have the required permission
 *
 * @typedef AuthorizationTooltipProps
 * @property {boolean} [authorized] Should the tooltip display or not. Basically.
 * @property {string} [tooltipContent] The tooltip text that appears when the user doesn't have the permission
 * @property {JSX.Element} children The children components that should be rendered as the root of the tooltip
 *
 * @param {AuthorizationTooltipProps} props
 * @returns {JSX.Element}
 */
const AuthorizationTooltip = ({ authorized, tooltipContent = defaultTooltipContent, children }) => {
	if (authorized) return children;

	return (
		<Tippy content={tooltipContent}>
			<span>{children}</span>
		</Tippy>
	);
};

export default AuthorizationTooltip;
