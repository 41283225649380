import React, { useState } from 'react';
import mixpanel from 'mixpanel-browser';
import { Icon, Modal, Cluster } from '@nearst/ui';

import styles from './DisableMetaCommerce.module.scss';
import { useShop } from '@services/ShopProvider';
import { disableChannel } from '@services/channels';
import LoadingButton from '../../../LoadingButton';

const DisableMetaCommerce = ({ showDisabledModal, setShowDisabledModal }) => {
	const { shop, updateShop } = useShop();
	const close = () => setShowDisabledModal(false);
	const [loading, setLoading] = useState(false);

	const confirm = async () => {
		setLoading(true);
		mixpanel.track('Meta Commerce disabled', { distinct_id: shop.id });
		await disableChannel('meta-commerce', shop.id, { clearConfig: true });
		await updateShop(shop.id, {
			facebookCatalogEnabled: false,
			facebookAccessToken: null,
			facebookCatalogId: null,
			facebookPages: null,
			facebookDomainVerificationCode: null
		});
		close();
		setLoading(false);
	};

	return (
		<Modal className={styles.metaModal} isOpen={showDisabledModal} onDismiss={close} aria-label="meta-modal">
			<div className={styles.metaModalTitleContainer}>
				<h2 className={styles.metaModalTitleText}>Disable Meta Commerce</h2>
				<button className={styles.metaModalExitButton} onClick={close}>
					<Icon>clear</Icon>
				</button>
			</div>
			<div className={styles.metaModalContainer}>
				<div>
					<p>
						This will disable the NearSt product catalog and remove access to your business account. If you want to re-enable at
						a future date, you will need to set up the integration again.
					</p>
					<p>Are you sure you want to disable it?</p>

					<Cluster className={styles.metaModalActionButton}>
						<LoadingButton onClick={confirm} loading={loading} disabled={loading} warning>
							Disable Meta Commerce
						</LoadingButton>
					</Cluster>
				</div>
			</div>
		</Modal>
	);
};

export default DisableMetaCommerce;
