import React from 'react';
import { Navigate } from 'react-router-dom';

import { useShop } from '@services/ShopProvider';
import useChannelInfo from './useChannelInfo';

export const useTemplateRouting = (channel) => {
	const { shop } = useShop();
	const { channelId, channelDefinition, status } = useChannelInfo(channel);

	// shop-page channel is a special case because it's always enabled
	if (channelId === 'shop-page') {
		return `/channels/${shop.id}/settings/shop-page`;
	}

	if (!channelDefinition) {
		return `/channels/${shop.id}`;
	}

	if (status === 'enabled' || status === 'pending') {
		return `/channels/${shop.id}/overview/${channelId}`;
	}
	return `/channels/${shop.id}/details/${channelId}`;
};

const TemplateRouter = () => {
	const url = useTemplateRouting();

	return <Navigate to={url} replace />;
};

export default TemplateRouter;
