import React from 'react';
import { Icon, DropdownItem, DropdownMenu } from '@nearst/ui';

const types = {
	pie: (
		<>
			<Icon>data_usage</Icon> Pie chart
		</>
	),
	bar: (
		<>
			<Icon>bar_chart</Icon> Bar chart
		</>
	),
	table: (
		<>
			<Icon>grid_on</Icon> Table
		</>
	)
};

const ChartTypeSelector = ({ chartType, setChartType }) => {
	const selectedType = types[chartType];

	return (
		<DropdownMenu primary={false} buttonText={selectedType}>
			{Object.entries(types).map(([type, content]) => (
				<DropdownItem key={type} active={chartType === type} onClick={() => setChartType(type)}>
					{content}
				</DropdownItem>
			))}
		</DropdownMenu>
	);
};

export default ChartTypeSelector;
