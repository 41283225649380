import React, { useState } from 'react';
import { ActualButton as Button, Stack, Cluster } from '@nearst/ui';
import { useFormContext } from 'react-hook-form';

import Field from '../../Components/Fields/Field';
import Dropdown from '../../Components/Fields/Dropdown';
import styles from '../Forms.module.scss';
import { isEmpty, countries, setValues, getGooglePlaceDetailsFromAddress, parseOpeningHours, getGooglePlaceDetails } from '../../utils';
import schema from '../../schema';

const AddressForm = ({ setStage }) => {
	const {
		formState: { errors, isValid },
		clearErrors,
		getValues,
		reset,
		setValue,
		register
	} = useFormContext();

	// here as a baseline to reset back to
	const [originalValues] = useState(getValues());

	const handleSave = async (e) => {
		e.preventDefault();
		const changes = getValues();
		if (isEmpty(changes)) {
			// wipe clean - reset back to defaults
			reset({ keepDefaultValues: true });
		} else {
			if (isValid) {
				try {
					const query = [changes.name, changes.addressLine1, changes.postcode].filter(Boolean).join(', ');
					const foundPlaces = await getGooglePlaceDetailsFromAddress(query);
					if (foundPlaces.length) {
						const placeDetails = await getGooglePlaceDetails(foundPlaces[0].place_id);
						const geolocation = { lat: placeDetails.geometry.location.lat(), lng: placeDetails.geometry.location.lng() };

						const details = {
							googlePlaceId: foundPlaces[0].place_id,
							openingHours: parseOpeningHours(placeDetails.opening_hours),
							geolocation
						};
						Object.keys(details).forEach((fieldName) => register(fieldName, { validate: schema[fieldName] }));
						setValues(details, setValue);
					}
				} catch (e) {
					console.error(`Error getting extra location info from the Places API: ${e.message}`);
				}
				setStage('address_complete');
			}
		}
	};

	const handleCancel = (e) => {
		e.preventDefault();
		const changes = getValues();
		// case where nothing is there
		if (isEmpty(changes) || isEmpty(originalValues)) {
			// wipe clean - reset back to defaults
			reset({ keepDefaultValues: true });
			setStage('autocomplete');
		} else {
			// go back to what we started with
			// go out of editing mode
			setValues({ ...originalValues }, setValue);
			// in case where form is opened straight from autocomplete and has invalid data
			if (Object.keys(errors).length && originalValues.stage === 'autocomplete_error') {
				// wipe clean - reset back to defaults
				reset({ keepDefaultValues: true });
			} else {
				clearErrors();
				setStage('address_complete');
			}
		}
	};

	return (
		<>
			<div className={styles.label}>Store address</div>
			<form className={styles.location}>
				<Stack className={styles.inputs} space="0.5rem">
					<Field name="name" required>
						Shop name
					</Field>
					<div className={styles.half}>
						<Field name="addressLine1" required>
							Address line 1
						</Field>
						<Field name="addressLine2">Address line 2</Field>
					</div>
					<div className={styles.half}>
						<Field name="postcode" required>
							Postcode
						</Field>
						<Dropdown name="country" list={countries} error="Please choose a country from the list." required>
							Country
						</Dropdown>
					</div>
					<Field name="publicPhoneNumber">Public phone number</Field>
				</Stack>
				<Cluster>
					<Button primary onClick={handleSave} disabled={!isValid}>
						Save
					</Button>
					<Button secondary onClick={handleCancel}>
						Cancel
					</Button>
				</Cluster>
			</form>
		</>
	);
};

export default AddressForm;
