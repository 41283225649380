const Categories = require('../model/Categories');
const ChannelDefinition = require('../model/ChannelDefinition');
const Requirements = require('../model/Requirements');

class SearchWidgetChannel extends ChannelDefinition {
	id = 'search-widget';
	title = 'Search Widget';
	description = 'Allow website customers to quickly search in-store stock for any location.';
	category = Categories.OMNICHANNEL_EXPERIENCE;

	iconUrl = 'https://go.near.st/hubfs/channels/nearst.png';
	coverImageUrl = 'https://go.near.st/hubfs/channels/headers/search-widget.png';

	longDescription = `
## Overview
The Product Locator is a widget designed to be embedded on your website product pages, allowing customers to see which of your locations currently have the specified product in stock.

## How it works
Embedding the widget in your website is very easy and only requires a few lines of code. You can see an example of the widget and play with the available parameters [here](https://product-locator.near.st/builder).

Adding the Product Locator will enable the customer to easily check which of your stores have the product in stock near their current location, complementing your e-commerce purchase journey.

## Requirements
- Online website with product pages
- Consistent use of barcodes across website and NearSt stock feed
	`;

	manageUrl = '/channels/{shopId}/settings/search-widget';
	productSpecificVisibility = false;

	worksWith = ['google'];

	helpArticleUrls = [
		{
			title: 'Adding the search widget',
			url: 'https://support.near.st/en/articles/6679923-how-to-add-the-nearst-search-widget-to-your-website'
		}
	];

	requirements = new Requirements({
		plans: ['standard', 'advanced']
	});
}

module.exports = SearchWidgetChannel;
