import React from 'react';
import Props from 'prop-types';
import Icon from '../Icon';
import { classnames } from '../../utils';

import styles from './style.module.scss';

/**
 * Visual status icon.
 *
 * @typedef {object} StatusIconProps
 * @property {'active'|'warning'|'error'|'waiting'|'hidden'} status The status to display
 * @property {string} [className]
 *
 * @param {React.HTMLAttributes<HTMLSpanElement> & StatusIconProps} props
 * @returns {JSX.Element}
 */
const StatusIcon = ({ status, className = '', ...props }) => {
	const iconMap = {
		active: 'done',
		warning: 'priority_high',
		error: 'priority_high',
		waiting: 'hourglass_bottom',
		hidden: 'visibility_off'
	};
	const muiIcon = iconMap[status];

	return (
		<Icon data-cy="status-icon" className={classnames(className, styles.icon, styles[status])} {...props}>
			{muiIcon}
		</Icon>
	);
};

Icon.propTypes = {
	children: Props.string.isRequired,
	color: Props.string,
	className: Props.string,
	style: Props.object,
	onClick: Props.func,
	rest: Props.object
};

export default StatusIcon;
