import Clerk from '@clerk/clerk-js';

export const clerkInstance = new Clerk(import.meta.env.VITE_CLERK_PUBLISHABLE_KEY);

export const getAuthSession = async () => {
	await clerkInstance.load();
	const { user, session } = clerkInstance;

	const email = user.primaryEmailAddress.emailAddress;
	const token = await session.getToken();
	return { email, token };
};

export const switchToOrganization = async (organizationId) => {
	await clerkInstance.load();

	const { session } = clerkInstance;
	session.clearCache();

	return clerkInstance.setActive({
		organization: organizationId
	});
};

export const getOrganizationMemberships = async (organizationId) => {
	const organization = await clerkInstance.getOrganization(organizationId);
	const memberships = await organization.getMemberships({ limit: 100 });
	return memberships;
};
