import React from 'react';
import PropTypes from 'prop-types';
import { classnames } from '../../utils';

import './style.scss';

const Root = ({ className = '', children }) => {
	return <div className={classnames('root', className)}>{children}</div>;
};

Root.propTypes = {
	children: PropTypes.node.isRequired
};

export default Root;
