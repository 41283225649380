import React from 'react';
import { useShop } from '../ShopProvider';
import qs from 'qs';
import axios from 'axios';

/*
 * Feature flags are used to enable/disable features in the app, for QA testing
 * whilst building a feature in segments, without blocking the CI deploy.
 *
 * To enable a feature flag, add `?ff=flag1,flag2` to the URL.
 * To disable, add `?ff=` to the URL.
 *
 * Flag values are stored in localStorage, so will persist across page refreshes.
 *
 * More details:
 * https://www.notion.so/nearst/35382cd86c51463ca5077b4ea5275e6b
 */

export const getLocalFlags = () => (localStorage.getItem('featureFlags') || '').split(',');
export const getGlobalFlags = () => (localStorage.getItem('globalFeatureFlags') || '').split(',');

export const setGlobalFlags = (flags) => {
	if (flags && Object.keys(flags).length) {
		const stringFlags = Object.entries(flags)
			.filter(([, value]) => value)
			.map(([key]) => key)
			.join(',');
		localStorage.setItem('globalFeatureFlags', stringFlags);
	}
};

/**
 * Get whether a feature flag is enabled
 * @param {string} flag Name of the feature flag
 * @returns {boolean}
 */
export const useFlag = (flag) => {
	const { shop, data: shops } = useShop();
	const findFlag = (shop) => shop && shop.featureFlags && shop.featureFlags.includes(flag);
	// if one shop, check that shop, don't check the whole chain
	if (shop?.id) {
		if (findFlag(shop)) {
			return true;
		}
	} else if (shops?.every(findFlag)) {
		return true;
	}
	const flags = getLocalFlags();
	return flags.includes(flag);
};

export const getRemoteGlobalFlags = async () => {
	try {
		const { data: globalFlags } = await axios.get('https://nearlive-myshop-features.s3.eu-west-1.amazonaws.com/feature-flags.json', {
			headers: { 'Cache-Control': 'no-store' }
		});
		return globalFlags[import.meta.env.MODE];
	} catch (e) {
		console.error(`Error accessing global flags: ${e}`);
		return {};
	}
};

const setLocalFlags = () => {
	if (window?.location?.search) {
		const queryStrings = qs.parse(window?.location?.search.substring(1));
		if (queryStrings.hasOwnProperty('ff')) {
			const flags = queryStrings.ff;
			localStorage.featureFlags = flags;
		}
	}
};

// Save flags to localStorage on page load.
// This will run every time useFlags is called in a component
setLocalFlags();
