import React from 'react';
import { Protect } from '@clerk/clerk-react';
import { Outlet, Navigate } from 'react-router-dom';

import { RedirectWithShopId, FallbackWithShopId } from '@utils/RedirectWithShopId';
import AccessDenied from '../errors/AccessDenied';

import ChannelsDashboard from './ChannelsDashboard';
import SearchWidget from './SettingsPages/SearchWidget';
import LocalCheckout from './SettingsPages/LocalCheckout';
import ShopPage from './SettingsPages/ShopPage';
import ProductReservations from './SettingsPages/ProductReservations';
import ProductLocator from './SettingsPages/ProductLocator';
import MetaCommerce from './SettingsPages/MetaCommerce';

import ChannelDetails from './TemplatePages/ChannelDetails';
import ChannelIssues from './TemplatePages/ChannelIssues';
import TemplateRouter from './TemplatePages/TemplateRouter';

export const ChannelsLayout = () => {
	return (
		<FallbackWithShopId>
			<Protect permission="org:channels:read" fallback={<AccessDenied />}>
				<Outlet />
			</Protect>
		</FallbackWithShopId>
	);
};

export const channelsRoutes = [
	// shop only
	{ index: true, element: <ChannelsDashboard /> },
	{ path: 'manage/:channel', element: <TemplateRouter /> },
	{ path: 'overview/:channel', element: <ChannelIssues /> },
	{ path: 'details/:channel', element: <ChannelDetails /> },
	{ path: 'settings/google', element: <Navigate replace to="details/google" /> },
	{ path: 'settings/local-ads', element: <RedirectWithShopId to="/subscription/:shopId" /> },
	{ path: 'meta', element: <Navigate replace to="settings/meta-commerce" /> },
	{ path: 'settings/meta-commerce', element: <MetaCommerce /> },
	{ path: 'reservations', element: <Navigate replace to="settings/product-reservations" /> },
	{ path: 'settings/product-reservations', element: <ProductReservations /> },
	{ path: 'profile', element: <Navigate replace to="settings/shop-page" /> },
	{ path: 'settings/shop-page', element: <ShopPage /> },
	{ path: 'search-embed', element: <Navigate replace to="settings/search-widget" /> },
	{ path: 'settings/search-widget', element: <SearchWidget /> },
	{ path: 'checkout', element: <Navigate replace to="settings/local-checkout" /> },
	{ path: 'settings/local-checkout', element: <LocalCheckout /> },
	{ path: 'locatory', element: <Navigate replace to="settings/product-locator" /> },
	{ path: 'settings/product-locator', element: <ProductLocator /> }
];
