const Categories = require('../model/Categories');
const ChannelDefinition = require('../model/ChannelDefinition');
const Requirements = require('../model/Requirements');

class GoogleLocalInventoryAdsChannel extends ChannelDefinition {
	id = 'local-ads';
	title = 'Google Local Inventory Ads';
	description = `Boost your online presence and local SEO ranking by promoting products to relevant local customers.`;
	category = Categories.DISCOVERY_CHANNELS;

	iconUrl = 'https://go.near.st/hubfs/channels/google-ads.svg';
	coverImageUrl = 'https://go.near.st/hubfs/channels/headers/google-local-inventory-ads.png';

	longDescription = `
## Overview
Local Ads increase the online visibility of your local products by showing relevant products at the top of the Google search results. This increases the chance of capturing local audiences from your competitors, converting online clicks into in-store sales.

## How it works
NearSt automatically manages and optimises your product catalog, account setup and targeting for your ads. You can configure a budget per store, and your ads will start showing up within 24 hours. The budget can be flexibility updated at any point to support seasonal campaigns or additional targeting.

When a shopper near your store searches for an item that you stock, your products will show at the top of the search results with a distance tag.

## Requirements
- At least 50 in-stock products
- Enable Google Local Listings
- Have physical store locations in one of the [supported countries](https://support.google.com/merchants/answer/3057972?hl=en#:~:text=Availability)
	`;

	manageUrl = '/subscription/{shopId}';
	productSpecificVisibility = true;
	isAvailableInPartnerApi = true;
	issuesChannelId = 'google';

	worksWith = ['google'];

	helpArticleUrls = [
		{
			title: 'Local Inventory Ads documentation',
			url: 'https://support.near.st/en/collections/3685047-google-local-inventory-ads-lia'
		}
	];

	requirements = new Requirements({
		plans: ['lite', 'standard', 'advanced'],
		countries: ['AU', 'AT', 'BR', 'CA', 'DK', 'FR', 'DE', 'IN', 'IE', 'IT', 'JP', 'NL', 'NZ', 'NO', 'PL', 'ES', 'SE', 'CH', 'GB', 'US']
	});
}

module.exports = GoogleLocalInventoryAdsChannel;
