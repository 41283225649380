import React from 'react';
import { ActualButton as Button } from '@nearst/ui';

import styles from './SwitchPOS.module.scss';

const SwitchPOS = ({ posName, onEdit, hideEdit = false }) => {
	return (
		<>
			<div className={styles.posName}>{posName}</div>
			{!hideEdit && (
				<Button className={styles.switchButton} onClick={onEdit}>
					Switch POS
				</Button>
			)}
		</>
	);
};

export default SwitchPOS;
