import React from 'react';
import { StatusIcon } from '@nearst/ui';
import { hasUpload, hasRecentUpload } from '@services/inventory';

import styles from '../LocationManagement.module.scss';

const InventorySourceInfo = ({ inventorySource }) => {
	const status = hasRecentUpload(inventorySource) ? 'active' : hasUpload(inventorySource) ? 'warning' : 'waiting';

	return (
		<div className={styles.inventoryProvider}>
			<StatusIcon status={status} />
			<p>{inventorySource ? inventorySource.providerLabel : 'Pending'} </p>
		</div>
	);
};

const LocationInventorySources = ({ shop }) => {
	return (
		<>
			<InventorySourceInfo inventorySource={shop.stockInventorySource} />
			{shop.productInventorySource ? <InventorySourceInfo inventorySource={shop.productInventorySource} /> : null}
		</>
	);
};

export default LocationInventorySources;
