const formatKUID = (id) => {
	const array = id.split('');
	// get the last five digits only
	const shortenedId = array
		.slice(array.length - 5, array.length)
		.join('')
		.toUpperCase();
	return shortenedId;
};

const buildNumberArray = (size) => {
	return Array.from({ length: size }, (v, i) => i + 1);
};

const buildPaginationList = (totalPages, currentPage, offset) => {
	if (totalPages <= offset) {
		return buildNumberArray(totalPages);
	}

	if (currentPage > offset) {
		const newRange = [];
		for (let i = 0; i < offset; i++) {
			newRange.push(currentPage - i);
		}
		return newRange.reverse();
	} else {
		return buildNumberArray(offset);
	}
};

const generatePaymentStatus = (order) => {
	if (order.orderType === 'reservation') return 'Pay in store';
	else if (order.orderType === 'click-collect' && order.status === 'refunded') return 'Refunded';
	else if (order.orderType === 'click-collect' && order.status === 'refund_failed') return 'Refund failed';
	else if (order.orderType === 'click-collect') return 'Paid online';
};
export { formatKUID, buildNumberArray, buildPaginationList, generatePaymentStatus };
