import React from 'react';
import { classnames } from '../../utils';

import styles from './Details.module.scss';

/**
 * Expandable details component.
 * Styled version of the native HTML details element.
 *
 * @param {object} props
 * @param {string} props.title Summary text
 * @param {*} props.children Any content to be displayed when expanded
 * @param {string?} [props.className] Additional classnames to be applied to the container
 */
const Details = ({ children, title, className = '', ...props }) => {
	return (
		<details className={classnames(styles.container, className)} {...props}>
			<summary>{title}</summary>
			{children}
		</details>
	);
};

export default Details;
