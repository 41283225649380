import React from 'react';
import { Protect } from '@clerk/clerk-react';

import { Cog6ToothIcon, AcademicCapIcon, ArrowRightStartOnRectangleIcon } from '@heroicons/react/20/solid';

import { useShop } from '@services/ShopProvider';
import Sidebar from '@components/Sidebar';

const AccountManagement = () => {
	const { shop, data: shops } = useShop();
	const shopSelected = !!shop?.id;

	const liaAvailable = shopSelected ? shop.availableChannels?.['local-ads'] : shops.some((shop) => shop.availableChannels?.['local-ads']);

	return (
		<>
			<Sidebar.Menu>
				<Sidebar.Item to="/settings">
					<Cog6ToothIcon />
					Settings
				</Sidebar.Item>
				<Sidebar.SubMenu prefix="/settings">
					{shopSelected && (
						<Sidebar.SubItem end to="/settings">
							Location profile
						</Sidebar.SubItem>
					)}
					<Sidebar.SubItem end to="/settings/profile">
						My account
					</Sidebar.SubItem>
					<Sidebar.SubItem end to="/settings/team">
						Manage team
					</Sidebar.SubItem>
					<Sidebar.SubItem end to="/settings/billing">
						Billing
					</Sidebar.SubItem>
				</Sidebar.SubMenu>
			</Sidebar.Menu>
			{shopSelected && shop.swisUrl && liaAvailable && (
				<Protect permission="org:channels_lia:manage">
					<Sidebar.Item
						to={`https://start.near.st/myshop-retailer-resources?shop_id=${shop?.id}&shop_slug=${shop?.slug}&shop_legacyId=${shop?.legacyId}`}
					>
						<AcademicCapIcon />
						Resources
					</Sidebar.Item>
				</Protect>
			)}
			<Sidebar.Item to={`/logout?shop=${!shopSelected ? shops?.[0]?.organisation : shop.id}`}>
				<ArrowRightStartOnRectangleIcon />
				Sign out
			</Sidebar.Item>
		</>
	);
};

export default AccountManagement;
