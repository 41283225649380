import React from 'react';
import Skeleton from '../../../../../components/Skeleton';
import styles from '../InventoryConnection.module.scss';
import InventoryGraph from './InventoryGraph';
import InventorySourceInformation from '../../../../../components/InventorySourceInformation';

const InventorySource = ({ chainLength, chartData, inventorySources }) => {
	if (!inventorySources.length) return null;

	return (
		<div className={styles.inventorySources}>
			<InventorySourceInformation inventorySources={inventorySources}>
				<InventorySourceInformation.PosStatus inventorySources={inventorySources} />
				<InventorySourceInformation.Kind inventorySources={inventorySources} />
				{chainLength > 1 ? (
					<InventorySourceInformation.Locations inventorySources={inventorySources} locationCount={chainLength} />
				) : (
					<InventorySourceInformation.UploadStatus inventorySources={inventorySources} locationCount={chainLength} />
				)}
			</InventorySourceInformation>
			{chartData && <InventoryGraph chartData={chartData} />}
		</div>
	);
};

const Loader = () => {
	return (
		<div className={styles.inventorySourceLoader}>
			<Skeleton className={styles.skeleton} />
			<Skeleton className={styles.skeleton} />
		</div>
	);
};

InventorySource.Loader = Loader;

export default InventorySource;
