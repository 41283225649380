import React from 'react';
import styles from './MobileNavigator.module.scss';
import { Bars3Icon as Burger, XMarkIcon as Close } from '@heroicons/react/20/solid';
import { useShop } from '@services/ShopProvider';

const MobileNavigator = ({ onToggle, mobileExpanded }) => {
	const { shop, data } = useShop();
	const title = shop.name || data?.[0]?.organisation;

	return (
		<div className={styles.mobileNavigator}>
			{mobileExpanded ? (
				<Close className={styles.toggle} onClick={onToggle} />
			) : (
				<>
					<Burger className={styles.toggle} onClick={onToggle} />
					{title}
				</>
			)}
		</div>
	);
};

export default MobileNavigator;
