import React from 'react';
import { format } from 'date-fns';

import LineGraph from '@components/Graphs/LineGraph';
import styles from '../Performance.module.scss';

const LocalListingsPerformanceChart = ({ data, ...props }) => {
	return (
		<LineGraph
			series={data.map((row) => ({ swis: row["See What's In Store"], date: row.Date }))}
			seriesConfig={[{ key: 'swis', colour: '#2aca79', label: 'Views' }]}
			width="100%"
			height={160}
			tooltip={({ payload, label }) => {
				const isEstimate = payload.some((item) => {
					return item.payload?.swisEstimate;
				});
				return (
					<>
						<p>{format(new Date(label), 'MMM d, yyyy')}</p>
						{payload.map((item) => (
							<p style={item} key={item.name}>
								{item.value.toLocaleString()} views
							</p>
						))}
						{isEstimate && <p className={styles.estimate}>Estimate - this data is calculated from your weekly average</p>}
					</>
				);
			}}
			{...props}
		/>
	);
};

export default LocalListingsPerformanceChart;
