import React from 'react';
import { classnames } from '../../utils';

import './style.scss';

const InfoLink = ({ href, classes = '', className = '', children, ...props }) => {
	const classList = ['info-link'];

	if (classes) {
		classList.push(classes);
	}

	return (
		<a className={classnames(classList.join(' '), className)} href={href} {...props}>
			{children}
		</a>
	);
};

export default InfoLink;
