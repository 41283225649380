import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
	const { pathname } = useLocation();
	const [previousLocation, setPreviousLocation] = useState('');

	useEffect(() => {
		if (pathname !== previousLocation) {
			document.getElementById('scroller').scroll(0, 0);
		}
		setPreviousLocation(pathname);
	}, [pathname, previousLocation]);

	return null;
};

export default ScrollToTop;
