import React from 'react';
import { Icon, Stack, Switcher, Modal } from '@nearst/ui';

import LocationLabel from '../LocationLabel';
import { formatKUID } from '../../utils';
import styles from './OrdersModal.module.scss';

const OrdersModal = ({ selectedOrder, onModalClose }) => {
	return (
		<Modal className={styles.modal} isOpen={!!selectedOrder} onDismiss={onModalClose}>
			<div className="modal__title-container">
				<div>
					<LocationLabel>{selectedOrder.location}</LocationLabel>
					<h2 className={styles.modalTitle}>{selectedOrder && `Order ${formatKUID(selectedOrder.id)}`}</h2>
				</div>
				<button className={styles.modalExit} onClick={onModalClose}>
					<Icon>clear</Icon>
				</button>
			</div>
			{selectedOrder && (
				<div className="modal__content-container">
					<Stack>
						{selectedOrder.products.map((product) => (
							<>
								<Switcher className={styles.modalContent} key={product.barcode}>
									<div className={styles.sectionContainer}>
										<p className={styles.sectionTitle}>
											{product.quantity} x {product.name}
										</p>
										<p className={styles.orderBarcode}>{product.barcode}</p>
										<p>
											{product.price?.toLocaleString('en', {
												style: 'currency',
												currency: product.currency || 'GBP'
											})}
										</p>
										{['paid', 'confirmed', 'completed', 'reserved'].includes(selectedOrder.status) && (
											<>
												{selectedOrder.orderType === 'reservation' && (
													<p className={styles.payInStore}>Pay in store</p>
												)}
												{selectedOrder.orderType === 'click-collect' && (
													<p className={styles.paidOnline}>Paid online</p>
												)}
											</>
										)}
									</div>
									{product.image && <img src={product.image} alt={product.name} />}
								</Switcher>
							</>
						))}

						<hr className={styles.hr} />

						<div className={styles.sectionContainer}>
							<p className={styles.sectionTitle}>{selectedOrder.customerName}</p>
							{selectedOrder.customerEmail && <p>{selectedOrder.customerEmail}</p>}
							{selectedOrder.customerPhone && <p>{selectedOrder.customerPhone}</p>}
						</div>
						{selectedOrder.notes && (
							<div className={styles.sectionContainer}>
								<p className={styles.sectionTitle}>Notes</p>
								<p className={styles.notes}>{selectedOrder.notes}</p>
							</div>
						)}
					</Stack>
				</div>
			)}
		</Modal>
	);
};

export default OrdersModal;
