import React, { useState } from 'react';
import { Link, useParams, useRouteLoaderData } from 'react-router-dom';
import { format } from 'date-fns';
import useSWR from 'swr';

import { Button, Pill } from '@nearst/ui';
import { useShop } from '@services/ShopProvider';
import { getCustomer } from '@services/billing';
import styles from './UpgradeReviewPlan.module.scss';

const UpgradeReviewPlan = () => {
	const { pricing } = useRouteLoaderData('upgrade');

	const { productId, period } = useParams();
	const [billingCycle, setBillingCycle] = useState(period);
	const plan = pricing[billingCycle];

	// Determine if the customer has a payment method setup
	const { data: shops } = useShop();
	const { data: customer } = useSWR([shops[0].id, 'customer'], getCustomer);
	const hasPaymentMethod = customer?.paymentMethod;

	const basePath = `/upgrade/${productId}/${billingCycle}`;
	const nextUrl = hasPaymentMethod ? `${basePath}/confirm` : `${basePath}/payment`;

	return (
		<div className={styles.container}>
			<h2>NearSt {pricing.title}</h2>
			{pricing.description && <p>{pricing.description}</p>}

			<div className={styles.group}>
				<label>Billing cycle</label>
				<div className={styles.radioButtons}>
					<label>
						<input type="radio" checked={billingCycle === 'annually'} onClick={() => setBillingCycle('annually')} />
						Annual
						<Pill className={styles.pill} colour="blue">
							{pricing.annually.discount}% discount
						</Pill>
					</label>
					<label>
						<input type="radio" checked={billingCycle === 'monthly'} onClick={() => setBillingCycle('monthly')} />
						Monthly
					</label>
				</div>
			</div>

			<div className={styles.group}>
				<label>
					{plan.price} per location, billed {billingCycle}
				</label>
				<p>Subscription starts {format(new Date(), 'MMMM do, yyyy')}</p>
			</div>

			<Button as={Link} to={nextUrl} primary className={styles.button}>
				Continue
			</Button>
		</div>
	);
};

export default UpgradeReviewPlan;
