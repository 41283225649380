export const isEmpty = (details) => {
	if (!details) return false;
	// ignore fields which aren't inputs
	const invalidKeys = ['barcode'];
	const validKeysOnly = Object.keys(details).filter((d) => !invalidKeys.includes(d));

	const isValidKeysEmpty = validKeysOnly.every((key) => {
		return details[key] === undefined || details[key] === '';
	});
	return isValidKeysEmpty;
};
