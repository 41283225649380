import React from 'react';
import * as Chart from 'recharts';
import { format } from 'date-fns';

import { Switcher } from '@nearst/ui';

import * as Stats from '@components/StatsCard';
import SimpleWrapper from '@components/Graphs/DataWrapper/SimpleWrapper';
import NoData from '@components/Graphs/Placeholders/NoData';
import HelpButton from '@components/HelpButton';
import { getCSVData } from '@components/Graphs/DataWrapper';
import { TooltipContainer } from '@components/Graphs/Tooltip';
import { formatDate } from '@components/Graphs/TimeSeriesGraph/MultiPeriodTooltip';
import { useInsightsDate } from '@services/InsightsDateProvider';
import { fetcher } from './fetcher';

import styles from './StoreVisitsCard.module.scss';

const StoreVisitsTooltip = ({ active, payload, contentStyle }) => {
	const { interval } = useInsightsDate();

	if (!active || !payload || !payload.length) {
		return null;
	}

	const payloadItem = payload[0].payload;

	return (
		<TooltipContainer contentStyle={contentStyle}>
			<p>
				<strong>Store visits</strong>
			</p>
			<p className={styles.tooltipValue}>
				{formatDate(new Date(payloadItem.date), interval)}: {payloadItem.store_visits.toLocaleString()}
			</p>
			{payloadItem.preliminary ? <p className={styles.tooltipNote}>Data may be adjusted retroactively</p> : null}
		</TooltipContainer>
	);
};

const StoreVisitsCard = ({ shops }) => {
	const { startDate, endDate, selectedRange, interval } = useInsightsDate();

	return (
		<Stats.Card>
			<Stats.Title className={styles.cardTitle}>
				Store visits
				<HelpButton href="https://support.near.st/en/articles/10033991" />
			</Stats.Title>
			<Stats.Subtitle>{selectedRange}</Stats.Subtitle>

			<SimpleWrapper fetcher={fetcher} args={{ shops, startDate, endDate, interval }}>
				{([storeVisits, shopsWithVisits, shopsWithLocalAds, adsBudget]) => {
					const csv = getCSVData(storeVisits);

					const totalVisits = storeVisits.reduce((acc, cur) => (acc += cur.store_visits), 0);
					const costPerVisit = totalVisits && adsBudget ? adsBudget / totalVisits : 0;

					return (
						<>
							<Stats.Download filename="store-visits" csv={csv} />
							<Switcher space="2rem" className={styles.stats}>
								<Stats.Figure>
									{adsBudget.toLocaleString('en', { style: 'currency', currency: shops[0].billingCurrency })}
									<Stats.FigureDescription>
										Ad spend
										<Stats.Tooltip>
											The budget spent on Local Ads for the selected period for any stores reporting data
										</Stats.Tooltip>
									</Stats.FigureDescription>
								</Stats.Figure>
								<Stats.Figure>
									{totalVisits.toLocaleString()}
									<Stats.FigureDescription>Store visits</Stats.FigureDescription>
								</Stats.Figure>
								<Stats.Figure>
									{costPerVisit.toLocaleString('en', { style: 'currency', currency: shops[0].billingCurrency })}
									<Stats.FigureDescription>Average cost per visit</Stats.FigureDescription>
								</Stats.Figure>
								<Stats.Figure>
									{shopsWithVisits.size.toLocaleString()}/{shopsWithLocalAds.size.toLocaleString()}
									<Stats.FigureDescription>Locations reporting store visits</Stats.FigureDescription>
								</Stats.Figure>
							</Switcher>

							{storeVisits.length ? (
								<div className={styles.maxHeight}>
									<Chart.ResponsiveContainer width="100%">
										<Chart.BarChart
											aria-roledescription="Bar chart"
											margin={{ top: 5, right: 0, bottom: 0, left: -12 }}
											data={storeVisits}
										>
											<Chart.XAxis
												dataKey="date"
												aria-roledescription="axis"
												aria-orientation="horizontal"
												aria-label="Date"
												tickFormatter={(date) => format(new Date(date), 'MMM d')}
											/>
											<Chart.YAxis
												aria-label={'Store visits'}
												aria-roledescription="axis"
												aria-orientation="vertical"
												tickFormatter={(value) => value.toLocaleString()}
											/>

											<Chart.Tooltip
												content={StoreVisitsTooltip}
												contentStyle={{
													backgroundColor: 'var(--blue-darkest)',
													border: 'none',
													color: 'white',
													padding: '0.5rem',
													lineHeight: '1.25',
													borderRadius: '5px'
												}}
												cursor={{ fill: 'var(--grey-lightest' }}
											/>

											<Chart.Bar
												dataKey="store_visits"
												aria-label="Store visits"
												maxBarSize={100}
												radius={[4, 4, 0, 0]}
											>
												{storeVisits.map((item) => (
													<Chart.Cell key={item.date} fill={item.preliminary ? 'var(--grey)' : 'var(--blue)'} />
												))}
											</Chart.Bar>
										</Chart.BarChart>
									</Chart.ResponsiveContainer>
								</div>
							) : (
								<NoData
									className={styles.placeholder}
									text={
										<>
											No data available for the selected date range.
											<br />
											Your ad spend might not have reached the required threshold for reporting.
										</>
									}
								/>
							)}
						</>
					);
				}}
			</SimpleWrapper>
		</Stats.Card>
	);
};

export default StoreVisitsCard;
