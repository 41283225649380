import React from 'react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';

import { Alert, Page } from '@nearst/ui';

import ReportOverviewCard from '../components/ReportOverviewCard';
import { showIntercomWithMessage } from '@services/Intercom/utils';
import { useShop } from '@services/ShopProvider';
import { reports } from '../reports';

import ExportDataIllustration from '../../../assets/images/reports/export-data.svg';
import styles from './ReportsOverview.module.scss';

export const ReportsOverview = () => {
	const { data } = useShop();

	const needsUpgrade = data[0]?.plan === 'lite';
	const needsMoreLocations = data.length < 2;
	const advancedReportsLocked = needsUpgrade || needsMoreLocations;

	const availableReports = reports.filter((report) => !report.advanced || !advancedReportsLocked);
	const advancedReports = reports.filter((report) => report.advanced);

	const contactSupport = (message) => {
		if (!showIntercomWithMessage(message)) {
			window.location.href = `mailto:support@near.st?subject=${encodeURIComponent(message)}`;
		}
	};

	return (
		<>
			<Page.Header>
				<h1>Reports library</h1>
				<p>See the impact of your inventory across channels.</p>
			</Page.Header>
			<Page.Section>
				<div className={styles.cards}>
					{availableReports.map((report) => (
						<ReportOverviewCard key={report.id} {...report} />
					))}
				</div>

				{advancedReportsLocked && (
					<>
						<h3>Advanced reports</h3>
						{needsMoreLocations ? (
							<Alert>
								<p>These reports are only available for organisations with more than 1 location.</p>
								<button onClick={() => contactSupport('I want to add more locations to my NearSt account')}>
									Expand account
									<ChevronRightIcon />
								</button>
							</Alert>
						) : (
							<Alert>
								<p>These reports not available on your current plan.</p>
								<button onClick={() => contactSupport('I want to upgrade my account')}>
									Upgrade to unlock
									<ChevronRightIcon />
								</button>
							</Alert>
						)}
						<div className={styles.cards}>
							{advancedReports.map((report) => (
								<ReportOverviewCard key={report.id} {...report} locked image={report.lockedImage} />
							))}
						</div>
					</>
				)}

				<h3>Export data</h3>
				<div className={styles.cards}>
					<ReportOverviewCard
						id="export-data"
						image={ExportDataIllustration}
						title="Export data"
						description="Download raw data to build your own reports."
					/>
				</div>
			</Page.Section>
		</>
	);
};
