import React from 'react';
import { AdjustmentsHorizontalIcon } from '@heroicons/react/20/solid';

import { Filter } from '@nearst/ui';

import styles from './OptionsButton.module.scss';

const OptionsButton = ({ options, values, setValues }) => {
	return (
		<div className={styles.optionsButton}>
			<Filter.Dropdown text={<AdjustmentsHorizontalIcon title="Options" className={styles.optionsIcon} />}>
				<Filter.Fieldset title="View options">
					{Object.values(options).map(({ id, label }) => (
						<Filter.Field
							key={id}
							value={id}
							checked={values[id]}
							onChange={() =>
								setValues((values) => {
									values[id] = !values[id];
									return { ...values };
								})
							}
						>
							{label}
						</Filter.Field>
					))}
				</Filter.Fieldset>
			</Filter.Dropdown>
		</div>
	);
};

export default OptionsButton;
