import { loadStripe } from '@stripe/stripe-js';

const stripeKey =
	window.location.href.includes('localhost') && import.meta.env.MODE !== 'staging'
		? 'pk_test_BBF9YlZHVNamcNZadIbJAxwL'
		: 'pk_live_wPgjkNo0t6rc5m5TWqhFaNjL';

const stripePromise = loadStripe(stripeKey);

export default stripePromise;
