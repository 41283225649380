import React from 'react';
import useSWR from 'swr';
import { Loader } from '@nearst/ui';

import * as Stats from '@components/StatsCard';
import { getChannels } from '@services/channels';
import { useShop } from '@services/ShopProvider';
import styles from './ChannelsOverviewCard.module.scss';
import plurify from '@utils/plurify';

const ChannelsOverviewCard = () => {
	const { shop, data: shops } = useShop();
	const { data } = useSWR(`channels-${shop?.id}`, () => getChannels(shop?.id ? [shop] : shops));
	const enabledChannels = data?.filter(({ enabledShops }) => enabledShops.length) || [];

	return (
		<Stats.Card className={styles.card}>
			<div className={styles.body}>
				<Stats.Title>Channels</Stats.Title>
				<Stats.Subtitle>
					{enabledChannels.length} {plurify('channel', enabledChannels.length)} enabled
				</Stats.Subtitle>
				{data ? (
					<>
						{enabledChannels.slice(0, 5).map((channel) => (
							<div className={styles.channel} key={channel.id}>
								<div className={styles.channelInfo}>
									<div className={styles.channelIcon} style={{ backgroundImage: `url(${channel.iconUrl})` }} />
									{channel.label}
								</div>
								<div className={styles.channelStatus}>{!shop?.id && `${channel.enabledShops.length} locations`}</div>
							</div>
						))}
					</>
				) : (
					<div className="myshop-graph__content-wrapper">
						<Loader />
					</div>
				)}
			</div>
			<div className={styles.right}>
				<Stats.ViewMore to={`/channels/${shop?.id || shops[0].id}`}>Manage channels</Stats.ViewMore>
			</div>
		</Stats.Card>
	);
};

export default ChannelsOverviewCard;
