import React from 'react';

import styles from './FeaturesList.module.scss';
import PlanFeature from './PlanFeature';

export const LocalInventoryAdsFeature = () => (
	<PlanFeature icon="ads_click">
		<h3>Local Inventory Ads</h3>
		<p>Advertise your products to nearby shoppers and boost local visibility</p>
	</PlanFeature>
);

export const ClickCollectFeature = () => (
	<PlanFeature icon="shopping_cart">
		<h3>Click & collect</h3>
		<p>Allow shoppers to seamlessly purchase items for collection</p>
	</PlanFeature>
);

export const AdvancedAnalyticsFeature = () => (
	<PlanFeature icon="bar_chart">
		<h3>Advanced analytics</h3>
		<p>Track product popularity with local shoppers</p>
	</PlanFeature>
);

export const IndustryInsightsFeature = () => (
	<PlanFeature icon="insights">
		<h3>Industry insights</h3>
		<p>Discover what local shoppers are looking for in your industry</p>
	</PlanFeature>
);

export const ReportsFeature = () => (
	<PlanFeature icon="download">
		<h3>Download reports</h3>
		<p>Build and download custom reports from your data</p>
	</PlanFeature>
);

export const AdditionalChannelsFeature = () => (
	<PlanFeature icon="switch_access_shortcut_add">
		<h3>Additional channels</h3>
		<p>Offer new discovery and purchase journeys for your local customers</p>
	</PlanFeature>
);

const FeaturesList = () => (
	<div className={styles.featuresGrid}>
		<LocalInventoryAdsFeature />
		<ClickCollectFeature />
		<AdvancedAnalyticsFeature />
		<IndustryInsightsFeature />
		<ReportsFeature />
	</div>
);

export const AdditionalFeaturesList = ({ children }) => <div className={styles.featuresGrid}>{children}</div>;

export default FeaturesList;
