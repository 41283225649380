import React from 'react';
import { Link, NavLink, useParams } from 'react-router-dom';

import styles from './UpgradeNavigation.module.scss';

const UpgradeNavigation = () => {
	const { productId, period } = useParams();
	const basePath = `/upgrade/${productId}/${period}`;

	return (
		<div className={styles.container}>
			<NavLink to={basePath} className={({ isActive }) => (isActive ? ' activeStep' : '')} end>
				Review plan
			</NavLink>
			<NavLink to={`${basePath}/payment`} className={({ isActive }) => (isActive ? ' activeStep' : '')}>
				Payment information
			</NavLink>
			<NavLink to={`${basePath}/confirm`} className={({ isActive }) => (isActive ? ' activeStep' : '')}>
				Confirm
			</NavLink>
		</div>
	);
};

export default UpgradeNavigation;
