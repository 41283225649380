import React from 'react';
import { format } from 'date-fns';
import * as Stats from '@components/StatsCard';
import InsightsWrapper from '@components/Graphs/DataWrapper';
import StackedColourGraph from '../../StackedColourGraph';
import { useInsightsDate } from '@services/InsightsDateProvider';
import { colourScheme } from '../../utils';

import styles from '../Cards.module.scss';

export const ProfileInteractionsPerformanceCard = ({ shopIds }) => {
	const { startDate, endDate, selectedRange, interval } = useInsightsDate();
	const query = {
		table: 'gmb',
		from: format(startDate, 'yyyy-MM-dd'),
		to: format(endDate, 'yyyy-MM-dd'),
		id: shopIds,
		interval,
		columns: ['website_clicks', 'phone_clicks', 'directions_clicks', 'date'],
		sort: [{ column: 'date', order: 'asc' }],
		sum: ['website_clicks', 'phone_clicks', 'directions_clicks']
	};

	return (
		<Stats.Card className={`${styles.card} ${styles.businessProfile}`}>
			<Stats.Title>Directions, website and phone clicks</Stats.Title>
			<Stats.Subtitle>{selectedRange}</Stats.Subtitle>
			<InsightsWrapper query={query} hideError>
				{(data) => {
					const series = [
						{
							label: 'Directions clicks',
							dataset: data.map((insight) => ({
								label: new Date(insight['date']),
								value: insight['directions_clicks']
							}))
						},
						{
							label: 'Phone clicks',
							dataset: data.map((insight) => ({
								label: new Date(insight['date']),
								value: insight['phone_clicks']
							}))
						},
						{
							label: 'Website clicks',
							dataset: data.map((insight) => ({
								label: new Date(insight['date']),
								value: insight['website_clicks']
							}))
						}
					].map((obj, i) => {
						obj.backgroundColor = colourScheme[i];
						return obj;
					});

					return (
						<div className={styles.maxGraphWrapper}>
							<StackedColourGraph className={styles.maxGraph} series={series} />
						</div>
					);
				}}
			</InsightsWrapper>
		</Stats.Card>
	);
};
