import React, { useState } from 'react';
import mixpanel from 'mixpanel-browser';
import { useShop } from '@services/ShopProvider';

import ReportsUpgradeModal from '../Modals/Upgrade/ReportsUpgradeModal';
import styles from './UpgradeRequired.module.scss';
import { ActualButton, Icon } from '@nearst/ui';
import { useUpgradeModal } from '@services/UpgradeProvider';

export const useCheckPlan = (availableOnPlans = [], eventSource = '') => {
	const [showDialog, setShowDialog] = useState(false);
	const { openUpgradeModal } = useUpgradeModal();
	const { shop, data } = useShop();
	const currentPlan = shop?.plan || data?.[0]?.plan;

	const isFreemiumUpgrade = availableOnPlans.includes('lite') && currentPlan === 'freemium';

	const upgrade = async () => {
		if (isFreemiumUpgrade) {
			openUpgradeModal();
		} else {
			setShowDialog(true);
		}

		const defaultStr = 'Upgrade subscription button click';
		const eventName = eventSource ? `${eventSource} - ${defaultStr}` : defaultStr;

		mixpanel.track(eventName, {
			availableOnPlans: availableOnPlans.join(','),
			currentPlan
		});
	};

	const unlocked = !availableOnPlans.length || availableOnPlans.includes(currentPlan);

	return {
		unlocked,
		upgrade,
		showDialog,
		setShowDialog
	};
};

const UpgradeRequired = ({ children, availableOnPlans, buttonTitle = 'Upgrade to unlock', eventSource = '' }) => {
	const { unlocked, upgrade, showDialog, setShowDialog } = useCheckPlan(availableOnPlans, eventSource);

	if (unlocked) {
		return children;
	}

	return (
		<>
			<ReportsUpgradeModal showDialog={showDialog} setShowDialog={setShowDialog} />
			<ActualButton className={styles.upgradeButton} data-cy="upgrade-button" success onClick={upgrade}>
				{buttonTitle} <Icon>lock</Icon>
			</ActualButton>
		</>
	);
};

export default UpgradeRequired;
