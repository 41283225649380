import React from 'react';
import papa from 'papaparse';
import mixpanel from 'mixpanel-browser';
import * as Stats from '@components/StatsCard';
import { Loader } from '@nearst/ui';
import { useInsightsDate } from '@services/InsightsDateProvider';
import { useProductFreeListings } from '@services/insights';
import Download from '@components/Graphs/Download';
import { useShop } from '@services/ShopProvider';
import NoData from '@components/Graphs/Placeholders/NoData';
import Table from '@components/Table';
import InsightCardContainer from '../../components/InsightCardContainer';
import { updateImpressions } from '../../utils';

const PopularProducts = () => {
	const { shop } = useShop();
	const { to, from, selectedRange } = useInsightsDate();
	const { resultSet: productFreeListings, isLoading } = useProductFreeListings({ id: shop.id, to, from });

	const enhancedListings = productFreeListings?.map(updateImpressions);

	return (
		<InsightCardContainer>
			<Stats.Title>
				Popular Products
				{!isLoading ? (
					<Download
						filename="popular-products"
						csv={papa.unparse(
							enhancedListings.map((row) => ({
								product: row.title,
								barcode: row.barcode,
								clicks: row.clicks,
								views: row.displayImpressions
							}))
						)}
					/>
				) : (
					''
				)}
			</Stats.Title>
			<Stats.Subtitle>{selectedRange}</Stats.Subtitle>
			<div>
				{!isLoading ? (
					enhancedListings && enhancedListings.length ? (
						<Table
							data={enhancedListings}
							defaultSort={{ orderBy: 'views', direction: 'asc', handler: (a, b) => a.clicks - b.clicks }}
							initialLength={15}
							maxLength={40}
							expandable
							onExpand={(expanded) =>
								mixpanel.track(expanded ? 'Expand insights table' : 'Collapse insights table', {
									'Table name': 'Local Listings Popular Products'
								})
							}
							fields={[
								{
									id: 'product',
									title: 'Product name',
									formatter: (row) => {
										return row.title;
									}
								},
								{
									id: 'clicks',
									title: 'Clicks',
									formatter: (row) => {
										return row.clicks;
									},
									sortFn: { desc: (a, b) => a.clicks - b.clicks, asc: (a, b) => b.clicks - a.clicks }
								},
								{
									id: 'views',
									title: 'Views',
									formatter: (row) => {
										return row.displayImpressions;
									},
									sortFn: { desc: (a, b) => a.impressions - b.impressions, asc: (a, b) => b.impressions - a.impressions }
								}
							]}
						/>
					) : (
						<NoData />
					)
				) : (
					<Loader />
				)}
			</div>
		</InsightCardContainer>
	);
};

export default PopularProducts;
