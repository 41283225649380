import React from 'react';
import { Navigate } from 'react-router-dom';
import { RedirectWithShopId } from '@utils/RedirectWithShopId';

import LocationProfile from './routes/LocationProfile';
import Billing from './routes/Billing';
import Team from './routes/Team';
import AccountProfile from './routes/Account';

export const settingsRoutes = [
	// shop only
	{ path: ':shopId', element: <LocationProfile /> },
	//chain only
	{ index: true, element: <Navigate to="/settings/profile" /> },
	// both
	{
		path: ':shopId?',
		children: [
			{ path: 'billing', element: <Billing /> },
			{ path: 'team', element: <Team /> },
			{ path: 'profile', element: <AccountProfile /> }
		]
	},
	{ path: '*', element: <RedirectWithShopId to="/settings/:shopId" /> }
];
