import SearchIcon from '@mui/icons-material/Search';
import { TextField, InputAdornment } from '@material-ui/core';

import styles from './OrderSearch.module.scss';

const OrderSearch = ({ setSearchText }) => {
	const handleSearchChange = (e) => {
		const input = e.target.value.toLowerCase();
		setSearchText(input);
	};

	return (
		<TextField
			data-testid="inventory-search-input"
			variant="outlined"
			className={styles.searchField}
			onChange={handleSearchChange}
			placeholder="Search for an order ID or customer"
			InputProps={{
				className: styles.searchInput,
				startAdornment: (
					<InputAdornment position="start">
						<SearchIcon className={styles.searchFieldIcon} />
					</InputAdornment>
				)
			}}
		/>
	);
};

export default OrderSearch;
