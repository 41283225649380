import React from 'react';
import { Page } from '@nearst/ui';
import DatePicker from '@components/DatePicker';
import UpgradeRequiredProductsBanner from '@components/UpgradeRequiredProductsBanner';
import { useShop } from '@services/ShopProvider';
import { useIsChain } from '@utils/useIsChain';

import LocalListingsPerformance from './components/Performance/LocalListings';
import LocalAdsPerformance from './components/Performance/LocalAds';
import ChannelsOverviewCard from './components/ChannelsOverviewCard';
import InventoryConnection from './components/InventoryConnection';
import LocalCheckout from './components/LocalCheckout';

import styles from './Dashboard.module.scss';

const Dashboard = () => {
	const { data: chain, shop } = useShop();
	const isChain = useIsChain();
	const shops = isChain ? chain : [shop];

	const excludedRecords = shops[0].stockInventorySource?.latestIngest ? shops[0].stockInventorySource.latestIngest.excludedRecords : 0;
	const name = isChain ? shops[0].organisation : shop.name;

	return (
		<div className={styles.dashboard}>
			<Page.Header>
				<h1>{name}</h1>
				<DatePicker />
			</Page.Header>
			<Page.Section>
				{shops[0].plan === 'freemium' && excludedRecords > 0 ? (
					<UpgradeRequiredProductsBanner unprocessedStock={excludedRecords} isChain={isChain} />
				) : null}
				<div className={styles.container}>
					<div className={styles.container}>
						<InventoryConnection shops={shops} />
						<ChannelsOverviewCard />
					</div>
					<div className={styles.container}>
						<LocalAdsPerformance shops={shops} />
						<LocalListingsPerformance shops={shops} />
					</div>

					<LocalCheckout shops={shops}></LocalCheckout>
				</div>
			</Page.Section>
		</div>
	);
};

export default Dashboard;
