import { useShop } from '@services/ShopProvider';
import React from 'react';
import { Navigate, useParams } from 'react-router-dom';

export const RedirectWithShopId = ({ to }) => {
	let redirectRoute;
	const { shopId } = useParams();
	if (!shopId) {
		redirectRoute = to.replace(':shopId', '');
	} else {
		redirectRoute = to.replace(':shopId', shopId);
	}
	return <Navigate to={redirectRoute} replace />;
};

export const FallbackWithShopId = ({ children }) => {
	const { shopId } = useParams();
	const { data: shops } = useShop();
	if (!shopId) {
		return <Navigate to={shops[0].id} />;
	}
	return children;
};
