import React, { useState } from 'react';
import { Marker, Popup } from 'react-map-gl';
import { MapPinIcon } from '@heroicons/react/20/solid';
import { UItils } from '@nearst/ui';
import styles from './EventLocations.module.scss';

export const Pin = ({ shop }) => {
	const [open, setOpen] = useState(false);
	const location = JSON.parse(shop.geolocation);
	return (
		<Marker longitude={location.lng} latitude={location.lat}>
			<MapPinIcon
				className={UItils.classnames(styles.pin, styles.pointer, styles.green)}
				onMouseEnter={() => setOpen(true)}
				onMouseLeave={() => setOpen(false)}
			/>

			{open && (
				<Popup className={styles.tooltip} longitude={location.lng} latitude={location.lat} anchor="bottom">
					{shop.name}
				</Popup>
			)}
		</Marker>
	);
};
