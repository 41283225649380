import React from 'react';
import { Card } from '@nearst/ui';

import { useShop } from '@services/ShopProvider';

const AddOns = () => {
	const { data } = useShop();

	const addOns = Object.values(
		data.reduce((acc, shop) => {
			if (shop.currentPlusPlan) {
				const plan = shop.currentPlusPlan;
				const currency = shop.billingCurrency || shop.currency || 'GBP';
				const addOnId = `${plan.title}-${plan.amount}-${plan.interval}-${currency}`;
				if (!acc[addOnId]) {
					acc[addOnId] = {
						id: addOnId,
						title: 'Local Ads',
						description: `${plan.amount.toLocaleString('en-US', {
							style: 'currency',
							currency
						})} per day`,
						interval: plan.interval,
						count: 0
					};
				}
				acc[addOnId].count++;
			}
			return acc;
		}, {})
	);

	if (!addOns.length) {
		return null;
	}

	return (
		<Card.Section className="card__section add-ons">
			<h4>Add-ons</h4>
			{addOns.map((addOn) => (
				<div className="add-ons__item" key={addOn.id}>
					<strong>{addOn.title}</strong>
					<span>{addOn.description}</span>
					<span>
						{addOn.count} locations, billed {addOn.interval}ly
					</span>
				</div>
			))}
		</Card.Section>
	);
};

export default AddOns;
