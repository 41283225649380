import { mutate } from 'swr';

import myShopClient from './nearlive';

/**
 * Update the enabled channels object for a given list of stock items in a shop.
 *
 * @param {string} shopId Shop ID
 * @param {string[]} barcodes List of barcodes to update
 * @param {Object<string, boolean>} newChannels New channels object
 * @return {Promise<void>}
 */
export const updateChannelsForStockItems = async (shopId, barcodes, newChannels) => {
	const client = await myShopClient();

	await client.put(`/api/inventory/channels/${shopId}`, { newChannels, barcodes });

	if (barcodes.length === 1) {
		await mutate(`stock_data-${shopId}-${barcodes[0]}`);
	}
};

export const getChannelsForStockItem = (shopId, stockItem, channels) =>
	channels
		?.filter(({ enabledShops }) => enabledShops.some((id) => id === shopId))
		.filter(({ productSpecificVisibility }) => productSpecificVisibility)
		.map(({ id, label }) => {
			// currently, google visibility also toggles local-ads visibility
			const channelId = id === 'local-ads' ? 'google' : id;
			return {
				id,
				label,
				enabled: stockItem.channels?.[channelId] || false
			};
		})
		.sort((a, b) => {
			if (a.enabled === b.enabled) {
				return a.label.localeCompare(b.label);
			}
			return a.enabled > b.enabled ? -1 : 1;
		});

export const getChannelsForStockItems = (shopId, stockItems, channels) =>
	channels
		?.filter(({ enabledShops }) => enabledShops.some((id) => id === shopId))
		.filter(({ productSpecificVisibility }) => productSpecificVisibility)
		.map(({ id, label }) => {
			// currently, google visibility also toggles local-ads visibility
			const channelId = id === 'local-ads' ? 'google' : id;
			return {
				id,
				label,
				enabled: stockItems.some(
					(stockItem) => stockItem.channels?.includes?.(channelId) || stockItem.channels?.[channelId] || false
				)
			};
		})
		.sort((a, b) => {
			if (a.enabled === b.enabled) {
				return a.label.localeCompare(b.label);
			}
			return a.enabled > b.enabled ? -1 : 1;
		});
