import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Page, Switcher } from '@nearst/ui';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import DatePicker from '@components/DatePicker';
import SpinnerCard from '@components/SpinnerCard';
import * as Stats from '@components/StatsCard';
import { useShop } from '@services/ShopProvider';
import LocationFilter from '../../insights/components/LocationFilter';
import ReportFilters from '../components/ReportFilters';

import DataWrapper from '@components/Graphs/DataWrapper';
import NoData from '@components/Graphs/Placeholders/NoData';
import { useInsightsDate } from '@services/InsightsDateProvider';
import styles from './ClicksToSales.module.scss';
import TablePagination from '../../inventory/InventoryManagement/InventoryViewer/TablePagination';

const addS = (num) => (+num === 1 ? '' : 's');

const Loading = () => <SpinnerCard message="Generating report..." />;

const ClicksToSalesCard = ({ shops, shopIds }) => {
	const { startDate: from, endDate: to } = useInsightsDate();
	const [currentPage, setCurrentPage] = useState(1);

	useEffect(() => {
		setCurrentPage(1);
	}, [shopIds, from, to]);

	const query = {
		id: shopIds,
		table: 'clicks_to_sales',
		sort: [
			{ column: 'sales_date', order: 'desc' },
			{ column: 'sales_from_clicks', order: 'desc' },
			{ column: 'attributed_clicks', order: 'desc' }
		],
		from,
		to
	};

	return (
		<DataWrapper query={query} LoaderPlaceholder={Loading}>
			{(data) => {
				const rowsPerPage = 15;
				const pages = Math.ceil(data.length / rowsPerPage);
				const paginatedData = data.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

				return (
					<Stats.Card>
						<Switcher space="2rem">
							{data.length === 0 ? (
								<NoData
									text={
										<>
											<h3>No suspected sales yet</h3>
											<p>We don&rsquo;t have any data to show yet. Please check back here in a few days.</p>
										</>
									}
								/>
							) : (
								<>
									<Table aria-label="clicks to sales table" className={styles.table} size="small">
										<TableHead>
											<TableRow>
												<TableCell>
													<div className={styles.tableHeader}>Date</div>
												</TableCell>
												<TableCell>
													<div className={styles.tableHeader}>Location name</div>
												</TableCell>
												<TableCell>
													<div className={styles.tableHeader}>Product</div>
												</TableCell>
												<TableCell>
													<div className={styles.tableHeader}>Clicks & stock data</div>
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{paginatedData.map((item, index) => {
												return (
													<TableRow key={`clicks-to-sales-${index}`}>
														<TableCell>
															<div className={styles.tableCellColumn}>
																<p className={styles.tableText}>
																	{format(new Date(item.sales_date), 'dd-MM-yyyy')}
																</p>
															</div>
														</TableCell>
														<TableCell>
															<div className={styles.tableCellColumn}>
																<p className={styles.tableText}>
																	{shops.find((shop) => shop.id === item.shop_id).name}
																</p>
															</div>
														</TableCell>
														<TableCell>
															<div className={styles.tableCellColumn}>
																<p className={styles.tableTextBold}>{item.product_name}</p>
																<p className={styles.tableTextDetail}>
																	{item.barcode} -{' '}
																	{Number(item.price).toLocaleString('en-GB', {
																		style: 'currency',
																		currency:
																			shops.find((shop) => shop.id === item.shop_id)?.currency ||
																			'GBP'
																	})}
																</p>
															</div>
														</TableCell>
														<TableCell>
															<div className={styles.tableCellColumn}>
																<p className={styles.tableTextDetail}>
																	{item.attributed_clicks} click
																	{addS(item.attributed_clicks)}, {item.sales_from_clicks} suspected sale
																	{addS(item.sales_from_clicks)}
																</p>
															</div>
														</TableCell>
													</TableRow>
												);
											})}
										</TableBody>
									</Table>
									<TablePagination pages={pages} currentPage={currentPage} onPageChange={setCurrentPage} />
								</>
							)}
						</Switcher>
					</Stats.Card>
				);
			}}
		</DataWrapper>
	);
};

export const ClicksToSales = () => {
	const { data: shops } = useShop();
	const [shopIds, setShopIds] = useState(shops.map((shop) => shop.id));

	return (
		<>
			<Page.Header>
				<Link to="..">
					<Page.BackButton>Reports</Page.BackButton>
				</Link>
				<h1>Clicks to Sales</h1>
				<p>These are your products that have been clicked on and sold in-store within 24 hours.</p>
				<ReportFilters>
					<DatePicker />
					<LocationFilter shops={shops} selectedShops={shopIds} setSelectedShops={setShopIds} />
				</ReportFilters>
			</Page.Header>
			<Page.Section>
				<ClicksToSalesCard shops={shops} shopIds={shopIds} />
			</Page.Section>
		</>
	);
};
