import React, { useState } from 'react';
import { DropdownMenu, DropdownItem } from '@nearst/ui';

import * as Stats from '@components/StatsCard';
import InsightsWrapper from '@components/Graphs/DataWrapper';
import TimeSeriesGraph from '@components/Graphs/TimeSeriesGraph';
import { useInsightsDate } from '@services/InsightsDateProvider';
import styles from '../Cards.module.scss';

const getTotalViews = (row) => {
	return row['search_views_desktop'] + row['search_views_mobile'] + row['map_views_desktop'] + row['map_views_mobile'];
};
const getSearchViews = (row) => {
	return row['search_views_desktop'] + row['search_views_mobile'];
};
const getMapsViews = (row) => {
	return row['map_views_desktop'] + row['map_views_mobile'];
};

const getTotalClicks = (row) => {
	return row['website_clicks'] + row['phone_clicks'] + row['directions_clicks'];
};

const TotalClicks = ({ shopIds }) => {
	const { from, to, lookbackPeriod, interval } = useInsightsDate();
	const query = [
		{
			table: 'gmb',
			from,
			to,
			id: shopIds,
			interval,
			columns: ['website_clicks', 'phone_clicks', 'directions_clicks', 'date'],
			sort: [{ column: 'date', order: 'asc' }],
			sum: ['website_clicks', 'phone_clicks', 'directions_clicks']
		},
		{
			table: 'gmb',
			from: lookbackPeriod.from,
			to: lookbackPeriod.to,
			id: shopIds,
			interval,
			columns: ['website_clicks', 'phone_clicks', 'directions_clicks', 'date'],
			sort: [{ column: 'date', order: 'asc' }],
			sum: ['website_clicks', 'phone_clicks', 'directions_clicks']
		}
	];

	return (
		<InsightsWrapper query={query} hideError>
			{([data, previousData]) => {
				const offset = previousData.length - data.length;

				const totalClicks = data.reduce((acc, value) => acc + getTotalClicks(value), 0);
				const previousTotalClicks = previousData.reduce((acc, value) => acc + getTotalClicks(value), 0);
				const websiteClicks = data.reduce((acc, value) => acc + value.website_clicks, 0);
				const previousWebsiteClicks = previousData.reduce((acc, value) => acc + value.website_clicks, 0);
				const directionsClicks = data.reduce((acc, value) => acc + value.directions_clicks, 0);
				const previousDirectionsClicks = previousData.reduce((acc, value) => acc + value.directions_clicks, 0);
				const phoneClicks = data.reduce((acc, value) => acc + value.phone_clicks, 0);
				const previousPhoneClicks = previousData.reduce((acc, value) => acc + value.phone_clicks, 0);

				return (
					<>
						<div className={styles.stats}>
							<Stats.Block
								description="Total clicks"
								currentValue={Number(totalClicks)}
								previousValue={Number(previousTotalClicks)}
							/>
							<Stats.Block
								description="Directions clicks"
								currentValue={Number(directionsClicks)}
								previousValue={Number(previousDirectionsClicks)}
							/>
							<Stats.Block
								description="Website clicks"
								currentValue={Number(websiteClicks)}
								previousValue={Number(previousWebsiteClicks)}
							/>
							<Stats.Block
								description="Phone clicks"
								currentValue={Number(phoneClicks)}
								previousValue={Number(previousPhoneClicks)}
							/>
						</div>
						<div className={styles.maxGraphWrapper}>
							<TimeSeriesGraph
								series={data.map((insight, index) => {
									const previous = previousData[index + offset];
									return {
										date: insight.date,
										value: getTotalClicks(insight),
										previousDate: previous?.date,
										previousValue: previous ? getTotalClicks(previous) : 0
									};
								})}
								yAxisLabel="Profile clicks"
								height={250}
							/>
						</div>
					</>
				);
			}}
		</InsightsWrapper>
	);
};

const TotalViews = ({ shopIds }) => {
	const { from, to, lookbackPeriod, interval } = useInsightsDate();
	const query = [
		{
			table: 'gmb',
			from,
			to,
			id: shopIds,
			interval,
			columns: ['map_views_desktop', 'map_views_mobile', 'search_views_desktop', 'search_views_mobile', 'date'],
			sort: [{ column: 'date', order: 'asc' }],
			sum: ['map_views_desktop', 'map_views_mobile', 'search_views_desktop', 'search_views_mobile']
		},
		{
			table: 'gmb',
			from: lookbackPeriod.from,
			to: lookbackPeriod.to,
			id: shopIds,
			interval,
			columns: ['map_views_desktop', 'map_views_mobile', 'search_views_desktop', 'search_views_mobile', 'date'],
			sort: [{ column: 'date', order: 'asc' }],
			sum: ['map_views_desktop', 'map_views_mobile', 'search_views_desktop', 'search_views_mobile']
		}
	];

	return (
		<InsightsWrapper query={query} hideError>
			{([data, previousData]) => {
				const offset = previousData.length - data.length;

				const totalViews = data.reduce((acc, value) => acc + getTotalViews(value), 0);
				const previousTotalViews = previousData.reduce((acc, value) => acc + getTotalViews(value), 0);
				const searchViews = data.reduce((acc, value) => acc + getSearchViews(value), 0);
				const previousSearchViews = previousData.reduce((acc, value) => acc + getSearchViews(value), 0);
				const mapsViews = data.reduce((acc, value) => acc + getMapsViews(value), 0);
				const previousMapsViews = previousData.reduce((acc, value) => acc + getMapsViews(value), 0);

				return (
					<>
						<div className={styles.stats}>
							<Stats.Block
								description="Total views"
								currentValue={Number(totalViews)}
								previousValue={Number(previousTotalViews)}
							/>
							<Stats.Block
								description="Search views"
								currentValue={Number(searchViews)}
								previousValue={Number(previousSearchViews)}
							/>
							<Stats.Block
								description="Maps views"
								currentValue={Number(mapsViews)}
								previousValue={Number(previousMapsViews)}
							/>
						</div>
						<div className={styles.maxGraphWrapper}>
							<TimeSeriesGraph
								series={data.map((insight, index) => {
									const previous = previousData[index + offset];
									return {
										date: insight.date,
										value: getTotalViews(insight),
										previousDate: previous?.date,
										previousValue: previous ? getTotalViews(previous) : 0
									};
								})}
								yAxisLabel="Profile views"
								height={250}
							/>
						</div>
					</>
				);
			}}
		</InsightsWrapper>
	);
};

export const TotalPerformanceCard = ({ shopIds }) => {
	const [selectedMeasure, setSelectedMeasure] = useState('views');
	const { selectedRange } = useInsightsDate();

	return (
		<Stats.Card>
			<div className={styles.cardHeader}>
				<div>
					<Stats.Title>Google Business Profile performance</Stats.Title>
					<Stats.Subtitle>{selectedRange}</Stats.Subtitle>
				</div>
				<DropdownMenu primary={false} buttonText={selectedMeasure === 'views' ? 'Views' : 'Clicks'}>
					<DropdownItem active={selectedMeasure === 'views'} onClick={() => setSelectedMeasure('views')}>
						Views
					</DropdownItem>
					<DropdownItem active={selectedMeasure === 'clicks'} onClick={() => setSelectedMeasure('clicks')}>
						Clicks
					</DropdownItem>
				</DropdownMenu>
			</div>
			{selectedMeasure === 'views' ? <TotalViews shopIds={shopIds} /> : <TotalClicks shopIds={shopIds} />}
		</Stats.Card>
	);
};
