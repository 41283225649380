import React from 'react';
import useSWR from 'swr';
import { groupBy } from 'lodash';

import { Page } from '@nearst/ui';
import ChannelCard from '../components/ChannelCard';
import { useShop } from '@services/ShopProvider';
import { getChannels } from '@services/channels';

import styles from './ChannelsDashboard.module.scss';

const ChannelsDashboard = () => {
	const { shop } = useShop();
	const { data } = useSWR(`channels-${shop.id}`, () => getChannels([shop]), { suspense: true });

	const categorisedChannels = groupBy(
		data.filter((channel) => !!channel.category),
		'category'
	);

	return (
		<>
			<Page.Header>
				<h1>Channels</h1>
				<p>Choose where your products are shown online.</p>
			</Page.Header>
			<Page.Section>
				{Object.entries(categorisedChannels).map(([category, channels]) => (
					<>
						<h3 className={styles.sectionTitle}>{category}</h3>
						<p className={styles.sectionSubtitle}>{channels[0].categorySubtitle}</p>
						<div className={styles.cards} data-cy="channels-tiles">
							{channels
								.sort((a, b) => b.enabledShops.length - a.enabledShops.length)
								.map((channel) => (
									<ChannelCard key={channel.id} shop={shop} channel={channel} />
								))}
						</div>
					</>
				))}
			</Page.Section>
		</>
	);
};

export default ChannelsDashboard;
