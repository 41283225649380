import React from 'react';

import { Page } from '@nearst/ui';

import { useShop } from '@services/ShopProvider';
import { getInventoryConnection } from '@services/onboarding';

import HelpButton from '@components/HelpButton';
import UpgradeRequiredProductsBanner from '@components/UpgradeRequiredProductsBanner';
import FeedDiagnostics from '@components/Feed/FeedDiagnostics';
import FeedQuality from '@components/Feed/FeedQuality';

import ConnectorStatus from '../components/ConnectorStatus';
import StockMovement from '../components/StockMovement';
import styles from './FeedOverview.module.scss';

const FeedOverview = () => {
	const { shop } = useShop();

	const { status } = getInventoryConnection(shop.stockInventorySource?.latestIngest);
	const excludedRecords = shop.stockInventorySource?.latestIngest?.excludedRecords || 0;

	return (
		<>
			<Page.Header>
				<Page.HeaderCta>
					<h1>Feed overview</h1>
					<HelpButton href="https://support.near.st/en/articles/8911483-understanding-feed-and-item-issues">
						Help center
					</HelpButton>
				</Page.HeaderCta>
			</Page.Header>
			<Page.Section>
				{shop.plan === 'freemium' && excludedRecords > 0 ? (
					<UpgradeRequiredProductsBanner unprocessedStock={excludedRecords} />
				) : null}

				<div>
					<div className={styles.feedOverviewSection}>
						<ConnectorStatus shop={shop} />
						<StockMovement shop={shop} />
					</div>

					{status !== 'disconnected' ? (
						<>
							<FeedQuality />
							<FeedDiagnostics />
						</>
					) : null}
				</div>
			</Page.Section>
		</>
	);
};

export default FeedOverview;
