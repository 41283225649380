import React from 'react';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { Icon, Stack } from '@nearst/ui';
import Pills from '../Pills';
import { useInventory } from '@services/stock';
import { formatPrice } from '@utils/currency';
import { removeCid } from '../utils';

import styles from './InventoryViewer.module.scss';

const MobileCell = ({ row }) => (
	<div className={styles.summary}>
		<Stack space="0.25rem">
			<p>{row.title}</p>
			<p className={styles.greyText}>{removeCid(row.barcode)}</p>
			<p className={styles.greyText}>
				{formatPrice(row.price, row.currency)} - {row.quantity} in stock
			</p>
			<Pills>{row}</Pills>
		</Stack>
		<Icon className={styles.summaryIcon}>chevron_right</Icon>
	</div>
);

const MobileTable = ({ shop }) => {
	let navigate = useNavigate();
	const { inventory } = useInventory(shop.id);

	return (
		<Table className={styles.mobileTable} role="table">
			<TableBody>
				{inventory &&
					inventory.map((inventory) => {
						const row = {
							...inventory,
							hidden: !inventory.enabled,
							edited: inventory.productSource === 'myshop',
							quantity: inventory.quantity_i,
							hasIssues: inventory.issues?.length > 0
						};
						return (
							<TableRow
								onClick={() => navigate(`/inventory/${shop.id}/items/${row.barcode}`)}
								key={row.barcode}
								data-testid="inventory-table-row__mobile"
							>
								<TableCell>
									<MobileCell row={{ ...row, currency: shop.currency }} />
								</TableCell>
							</TableRow>
						);
					})}
			</TableBody>
		</Table>
	);
};

export default MobileTable;
