import { useState } from 'react';
import { Filter } from '@nearst/ui';

const statusFilters = {
	pending: {
		value: 'pending',
		checked: false,
		label: 'Pending'
	},
	confirmed: {
		value: 'confirmed',
		checked: false,
		label: 'Confirmed'
	},
	completed: {
		value: 'completed',
		checked: false,
		label: 'Completed'
	},
	cancelled: {
		value: 'cancelled',
		checked: false,
		label: 'Cancelled'
	}
};

const filterMap = {
	pending: ['paid', 'reserved'],
	confirmed: ['confirmed'],
	completed: ['completed'],
	cancelled: ['cancelled', 'refunded', 'refund_failed']
};

const OrderFilter = ({ setAppliedFilters }) => {
	const [selectedFilters, setSelectedFilters] = useState({});
	const [filterCount, setFilterCount] = useState(0);

	const handleFilterChange = (event) => {
		setSelectedFilters({ ...selectedFilters, [event.target.name]: event.target.checked });
	};

	const handleOnReset = () => {
		setSelectedFilters({});
		setAppliedFilters([]);
		setFilterCount(0);
	};

	const handleApplyClick = () => {
		setFilterCount(Object.values(selectedFilters).filter(Boolean).length);
		setAppliedFilters(
			Object.entries(selectedFilters)
				.map(([name, checked]) => checked && filterMap[name])
				.filter(Boolean)
				.flat()
		);
	};

	return (
		<Filter.Dropdown
			text={`Order status${filterCount ? ` (${filterCount})` : ''}`}
			handleOnReset={handleOnReset}
			handleApplyClick={handleApplyClick}
			content={<Filter.Block filters={statusFilters} selectedFilters={selectedFilters} handleFilterChange={handleFilterChange} />}
		/>
	);
};

export default OrderFilter;
