import React from 'react';
import { RouterProvider } from 'react-router-dom';

import { ClerkProvider } from '@clerk/clerk-react';
import { Root } from '@nearst/ui';

import ErrorBoundary from './components/ErrorBoundary';

import { clerkInstance } from './services/auth';
import IntercomProvider from './services/Intercom/Provider';

import 'tippy.js/dist/tippy.css';
import ClerkTheme from './ClerkTheme.module.scss';

// import must come after ClerkTheme import for style overrides
import { router } from './router.jsx';

export default function App() {
	return (
		<IntercomProvider>
			<Root className={ClerkTheme.theme}>
				<ErrorBoundary>
					<ClerkProvider Clerk={clerkInstance}>
						<RouterProvider router={router} />
					</ClerkProvider>
				</ErrorBoundary>
			</Root>
		</IntercomProvider>
	);
}
