import React from 'react';
import useSWR from 'swr';
import { useLocation } from 'react-router-dom';

import { Card } from '@nearst/ui';

import CustomerDetails from '../../components/CustomerDetails';
import SubscriptionDetails from '../../components/SubscriptionDetails';
import ErrorBoundary from '../../../../components/ErrorBoundary';

import { useShop } from '@services/ShopProvider';
import { getCustomer } from '@services/billing';

const BillingSettings = () => {
	const location = useLocation();

	const { shop, data } = useShop();
	const shopId = shop.id || data[0].id;

	const { data: customer, mutate } = useSWR([shopId, 'customer'], getCustomer);

	return (
		<>
			{location.search.includes('upgradeSuccess') && (
				<Card className="upgrade-successful">
					<strong>Your plan has been upgraded 🎉</strong>
					<p>Your subscription on the new plan is now active. Please contact us if you have any questions!</p>
				</Card>
			)}

			<Card title="Payment details" sectioned>
				<ErrorBoundary>
					<CustomerDetails customer={customer} mutate={mutate} shopId={shopId} shopPlan={shop.plan} />
				</ErrorBoundary>
			</Card>

			<Card title="Subscription" sectioned>
				<ErrorBoundary>
					<SubscriptionDetails customer={customer} />
				</ErrorBoundary>
			</Card>
		</>
	);
};

export default BillingSettings;
