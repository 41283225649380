import React from 'react';
import { Link } from 'react-router-dom';
import { Page } from '@nearst/ui';

import Disabled from './Disabled';
import Enabled from './Enabled';
import { useShop } from '@services/ShopProvider';

import styles from './ClicksToSales.module.scss';

const ClicksToSales = () => {
	const { shop } = useShop();
	const enabled = shop.totalAdsBudget;

	return (
		<>
			<Page.Header>
				<Link to={`/dashboard/${shop.id}`}>
					<Page.BackButton>Dashboard</Page.BackButton>
				</Link>
				<h1 className={styles.header}>Clicks to sales</h1>
				<p>
					Based on your clicks data and changes in your stock, we match online clicks with suspected sales in the following 24
					hour period.
				</p>
			</Page.Header>
			<Page.Section>{enabled ? <Enabled shop={shop} /> : <Disabled shop={shop} />}</Page.Section>
		</>
	);
};

export default ClicksToSales;
